import {useEffect, useRef, useState} from "react";
import cn from "classnames";

import Svg from "../../Svg";

import {getTimeComponents} from "./helpers/formatTimeString";

import "./SettingsTimePicker.scss";

const SettingsTimePicker = ({
                                label,
                                labelHidden = false,
                                icon,
                                value,
                                disabled,
                                onChange,
                                readOnly,
                                ...rest
                            }) => {
    const initialRender = useRef(true);
    const [timestring12, setTimestring12] = useState("");
    const [daytime, setDaytime] = useState(null);

    const [error, setError] = useState(false);

    useEffect(() => {
        const {timestring12, daytime: updatedDaytime} = getTimeComponents(value);

        setTimestring12(timestring12);
        setDaytime(updatedDaytime);
    }, [value]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }

        const {timestring24} = getTimeComponents(timestring12, daytime);

        if (
            timestring24 === value ||
            timestring24 === null ||
            timestring24.length !== 5
        )
            return;

        onChange(timestring24);
    }, [timestring12, daytime]);

    const handleInputChange = (e) => {
        const value = e.target.value;

        const {timestring12, daytime: updatedDaytime} = getTimeComponents(
            value,
            daytime
        );

        setTimestring12(timestring12);
        setDaytime(updatedDaytime);
        setError(timestring12 !== "" && timestring12.length !== 5);
    };

    const handleDaytimeToggle = () => {
        setDaytime((state) => (state === "PM" ? "AM" : "PM"));
    };

    return (
        <div
            className={cn("SettingsTimePicker", {
                "SettingsTimePicker--error": error,
                "SettingsTimePicker--iconed": icon,
            })}
        >
            <label
                className={cn("SettingsTimePicker-label", {
                    "SettingsTimePicker-label--hidden": labelHidden,
                })}
                htmlFor={rest.id}
            >
                {label}
            </label>
            <div className="SettingsTimePicker-field">
                {icon && (
                    <span className="FormSelect-fieldIcon">
            <Svg {...icon} />
          </span>
                )}
                <input
                    className="SettingsTimePicker-input"
                    value={timestring12}
                    onChange={handleInputChange}
                    readOnly={disabled || readOnly}
                    {...rest}
                />
                <button
                    className="SettingsTimePicker-daytimeToggler"
                    disabled={disabled || readOnly}
                    onClick={handleDaytimeToggle}
                >
                    {daytime}
                </button>
            </div>
        </div>
    );
};

export default SettingsTimePicker;
