export default {
  GeneralFirstService: [
    {
      id: "conditionBlock-01",
      label: "Communication will start if:",
      conditions: [
        {
          id: "condition-0101",
          conditionText: "The vehicle purchase date was ${numDays} days ago",
          description:
            "Choose how long after a customer’s vehicle purchase that the initial email will go out. It’s recommended to target customers between 90 and 110 days. Please note: we cannot target customers beyond 180 days, or six months.",
          placeholders: [
            {
              name: "numDays",
              type: "number",
            },
          ],
          hasCheckbox: true,
          checkable: false,
        },
        {
          id: "condition-0102",
          conditionText:
            "An appointment has not been scheduled at your dealership",
          description:
            "Exclude customers who already have a service appointment scheduled with your service department.",
          placeholders: [],
          hasCheckbox: true,
          checkable: true,
          key: "AppointmentOccurredOrAskedMarked",
        },
        {
          id: "condition-0103",
          conditionText: "The selected services have been performed",
          description:
            "Exclude customers who have already had their first services done. Click “Select Services,” then click “Select All”, and de-select all OP codes that are related to a first service appointment at your dealership.",
          placeholders: [],
          hasCheckbox: true,
          checkable: false,
          opCodeSelector: {
            type: "start",
            label: "SELECT SERVICES",
          },
        },
      ],
    },
    {
      id: "conditionBlock-02",
      label: "Communication will stop if:",
      conditions: [
        {
          id: "condition-0201",
          conditionText:
            "An appointment has been scheduled or has occurred during the First Service initiative",
          placeholders: [],
          hasCheckbox: true,
          checkable: true,
          key: "AppointmentNeverOccurredOrAskedMarked",
        },
        // {
        //   id: "condition-0202",
        //   conditionText:
        //     "IF There has been ANY of the services performed, except",
        //   placeholders: [],
        //   hasCheckbox: true,
        //   checkable: false,
        //   opCodeSelector: {
        //     type: "interruption",
        //     label: "SELECT SERVICES",
        //   },
        // },
      ],
    },
  ],
};
