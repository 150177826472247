import cn from "classnames";

const prepareWidgets = (widgets) => {
  if (!widgets) return null;

  return widgets.map((widget) => {
    const { key } = widget;

    return (
      <div key={key} className={cn("Header-widget", `Header-widget--${key}`)}>
        {widget}
      </div>
    );
  });
};

export default prepareWidgets;
