import { FormControlLabel, FormGroup, InputAdornment, makeStyles, Slider, TextField, withStyles } from "@material-ui/core";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import DatePicker from "../../DatePicker/DatePicker";
import Svg from "../../../../Svg/Svg";
import { IOSSwitch } from "../../Toogle/Toggle";
import FormElement from "../../../../FormElement/FormElement";
import { useEffect, useState } from "react";
import moment from "moment";
import "./SpecialBusinessForm.scss";
import { EditLocationOutlined } from "@material-ui/icons";

const useSliderStyles = makeStyles({
    root: {
      width: 392,
      color: 'var(--s90)',
      height: 8,
      padding: 0,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      marginTop: -7,
      marginLeft: -7,
      border: '2px solid var(--s90)',
      boxShadow: '#ebebeb 0 2px 2px',
      '&:focus, &:hover, &$active': {
        boxShadow: '#ccc 0 2px 20px 5px',
      }
    },
    track: {
      height: 8,
    },
    rail: {
      height: 8,
      opacity: 0.5,
      backgroundColor: '#ccc',
      borderRadius: 100
    },
    valueLabel: {
      left: 'calc(-50%)',
  
    },
});

const StyledValueLabel = withStyles({
    circle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 70, // Height and Width should be equal
      height: 10,
      backgroundColor: "transparent",
      borderRadius: 0,
      marginTop: 20,
      marginLeft: -12
    },
    label: {
      color: "black"
    }
})(ValueLabel);

const SpecialBusinessForm = ({ hoursArr, setHolidaysArr, setChangeStatus }) => {
    const sliderClasses = useSliderStyles();

    const [toggle, setToggle] = useState(true);
    const [sliderValue, setSliderValue] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [weekdays, setWeekdays] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const toggleHandleChange = (name, value) => {
        const workHoursObj = value ? { start_time: 720, end_time: 1080 } : null
        const arr = hoursArr.holidays.map(el => el.name === name ? { ...el, open: value, work_hours: workHoursObj } : { ...el });

        setHolidaysArr({ ...hoursArr, holidays: [ ...arr ]});
        setChangeStatus(true);
    };

    const eventNameHandler = (value, index) => {
            const arr = hoursArr.holidays.map((el, i) => i === index ? { ...el, name: value } : { ...el });

            setHolidaysArr({ ...hoursArr, holidays: [ ...arr ]});
            setChangeStatus(true);
    }

    const convertTime = (obj) => {
        if(obj){
            return [ obj.start_time, obj.end_time ];
        }else{
            return [720, 1080];
        }
      }
    
      const sliderHandleChange = (event, newValue, name, index) => {
        const arr = hoursArr.holidays.map((el, i) => {
            if(i === index){
                return { ...el, work_hours: { ...el.work_hours, start_time: newValue[0], end_time: newValue[1] } }
            }
    
            return { ...el }
        });
        
        setHolidaysArr({ ...hoursArr, holidays: [ ...arr ]});
        setChangeStatus(true);
      };

      const deleteCustomHandler = (index) => {
        const arr = hoursArr.holidays.splice(index, 1)
        setHolidaysArr({ ...hoursArr, holidays: [ ...hoursArr.holidays ]});
        setChangeStatus(true);
      }

    return(
        <>
        <div className="SpecialBusiness_form">
            {
                !!hoursArr?.holidays?.length &&
                hoursArr?.holidays.map((el, index) => (
                    <div key={index} className="SpecialBusiness_elements">
                        <div className="BusinessHoursName">
                            <FormElement
                                type="textfield"
                                label="Event Name"
                                id={el.name}
                                name={el.name}
                                readOnly={!el.custom}
                                labelHidden={!el.custom}
                                value={el.name}
                                placeHolder={el.name}
                                onChange={(e) => eventNameHandler(e.target.value, index)}
                                /* errorMessage = { !el.name ? "error" : ""} */
                            />
                        </div>  
                        <div className="BusinessHoursDate">
                            <DatePicker key={el.name} dateValue={el.date ? new Date(el.date) : ""} eventIndex={index} eventName={el.name} hoursArr={hoursArr} setHolidaysArr={setHolidaysArr} />
                        </div>
                        <div className="SpecialBusiness_form_isOpen">
                            <FormGroup>
                                <FormControlLabel
                                    className={"switchFormControlLabel"}
                                    control={<IOSSwitch checked={el.open} onChange={() => toggleHandleChange(el.name, !el.open, index)} name="Toggle" />}
                                    label={el.open ? "Open" : "close"}
                                />
                            </FormGroup>
                        </div>
                        <Slider
                            classes={sliderClasses}
                            value={convertTime(el.work_hours)}
                            onChange={(e,value) => sliderHandleChange(e, value, el.name, index)}
                            valueLabelDisplay="on"
                            aria-labelledby="range-slider"
                            ValueLabelComponent={StyledValueLabel}
                            valueLabelFormat={value => <div style={{ fontSize: "14px" }}>{moment(`${Math.floor(value / 60)}.${value % 60}`, ["HH.mm"]).format("hh:mm a")}</div>}
                            step={30}
                            min={0}
                            max={1439}
                            style={{ display: !el.open ? "none": "block" }}
                        />
                        <div>
                            {
                                el.custom &&
                                <button type="button" className="BusinessHoursDate-action BusinessHoursDate-action--delete" onClick={() => deleteCustomHandler(index)}>
                                    <Svg w="18" h="18" i="garbage" />
                                </button>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
        </>
    )
}


export default SpecialBusinessForm;