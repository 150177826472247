import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../Table";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import {
  chooseConversationsEndpoint,
  chooseConversationsSource,
  chooseConversationLeadType,
} from "../../../store/actions/conversationActions";

import tableColumnsLeads from "./tableColumnsLeads";

import { formatToDateString } from "../../../utils/formatDate";

import "./DashboardSources.scss";

const DashboardSources = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dateRange = useSelector((state) => state.date);

  const { data, status, error } = useData(
    "leadSourceStatistics",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const handleOpenSourceLeads = (sourceId) => {
    const leadType = data.filter((item) => item.source_name === sourceId)[0]
      .lead_type;
    dispatch(chooseConversationsEndpoint("newBySourceLeads"));
    dispatch(chooseConversationsSource(sourceId));
    dispatch(chooseConversationLeadType(leadType));
    history.push("conversations");
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    return (
      <>
        <Table
          columns={tableColumnsLeads}
          rows={data}
          options={{ fixedLayout: true }}
          disabled={true}
          events={{
            rows: [
              {
                eventName: "onClick",
                eventHandler: handleOpenSourceLeads,
                id: "source_name",
              },
            ],
          }}
        />
        {data.length === 0 && (
          <div className="Sources-coming-soon">
            Campaign data will appear here
          </div>
        )}
      </>
    );
  };

  return render();
};

export default DashboardSources;
