const PageHealth = () => {
  const render = () => {
    return (
      <h1>Health OK</h1>
    );
  };

  return render();
};

export default PageHealth;
