export default [
  {
    type: "textfield",
    label: "User Name:",
    id: "userName",
    name: "userName",
    key: "Username",
    disabled: true,
  },
  {
    type: "textfield",
    label: "Role:",
    id: "userRole",
    name: "userRole",
    key: "Role",
    disabled: true,
  },
  {
    type: "textfield",
    label: "E-mail:",
    id: "userEmail",
    name: "userEmail",
    key: "Email",
  },

  {
    type: "password",
    label: "New password:",
    id: "userNewPassword",
    name: "userNewPassword",
    key: "NewPassword",
  },
  {
    type: "password",
    label: "Old password:",
    id: "userOldPassword",
    name: "userOldPassword",
    key: "OldPassword",
  },
];
