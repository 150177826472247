import useRealVh from "../../hook/useRealVh";
import Content from "../Content";

function App() {
  console.log("### App Starting");
  useRealVh();
  return <Content />;
}

export default App;
