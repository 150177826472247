import { useCallback, useEffect } from "react";

const useAutoClose = ({ setIsOpen, ref }) => {
  const handleClosure = useCallback(
    (event) => {
      return (
        ref.current && !ref.current.contains(event.target) && setIsOpen(false)
      );
    },
    [setIsOpen, ref]
  );

  useEffect(() => {
    window.addEventListener("click", handleClosure);
    window.addEventListener("focusin", handleClosure);

    return () => {
      window.removeEventListener("click", handleClosure);
      window.removeEventListener("focusin", handleClosure);
    };
  }, [handleClosure, ref]);
};

export default useAutoClose;
