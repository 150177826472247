import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
} from "date-fns";

import { createStaticRanges } from "react-date-range";

function prepareStaticRanges() {
  const staticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -1)),
        endDate: endOfDay(addDays(new Date(), -1)),
      }),
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -6)),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: "Last 30 days",
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -29)),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: startOfMonth(addMonths(new Date(), -1)),
        endDate: endOfMonth(addMonths(new Date(), -1)),
      }),
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: startOfMonth(addMonths(new Date(), -3)),
        endDate: endOfDay(new Date()),
      }),
    },
  ];

  return createStaticRanges(staticRanges);
}

export default prepareStaticRanges;
