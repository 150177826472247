import cn from "classnames";
import Loader from "../../../Loader/Loader";
import "./AIRegistered.scss";
import QuestionMark from "../../../Tile/QuestionMark/QuestionMark";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  chooseConversationsEndpoint,
  chooseConversationsMetricType,
  chooseConversationsSubMetricType,
} from "../../../../store/actions/conversationActions";

const offline = {
  acquired_phone_number: {
    label: "ACQUIRED PHONE NUMBERS",
    description:
      "Number of shoppers that purchased a vehicle from your dealership after engaging with the AI during the selected date range",
  },
  phone_call_requested: {
    label: "PHONE CALL REQUESTS",
    description:
      "Total gross revenue generated from AI-assisted vehicle sales during the selected date range",
  },
};

const AIRegistered = ({ type, status, error, dataPrimary, statusCode }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTileClick = (redirectObj) => {
    dispatch(chooseConversationsEndpoint(redirectObj?.endpoint));
    dispatch(chooseConversationsMetricType(redirectObj?.metric));
    dispatch(chooseConversationsSubMetricType(redirectObj?.sub_metric));
    history.push("conversations");
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  if (status === "pending") {
    return (
      <div className="AIRegistered errorContainer">
        <div className="errorHeader">
          <div className="cardError">
            <p>
              {offline?.acquired_phone_number?.label}{" "}
              <QuestionMark
                definition={offline?.acquired_phone_number?.description}
              />
            </p>
            <div className="error">
              <Loader />
            </div>
          </div>
          <div className="cardError">
            <p>
              {offline?.phone_call_requested?.label}{" "}
              <QuestionMark
                definition={offline?.phone_call_requested?.description}
              />
            </p>
            <div className="error">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    status === "error" ||
    (statusCode !== undefined && statusCode !== 200) ||
    status === "init"
  ) {
    return (
      <div className="AIRegistered errorContainer">
        <div className="errorHeader">
          <div className="cardError">
            <p>
              {offline?.acquired_phone_number?.label}{" "}
              <QuestionMark
                definition={offline?.acquired_phone_number?.description}
              />
            </p>
            <div className="error">
              <svg width="35px" height="30px" viewBox="0,0,256,256">
                <g
                  fill="#8777c6"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(8.53333,8.53333)">
                    <path d="M15,3c-0.83157,0 -1.54394,0.50672 -1.8457,1.22852l-10.85352,18.71875v0.00195c-0.19588,0.31554 -0.30003,0.67939 -0.30078,1.05078c0,1.10457 0.89543,2 2,2c0.04693,-0.0003 0.09383,-0.00226 0.14063,-0.00586l0.00391,0.00586h10.85547h10.85547l0.00391,-0.00781c0.04677,0.00425 0.09367,0.00686 0.14063,0.00781c1.10457,0 2,-0.89543 2,-2c-0.00039,-0.37206 -0.10455,-0.73663 -0.30078,-1.05273l-0.01562,-0.02734c-0.00065,-0.00065 -0.0013,-0.0013 -0.00195,-0.00195l-10.83594,-18.68945c-0.30177,-0.72179 -1.01413,-1.22852 -1.8457,-1.22852zM13.78711,11.35938h2.42578l-0.20117,6.47266h-2.02344zM15.00391,19.81055c0.822,0 1.31445,0.44227 1.31445,1.19727c0,0.741 -0.49245,1.18164 -1.31445,1.18164c-0.828,0 -1.32422,-0.44064 -1.32422,-1.18164c0,-0.755 0.49522,-1.19727 1.32422,-1.19727z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="cardError">
            <p>
              {offline?.phone_call_requested?.label}{" "}
              <QuestionMark
                definition={offline?.phone_call_requested?.description}
              />
            </p>
            <div className="error">
              <svg width="35px" height="30px" viewBox="0,0,256,256">
                <g
                  fill="#8777c6"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <g transform="scale(8.53333,8.53333)">
                    <path d="M15,3c-0.83157,0 -1.54394,0.50672 -1.8457,1.22852l-10.85352,18.71875v0.00195c-0.19588,0.31554 -0.30003,0.67939 -0.30078,1.05078c0,1.10457 0.89543,2 2,2c0.04693,-0.0003 0.09383,-0.00226 0.14063,-0.00586l0.00391,0.00586h10.85547h10.85547l0.00391,-0.00781c0.04677,0.00425 0.09367,0.00686 0.14063,0.00781c1.10457,0 2,-0.89543 2,-2c-0.00039,-0.37206 -0.10455,-0.73663 -0.30078,-1.05273l-0.01562,-0.02734c-0.00065,-0.00065 -0.0013,-0.0013 -0.00195,-0.00195l-10.83594,-18.68945c-0.30177,-0.72179 -1.01413,-1.22852 -1.8457,-1.22852zM13.78711,11.35938h2.42578l-0.20117,6.47266h-2.02344zM15.00391,19.81055c0.822,0 1.31445,0.44227 1.31445,1.19727c0,0.741 -0.49245,1.18164 -1.31445,1.18164c-0.828,0 -1.32422,-0.44064 -1.32422,-1.18164c0,-0.755 0.49522,-1.19727 1.32422,-1.19727z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="AIRegistered cardContainer">
      <div className="box1">
        <div className="cardLabel">
          {dataPrimary?.data?.acquired_phone_number?.label}{" "}
          <QuestionMark
            definition={
              dataPrimary?.data?.acquired_phone_number?.description
            }
          />
        </div>
        <div className="cardValue">
          <p
            className={`${
              dataPrimary?.data?.acquired_phone_number?.redirect &&
              "redirectLink"
            }`}
            onClick={() =>
              dataPrimary?.data?.acquired_phone_number?.redirect &&
              handleTileClick(
                dataPrimary?.data?.acquired_phone_number?.redirect
              )
            }
          >
            {dataPrimary?.data?.acquired_phone_number?.count === null ? (
              <span>&mdash;</span>
            ) : (
              dataPrimary?.data?.acquired_phone_number?.count
            )}
          </p>
        </div>
      </div>
      <div className="box2">
        <div className="cardLabel">
          {dataPrimary?.data?.phone_call_requested?.label}{" "}
          <QuestionMark
            definition={dataPrimary?.data?.phone_call_requested?.description}
          />
        </div>
        <div className="cardValue">
          <p
            className={`${
              dataPrimary?.data?.phone_call_requested?.redirect &&
              "redirectLink"
            }`}
            onClick={() =>
              dataPrimary?.data?.phone_call_requested?.redirect &&
              handleTileClick(dataPrimary?.data?.phone_call_requested?.redirect)
            }
          >
            {dataPrimary?.data?.phone_call_requested?.count === null ? (
              <span>&mdash;</span>
            ) : (
                dataPrimary?.data?.phone_call_requested?.count
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AIRegistered;
