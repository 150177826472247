import { useEffect, useState, useRef } from "react";

import cn from "classnames";

import FormElement from "../../FormElement/FormElement";
import Loader from "../../Loader/Loader";

import useData from "../../../hook/useData";

import "./SettingsPreferences.scss";
import { useSelector } from "react-redux";

const RESOURCE_NAME = "Settings:Preferences";

const Preferences = (props) => {
  const renderedCallback = props.renderedCallback || (() => {});
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);

  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  let policy =
    useSelector((state) => state?.user?.auth?.data?.user_info?.Policy) || [];
  if (typeof policy === "string") {
    policy = JSON.parse(policy);
  }
  const dealershipCountry =
    useSelector((state) => state?.user?.auth?.data?.dealerSettings?.Country) ||
    "";
  const policyItem = policy.find((item) => item.Resource === RESOURCE_NAME);
  const attributes =
    dealershipCountry !== "US"
      ? policyItem?.Attributes?.filter((item) => item.Id !== "ReportChannel")
      : policyItem?.Attributes;

  const preferences = attributes?.map((item) => item.Id);

  if (!preferences) {
    return <Loader />;
  }

  const { data: preferenceOptions, execute: fetchPreferenceOptions } = useData(
    "getPreferences",
    {
      Preferences: preferences,
      QueryType: "options",
    },
    [accountId]
  );

  const { data: preferenceValues, execute: fetchPreferenceValues } = useData(
    "getPreferences",
    {
      Preferences: preferences,
      QueryType: "values",
    },
    [accountId]
  );

  const { execute: updateExecute, status: updateStatus } = useData(
    "updatePreferences",
    form,
    [form],
    false
  );

  // useEffect(() => {
  //   if (dealershipCountry !== "US") {
  //     setPreferences((prevState) =>
  //       prevState.filter((item) => item !== "ReportChannel")
  //     );
  //   }
  // }, [dealershipCountry]);

  useEffect(() => {
    if (preferenceOptions && Array.isArray(preferenceOptions)) {
      setState((prevState) => {
        return {
          ...prevState,
          preferenceOptions,
        };
      });

      renderedCallback();
    }
  }, [preferenceOptions, accountId]);

  useEffect(() => {
    if (preferenceValues && Array.isArray(preferenceValues)) {
      const mappedValues = {};
      for (const preferenceValue of preferenceValues) {
        const { Id, Data } = preferenceValue;
        mappedValues[Id] = Data;
      }

      setForm((prevState) => {
        return {
          ...prevState,
          ...mappedValues,
        };
      });
    }
  }, [preferenceValues, accountId]);

  useEffect(() => {
    if (updateStatus === "success") {
      setState({});
      setLoading(false);
      fetchPreferenceOptions();
      fetchPreferenceValues();
    }
    if (updateStatus === "pending") {
      setLoading(true);
    }
  }, [updateStatus]);

  if (!state.preferenceOptions || !form) {
    return <Loader />;
  }

  const handleFormChange = (key, value) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleSubmit = () => {
    updateExecute();
  };

  return (
    <div className="SettingsPreferences-Container">
      {attributes.map(({ Id, Type, Name }) => {
        const options = preferenceOptions.find((item) => item.Id === Id);
        let optionsData = options?.Data;
        const valueId = form[Id];

        if (!optionsData || !Array.isArray(optionsData)) {
          return null;
        }

        let formattedOptions = optionsData.map((item) => ({
          id: item,
          label: item,
        }));

        const value = formattedOptions.find((item) => item.id === valueId);
        if (!value) {
          formattedOptions = [...formattedOptions];
          formattedOptions.unshift("");
        }

        return (
          <div className="SettingsPreferences-Select">
            <FormElement
              type={Type}
              label={Name}
              defaultValue={value}
              values={formattedOptions}
              onChange={(v) => handleFormChange(Id, v.id)}
              disabled={loading}
            />
          </div>
        );
      })}
      <div className="SettingsPreferences-Actions">
        {loading ? (
          <Loader />
        ) : (
          <FormElement
            type="submit"
            label="save"
            disabled={!form || loading}
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default Preferences;
