import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Tile from "../../Tile";
import { formatToDateString } from "../../../utils/formatDate";
import useData from "../../../hook/useData";

import { chooseConversationsEndpoint } from "../../../store/actions/conversationActions";

import "./DashboardTilesFI.scss";

const DashboardTiles = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dateRange = useSelector((state) => state.date);

  const {
    data: engagementStatisticsData,
    status: engagementStatisticsStatus,
    error: engagementStatisticsError,
  } = useData(
    "engagementStatistics",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: totalSalesData,
    status: totalSalesStatus,
    error: totalSalesError,
  } = useData(
    "totalSales",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: messagesOutData,
    status: messagesOutStatus,
    error: messagesOutError,
  } = useData(
    "messagesOut",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: acquirePhoneNumberData,
    status: acquirePhoneNumberStatus,
    error: acquirePhoneNumberError,
  } = useData(
    "acquiredPhoneNumbers",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: htoStatisticsData,
    status: htoStatisticsStatus,
    error: htoStatisticsError,
  } = useData(
    "htoStatistics",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const handleTileClick = (endpoint) => {
    dispatch(chooseConversationsEndpoint(endpoint));
    history.push("conversations");
  };

  return (
    <>
      <div className="TilesArea TilesArea--alpha TilesAreaFI--alpha">
        <Tile
          type="square"
          number="1"
          isMain="true"
          label="customers messaged"
          description="Number of customers the AI messaged during the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          dataSecondary={engagementStatisticsData}
          keyPrimary="customer_messaged"
          onClick={() => handleTileClick("newProcessedLeads")}
        />
        <Tile
          type="square"
          number="2"
          label="Engagement Rate"
          description="Percentage of all customers the AI messaged that have responded to one or more of the AI's messages during the selected date range. Note: this metric excludes unsubscribe and cancellation requests"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="engaged_leads"
          dataSecondary={engagementStatisticsData}
          keySecondary="engagement_rate"
          onClick={() => handleTileClick("engagedLeads")}
        />
        <Tile
          type="square"
          number="3"
          label="Cta Conversions"
          description="Number of customers that clicked on the CTA link during the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="cta_conversions"
          dataSecondary={engagementStatisticsData}
          keySecondary="cta_conversions_rate"
          onClick={() => handleTileClick("ctaConversions")}
        />
        <Tile
          type="square"
          number="4"
          label="Closed Deals"
          description="Number of customers who purchased a protection product from your dealership during the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          dataSecondary={engagementStatisticsData}
          keyPrimary="confirmed_purchases"
          keySecondary="purchase_rate"
          onClick={() => handleTileClick("confirmedPurchases")}
        />
        <Tile
          type="details"
          number="1"
          label="Total Messages Sent"
          description="Total number of email and text messages sent by the AI, as well as dealer reps using the Reply as Assistant feature, for the selected date range. Note: this metric includes both initial and follow-up email and text messages"
          status={messagesOutStatus}
          error={messagesOutError}
          dataPrimary={messagesOutData}
          dataSecondary={messagesOutData}
          keyPrimary="total_messages_out"
        />
        <Tile
          type="details"
          number="2"
          label="Email Open Rate"
          description="Number of emails, as well as the percentage of total emails, that were delivered, opened and presumably read during the selected date range"
          status={messagesOutStatus}
          error={messagesOutError}
          dataPrimary={messagesOutData}
          dataSecondary={messagesOutData}
          keyPrimary="opened_emails"
          keySecondary="opened_email_rate"
        />
        <Tile
          type="details"
          number="4"
          label="Gross Sales"
          description="Total gross revenue generated on closed deals for the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="confirmed_sales"
          currency="USD"
          onClick={() => handleTileClick("confirmedPurchases")}

        />
      </div>
      <div className="TilesArea TilesArea--beta">
        <Tile
          type="square"
          number="1"
          isMain="true"
          label="Business Office Hand-Offs"
          description="Number of opportunities the AI flagged as needing expertise from a dealer rep for the selected date range"
          status={htoStatisticsStatus}
          error={htoStatisticsError}
          dataPrimary={htoStatisticsData}
          keyPrimary="sales_expertise_needed"
          onClick={() => handleTileClick("businessOfficeHandOffs")}
        />
        <Tile
          type="details"
          number="1"
          label="Acquired Phone Numbers"
          description="Number of phone numbers the AI has acquired during conversation or confirmed using the original lead source for the selected date range"
          status={acquirePhoneNumberStatus}
          error={acquirePhoneNumberError}
          dataPrimary={acquirePhoneNumberData}
          dataSecondary={acquirePhoneNumberData}
          keyPrimary="acquired_phone_numbers"
          onClick={() => handleTileClick("acquiredPhoneNumbersLeads")}
        />
        <Tile
          type="details"
          number="2"
          label="Awaiting Rep Response"
          description="Number of opportunities requiring urgent sales expertise, as well as the percentage of business office handoffs, for the selected date range. These are customers that claim they have not yet been contacted by a dealer rep and require immediate attention"
          status={htoStatisticsStatus}
          error={htoStatisticsError}
          dataPrimary={htoStatisticsData}
          keyPrimary="losing_opportunities"
        />
      </div>
    </>
  );
};

export default DashboardTiles;
