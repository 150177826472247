import "./AppointmentModalLayout.scss";
import FormElement from "../../FormElement/FormElement";
import ChatFormModalButtonsGroup from "../ChatFormModalButtonsGroup/ChatFormModalButtonsGroup";
import { useEffect, useState } from "react";
import CloseIcon from "../../../assets/img/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  validateEmail,
  validatePhonenumber,
} from "../../../utils/fieldValidators";
import useData from "../../../hook/useData";
import { useParams } from "react-router-dom";
import { refetchChat } from "../../../store/actions/conversationDealerChatActions";
import DateAndTimePicker from "../../../ui/common/DateAndTimePicker/DateAndTimePicker";

const AppointmentModalLayout = ({
  closeModal,
  changeModalState,
  setIsShrinkClose,
  setShowSnackbar,
  setShrinkModal,
}) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  const cid = useSelector((state) => state.conversation.conversationsId);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const { cid: urlCid } = useParams();

  const formatJsDateToString = (jsDate) => {
    const dateValue = new Date(jsDate);
    const chosenDate = `${dateValue.getDate().toString().padStart(2, "0")}/${(
      dateValue.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${dateValue.getFullYear()}`;
    const chosenTime = `${dateValue
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${dateValue
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;
    setDate(chosenDate);
    setTime(chosenTime);
  };

  const validateForm = async () => {
    const errors = {};
    if (firstname === "" && lastname === "") {
      errors["firstname"] = "Firstname or lastname is required!";
      errors["lastname"] = "Firstname or lastname is required!";
    }
    if (email === "" && phoneNumber === "") {
      errors["email"] = "Email or phone number is required!";
      errors["phonenumber"] = "Email or phone number is required!";
    }
    if (email !== "" && !validateEmail(email)) {
      errors["email"] = "Incorrect email";
    }
    if (phoneNumber !== "" && !validatePhonenumber(phoneNumber)) {
      errors["phonenumber"] = "Phone number should be at least 10 digits!";
    }
    if (!date || !time) {
      errors["date"] = "Date and time is required!";
    }
    setValidationErrors(errors);
    if (Object.keys(errors)?.length === 0) {
      await createContactForm();
      const timer = setTimeout(async () => {
        dispatch(refetchChat(true));
      }, 2000);
      return () => clearTimeout(timer);
    }
  };

  const checkIfAllFieldsEmpty = () => {
    return (
      phoneNumber === "" && firstname === "" && lastname === "" && email === ""
    );
  };

  const { status: createContactFormStatus, execute: createContactForm } =
    useData(
      "createContactForm",
      {
        account_id: accountId,
        user_id: urlCid,
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone_number: phoneNumber,
        form_type: "AppointmentForm",
        date: date,
        time: time,
      },
      [accountId, urlCid, firstname, lastname, email, phoneNumber, date, time],
      false
    );

  useEffect(() => {
    if (createContactFormStatus === "success") {
      closeModal(false);
      setShrinkModal(false);
      changeModalState(false);
      setShowSnackbar(true);
    }
  }, [createContactFormStatus]);

  const { data: generalInfoDataFetched } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid],
    true
  );

  useEffect(() => {
    const splittedFullname = generalInfoDataFetched?.FullName?.split(" ");
    const splittedFirstname =
      splittedFullname && splittedFullname[0] ? splittedFullname[0] : "";
    const splittedLastname =
      splittedFullname && splittedFullname[1] ? splittedFullname[1] : "";
    if (generalInfoDataFetched) {
      setFirstname(splittedFirstname);
      setLastname(splittedLastname);
      setEmail(
        generalInfoDataFetched?.Email ? generalInfoDataFetched.Email : ""
      );
      setPhoneNumber(
        generalInfoDataFetched?.Phone ? generalInfoDataFetched.Phone : ""
      );
    }
  }, [generalInfoDataFetched]);

  return (
    <div className="AppoitmentForm_layout">
      <div className="CloseIconArea">
        <div
          className="CloseIcon"
          onClick={() => {
            if (checkIfAllFieldsEmpty()) {
              changeModalState(false);
            } else {
              closeModal(true);
              setShrinkModal(true);
            }
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <h1 className="AppointmentTitle">Schedule Appointment Form</h1>
      <p className="AppointmentDescription">
        Submit customer's information below to set an appointment
      </p>
      <form className="Appointment_form">
        <FormElement
          type="textfield"
          label="First name"
          id="first_name"
          name="first name"
          value={firstname}
          onChange={(event) => setFirstname(event.target.value)}
          error={!!validationErrors["firstname"]}
          errorMessage={validationErrors["firstname"]}
        />
        <FormElement
          type="textfield"
          label="Last name"
          id="last_name"
          name="last name"
          value={lastname}
          onChange={(event) => setLastname(event.target.value)}
          error={!!validationErrors["lastname"]}
          errorMessage={validationErrors["lastname"]}
        />
        <FormElement
          type="textfield"
          label="Email"
          id="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="user@domain.com"
          error={!!validationErrors["email"]}
          errorMessage={validationErrors["email"]}
        />
        <FormElement
          type="textfield"
          label="phone number (optional)"
          id="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
          placeholder="(123) 456-7890"
          error={!!validationErrors["phonenumber"]}
          errorMessage={validationErrors["phonenumber"]}
        />
        <DateAndTimePicker
          onChange={(name, value) => {
            formatJsDateToString(value);
          }}
          input={{
            label: "appointment.date_time",
            name: "date_time",
            type: "timestamp",
            required: true,
          }}
          error={!!validationErrors["date"]}
        />
        <ChatFormModalButtonsGroup
          validate={validateForm}
          changeModalState={changeModalState}
          setShrinkModal={setShrinkModal}
          closeModal={closeModal}
          setIsShrinkClose={setIsShrinkClose}
          checkIfAllFieldsEmpty={checkIfAllFieldsEmpty}
        />
      </form>
    </div>
  );
};

export default AppointmentModalLayout;
