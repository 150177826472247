import FormElement from "../FormElement";

import "./FormCheckboxes.scss";

const FormCheckboxes = ({ values, value, id, ...rest }) => {
  delete rest.type;

  const checkboxes =
    values &&
    values.map((checkbox) => {
      return (
        <FormElement
          key={checkbox.id}
          type="checkbox"
          label={checkbox.label}
          id={`${id}${checkbox.id}`}
          checked={value.indexOf(checkbox.value) !== -1}
          {...rest}
        />
      );
    });

  return <div className="FormCheckboxes">{checkboxes}</div>;
};

export default FormCheckboxes;
