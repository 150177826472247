export default [
  {
    id: "+04",
    label: "Georgia Standard Time +04:00",
  },
  {
    id: "+03",
    label: "Eastern European Daylight Time +03:00",
  },
  {
    id: "+02",
    label: "Eastern European Time, Central European Daylight Time +02:00",
  },
  {
    id: "+01",
    label: "Central European Time, Western European Daylight Time +01:00",
  },
  {
    id: "+00",
    label: "Western European Time, Greenwich Mean Time 00:00",
  },
  {
    id: "-01",
    label: "Eastern Greenland Time -01:00",
  },
  {
    id: "-02",
    label: "Brasilia Summer Time -02:00",
  },
  {
    id: "-03",
    label: "Atlantic Daylight Time -03:00",
  },
  {
    id: "-04",
    label: "Atlantic Standard Time, Eastern Daylight Time -04:00",
  },
  {
    id: "-05",
    label: "Eastern Standard Time, Central Daylight Time -05:00",
  },
  {
    id: "-06",
    label: "Central Standard Time, Mountain Daylight Time -06:00",
  },
  {
    id: "-07",
    label: "Mountain Standard Time, Pacific Daylight Time -07:00",
  },
  {
    id: "-08",
    label: "Pacific Standard Time, Alaska Daylight Time -08:00",
  },
  {
    id: "-09",
    label: "Alaska Standard Time -09:00",
  },
  {
    id: "-10",
    label: "Hawaii–Aleutian Standard Time -10:00",
  },
];
