import {
  FETCH_SUBSCRIPTIONS,
  FETCH_PRINCIPALS,
} from "../actionTypes/settingTypes";

const initialState = {
  notificationSubscriptions: null,
  notificationPrincipals: null,
};

const setting = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS: {
      return { ...state, notificationSubscriptions: action.payload };
    }

    case FETCH_PRINCIPALS: {
      return { ...state, notificationPrincipals: action.payload };
    }

    default:
      return state;
  }
};

export default setting;
