import React from "react";
import FormElement from "../../../../../../FormElement/FormElement";
import "./index.scss";

const SettingsModal = ({ component, close, accessConfig, refetch }) => {
  return (
    <div className="SettingsModal-Container">
      {React.cloneElement(component, { accessConfig, refetch, close })}
      <div className="SettingsModal-Actions">
        <FormElement onClick={close} label="close" type="submit" />
      </div>
    </div>
  );
};

export default SettingsModal;
