import "./index.scss";
import SettingsNotifications from "../../../../Settings/SettingsNotifications/SettingsNotifications";

const NotificationsSection = ({ form, setForm }) => {
  const getSelectedPersons = () =>
    new Set(form?.["Config"]?.["AssignedSubscriptionResources"] || []);

  const selected = (Id, Ids) => {
    for (const id of Ids) {
      if (id === Id) return true;
    }
    return false;
  };

  const selectPerson = (Id) => {
    let updatedSubscriptionIds = [
      ...form["Config"]["AssignedSubscriptionResources"],
    ];
    if (selected(Id, updatedSubscriptionIds)) {
      updatedSubscriptionIds = updatedSubscriptionIds.filter(
        (item) => item !== Id
      );
    } else {
      updatedSubscriptionIds.push(Id);
    }

    setForm({ AssignedSubscriptionResources: updatedSubscriptionIds });
  };

  return (
    <div className="NotificationsSection-Container">
      <div className="NotificationsSection-Settings-Container">
        <span className="NotificationsSection-BlockTitle">Notifications</span>
        <div className="NotificationsSection">
          <SettingsNotifications
            campaigns={true}
            selectPerson={selectPerson}
            selectedPersons={getSelectedPersons()}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationsSection;
