import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ChatsList from "./ChatsList";
import ChatsListCollapsed from "./ChatsListCollapsed";
import ChatsDialog from "./ChatsDialog";
import ChatsDetails from "./ChatsDetails";
import ChatsDetailsCollapsed from "./ChatsDetailsCollapsed";

import useData from "../../hook/useData";
import useWindowSize from "../../hook/useWindowSize";

import { chooseConversationsId } from "../../store/actions/conversationActions";

import "./Chats.scss";
import { switchProduct } from "../../store/actions/userActions";

const ChatLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const [detailsCollapsed, setDetailsCollapsed] = useState();
  const [chatsCollapsed, setListCollapsed] = useState(true);
  const { cid: urlCid } = useParams();
  const savedCid = useSelector((state) => state.conversation.conversationsId);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const selectedProduct = useSelector(
    (state) => state.user.auth?.data?.user_info?.selectedProduct
  );

  console.log("selected product is: ", selectedProduct);

  useEffect(async () => {
    if (cid) {
      try {
        const cid_info = JSON.parse(atob(cid));
        const product = cid_info["product"];
        console.log("product to switch: ", product);
        if (
          ["DEALER_CHAT", "OEM_CHAT"].includes(product) &&
          selectedProduct.Category === "SALES_AI"
        ) {
          await dispatch(
            switchProduct({
              Id: "DealerChat",
              Name: "DealerChat",
              Category: "CHAT_AI",
            })
          );
        }
      } catch (error) {
        console.log("error is: ", error);
      }
    }
  }, []);

  const cid = urlCid || savedCid || null;

  if (urlCid && urlCid !== savedCid) dispatch(chooseConversationsId(urlCid));

  if (cid && !urlCid) history.push(cid);

  useEffect(() => {
    setDetailsCollapsed(windowSize.width < 1280 ? true : false);
  }, [windowSize]);

  const {
    data,
    status,
    error,
    execute: fetchDialogue,
  } = useData(
    "dialogue",
    {
      conversation_id: cid,
    },
    [cid, accountId, selectedProduct]
  );

  const { data: leadNotifications, execute: fetchNotifications } = useData(
    "getNotificationsInvoked",
    {
      conversation_id: cid,
    },
    [cid, accountId]
  );

  const { data: leadEvents, execute: fetchEvents } = useData(
    "getEvents",
    {
      conversation_id: cid,
    },
    [cid, accountId]
  );

  const { data: stopBotEvents, execute: fetchStopBotEvents } = useData(
    "getStopBotEvents",
    {
      conversation_id: cid,
    },
    [cid, accountId]
  );

  const handleToggleListCollapsed = () => {
    if (windowSize.width < 768) {
      setDetailsCollapsed(true);
    }

    setListCollapsed((state) => {
      return !state;
    });
  };

  const handleToggleDetailsCollapsed = () => {
    if (windowSize.width < 768) {
      setListCollapsed(true);
    }

    setDetailsCollapsed((state) => {
      return !state;
    });
  };

  const refetchConversation = () => {
    fetchDialogue();
    fetchNotifications();
    fetchEvents();
    fetchStopBotEvents();
  };

  return (
    <div className="Chats">
      {
        <ChatsDialog
          data={data}
          status={status}
          error={error}
          leadNotifications={leadNotifications}
          leadEvents={leadEvents?.Items}
          stopBotEvents={stopBotEvents}
          refetchConversation={refetchConversation}
          cid={cid}
        />
      }

      {windowSize.width >= 768 || !chatsCollapsed ? (
        <ChatsList
          cid={cid}
          onToggleListCollapsed={handleToggleListCollapsed}
        />
      ) : (
        <ChatsListCollapsed onToggleListCollapsed={handleToggleListCollapsed} />
      )}

      {cid &&
        ((windowSize.width >= 1280 || !detailsCollapsed) &&
        !detailsCollapsed ? (
          <ChatsDetails
            cid={cid}
            onToggleDetailsCollapsed={handleToggleDetailsCollapsed}
          />
        ) : (
          <ChatsDetailsCollapsed
            cid={cid}
            onToggleDetailsCollapsed={handleToggleDetailsCollapsed}
          />
        ))}
    </div>
  );
};

export default ChatLayout;
