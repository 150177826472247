import {useEffect, useState, useRef} from "react";

import cn from "classnames";

import {getDateComponents} from "./helpers/formatDateString";

import "./SettingsDatePicker.scss";

const SettingsDatePicker = ({
                                label,
                                labelHidden = false,
                                value,
                                disabled,
                                onChange,
                                ...rest
                            }) => {
    const actualTime = useRef(null)
    const [datestring, setDatestring] = useState(value);
    const [error, setError] = useState(false);

    useEffect(() => {
        const {
            datestring: updatedDatestring,
            dayStr,
            monthStr,
            yearStr,
        } = getDateComponents(value.includes("mm") || value.includes("dd") || value.includes('yyyy') ? datestring : value);

        if (dayStr.length < 2 && dayStr !== "*") {
            setError(true);
            return;
        }
        if (monthStr.length < 2 && monthStr !== "*") {
            setError(true);
            return;
        }
        if (yearStr.length < 4 && yearStr !== "*") {
            setError(true);
            return;
        }

        setError(false);
        // if (actualTime.current.value === value) return;
        setDatestring(updatedDatestring)

    }, [value]);

    const handleInputChange = (e) => {
        const {
            datestring, dayStr,
            monthStr,
            yearStr
        } = getDateComponents(e.target.value);

        onChange({
            Day: dayStr,
            Month: monthStr,
            Year: yearStr,
        });

        actualTime.current = datestring;
        setDatestring(datestring);
    };

    return (
        <div
            className={cn("SettingsDatePicker", {
                "SettingsDatePicker--error": error,
                "SettingsDatePicker--disabled": disabled,
            })}
        >
            <label
                className={cn("SettingsDatePicker-label", {
                    "SettingsDatePicker-label--hidden": labelHidden,
                })}
                htmlFor={rest.id}
            >
                {label}
            </label>
            <input
                ref={actualTime}
                className="SettingsDatePicker-input"
                value={datestring}
                onChange={handleInputChange}
                {...rest}
            />
        </div>
    );
};

export default SettingsDatePicker;
