import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";

import "./UserManagementTable.scss";
import usersTableColumns from "./usersTableColumns";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  chooseUserManagementSnackbarState,
  chooseUserManagementTablePage,
} from "../../store/actions/userManagementActions";
import req from "../../utils/request";
import { useAuth0 } from "@auth0/auth0-react";

const UserManagementTable = () => {
  const dispatch = useDispatch();

  const { usersPage, usersSearch, snackbarState, reload } = useSelector(
    (state) => {
      return state.userManagement;
    }
  );
  const auth = useSelector((state) => state.user.auth.data);
  const { user: auth_user } = useAuth0();

  const [data, setData] = useState({ Items: [] });
  const [status, setStatus] = useState("init");

  // TODO: change retrieving all users when backend is ready

  // const [requestOptions, setRequestOptions] = useState({});
  // const {data: responseData, status, error} = useData("queryUsers", requestOptions, [requestOptions]);

  useEffect(async () => {
    setData({ Items: [] });
    setStatus("pending");

    const result = await req(
      "queryUsers",
      {
        dealership_id: auth.user_info.DealerId,
        impel_users:
          auth_user?.email?.endsWith("impel.io") ||
          auth_user?.email?.endsWith("impel.ai") ||
          auth_user?.email?.includes("super-admin") ||
          auth_user?.email?.includes("root-admin"),
      },
      auth?.access_token
    );
    if ([200, 201].includes(result.status)) {
      let filteredResponseItems;
      if (usersSearch) {
        const usersSearchLC = usersSearch.toLowerCase();
        filteredResponseItems = result.data["Items"].filter((item) => {
          return (
            item.FirstName.toLowerCase().includes(usersSearchLC) ||
            item.LastName.toLowerCase().includes(usersSearchLC) ||
            item.Email.toLowerCase().includes(usersSearchLC)
          );
        });
      } else {
        filteredResponseItems = result.data["Items"];
      }

      setData({ Items: filteredResponseItems });
      setStatus("success");
    } else {
      setStatus("error");
    }
  }, [usersPage, usersSearch, reload]);

  // retrieving users END

  useEffect(() => {
    if (data && data?.num_pages < usersPage) {
      dispatch(chooseUserManagementTablePage(1));
    }
  }, [data, usersPage, dispatch]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= data?.num_pages) {
      dispatch(chooseUserManagementTablePage(pageNumber));
    }
  };

  const handleAlertClose = () => {
    dispatch(
      chooseUserManagementSnackbarState({
        severity: "",
        message: "",
        open: false,
      })
    );
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    return (
      <>
        <Snackbar
          open={snackbarState?.open || false}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <SnackbarMessage
            elevation={2}
            variant="filled"
            onClick={handleAlertClose}
            severity={snackbarState?.severity}
          >
            {snackbarState?.message}
          </SnackbarMessage>
        </Snackbar>
        <Table
          sortArray={false}
          columns={usersTableColumns}
          rows={data && data.Items}
          options={{ fillParent: true, baseColumnWidth: 160 }}
        />
        {/*<div className="UserManagementTable-summary">*/}
        {/*  <div className="UserManagementTable-info">*/}
        {/*    <span className="UserManagementTable-infoLabel">Showing page </span>*/}
        {/*    {data.page_id} / {data.num_pages}*/}
        {/*  </div>*/}
        {/*  <div className="UserManagementTable-pagination">*/}
        {/*    <Pagination*/}
        {/*      current={data.page_id}*/}
        {/*      total={data.num_pages}*/}
        {/*      onPageChange={handlePageChange}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    );
  };

  return <div className="UserManagementTable">{render()}</div>;
};

export default UserManagementTable;
