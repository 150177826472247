import {
  CHOOSE_USER_MANAGEMENT_TABLE_PAGE,
  CHOOSE_USER_MANAGEMENT_QUERY,
  CHOOSE_USER_MANAGEMENT_SNACKBAR_STATE,
  UPDATE_USER_MANAGEMENT_RELOAD_COUNTER
} from "../actionTypes/userManagementTypes";

export const chooseUserManagementTablePage = (page) => {
  return {
    type: CHOOSE_USER_MANAGEMENT_TABLE_PAGE,
    payload: page,
  };
};

export const chooseUserManagementQuery = (query) => {
  return {
    type: CHOOSE_USER_MANAGEMENT_QUERY,
    payload: query,
  };
};

export const chooseUserManagementSnackbarState = (state) => {
  return {
    type: CHOOSE_USER_MANAGEMENT_SNACKBAR_STATE,
    payload: state,
  };
};

export const updateUserManagementReloadCounter = () => {
  return {
    type: UPDATE_USER_MANAGEMENT_RELOAD_COUNTER
  };
};
