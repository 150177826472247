import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import cn from "classnames";

import { chooseConversationsMode } from "../../../store/actions/conversationActions";

import "./ChatsListCard.scss";

const ChatsListCard = ({ data, cid }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleActiveChat = () => {
    dispatch(chooseConversationsMode("regular"));
    history.push(`${data.ConversationId}`);
  };

  return (
    <div
      className={cn("ChatsListCard", {
        "is-active": data.ConversationId === cid,
      })}
      onClick={handleActiveChat}
    >
      <h2 className="ChatsListCard-name">
        {data.FullName || `${data.Name} ${data.Surname}`}
      </h2>
      <div className="ChatsListCard-table">
        <div className="ChatsListCard-label">Channel:</div>
        <div className="ChatsListCard-value ChatsListCard-value--channel">
          {data.Channel}
        </div>
        <div className="ChatsListCard-label">Contact:</div>
        <div className="ChatsListCard-value ChatsListCard-value--contact">
          {data.Channel === "email" ? (
            <span title={data.Email}>{data.Email}</span>
          ) : (
            data.Phone
          )}
        </div>
        <div className="ChatsListCard-label">Source:</div>
        <div className="ChatsListCard-value ChatsListCard-value--source">
          {data.Source}
        </div>
      </div>
    </div>
  );
};

export default ChatsListCard;
