import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Tile from "../../Tile";
import { formatToDateString } from "../../../utils/formatDate";
import useData from "../../../hook/useData";

import { chooseConversationsEndpoint } from "../../../store/actions/conversationActions";

import "./DashboardTilesCampaigns.scss";

const DashboardTiles = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dateRange = useSelector((state) => state.date);

  const {
    data: closedRosData,
    status: closedRosStatus,
    error: closedRosError,
  } = useData(
    "closedRos",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: engagementStatisticsData,
    status: engagementStatisticsStatus,
    error: engagementStatisticsError,
  } = useData(
    "engagementStatistics",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: messagesOutData,
    status: messagesOutStatus,
    error: messagesOutError,
  } = useData(
    "messagesOut",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const {
    data: acquiredPhoneNumbersData,
    status: acquiredPhoneNumbersStatus,
    error: acquiredPhoneNumbersError,
  } = useData(
    "acquiredPhoneNumbers",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const handleTileClick = (endpoint) => {
    dispatch(chooseConversationsEndpoint(endpoint));
    history.push("conversations");
  };

  return (
    <>
      <div className="TilesArea TilesArea--alpha TilesAreaCampaigns--alpha">
        <Tile
          type="square"
          number="1"
          isMain="true"
          label="Customers Messaged"
          description="Number of customers the AI messaged during the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="customer_messaged"
          onClick={() => handleTileClick("newProcessedLeads")}
        />
        <Tile
          type="square"
          number="2"
          label="Engagement Rate"
          description="Percentage of all customers the AI messaged that have responded to one or more of the AI's messages and/or have clicked on the CTA link during the selected date range. Note: this metric excludes unsubscribe and cancellation requests"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="engaged_leads"
          dataSecondary={engagementStatisticsData}
          keySecondary="engagement_rate"
          onClick={() => handleTileClick("engagedLeads")}
        />
        <Tile
          type="square"
          number="3"
          label="Acquired Phone Numbers"
          description="Number of phone numbers the AI has confirmed using DMS records or acquired during conversation for the selected date range"
          status={acquiredPhoneNumbersStatus}
          error={acquiredPhoneNumbersError}
          dataPrimary={acquiredPhoneNumbersData}
          keyPrimary="acquired_phone_numbers"
          onClick={() => handleTileClick("acquiredPhoneNumbersLeads")}
        />
          <Tile
              type="details"
              number="1"
              label="Bounced Leads"
              description="Number of leads, as well as the percentage of total messages sent, that the AI was unable to contact for the selected date range. Note: emails and text messages can bounce for various reasons, such as when the recipient’s email address and/or phone number do not exist"
              status={messagesOutStatus}
              error={messagesOutError}
              dataPrimary={messagesOutData}
              keyPrimary="undelivered_messages"
              dataSecondary={messagesOutData}
              keySecondary="undelivered_messages_rate"
          />
          <Tile
              type="details"
              number="2"
              label="Opened Emails"
              description="Number of emails, as well as the percentage of total emails, that were delivered, opened and presumably read during the selected date range"
              status={messagesOutStatus}
              error={messagesOutError}
              dataPrimary={messagesOutData}
              keyPrimary="opened_emails"
              dataSecondary={messagesOutData}
              keySecondary="opened_email_rate"
          />
          <Tile
              type="square"
              number="4"
              isMain="true"
              label="Total Messages Sent"
              description="Total number of messages sent by the AI for the selected date range"
              status={messagesOutStatus}
              error={messagesOutError}
              dataPrimary={messagesOutData}
              keyPrimary="total_messages_out"
          />

      </div>

      <div className="TilesArea TilesArea--beta">
          <Tile
              type="square"
              number="1"
              label="Closed ROS"
              description="Number of customers who were contacted by the AI and had a repair order closed during the selected date range"
              status={closedRosStatus}
              error={closedRosError}
              dataPrimary={closedRosData}
              keyPrimary="closed_ros"
              dataSecondary={closedRosData}
              keySecondary="amount_closed_ros"
              secondarySymbol="$"
              currency="USD"
              onClick={() => handleTileClick("closedRosPage")}
          />
      </div>

      <div className="TilesArea TilesArea--gamma">
        <Tile
          type="square"
          number="1"
          label="Average Messages Sent to Engage Customers"
          description="Number of messages the AI had to send on average to initially engage customers for the selected date range"
          status={engagementStatisticsStatus}
          error={engagementStatisticsError}
          dataPrimary={engagementStatisticsData}
          keyPrimary="messages_sent_to_engage_customer"
        />
      </div>
    </>
  );
};

export default DashboardTiles;
