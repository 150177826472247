import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import WidgetSource from "../../components/WidgetSource/WidgetSource";
import WidgetSearch from "../../components/WidgetSearch/WidgetSearch";
import {chooseConversationsQuery} from "../../store/actions/conversationActions";
import Conversations from "../../components/CarBuyingConversations/Conversations";


const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
    <WidgetSearch key="widgetSearch" functionToDispatch={chooseConversationsQuery} />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageCarBuyingConversations = () => {
  return <Layout content={<Conversations />} widgets={widgets} />;
};

export default PageCarBuyingConversations;
