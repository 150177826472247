import { useEffect, useRef, useState } from "react";

import ChatsDialogEmpty from "../ChatsDialogEmpty";
import ChatsDialogMessage from "../ChatsDialogMessage";
import ChatsReply from "../ChatsReply";
// import ChatsDialogNotification from "../ChatsDialogNotification";
import Loader from "../../Loader";
import "./ChatsDialog.scss";
import cn from "classnames";
import useData from "../../../hook/useData";
const CHANNELS = [
  {
    Id: "*",
    Name: "All",
  },
  {
    Id: "email",
    Name: "Email",
  },
  {
    Id: "sms",
    Name: "SMS",
  },
  {
    Id: "whatsapp",
    Name: "Whatsapp",
  },
];

const Channel = ({ name, selected, onSelect }) => {
  return (
    <div
      className={cn("ChannelSelector_channel", `ChannelSelector_${name}`, {
        selected,
      })}
      onClick={onSelect}
    >
      {name}
    </div>
  );
};

const ChatsDialog = ({
  data,
  status,
  leadNotifications = [],
  leadEvents = [],
  stopBotEvents = [],
  refetchConversation,
  cid,
}) => {
  const refDialog = useRef(null);

  const [selectedChannel, setSelectedChannel] = useState(CHANNELS[0]);
  const [unsubscribed, setUnsubscribed] = useState(0);

  const { data: unsubscribedResponse, status: unsubscribedStatus } = useData(
    "isUnsubscribed",
    { conversationId: cid, selectedChannel: selectedChannel.Id },
    [cid, selectedChannel]
  );

  useEffect(() => {
    if (unsubscribedResponse && "Unsubscribed" in unsubscribedResponse) {
      setUnsubscribed(unsubscribedResponse.Unsubscribed);
    }
  }, [unsubscribedResponse]);

  useEffect(() => {
    const dialog = refDialog.current;
    if (!dialog) return null;
    dialog.scrollTop = dialog.scrollHeight - dialog.offsetHeight;
    setSelectedChannel(CHANNELS[0]);
  }, [data]);

  if (leadEvents) {
    leadEvents.map((event) => {
      event["Timestamp"] = Date.parse(event.RepairOrderClosed);
      return event;
    });
  }
  const render = () => {
    if (status === "error") {
      return "";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    if (!data || !data.length) return <ChatsDialogEmpty />;

    let channelFilteredDialog = data;
    if (leadEvents)
      channelFilteredDialog = channelFilteredDialog.concat(leadEvents);
    if (stopBotEvents)
      channelFilteredDialog = channelFilteredDialog.concat(
        stopBotEvents.map((event) => {
          event["Timestamp"] = event["ts"];
          return event;
        })
      );

    channelFilteredDialog.sort((a, b) => {
      return a.Timestamp - b.Timestamp;
    });

    console.log(channelFilteredDialog);

    if (selectedChannel && selectedChannel.Id !== "*") {
      channelFilteredDialog = channelFilteredDialog.filter(
        (item) => item.Channel === selectedChannel.Id
      );
    }

    // disable notifs when empty chat
    if (channelFilteredDialog.length === 0) {
      return null;
    }

    const messages = channelFilteredDialog.map((message) => {
      const notificationToAssign = leadNotifications?.find(
        (item) => item.MessageId === message.MessageId
      );
      const appointmentSet = message.AppointmentData;
      const sentByCrmSalesperson =
        message.Initiator !== "human" ? message.SentByCrmSalesperson : null;

      if ("RepairOrderClosed" in message) {
        return {
          component: (
            <ChatsDialogMessage
              key={message.Timestamp}
              sender="bot"
              // meta={{ timestamp: message.Timestamp }}
              justNotifications={true}
              event={message}
              botName={message.BotName}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      if ("Action" in message) {
        return {
          component: (
            <ChatsDialogMessage
              key={message.Timestamp}
              sender="bot"
              meta={{ timestamp: message.Timestamp }}
              justNotifications={true}
              action={message.Action}
              botName={message.BotName}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      return {
        component: (
          <ChatsDialogMessage
            key={message.Timestamp}
            sender={message.Initiator}
            message={message.Message}
            attachments={message.Attachments}
            meta={{ timestamp: message.Timestamp }}
            notification={notificationToAssign}
            appointmentSet={appointmentSet}
            sentByCrmSalesperson={sentByCrmSalesperson}
            channel={message.Channel}
            name={message.Name}
            surname={message.Surname}
            botName={message.BotName}
            blacklisted={message.Blacklisted}
          />
        ),
        ts: message.Timestamp,
      };
    });

    const justNotifications = leadNotifications?.filter((notification) => {
      const index = data?.findIndex(
        (item) => item.MessageId === notification.MessageId
      );
      return index === -1;
    });

    // if justSave and hto happens, that notifications are assigned to a message, that is not displayed here, so they are stand alone notifications
    const justNotificationsComponent = justNotifications?.map(
      (notification) => {
        return {
          component: (
            <ChatsDialogMessage
              key={notification.MessageId}
              sender="bot"
              notification={notification}
              justNotifications={true}
              // message={message.Message}
              // meta={{ timestamp: message.Timestamp }}
              // appointmentSet={appointmentSet}
              // sentByCrmSalesperson={sentByCrmSalesperson}
              // channel={message.Channel}
            />
          ),
          ts: notification?.Notifications[0].ts,
        };
      }
    );

    let render = [...messages];
    if (justNotificationsComponent) {
      render = [...render, ...justNotificationsComponent];
    }
    render = render.sort(function (a, b) {
      return a.ts - b.ts;
    });
    return render.map((item) => item.component);
  };

  return (
    <div className="ChatsDialog">
      <div className="ChatsDialog-header">
        {status === "success" &&
          CHANNELS.map((channel) => (
            <Channel
              key={channel.Id}
              name={channel.Name}
              selected={channel.Id === selectedChannel.Id}
              onSelect={() => setSelectedChannel(channel)}
            />
          ))}
      </div>
      <div className="ChatsDialog-container" ref={refDialog}>
        <div className="ChatsDialog-messages">{render()}</div>
      </div>
      <div className="ChatsDialog-footer">
        {unsubscribedStatus === "pending" ? (
          <Loader />
        ) : (
          <>
            {status === "success" && (
              <ChatsReply
                unsubscribed={unsubscribed}
                conversation={data}
                selectedChannel={selectedChannel}
                refetchConversation={refetchConversation}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatsDialog;
