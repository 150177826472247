import FormElement from "../FormElement";
import "./FormGroup.scss";

const FormGroup = (props) => {
  const { label, items, ...rest } = props;

  const fields =
    items &&
    items.map((item) => {
      return <FormElement key={item.id} {...rest} {...item} />;
    });

  return (
    <div className="FormGroup">
      <h2 className="FormGroup-title">{label}</h2>
      <div className="FormGroup-content">{fields}</div>
    </div>
  );
};

export default FormGroup;
