import config from "../config";

function getUrlWithParamsConfig(endpointConfig, params) {
  const { method, uri } =
    config.client.endpoints[
      endpointConfig.replace(/\/conversations\//, "conversations_")
    ];

  let body = {};

  const apiConfigUri = {
    ...config.client.server,
    ...uri,
    query: {
      ...uri.query,
    },
  };

  const query = {
    ...params,
  };

  Object.keys(query).filter(key => {
    if (apiConfigUri.query[key] !== undefined) {
      apiConfigUri.query[key] = query[key]
      return false
    }
    return true
  })

  apiConfigUri.pathname = Object.keys(query).reduce((acc, val) => {
    if (acc.indexOf(`{${val}}`) !== -1) {
      const result = acc.replace(`{${val}}`, query[val]);
      delete query[val];

      return result;
    }

    return acc;
  }, apiConfigUri.pathname);

  if (method === "GET") {
    apiConfigUri.query = {
      ...apiConfigUri.query,
      ...query,
    };
  } else {
    body = query;
  }

  return { method, uri: apiConfigUri, body };
}

export default getUrlWithParamsConfig;
