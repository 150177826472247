import SettingsSignature from "./SettingsSignature";
import SettingsTime from "./SettingsTime";
import SettingsApptTime from "./SettingsApptTime";
import SettingsNotifications from "./SettingsNotifications";
import SettingsAccess from "./SettingsAccess";
import SettingsAnswers from "./SettingsAnswers";
import SettingsFollow from "./SettingsFollow";
import SettingsPreferences from "./SettingsPreferences";
import SettingsNotifyTeam from "./SettingsNotifyTeam";
import SettingsDescriptions from "./SettingsDescriptions";
import SettingsBotControl from "./SettingsBotControl/SettingsBotControl";
import SettingsBusinessHours from "./settingsBusinessHours/SettingsBusinessHours";
import SettingsDealershipTakeover from "./SettingsDealershipTakeover/SettingsDealershipTakeover";

const settingsBlocks = [
  {
    index: 1,
    id: "01",
    resourceName: "Settings:Signature",
    title: "Signature Settings",
    description:
      "Give your AI assistant a name and position, and enter other essential information about your dealership. These details will be used to create a personalized signature, which will appear on messages sent by the AI assistant.",
    component: <SettingsSignature />,
  },

  {
    index: 2,
    id: "02",
    resourceName: "Settings:WorkingHours",
    title: "Work Hours Settings",
    description:
      "Set your dealership’s regular, special and appointment working hours.",
    component: <SettingsBusinessHours />,
  },
  /* 
  {
    index: 3,
    id: "03",
    resourceName: "Settings:WorkingHours",
    title: "Content Management (CMS)",
    description:
      "Provide the information AI will utilize to generate corresponding responses. For each topic/question provide all necessary textual information, including links if applicable. Please note: if not information is provided on a certain topic, AI won't be able to generate its reply.",
    component: <SettingsCMS />,
  }, */

  {
    index: 4,
    id: "04",
    resourceName: "Settings:Notifications",
    title: "Email & SMS Notification Settings",
    description:
      "Set up personnel to receive important AI notifications. Choose from text only, email only or text and email notifications, and select which events each rep will receive alerts for.",
    component: <SettingsNotifications />,
  },
  {
    index: 5,
    id: "05",
    resourceName: "Settings:Sources",
    title: "Lead Source Settings",
    description:
      "Choose which lead sources the AI assistant can respond to, and turn off any you don’t want the AI to work.",
    component: <SettingsAccess />,
  },

  {
    index: 6,
    id: "06",
    resourceName: "Settings:BotAnswers",
    title: "Answers Settings",
    description:
      "Customize the AI assistant’s answers to specific topics. For each question, select the answer you want from the default list, or write your own using the custom text box. Please note: some default answers contain text fields; you will need to fill these in accordingly before saving any changes.",
    component: <SettingsAnswers />,
    topDescription: <SettingsDescriptions id="6" />,
  },

  {
    index: 7,
    id: "07",
    resourceName: "Settings:FollowUp",
    title: "Follow Up Settings",
    description:
      "Setup when your AI Assistant will send follow up messages if the customer doesn’t reply",
    component: <SettingsFollow />,
  },

  {
    index: 8,
    id: "08",
    resourceName: "Settings:Preferences",
    title: "Preferences",
    description:
      "Set your vehicle history report source and sale price preferences (note: both fields are mandatory). For sale price, the AI assistant references either the Selling Price or MSRP field from the inventory feed. You can also choose “No Price,” meaning the AI assistant won’t pull any pricing info.",
    component: <SettingsPreferences />,
  },

  {
    index: 9,
    id: "09",
    resourceName: "Settings:NotifyAction",
    title: "Notify Team",
    description: "Notify Team",
    component: <SettingsNotifyTeam />,
    noHeader: true,
  },

  {
    index: 10,
    id: "10",
    resourceName: "Settings:PauseAI",
    title: "Pause AI",
    description:
      "By using the toggle, you can pause AI - new leads won't be received by AI.",
    component: <SettingsBotControl />,
  },
];

export default settingsBlocks;
