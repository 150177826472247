export default [
  {
    id: "Email",
    name: "Email",
  },
  {
    id: "SMS",
    name: "SMS",
  },
];
