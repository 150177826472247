import { useEffect } from "react";

import useData from "../../../../hook/useData";
import Loader from "../../../Loader/Loader";
import SelectableCard from "../../../SelectableCard/SelectableCard";

import "./index.scss";

const CampaignCategories = ({ setFormCompleted, setForm, formValue, form }) => {
  const { data: campaignCategories, status } = useData(
    "getCampaignCategories",
    { campaign_type: form["Type"]["Id"] },
    []
  );

  useEffect(() => {
    if (formValue) setFormCompleted();
  }, [formValue]);

  if (status === "pending" || status === "init") {
    return (
      <div className="CampaignCategories-Loader">
        <Loader />
      </div>
    );
  }

  if (status === "error" || !campaignCategories?.length) {
    return (
      <div className="CampaignCategories-Error">
        <p>No campaign categories</p>
      </div>
    );
  }

  console.log(campaignCategories);

  return (
    <div>
      <div className="CampaignCategories-Label">
        <h3>Select an initiative to configure.</h3>
      </div>
      <div className="CampaignCategories-Container">
        {campaignCategories.map((campaignCategory) => (
          <SelectableCard
            key={campaignCategory.Id}
            active={campaignCategory.Id === formValue?.Id}
            value={campaignCategory.Name}
            description={campaignCategory.Description}
            onSelect={() => setForm(campaignCategory)}
            disabled={
              campaignCategory.Id ===
                "Campaigns:ServiceCampaign:Category:LostSoulCampaign" ||
              campaignCategory.Id ===
                "Campaigns:ServiceCampaign:Category:NextServiceCampaign"
            }
            // count={
            //   campaignCategory.Id ===
            //   "Campaigns:ServiceCampaign:Category:FirstServiceCampaign"
            //     ? 1
            //     : 0
            // }
            // started={
            //   campaignCategory.Id ===
            //   "Campaigns:ServiceCampaign:Category:FirstServiceCampaign"
            //     ? true
            //     : false
            // }
          />
        ))}
      </div>
    </div>
  );
};

export default CampaignCategories;
