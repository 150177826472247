export const settingsCMS = {
    topics: [],
    openModal: false,
    modelType: '',
    questionIndex: null,
    currentTopicId: "",
    deleteState: {
        type: "",
        state: ""
    }
};