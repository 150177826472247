import cn from "classnames";

import "./SettingsAccessSwitcher.scss";

const SettingsAccessSwitcher = ({status, handleEvent, main_source = true, isDisabled = false}) => {
    return (
        <div className={cn(main_source ? "" : "SettingsAccess-cell--switcher_source", {"SettingsAccess--disabled": isDisabled})}>
            <div
                className={cn("SettingAccessSwitcher", {
                    "SettingAccessSwitcher--on": status,
                    "SettingsAccess-cell--switcher_source": !main_source
                })}
                onClick={handleEvent}
            >
                <div className="SettingAccessSwitcher-stateOn">On</div>
                <div className="SettingAccessSwitcher-stateOff">Off</div>
            </div>
        </div>
    );
};

export default SettingsAccessSwitcher;
