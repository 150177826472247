import { useState } from "react";
import cn from "classnames";

import "./FormCheckbox.scss";

import svg from "../../../assets/img/vector.svg";

const FormCheckbox = ({
  label,
  labelHidden = false,
  checked = false,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleToggleFocused = () => {
    return setIsFocused((oldState) => !oldState);
  };

  return (
    <div
      className={cn("FormCheckbox", {
        "FormCheckbox--checked": checked,
        "FormCheckbox--focused": isFocused,
      })}
    >
      <label className="FormCheckbox-field">
        <input
          className="FormCheckbox-input"
          type="checkbox"
          checked={checked}
          onFocus={handleToggleFocused}
          onBlur={handleToggleFocused}
          {...rest}
        />
        <span className="FormCheckbox-fakeInput">
          {checked && (
            <svg width="14" height="14">
              <use xlinkHref={svg + "#v-check"} />
            </svg>
          )}
        </span>
        <span
          className={cn("FormCheckbox-label", {
            "FormCheckbox-label--hidden": labelHidden,
          })}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default FormCheckbox;
