import WidgetAccount from "../WidgetAccount";

import prepareWidgets from "./helpers/prepareWidgets";

import "./Header.scss";

const Header = ({ widgets }) => {
  const { primary, secondary, main } = widgets || false;

  const primaryWidgets = prepareWidgets(primary);
  const secondaryWidgets = prepareWidgets(secondary);
  const mainWidgets = prepareWidgets(main);

  return (
    <div className="Header">
        {mainWidgets || <WidgetAccount />}
        {secondaryWidgets}
        {primaryWidgets}
    </div>
  );
};

export default Header;
