import DeleteForm from "./Forms/Delete";
import QuestionForm from "./Forms/Question";
import TopicForm from "./Forms/Topic";


const SwitchBody = ({ type }) => {
  switch (type) {
    case "topic":
      return <TopicForm />;
    case "question":
      return <QuestionForm />;
    case "delete":
      return <DeleteForm />;
    default:
      return <></>;
  }
};

export default SwitchBody;
