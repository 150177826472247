import "./index.scss";
import CSVUploader from "./CSVUploader";

const CampaignCSVUploader = ({ form, setForm }) => {
  return (
    <div className="CampaignCSVUploader-Container">
      <div className="CampaignCSVUploader-Settings-Container">
        <span className="CampaignCSVUploader-BlockTitle">CSV File Upload</span>
        <div className="CampaignCSVUploader">
          <CSVUploader form={form} setForm={setForm} />
        </div>
      </div>
    </div>
  );
};

export default CampaignCSVUploader;
