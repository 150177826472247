import React, { useState, useRef, useEffect } from "react";
import { Button, Tooltip } from "@material-ui/core";
import FormElement from "../../../../../FormElement";
import "./index.scss";
import ProcessedDataPreviewer from "./ProcessedDataPreviewer";
import Papa from "papaparse";
import useData from "../../../../../../hook/useData";
import Loader from "../../../../../Loader/Loader";

const MAX_FILE_SIZE = 2048000;

const CSVUploader = ({ form, setForm }) => {
  const [uploadedData, setUploadedData] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const inputFileRef = useRef();
  const [uploadError, setUploadError] = useState(null);

  const pathParams = () =>
    form.Type &&
    form.Category &&
    form.Sequence && {
      campaign_type: form["Type"]["Id"],
      campaign_category: form["Category"]["Id"],
      campaign_sequence: form["Config"]["Name"],
    };

  const {
    data: processDataResult,
    status: processDataStatus,
    execute: processData,
  } = useData(
    "processCampaignsData",
    { ...pathParams(), ...uploadedData },
    [uploadedData],
    false
  );

  useEffect(() => {
    if (uploadedData) {
      processData();
    }
  }, [uploadedData]);

  useEffect(() => {
    if (
      processDataResult &&
      "Data" in processDataResult &&
      "S3Key" in processDataResult
    ) {
      setProcessedData(processDataResult.Data);
      setForm({
        S3Key: processDataResult.S3Key,
        TotalDataCount: processDataResult.TotalDataCount,
        FailedDataCount: processDataResult.FailedDataCount,
        ValidDataCount: processDataResult.ValidDataCount,
        TotalItems: processDataResult.TotalItems,
      });
    }
  }, [processDataResult]);

  const handleUploadReset = () => {
    setUploadedData(null);
    setProcessedData(null);
    setUploadedFileName("");
    setForm({ S3Key: null });
    inputFileRef.current.value = "";
    setUploadError(null);
  };

  return (
    <div className="CSVUploaderWrapper-container">
      <p className="CSVUploaderWrapper-title">
        Important: Do not include Do Not Email customers in your file.
      </p>
      <p className="CSVUploaderWrapper-title">
        The CSV File must meet the following requirements:
      </p>
      <p style={{ marginLeft: "1vw", fontSize: "1.3vh" }}>
        Email column required
        <br />
        No empty headers
        <br />
        Not larger than 2 MB
        <br />
        CSV UTF-8 file format
        <br />
        Column names must match
        <br />
      </p>
      <p className="CSVUploaderWrapper-title">
        <a
          href={form?.Config?.Template?.ExampleDataSource}
          // href="https://pulsar-static-assets.s3.us-west-2.amazonaws.com/aidashboard/RecallExample.csv"
          style={{ textDecoration: "none" }}
        >
          <span className="CSVUploaderWrapper-title-meta">
            Download example
          </span>
        </a>
      </p>
      <div className="CSVUploader-container">
        {/* <div className="CSVUploader-control">
          <a
            href="https://pulsar-static-assets.s3.us-west-2.amazonaws.com/aidashboard/RecallExample.csv"
            style={{ textDecoration: "none" }}
          >
            <Button style={{ width: "12vw" }} variant="outlined">
              Download sample
            </Button>
          </a>
        </div> */}
        <div className="CSVUploader-control">
          {!form?.Config?.Name ? (
            <Tooltip title="Enter campaign name" placement="top">
              <span>
                <Button
                  style={{ width: "12vw" }}
                  variant="outlined"
                  onClick={() => {
                    handleUploadReset();
                    inputFileRef.current.click();
                  }}
                  disabled={
                    processDataStatus === "pending" || !form?.Config?.Name
                  }
                >
                  Upload data
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              style={{ width: "12vw" }}
              variant="outlined"
              onClick={() => {
                handleUploadReset();
                inputFileRef.current.click();
              }}
              disabled={processDataStatus === "pending" || !form?.Config?.Name}
            >
              Upload data
            </Button>
          )}
          {uploadError && (
            <span style={{ margin: "0 .5vw" }}>{uploadError}</span>
          )}
          {uploadedData && processDataStatus === "success" && (
            <span style={{ margin: "0 .5vw" }}>
              {uploadedFileName}{" "}
              <span style={{ cursor: "pointer" }} onClick={handleUploadReset}>
                X
              </span>
            </span>
          )}
          <input
            type="file"
            accept=".csv"
            ref={inputFileRef}
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                if (files.length === 1) {
                  if (files[0].size > MAX_FILE_SIZE) {
                    return setUploadError("File size exceeded limit (max 2MB)");
                  }
                  setUploadedFileName(files[0]["name"]);
                  Papa.parse(files[0], {
                    header: true,
                    complete: function (results) {
                      setUploadedData({ Data: results.data });
                    },
                  });
                } else {
                  // alert too many files
                  alert("too many files");
                }
              }
            }}
          />
        </div>
        {processDataStatus === "pending" && <Loader />}
      </div>

      <ProcessedDataPreviewer
        processedData={processedData}
        reupload={() => {
          handleUploadReset();
          inputFileRef.current.click();
        }}
      />
    </div>
  );
};

export default CSVUploader;
