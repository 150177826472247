import { BrowserRouter as Router, Redirect, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../components/Loader";
import "./Content.scss";

import prepareRoutes from "./helpers/prepareRoutes";

import {
  GENERAL_LINKS as GENERAL_LINKS_LEAD_ENGAGE,
  SECONDARY_LINKS as SECONDARY_LINKS_LEAD_ENGAGE,
  COMMON_LINKS as COMMON_LINKS_LEAD_ENGAGE,
} from "../../routes_lead_engage"; 

import {
  GENERAL_LINKS as GENERAL_LINKS_WEBCHAT,
  SECONDARY_LINKS as SECONDARY_LINKS_WEBCHAT,
  COMMON_LINKS as COMMON_LINKS_WEBCHAT,
} from "../../routes_webchat";

import {
  GENERAL_LINKS as GENERAL_LINKS_CAR_BUYING,
  SECONDARY_LINKS as SECONDARY_LINKS_CAR_BUYING,
  COMMON_LINKS as COMMON_LINKS_CAR_BUYING,
} from "../../routes_car_buying"; 

import {
  GENERAL_LINKS as GENERAL_LINKS_CAMPAIGNS,
  SECONDARY_LINKS as SECONDARY_LINKS_CAMPAIGNS,
  // COMMON_LINKS as COMMON_LINKS_CAMPAIGNS,
} from "../../routes_campaigns";

import {
  GENERAL_LINKS as GENERAL_LINKS_FI,
  SECONDARY_LINKS as SECONDARY_LINKS_FI,
} from "../../routes_fi";

import {
  GENERAL_LINKS as GENERAL_LINKS_FANDI,
  SECONDARY_LINKS as SECONDARY_LINKS_FANDI,
  // COMMON_LINKS as COMMON_LINKS_CAMPAIGNS,
} from "../../routes_FandI";

import { useEffect, useLayoutEffect, useState } from "react";
import { loginAction, switchProduct } from "../../store/actions/userActions";

const getAllowedPrivateRoutes = (product, policy, user, role) => {
  if (!product) return prepareRoutes([]);
  const { Category: productId } = product;
  if (productId === "SALES_AI") {
    let GENERAL_LINKS_FILTERED = [];
    let SECONDARY_LINKS_FILTERED = [];

    if (policy.length || !user) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_LEAD_ENGAGE;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_LEAD_ENGAGE.filter(
        (item) => item.path !== "/settings"
      );
    }
    if (role?.toUpperCase() === "REGULAR") {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_LEAD_ENGAGE.filter(
        (item) => !item.path.includes("/user-management")
      );
    } else {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_LEAD_ENGAGE;
    }

    return prepareRoutes([
      ...GENERAL_LINKS_FILTERED,
      ...SECONDARY_LINKS_FILTERED,
    ]);
  } else if (productId === "CHAT_AI") {
    let GENERAL_LINKS_FILTERED = [];
    let SECONDARY_LINKS_FILTERED = [];

    if (policy.length) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_WEBCHAT;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_WEBCHAT.filter(
        (item) => item.path !== "/settings"
      );
    }
    if (role?.toUpperCase() === "REGULAR") {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_WEBCHAT.filter(
        (item) => !item.path.includes("/user-management")
      );
    } else {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_WEBCHAT;
    }

    return prepareRoutes([
      ...GENERAL_LINKS_FILTERED,
      ...SECONDARY_LINKS_FILTERED,
    ]);
  } else if (productId === "CARBUYING_AI") {
    let GENERAL_LINKS_FILTERED = [];
    let SECONDARY_LINKS_FILTERED = [];

    if (policy.length || !user) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_CAR_BUYING;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_CAR_BUYING.filter(
        (item) => item.path !== "/settings"
      );
    }
    if (role?.toUpperCase() === 'REGULAR') {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_CAR_BUYING.filter(
        item => !item.path.includes('/user-management')
      )
    } else {
      SECONDARY_LINKS_FILTERED = SECONDARY_LINKS_CAR_BUYING
    }

    return prepareRoutes([
      ...GENERAL_LINKS_FILTERED,
      ...SECONDARY_LINKS_FILTERED,
    ]);
  }else if (productId === "SERVICE_AI") {
    return prepareRoutes([
      ...GENERAL_LINKS_CAMPAIGNS,
      ...SECONDARY_LINKS_CAMPAIGNS,
    ]);
  } else if (productId === "SALESCAMPAIGNS") {
    return prepareRoutes([...GENERAL_LINKS_FI, ...SECONDARY_LINKS_FI]);
  } else if (productId === "FANDI") {
    return prepareRoutes([...GENERAL_LINKS_FANDI, ...SECONDARY_LINKS_FANDI]);
  }

  return prepareRoutes([]);
};

const getAllowedGuestRoutes = () => {
  return prepareRoutes(COMMON_LINKS_LEAD_ENGAGE);
};

const Content = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently, error } =
    useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const [nickname, setNickname] = useState(null);
  const [privateRoutes, setPrivateRoutes] = useState([]);
  const [policy, setPolicy] = useState(user?.user_metadata?.Policy || []);

  // const policy = user?.user_metadata?.Policy || [];
  const selectedProduct = useSelector(
    (state) => state?.user?.auth?.selectedProduct
  );
  const products = useSelector((state) => {
    state?.user?.auth?.user_info?.Products;
  });
  const role = useSelector((state) => state?.user?.auth?.data?.user_info?.Role);
  const stateLoading = useSelector((state) => state?.user?.auth?.isLoading);

  const queryParameters = new URLSearchParams(window.location.search);
  const queryProduct = queryParameters.get("product");

  let guestRoutes = getAllowedGuestRoutes();

  useEffect(() => {
    if (error) {
      console.log("user in error scope is: ", user);
      console.log("error is: ", error.message);
    }
  }, [error]);

  useEffect(() => {
    let privateRoutes = getAllowedPrivateRoutes(
      queryProduct
        ? { Id: "DealerChat", Name: "DealerChat", Category: "CHAT_AI" }
        : selectedProduct,
      policy,
      user,
      role
    );
    console.log("private routes are: ", privateRoutes);
    setPrivateRoutes(privateRoutes);
  }, [policy, products]);

  useEffect(async () => {
    if (!isLoading && user) {
      const access_token = await getAccessTokenSilently();
      const loginActionResult = await dispatch(loginAction(user, access_token));
      if (loginActionResult?.user_info?.Policy) {
        setPolicy(loginActionResult.user_info?.Policy);
      }
    }
  }, [isLoading, user]);

  useEffect(() => {
    if (queryProduct) {
      isAuthenticated &&
        dispatch(
          switchProduct({
            Id: "DealerChat",
            Name: "DealerChat",
            Category: "CHAT_AI",
          })
        );
    } else {
      isAuthenticated &&
        dispatch(
          switchProduct({
            Id: "LEAD_ENGAGE",
            Name: "AI Assistant",
            Category: "SALES_AI",
          })
        );
    }
  }, [queryProduct]);

  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectUrl")
    if (isAuthenticated && user && privateRoutes.length && redirectUrl) {
      localStorage.removeItem("redirectUrl");
      history?.push(redirectUrl);
    }
  }, [isAuthenticated, user, privateRoutes.length, history])

  useEffect(() => {
    if (
      !(isAuthenticated && privateRoutes.length) &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/logout" &&
      window.location.pathname !== "/"
    ) {
      console.log("current url is: ", window.location.pathname);
      localStorage.setItem("redirectUrl", window.location.pathname);
    }
  }, [isAuthenticated, user, privateRoutes.length])

  useEffect(() => {
    if (
      !(isAuthenticated && privateRoutes.length) &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/logout" &&
      window.location.pathname !== "/"
    ) {
      console.log("current url is: ", window.location.pathname);
      localStorage.setItem("redirectUrl", window.location.pathname);
    }
  }, [isAuthenticated, user, privateRoutes.length])

  useLayoutEffect(() => {
    console.log(window.location.hostname)
    const indexCDK = window.location.hostname;
    const root = document.documentElement;
    const isCDK = indexCDK.includes("crmaiva");
    root.style.setProperty('--s90', !isCDK ? '#d62b56' : '#196FE1');
    root.style.setProperty('--s75', !isCDK ? '#d62b56' : '#196FE1');
    root.style.setProperty('--s70', !isCDK ? '#d62b56' : '#196FE1');
    root.style.setProperty('--s50', !isCDK ? '#d62b56' : '#196FE1');
    root.style.setProperty('--d90', !isCDK ? '#8b76cc' : '#9A9999');
    root.style.setProperty('--d80', !isCDK ? '#8777C6' : '#196FE1');
    root.style.setProperty('--d70', !isCDK ? '#ccc' : '#446AA3');
    root.style.setProperty('--chatEmailBackground', !isCDK ? '#f9ecdc' : '#bc6f320f');
    root.style.setProperty('--chatSMSBackground', !isCDK ? '#dfe9f7' : '#8514dd0f');
    root.style.setProperty('--chatWhatsAppBackground', !isCDK ? '#65D07233' : '#1ead470f');
    root.style.setProperty('--chatEmailAuthorColor', !isCDK ? '#e09f50' : '#bc6f32');
    root.style.setProperty('--chatSMSAuthorColor', !isCDK ? '#5d90d9' : '#8514dd');
    root.style.setProperty('--chatWhatsAppAuthorColor', !isCDK ? '#478D4F' : '#1ead47');
    root.style.setProperty('--danger', !isCDK ? '#bf3c30' : '#196FE1');
    root.style.setProperty('--iconActive', !isCDK ? '#fff' : '#000');
    root.style.setProperty('--iconHover', !isCDK ? '#fff' : '#000');
    root.style.setProperty('--navBackground', !isCDK ? '#212230' : '#F2F2F2');
    root.style.setProperty('--logoBackground', !isCDK ? '#212230' : '#fcfcfc');
    root.style.setProperty('--logoSize', !isCDK ? '40%' : '70%');
    root.style.setProperty('--smallFontSize', !isCDK ? '8px' : '14px');
    root.style.setProperty('--ordinaryFontSize', !isCDK ? '12px' : '14px');
    root.style.setProperty('--ordinaryFontWeight', !isCDK ? 'auto' : '500');
    root.style.setProperty('--settingsSubHeaderFontSize', !isCDK ? '1rem' : '14px');
    root.style.setProperty('--settingsSubHeaderBiggerFontSize', !isCDK ? '1.1rem' : '14px');
    root.style.setProperty('--settingsSubHeaderBiggestFontSize', !isCDK ? '1.2rem' : '14px');
    root.style.setProperty('--smallTitleFontWeight', !isCDK ? '700' : '500');
    root.style.setProperty('--subHeaderTextTransform', !isCDK ? 'uppercase' : 'capitalize');
    root.style.setProperty('--dividerColor', !isCDK ? '#f1f1f1' : '#cccccc');
    root.style.setProperty('--navDividerWidth', !isCDK ? '0' : '1px');
    root.style.setProperty('--cardBoxShadow', !isCDK ? '0px 0px 26px #00000026' : '0px 1px 3px 0px rgba(21, 27, 37, 0.5)');
    root.style.setProperty('--cardHoverBoxShadow', !isCDK ? '0 1rem 2rem 0.5rem rgba(black, 0.2)' : '0px 3px 5px 0px rgba(21, 27, 37, 0.5)');
    root.style.setProperty('--settingsCardBoxShadow', !isCDK ? '0 2rem 5rem 0 rgba(0, 0, 0, 0.1)' : '0px 1px 3px 0px rgba(21, 27, 37, 0.5)');
    root.style.setProperty('--buttonBorder', !isCDK ? 'none' : '2px solid #196FE1');
    root.style.setProperty('--sidebarMenuBackgroundColor', !isCDK ? '#414462' : '#373c45');
    root.style.setProperty('--sidebarMenuBackgroundHoverColor', !isCDK ? '#313349' : '#73777d');
    root.style.setProperty('--sidebarMenuTextDecoration', !isCDK ? 'underline' : 'none');
    root.style.setProperty('--actionButtonsColor', !isCDK ? '#afb8ba' : '#196FE1');
    root.style.setProperty('--buttonFillColor', !isCDK ? '#f1f1f1' : '#E8F1FC');
    root.style.setProperty('--buttonHoverFillColor', !isCDK ? '#d62b56' : '#DCE9FA');
    root.style.setProperty('--buttonTextColor', !isCDK ? '#d62b56' : '#155EBF');
    root.style.setProperty('--buttonHoverTextColor', !isCDK ? '#fff' : '#155EBF');
    root.style.setProperty('--userManagementCancelButtonFillColor', !isCDK ? '#fff' : '#E8F1FC');
    root.style.setProperty('--userManagementCancelButtonHoverFillColor', !isCDK ? '#fff' : '#DCE9FA');
    root.style.setProperty('--cancelButtonBorder', !isCDK ? '1px solid #d62b56' : 'none');
    root.style.setProperty('--iconBackgroundColor', !isCDK ? '#e6e6e6' : '#fff');
    root.style.setProperty('--iconColor', !isCDK ? '#b2b2b2' : '#196FE1');
    root.style.setProperty('--subSourcesLinkColor', !isCDK ? '#C53C58' : '#196FE1');
  },[])

  // Routes should be direct children of <Switch> component
  const render =
    isAuthenticated && user && privateRoutes.length ? (
      <Switch>
        {privateRoutes}
        <Redirect to="/" />
      </Switch>
    ) : (
      <Switch>
        {guestRoutes}
        <Redirect to="/login" />
      </Switch>
    );
  return isLoading || stateLoading ? (
    <div className="center">
      <Loader />
    </div>
  ) : (
    render
  );
};

const RouterWrappedContent = () => {
  return <Router><Content /></Router>
}

export default RouterWrappedContent;
