import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormElement from "../FormElement/FormElement";
import "./index.scss";
import classNames from "classnames";

import { switchProduct } from "../../store/actions/userActions";
import useAutoClose from "../../hook/useAutoClose";

const ProductSelector = () => {
  const ps = useRef(null);
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [dealerProducts, setDealerProducts] = useState([]);

  useAutoClose({ setIsOpen: setShowProductSelector, ref: ps });

  const dispatch = useDispatch();

  const selectedProduct = useSelector(
    (state) => state?.user?.auth?.selectedProduct
  );
  const products = useSelector(
    (state) => state?.user?.auth?.data?.user_info?.Products
  );
  const [defaultValue, setDefaultValue] = useState({
    id: products[0].Id,
    label:
      products[0].Id === "SALESCAMPAIGNS"
        ? `F&I ${products[0].Name}`
        : products[0].Name,
    attr: products[0].Attributes[0],
    isDisabled: false,
  });

  const handleProductChange = async (value) => {
    const productAttribute = {
      Id: value.attr.Id,
      Name: value.attr.Name,
      Category: value.id,
    };
    await dispatch(switchProduct(productAttribute));
    setShowProductSelector(false);
    window.location.reload();
  };

  useEffect(() => {
    const obj = products.find((el) => el.Id === selectedProduct.Category);
    if (obj) {
      setDefaultValue({
        id: obj.Id,
        label: obj.Id === "SALESCAMPAIGNS" ? `F&I ${obj.Name}` : obj.Id === "SALES_AI" ? `${obj.Name} Copilot` : obj.Name,
        attr: obj.Attributes[0],
        isDisabled: false,
      });
    } else {
      setDefaultValue({
        id: products[0].Id,
        label:
          products[0].Id === "SALESCAMPAIGNS"
            ? `F&I ${products[0].Name}`
            : products[0].Id === "SALES_AI" ? `${products[0].Name} Copilot` : products[0].Name,
        attr: products[0].Attributes[0],
        isDisabled: false,
      });
    }
  }, [selectedProduct]);
console.log(products)
  return (
    <>
      <FormElement
        type="select"
        label="Product"
        values={products.map((el) => ({
          id: el.Id,
          label: el.Id === "SALESCAMPAIGNS" ? `F&I ${el.Name}` : el.Id === "SALES_AI" ? `${el.Name} Copilot` : el.Name,
          attr: el.Attributes[0],
          isDisabled: false,
        }))}
        defaultValue={defaultValue}
        onChange={handleProductChange}
      />
    </>
  );
};

export default ProductSelector;

/*
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormElement from "../FormElement/FormElement";
import "./index.scss";
import classNames from "classnames";

import { switchProduct } from "../../store/actions/userActions";
import useAutoClose from "../../hook/useAutoClose";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const ProductSelector = () => {
  const ps = useRef(null);
  const [showProductSelector, setShowProductSelector] = useState(false);

  useAutoClose({ setIsOpen: setShowProductSelector, ref: ps });

  const dispatch = useDispatch();

  const selectedProduct = useSelector(
    (state) => state?.user?.auth?.selectedProduct
  );

  const products = useSelector(
    (state) => state?.user?.auth?.data?.user_info?.Products
  );

  const handleProductChange = (product, productAttribute) => {
    productAttribute.Category = product.Id;
    dispatch(switchProduct(productAttribute));
    setShowProductSelector(false);
  };

  return (
    <>
      <div
        className="ProductSelector-label"
        onClick={() => setShowProductSelector((state) => !state)}
        ref={ps}
      >
        <FormElement
          key="product_selector"
          type="textfield"
          label="Product"
          id="product"
          name="product"
          value="Product"
          readOnly={true}
          labelHidden
          style={{ cursor: "pointer" }}
          iconComponent={
            <ArrowDropDown
              fontSize="large"
              style={{ cursor: "pointer", fill: "black" }}
            />
          }
        />
      </div>
      {showProductSelector && (
        <div className="ProductSelector-selector">
          {products.map((product) => (
            <div className="ProductSelector-category">
              <span className="ProductSelector-category-label">
                {product.Name}
              </span>
              {product.Attributes.map((productAttribute) => (
                <div
                  className={classNames("ProductSelector-product", {
                    "ProductSelector-product-active":
                      productAttribute.Id === selectedProduct.Id,
                  })}
                  onClick={() => handleProductChange(product, productAttribute)}
                >
                  {productAttribute.Name}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductSelector;
*/
