const Close = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="open_in_full_FILL0_wght400_GRAD0_opsz24 2">
      <g id="Group 1501">
        <path
          id="Line 155"
          d="M3 3L16 16"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Line 156"
          d="M3 16L16 3"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);

export default Close;
