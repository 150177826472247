import Layout from "../../components/Layout";
import Chats from "../../components/Chats";
import DateRangePicker from "../../components/DateRangePicker";
import WidgetSource from "../../components/WidgetSource";

import "./PageConversation.scss";

const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageConversation = () => {
  return (
    <Layout
      content={<Chats />}
      widgets={widgets}
      className="Layout--pageConversation"
    />
  );
};

export default PageConversation;
