import {
  CHOOSE_START_DATE,
  CHOOSE_END_DATE,
  CHOOSE_DATES,
} from "../actionTypes/dateTypes";

export const chooseDates = (dates) => {
  return {
    type: CHOOSE_DATES,
    payload: dates,
  };
};

export const chooseStartDate = (date) => {
  return {
    type: CHOOSE_START_DATE,
    payload: date,
  };
};

export const chooseEndDate = (date) => {
  return {
    type: CHOOSE_END_DATE,
    payload: date,
  };
};
