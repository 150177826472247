import { useRef, useState } from "react";

import cn from "classnames";

import "./FormChoicesLinkSubmit.scss";

import Svg from "../../Svg";
import useAutoClose from "../../../hook/useAutoClose";
import {Tooltip, withStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: "#212230",
    backgroundColor: "#fff",
    maxWidth: '20rem',
    fontSize: '1.1rem',
    padding: '0.5rem 1rem',
    border: "1px solid #fff",
    borderRadius: "0.8rem",
  }
}))(Tooltip);


const FormChoicesLinkSubmit = ({
  label,
  labelHidden = false,
  icon = false,
  values,
  defaultValue = null,
  disabled = false,
  onChange,
  ...rest
}) => {
  const history = useHistory();
  const select = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useAutoClose({ setIsOpen, ref: select });

  const handleChange = (value) => {
    onChange(value);
  };

  const defaultValueCalculated = defaultValue || values[0];

  const listItems = values.map((value) => {
    return (
      <li
        key={value.id}
        className={cn("FormChoicesSelect-listItem", {
          "FormChoicesSelect-listItems--active":
            value.id === defaultValueCalculated.id,
          "FormChoicesSelect-listItems--disabled": value.isDisabled,
        })}
        onClick={() => {
          handleChange(value);
          setIsOpen(false);
        }}
      >
        <span>{value.label}</span>
      </li>
    );
  });

  const handleClick = () => {
    history.push(defaultValueCalculated.link);
  }

  return (
    <div className="FormLinkSubmit">
      <div
        ref={select}
        className={cn("FormChoicesSelect", {
          "FormChoicesSelect--iconed": icon,
          "FormChoicesSelect--open": isOpen,
          "FormChoicesSelect--disabled": disabled,
        })}
      >
        <label
          className={cn("FormChoicesSelect-label", {
            "FormChoicesSelect-label--hidden": labelHidden
          })}
          htmlFor={rest.id}
        >
          {label}
        </label>
        <div className="FormChoicesSelect-field">
          <button onClick={handleClick} className="FormChoicesLinkSubmit-button" {...rest}>
            {label}
          </button>
            {/*<span className="FormSelect-togglerText">*/}
            {/*  {defaultValueCalculated.label}*/}
            {/*</span>*/}
          <HtmlTooltip
            title={"Impel or CDK"}
            placement="bottom"
          >
            <button
              className={cn("FormChoicesSelect-toggler", {
                "FormChoicesSelect-toggler--disabled": disabled
              })}
              type="button"
              disabled={disabled}
              aria-expanded={isOpen}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen((isOpen) => !isOpen);
              }}
            >
              <span className="FormChoicesSelect-togglerIcon">
                <Svg w="9" h="6" i="markerDropdown" />
              </span>
            </button>
          </HtmlTooltip>
          <ul className="FormChoicesSelect-list" aria-hidden={!isOpen}>
            {listItems}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormChoicesLinkSubmit;
