import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Calendar } from 'react-date-range';
import cn from "classnames";

import "./DatePicker.scss";

import useAutoClose from "../../../../hook/useAutoClose";
import moment from "moment";

const DatePicker = ({ dateValue, eventName, hoursArr, setHolidaysArr, eventIndex }) => {
  const dropdown = useRef(null);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dateValue);
  const dateRange = useSelector((state) => state.date);

  useAutoClose({ setIsOpen, ref: dropdown });

  useEffect(() => {
    setSelectedDate(dateValue)
  },[dateValue])

  const handleSelectDate = (value) => {
    setSelectedDate(value);
    const arr = hoursArr.holidays.map((el, index) => index === eventIndex ? { ...el, date: moment(value).format("YYYY/MM/DD") } : { ...el });

    setHolidaysArr({ ...hoursArr, holidays: [ ...arr ]});
  };

  return (
    <div
      ref={dropdown}
      className={cn("DateRangePicker", {
        "DateRangePicker--open": isOpen,
      })}
      onClick={(e) => {
        if (e.target.closest("button.rdrStaticRange")) setIsOpen(false);
      }}
    >
      <label className="DateRangePicker-label">Date</label>
      <button
        className={cn("DateRangePicker-toggler")}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        <span className="DateRangePicker-togglerText">{moment(selectedDate).format("MM/DD/YYYY")}</span>
        <span className="DateRangePicker-togglerIcon">
          <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20">
            <path id="calendar-days-solid" d="M5,0A1.249,1.249,0,0,1,6.25,1.25V2.5h5V1.25a1.25,1.25,0,0,1,2.5,0V2.5h1.875A1.875,1.875,0,0,1,17.5,4.375V6.25H0V4.375A1.875,1.875,0,0,1,1.875,2.5H3.75V1.25A1.249,1.249,0,0,1,5,0ZM0,7.5H17.5V18.125A1.875,1.875,0,0,1,15.625,20H1.875A1.875,1.875,0,0,1,0,18.125Zm2.5,3.125v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,5,11.875v-1.25A.627.627,0,0,0,4.375,10H3.125A.627.627,0,0,0,2.5,10.625Zm5,0v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,10,11.875v-1.25A.627.627,0,0,0,9.375,10H8.125A.627.627,0,0,0,7.5,10.625ZM13.125,10a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,15,11.875v-1.25A.627.627,0,0,0,14.375,10ZM2.5,15.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,5,16.875v-1.25A.627.627,0,0,0,4.375,15H3.125A.627.627,0,0,0,2.5,15.625ZM8.125,15a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,10,16.875v-1.25A.627.627,0,0,0,9.375,15Zm4.375.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,15,16.875v-1.25A.627.627,0,0,0,14.375,15h-1.25A.627.627,0,0,0,12.5,15.625Z" fill="#adb5bd"/>
          </svg>
        </span>
      </button>
      <div className="DateRangePicker-dropdown" aria-hidden={!isOpen}>
        {
          <Calendar
            date={selectedDate}
            onChange={handleSelectDate}
          />
        }
      </div>
    </div>
  );
};

export default DatePicker;
