import { useEffect, useState, useRef, useCallback } from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import cn from "classnames";

import Svg from "../../Svg";

import useWindowSize from "../../../hook/useWindowSize";

import "./SidebarMenuLink.scss";

const useAutoClose = ({ setIsOpen, ref }) => {
  const handleClosure = useCallback(
    (e) => {
      if (!ref.current || ref.current.parentElement.contains(e.target))
        return false;

      return setIsOpen(false);
    },
    [setIsOpen, ref]
  );

  useEffect(() => {
    window.addEventListener("mousemove", handleClosure);

    return () => {
      window.removeEventListener("mousemove", handleClosure);
    };
  }, [handleClosure, ref]);
};

const SidebarMenuLink = ({ label, path, icon, exact, subroutes }) => {
  const refLink = useRef(null);
  const refMenu = useRef(null);

  const windowSize = useWindowSize();
  const match = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);

  useAutoClose({ setIsOpen, ref: refLink });

  useEffect(() => {
    const instance = refLink.current;

    instance.addEventListener("touchstart", handleTouch, {
      passive: false,
    });

    return () => {
      instance.removeEventListener("touchstart", handleTouch, {
        passive: false,
      });
    };
  }, [refLink]);

  useEffect(() => {
    const menu = refMenu.current;

    if (menu) {
      if (isOpen) {
        const menuRect = menu.getBoundingClientRect();

        if (menuRect.right > windowSize.width) {
          menu.style.transform = `translateX(calc(-50% - ${
            menuRect.right - windowSize.width + 20
          }px))`;
        }
      } else {
        menu.style.transform = "";
      }
    }
  }, [isOpen, refMenu, windowSize]);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleTouch = (e) => {
    e.preventDefault();

    setIsOpen((state) => {
      return !state;
    });
  };

  const submenu = subroutes && (
    <ul className="SidebarMenuLink-submenu" ref={refMenu}>
      {subroutes.map((subroute) => {
        return (
          <li key={subroute.label} className="SidebarMenuLink-submenuItem">
            <Link to={subroute.path} className="SidebarMenuLink-submenuLink">
              {subroute.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );

  const isActive = path === "/conversations" && match.path.indexOf("/conversation") > -1;
  const indexCDK = window.location.hostname;
  const isCDK = indexCDK.includes("crmaiva");

  return (
    <div className="SidebarMenuLink">
      <NavLink
        ref={refLink}
        className={cn("SidebarMenuLink-button", {
          "is-active": isActive,
          "is-open": isOpen,
        })}
        activeClassName="is-active"
        to={path}
        exact={exact}
        aria-label={label}
        onMouseEnter={handleMouseEnter}
      >
        <Svg w="24" h="24" i={`${icon}${isCDK ? "-CDK" : ""}`} />
      </NavLink>
      {submenu}
    </div>
  );
};

export default SidebarMenuLink;
