import Layout from "../../components/Layout/Layout";
import DashboardCampaigns from "../../components/DashboardCampaigns";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import "./index.scss";
import ProductSelector from "../../components/ProductSelector/ProductSelector";


const PageDashboardCampaigns = () => {
  const isCDK = window.location.hostname.includes('crmaiva');

  const widgets = {
    primary: [
      <DateRangePicker type="date" key="WidgetDateRange" label="Date Filter" />,
    ],
  };
  if (!isCDK) {
    widgets.secondary = [<ProductSelector key="WidgetProductSelector" />]
  }

  let content = <DashboardCampaigns />;

  return <Layout content={content} widgets={widgets} />;
};

export default PageDashboardCampaigns;
