import FormElement from "../../../../FormElement/FormElement";
import "./index.scss";

const CampaignVariables = ({ form, setForm, VARIABLES, noTitle }) => {
  if (noTitle) {
    return (
      <div className="CampaignVariables-Container">
        <div className="CampaignVariables-Settings-Container">
          <span className="CampaignVariables-BlockTitle"></span>
          <div className="CampaignVariables">
            {VARIABLES.map((variable) => (
              <div className="CampaignVariables-input" key={variable.Id}>
                <FormElement
                  label={`${variable.Name}:`}
                  type={variable.Type}
                  id={variable.Id}
                  name={variable.Id}
                  value={form?.Config?.[variable.Id]}
                  onChange={(e) => {
                    if (variable.Type === "numfield") {
                      const value = Number(e.target.value);
                      if (value < variable.min || value > variable.max) {
                        return;
                      }
                      setForm({ [variable.Id]: e.target.value });
                    } else {
                      setForm({ [variable.Id]: e.target.value });
                    }
                  }}
                  placeholder={variable.Placeholder}
                  style={{ width: "30vw" }}
                  min={variable.min}
                  max={variable.max}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="CampaignVariables-Container">
      <div className="CampaignVariables-Settings-Container">
        <span className="CampaignVariables-BlockTitle">Variables</span>
        <div className="CampaignVariables">
          {VARIABLES.map((variable) => (
            <div className="CampaignVariables-input" key={variable.Id}>
              <FormElement
                label={`${variable.Name}:`}
                type={variable.Type}
                id={variable.Id}
                name={variable.Id}
                value={form?.Config?.[variable.Id]}
                onChange={(e) => {
                  if (variable.Type === "numfield") {
                    const value = Number(e.target.value);
                    if (value < variable.min || value > variable.max) {
                      return;
                    }
                    setForm({ [variable.Id]: e.target.value });
                  } else {
                    setForm({ [variable.Id]: e.target.value });
                  }
                }}
                placeholder={variable.Placeholder}
                style={{ width: "30vw" }}
                min={variable.min}
                max={variable.max}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignVariables;
