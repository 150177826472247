const getInteger = (str, length) => {
  if (str === "*") return "*";

  const int = parseInt(str.slice(-length));

  return isNaN(int) ? null : int;
};

const getIntNormalized = (int, max = null) => {
  if (int === null) return null;
  if (int === "*") return "*";

  if (max && int > max) return max;

  return int;
};

const getFragmentStr = (fragment, fragmentLength) => {
  if (fragment === null) return "";
  if (fragment === "*") return "*";

  let fragmentString = fragment.toString();

  if (fragmentLength > fragmentString.length)
    fragmentString = `0${fragmentString}`;

  return fragmentString;
};

const getStr = (day, month, year) => {
  if (!month && !day && !year) return "";

  if (month.length < 2 && month !== "*" && !day && !year) return month;

  if (!day && !year) return `${month} / `;

  if (day.length < 2 && day !== "*" && !year) return `${month} / ${day}`;

  if (!year) return `${month} / ${day} / `;

  return `${month} / ${day} / ${year}`;
};

export const getDateComponents = (string = "") => {
  //Clean Up
  const parts = string.replace(/[^0-9/*]/g, "").split("/");

  // Fragments
  const rawMonth = parts[0] || "";
  const rawDay = parts[1] || "";
  const rawYear = parts[2] || "";

  // Fragments length
  const rawDayLength = rawDay.length;
  const rawMonthLength = rawMonth.length;
  const rawYearLength = rawYear.length;

  // Convert to Integers and cut up to target length
  const intDay = getInteger(rawDay, 2);
  const intMonth = getInteger(rawMonth, 2);
  const intYear = getInteger(rawYear, 4);

  // Correct day
  const intDayNormalized = getIntNormalized(intDay, 31);

  // Correct month
  const intMonthNormalized = getIntNormalized(intMonth, 12);

  // Make string fragments
  const dayStr = getFragmentStr(intDayNormalized, rawDayLength);
  const monthStr = getFragmentStr(intMonthNormalized, rawMonthLength);
  const yearStr = getFragmentStr(intYear, rawYearLength);

  const datestring = getStr(
    dayStr,
    monthStr,
    yearStr,
    rawDayLength,
    rawMonthLength,
    rawYearLength
  );

  return { datestring, dayStr, monthStr, yearStr };
};
