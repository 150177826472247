import React, { useState } from "react";
import "./QuestionMark.scss";

import { Tooltip, withStyles } from "@material-ui/core";

import Svg from "../../Svg";

const QuestionMark = ({ definition, w, h }) => {
  const [popUpShown, setPopupShown] = useState(false);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#212230",
      color: "#fff",
      maxWidth: '20rem',
      fontSize: '1.1rem',
      padding: '1rem',
      border: "1px solid #fff",
      borderRadius: "0.8rem",
    },
    arrow: {
      "&:before": {
        border: "1px solid #212230"
      },
      color: "#212230"
    },
  }))(Tooltip);

  return definition ? (
    <HtmlTooltip
      placement="top"
      arrow
      title={
        <React.Fragment>
          {definition}
        </React.Fragment>
      }
    >
      <a className="TileSquare-QuestionMark">
        <Svg w={w || "20"} h={h || "20"} i="questionMark" />
      </a>
    </HtmlTooltip>
  ) : (
    <></>
  );
};

export default QuestionMark;
