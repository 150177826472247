import React, { useState } from "react";
import "./ChatTabs.scss";
import { useDispatch, useSelector } from "react-redux";
import ChatConversationContext from "../ChatConversationContext/ChatConversationContext";
import CloseIcon from "@material-ui/icons/Close";
import ChatContactForm from "../ChatContactForm/ChatContactForm";
import ChatAppointmentForm from "../ChatAppointmentForm/ChatAppointmentForm";
import {
  isAppointmentFormOpen,
  isChatFormOpen,
  setActiveTab,
} from "../../../store/actions/conversationDealerChatActions";
import Confirmation from "../../Confirmation/Confirmation";

const ChatTabs = ({
  data,
  onToggleDetailsCollapsed,
  selectedProductId,
  highlightedEvents,
  setShowSnackbar,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.conversationDealerChat.isOpen);
  const appointmentFormOpen = useSelector(
    (state) => state.conversationDealerChat.isAppointmentFormOpen
  );
  const activeTab = useSelector(
    (state) => state.conversationDealerChat.setActiveTab
  );
  const [isCancelOpen, setIsCancelOpen] = useState(false);

  const handleTabClick = (index) => {
    dispatch(setActiveTab(index));
  };

  return (
    <>
      <div className="tabs">
        <Confirmation
          isOpen={isCancelOpen}
          closeDialog={() => {
            setIsCancelOpen(false);
          }}
          title="Close Form"
          text="Closing this form will discard any unsaved changes. &&
Do you want to continue?"
          cancelButtonTitle="CANCEL"
          confirmButtonTitle="CONTINUE"
          handleConfirmDelete={() => {
            dispatch(isChatFormOpen(false));
            dispatch(isAppointmentFormOpen(false));
          }}
        />
        <div className="tab-list">
          <div
            className={`tab ${activeTab === 0 ? "active" : "inactive"}`}
            onClick={() => handleTabClick(0)}
          >
            Conversation Context
          </div>
          <div
            className={`tab ${activeTab === 1 ? "active" : "inactive"}`}
            onClick={() => handleTabClick(1)}
          >
            Submit a Form{" "}
            <div
              className="close-icon"
              onClick={() => {
                setIsCancelOpen(true);
              }}
            >
              <CloseIcon style={{ width: "20px", color: "black" }} />
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div style={activeTab !== 0 ? { display: "none" } : {}}>
            <ChatConversationContext
              data={data}
              onToggleDetailsCollapsed={onToggleDetailsCollapsed}
              selectedProductId={selectedProductId}
              highlightedEvents={highlightedEvents}
            />
          </div>
          <div style={activeTab === 0 ? { display: "none" } : {}}>
            {appointmentFormOpen && (
              <ChatAppointmentForm setShowSnackbar={setShowSnackbar} />
            )}
            {isOpen && <ChatContactForm setShowSnackbar={setShowSnackbar} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatTabs;
