export default [
  {
    id: "engagedLeads",
    label: "Engaged Leads",
  },
  {
    id: "newProcessedLeads",
    label: "Customers Messaged",
  },
  {
    id: "acquiredPhoneNumbersLeads",
    label: "Acquired Phone Numbers",
  },
  {
    id: "closedRosPage",
    label: "Closed ROs"
  }
];
