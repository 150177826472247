import { useEffect, useRef, useState } from "react";
import useData from "../../../hook/useData";
import FormElement from "../../FormElement";
import Loader from "../../Loader/Loader";

import "./ChatsReply.scss";

const ChatsReply = ({
  conversation,
  selectedChannel,
  refetchConversation,
  unsubscribed,
}) => {
  const answerPanelContainer = useRef(null);
  const [newMessageObject, setNewMessageObject] = useState({
    text: "",
    html: "",
  });

  const {
    data: replyMessageResponse,
    execute: replyMessage,
    status: replyMessageStatus,
  } = useData("replyMessage", { newMessageObject }, [newMessageObject], false);

  const {
    data: signatureResponse,
    execute: getSignature,
    status: signatureStatus,
  } = useData(
    "getSignature",
    {
      UserId: conversation?.[0]?.UserId,
      MessageId: conversation?.[conversation.length - 1]?.MessageId,
    },
    [conversation],
    false
  );

  useEffect(() => {
    if (!conversation?.length || !answerPanelContainer.current) {
      return;
    }

    if (unsubscribed) {
      return;
    }

    let channelFilteredDialog = conversation;
    if (selectedChannel && selectedChannel.Id !== "*") {
      channelFilteredDialog = channelFilteredDialog.filter(
        (item) => item.Channel === selectedChannel.Id
      );
    }

    if (channelFilteredDialog.length === 0) {
      answerPanelContainer.current.innerText = "";
      answerPanelContainer.current.innerHTML = "";
      setNewMessageObject({
        text: "",
        html: "",
      });
      return;
    }

    const lastMessage = channelFilteredDialog[channelFilteredDialog.length - 1];
    const currentChannel = lastMessage.Channel;
    if (currentChannel === "sms") {
      const smsSignature = "<br><br>Opt-out reply STOP";
      answerPanelContainer.current.innerText = smsSignature;
      answerPanelContainer.current.innerHTML = smsSignature;
      setNewMessageObject({
        text: smsSignature,
        html: smsSignature,
        id: conversation[0].ConversationId,
        selectedChannel: currentChannel,
      });
    } else {
      answerPanelContainer.current.innerText = "";
      answerPanelContainer.current.innerHTML = "";
      setNewMessageObject({
        text: "",
        html: "",
        id: conversation[0].ConversationId,
        selectedChannel: currentChannel,
      });
      getSignature();
    }
  }, [conversation, selectedChannel, unsubscribed]);

  useEffect(() => {
    if (unsubscribed) {
      return;
    }

    if (
      signatureResponse &&
      answerPanelContainer &&
      answerPanelContainer.current
    ) {
      if (!conversation?.length || !answerPanelContainer.current) return;
      let channelFilteredDialog = conversation;
      if (selectedChannel && selectedChannel.Id !== "*") {
        channelFilteredDialog = channelFilteredDialog.filter(
          (item) => item.Channel === selectedChannel.Id
        );
      }
      const lastMessage =
        channelFilteredDialog[channelFilteredDialog.length - 1];
      const currentChannel = lastMessage.Channel;
      if (currentChannel === "email") {
        const emailSignature = `<br><br>${signatureResponse.data}`;
        answerPanelContainer.current.innerText = emailSignature;
        answerPanelContainer.current.innerHTML = emailSignature;

        setNewMessageObject({
          ...newMessageObject,
          text: emailSignature,
          html: emailSignature,
          id: conversation[0].ConversationId,
          selectedChannel: currentChannel,
        });
      }
    }
  }, [signatureResponse, getSignature, unsubscribed]);

  useEffect(() => {
    if (unsubscribed) {
      return;
    }

    if (!newMessageObject._sending) return;
    console.log("sending", newMessageObject);
    replyMessage();
  }, [newMessageObject, unsubscribed]);

  useEffect(() => {
    if (unsubscribed) {
      return;
    }

    if (
      replyMessageStatus === "success" &&
      replyMessageResponse &&
      replyMessageResponse.length
    ) {
      answerPanelContainer.current.innerText = "";
      answerPanelContainer.current.innerHTML = "";
      setNewMessageObject({
        ...newMessageObject,
        text: "",
        html: "",
        _sending: false,
      });
      refetchConversation();
    }
  }, [replyMessageStatus, unsubscribed]);

  const handleSubmit = (e) => {
    if (unsubscribed) {
      return;
    }

    e.preventDefault();

    answerPanelContainer.current.innerText =
      answerPanelContainer.current.innerHTML;
    setNewMessageObject({
      ...newMessageObject,
      text: answerPanelContainer.current.innerText.trim(),
      html: answerPanelContainer.current.innerHTML.trim(),
      _sending: true,
    });
    answerPanelContainer.current.innerHTML = "";
  };

  // console.log("CONVERSATION IS", conversation);
  // console.log("MESSAGE IS", newMessageObject);

  // console.log("replyMessageResponse", replyMessageResponse);
  // console.log("signatureResponse", signatureResponse);

  // console.log(answerPanelContainer?.current?.innerHTML);

  if (
    selectedChannel.Id !== "*" &&
    conversation?.filter((item) => item.Channel === selectedChannel.Id)
      .length === 0
  ) {
    return null;
  }
  console.log(conversation[conversation.length - 1]?.Channel);
  return (
    <form className="ChatsReply" onSubmit={handleSubmit}>
      {signatureStatus === "pending" || replyMessageStatus === "pending" ? (
        <Loader />
      ) : (
        <>
          {!unsubscribed ? (
            <div
              ref={answerPanelContainer}
              spellCheck={false}
              dangerouslySetInnerHTML={{ __html: newMessageObject.html }}
              className="ChatReply__editable-div scrollable"
              contentEditable
            ></div>
          ) : (
            <div
              ref={answerPanelContainer}
              spellCheck={false}
              dangerouslySetInnerHTML={{
                __html:
                  "Unfortunately, you cannot reply to the conversation - The lead has unsubscribed from the conversation",
              }}
              className="ChatReply__editable-div scrollable"
            ></div>
          )}
        </>
      )}
      <FormElement
        type="submit"
        label="Send"
        id="messageSend"
        name="messageSend"
        value="Submit message"
        disabled={
          conversation[conversation.length - 1]?.Channel === "whatsapp" ||
          unsubscribed ||
          signatureStatus === "pending" ||
          (selectedChannel.Id !== "*" &&
            conversation?.filter((item) => item.Channel === selectedChannel.Id)
              .length === 0)
        }
      />
    </form>
  );
};

export default ChatsReply;
