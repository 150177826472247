import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import FormElement from "../../../FormElement";
import Loader from "../../../Loader";

import useData from "../../../../hook/useData";
import reducer from "./reducer";

import { HelpOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

import {
  saveSubscriptions,
  savePrincipals,
} from "../../../../store/actions/settingActions";

import FORM_FIELDS from "./formStructure";

import CHANNELS from "./channels";

import "./SettingsNotificationsForm.scss";
import useDebounce from "../../../../hook/useDebounce";

const defaultState = {
  ...FORM_FIELDS.reduce((res, field) => {
    return { ...res, [field.name]: "" };
  }, {}),
  Channels: [],
};

const SettingsNotificationsForm = ({
  data = defaultState,
  onUpdate,
  type,
  readOnly,
  campaigns = false,
}) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, data);
  const [apiBody, setApiBody] = useState()
  const [error, setError] = useState(null);
  const [debounce, setDebounce] = useState({});
  const [stateChanged, setStateChanged] = useState(false);

  const debouncedState = useDebounce(debounce, 300);

  const { notificationSubscriptions, notificationPrincipals } = useSelector(
    (state) => state.setting
  );

  const prepareEndpoint = (uid) => {
    if (uid) {
      return "updateDealerChatSubscriberData";
    } else {
      return "createDealerChatSubscriberData";
    }
  };

  const uid = data.Id //data.UserId;

  const endpoint = prepareEndpoint(uid);

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData(endpoint, apiBody, [apiBody], false);

  const {
    data: subscriptionsData,
    status: subscriptionsStatus,
    execute: subscriptionsExecute,
  } = useData("getDealerChatNotificationSettingsSubscriptions", {}, [], true);

  const {
    data: principalsData,
    status: principalsStatus,
    execute: principalsExecute,
  } = useData(
    "getDealerChatNotificationSettingsSubscriptionsPrincipals",
    {},
    [],
    true
  );

  useEffect(() => {
    if (!notificationSubscriptions && subscriptionsStatus === "init")
      subscriptionsExecute();

    if (!notificationPrincipals && principalsStatus === "init")
      principalsExecute();
  });

  useEffect(() => {
    if (subscriptionsStatus === "success") {
      reduxDispatch(saveSubscriptions(subscriptionsData));
    }
  }, [subscriptionsData, subscriptionsStatus, reduxDispatch]);

  useEffect(() => {
    if (principalsStatus === "success") {
      reduxDispatch(savePrincipals(principalsData));
    }
  }, [principalsData, principalsStatus, reduxDispatch]);

  useEffect(() => {
    if (updateStatus === "success") onUpdate(updateData, uid);
  }, [updateData, updateStatus, uid, onUpdate]);

  useEffect(() => {
    if(uid){
      setApiBody({
        subscriber_data: { 
          Channels: state?.Channels,
          Email: state?.Email,
          FirstName: state?.FirstName,
          LastName: state?.LastName,
          MainPhone: state?.MainPhone
        }, 
        subscriber_id: state.Id 
      })
    }else{
      setApiBody({
        subscriber_data: { 
          Channels: state?.Channels,
          Email: state?.Email,
          FirstName: state?.FirstName,
          LastName: state?.LastName,
          MainPhone: state?.MainPhone
        }
      });
    }
  }, [state])

  const handleChangeInput = (value, field) => {
    setStateChanged(true);
    if (field !== "CrmUserId") {
      setDebounce({});
    }

    dispatch({
      type: "changeInput",
      payload: { value, field },
    });
  };

  const handleToggleNotification = (checked, channel, notification) => {
    dispatch({
      type: "toggleNotification",
      payload: { checked, channel, notification, notificationPrincipals },
    });
  };

  const handleTogglePrincipal = (checked, channel, notification, principal) => {
    dispatch({
      type: "togglePrincipal",
      payload: { checked, channel, notification, principal },
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null);
    updateExecute();
  };

  const phoneErrorMessage = (text) => {
    if (!text) return "";
    const reg = /^\d+$/;
    if (text.charAt(0) === "+") {
      text = text.substr(1);
    }
    if (reg.test(text)) {
      return "";
    }
    return "Please enter valid phone number (example: +1xxxxxxxxx)";
  };

  const disabledActionButton = () => {
    let error = false;

    if (state["MainPhone"] && phoneErrorMessage(state["MainPhone"])) {
      error = true;
    }

    if (state["SecondaryPhone"] && phoneErrorMessage(state["SecondaryPhone"])) {
      error = true;
    }

    if (!state?.Channels?.length) {
      error = true;
    }

    return error;
  };

  const fields = FORM_FIELDS.map((field) => {
    if (field.hidden) {
      return null;
    }
    if (campaigns && field.id === "addPersonID") {
      return null;
    }

    return (
      <div
        key={field.id}
        className={cn(
          "SettingsNotificationsForm-field",
          `SettingsNotificationsForm-field--${field.type}`
        )}
      >
        <FormElement
          errorMessage={
            (["MainPhone", "SecondaryPhone"].includes(field.name) &&
              phoneErrorMessage(state[field.name])) 
          }
          onChange={(e) => handleChangeInput(e.target.value, field.name)}
          value={state[field.name] || ""}
          // disabled={field.name === "CrmUserId" && crmUserStatus === "pending"}
          readOnly={readOnly}
          {...field}
        />
      </div>
    );
  });

  const render = () => {
    if (subscriptionsStatus === "error") {
      return error || "Error";
    }

    if (
      !notificationSubscriptions &&
      (subscriptionsStatus === "pending" ||
        subscriptionsStatus === "init" ||
        notificationSubscriptions === null)
    ) {
      return <Loader />;
    }

    if (
      !notificationPrincipals &&
      (principalsStatus === "pending" ||
        principalsStatus === "init" ||
        notificationPrincipals === null)
    ) {
      return <Loader />;
    }

    const groups = CHANNELS.map((channel) => {
      const stateChannels = state.Channels;
      
      const currentChannel = stateChannels.find((personChannel) => {
        return channel.id === personChannel.Channel;
      });
      
      const enabledNotifications =
        (currentChannel && currentChannel.SubscribedNotifications) || [];
        
      const items = notificationSubscriptions?.map((notification) => {
        const enabledNotification = enabledNotifications.find(
          (enabledNotification) => enabledNotification?.Id === notification?.Id
        );

        let enabledPrincipals =
          (enabledNotification && enabledNotification.Principals) || [];
        enabledPrincipals = enabledPrincipals.filter(
          (principal) => principal.Status === true
        );
        const enabledPrincipalIds = enabledPrincipals.map(
          (enabled) => enabled?.Id
        );

        const principals = notificationPrincipals.map((principal) => {
          const enabledPrincipal = enabledPrincipals.find(
            (enabledPrincipal) => enabledPrincipal?.Id === principal?.Id
          );

          return (
            <div
              key={principal.Id}
              className="SettingsNotificationsForm-principal"
            >
              <FormElement
                type="checkbox"
                id={principal.Id}
                value={principal.Id}
                label={principal.Name}
                checked={!!enabledPrincipal}
                onChange={() =>
                  handleTogglePrincipal(
                    !enabledPrincipal,
                    channel.id,
                    notification.Id,
                    principal.Id
                  )
                }
                disabled={
                  readOnly ||
                  (principal.Id === "OnlyAssigned") ||
                  (!enabledPrincipal &&
                    principal.Id === "OnlyAssigned" &&
                    enabledPrincipalIds.includes("OnlyOrphan")) ||
                  (!enabledPrincipal &&
                    principal.Id === "OnlyOrphan" &&
                    enabledPrincipalIds.includes("OnlyAssigned"))
                }
              />
            </div>
          );
        });

        return (
          <div key={notification.Id} className="SettingsNotificationsForm-item">
            <div className="SettingsNotificationsForm-checkbox-container">
              <div className="SettingsNotificationsForm-checkbox">
                <FormElement
                  type="checkbox"
                  id={notification.Id}
                  value={notification.Id}
                  label={notification.Name}
                  checked={!!enabledNotification}
                  onChange={() =>
                    handleToggleNotification(
                      !enabledNotification,
                      channel.id,
                      notification.Id
                    )
                  }
                />
              </div>
              <div className="questionmark">
                <Tooltip
                  title={
                    <p style={{ fontSize: "1.3vh" }}>
                      {notification.Description}
                    </p>
                  }
                >
                  <HelpOutline style={{ fill: "grey", cursor: "pointer" }} />
                </Tooltip>
              </div>
            </div>
            {!!enabledNotification && (
              <div className="SettingsNotificationsForm-principals">
                {principals}
              </div>
            )}
          </div>
        );
      });

      return (
        <div key={channel.id} className="SettingsNotificationsForm-group">
          <div className="SettingsNotificationsForm-groupTitle">
            {channel.name}
          </div>
          <div className="SettingsNotificationsForm-groupContent">{items}</div>
        </div>
      );
    });

    return groups;
  };

  return (
    <form
      className={cn("SettingsNotificationsForm", {
        "SettingsNotificationsForm--disabled": updateStatus === "pending",
      })}
      onSubmit={handleFormSubmit}
    >
      <div className="SettingsNotificationsForm-frame"></div>
      {error && <div className="SettingsNotificationsForm-errors">{error}</div>}
      <div className="SettingsNotificationsForm-primary">{fields}</div>
      <div className="SettingsNotificationsForm-secondary">{render()}</div>
      {!readOnly && (
        <div className="SettingsNotificationsForm-actions">
          <FormElement
            type="submit"
            label={`${type} personnel`}
            disabled={disabledActionButton()}
          />
        </div>
      )}
    </form>
  );
};

export default SettingsNotificationsForm;
