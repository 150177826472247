import {
  isAppointmentFormOpen,
  setActiveTab,
} from "../actions/conversationDealerChatActions";
import {
  CHOOSE_CONVERSATIONS_PAGE,
  CHOOSE_CONVERSATIONS_ID,
  CHOOSE_CONVERSATIONS_ENDPOINT,
  CHOOSE_CONVERSATIONS_QUERY,
  CHOOSE_CONVERSATIONS_MODE,
  CHOOSE_CONVERSATIONS_SOURCE,
  CHOOSE_CONVERSATIONS_LEAD_TYPE,
  CHOOSE_CONVERSATIONS_METRIC_TYPE,
  CHOOSE_CONVERSATIONS_SubMetric_TYPE,
  CHOOSE_CONVERSATIONS_PersonId_TYPE,
  CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION,
  CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN,
  CHOOSE_COVERSATIONS_CHAT_REFETCH,
  CHOOSE_CONVERSATIONS_APPOINTMENT_FORM_OPEN,
  CHOOSE_CONVERSATIONS_SORT,
  SET_ACTIVE_TAB,
} from "../actionTypes/conversationDealerChatTypes";

const initialState = {
  conversationsPage: 1,
  conversationsId: null,
  conversationsEndpoint: "conversations_total_chat_leads_worked",
  conversationsMetric: "total_chat_leads_worked",
  conversationsSubMetric: "",
  conversationsQuery: "",
  conversationsMode: "simple",
  conversationsSource: null,
  conversationsPersonId: "",
  conversationsSortColumn: null,
  conversationsSortDirection: null,
  valid: true,
  isOpen: false,
  refetch: false,
  isAppointmentFormOpen: false,
  setActiveTab: 0,
};

const conversationDealerChat = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_CONVERSATIONS_PAGE:
      return {
        ...state,
        conversationsPage: action.payload,
      };

    case CHOOSE_CONVERSATIONS_ID:
      return {
        ...state,
        conversationsId: action.payload,
      };

    case CHOOSE_CONVERSATIONS_ENDPOINT:
      return {
        ...state,
        conversationsEndpoint: action.payload,
        conversationsId: null,
      };

    case CHOOSE_CONVERSATIONS_QUERY:
      return {
        ...state,
        conversationsQuery: action.payload,
      };

    case CHOOSE_CONVERSATIONS_MODE:
      return {
        ...state,
        conversationsMode: action.payload,
      };

    case CHOOSE_CONVERSATIONS_SOURCE:
      return {
        ...state,
        conversationsSource: action.payload,
      };

    case CHOOSE_CONVERSATIONS_LEAD_TYPE:
      return {
        ...state,
        conversationLeadType: action.payload,
      };

    case CHOOSE_CONVERSATIONS_METRIC_TYPE:
      return {
        ...state,
        conversationsMetric: action.payload,
      };

    case CHOOSE_CONVERSATIONS_SubMetric_TYPE:
      return {
        ...state,
        conversationsSubMetric: action.payload,
      };

    case CHOOSE_CONVERSATIONS_PersonId_TYPE:
      return {
        ...state,
        conversationsPersonId: action.payload,
      };
    case CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION:
      return {
        ...state,
        valid: action.payload,
      };

    case CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };

    case CHOOSE_CONVERSATIONS_APPOINTMENT_FORM_OPEN:
      return {
        ...state,
        isAppointmentFormOpen: action.payload,
      };

    case CHOOSE_COVERSATIONS_CHAT_REFETCH:
      return {
        ...state,
        refetch: action.payload,
      };

    case CHOOSE_CONVERSATIONS_SORT:
      return {
        ...state,
        conversationsSortColumn: action.payload.column,
        conversationsSortDirection: action.payload.direction
      }

    case SET_ACTIVE_TAB:
      return {
        ...state,
        setActiveTab: action.payload,
      };

    default:
      return state;
  }
};

export default conversationDealerChat;
