import { settingsCMS } from "../initState/SettingsCMS";

export function settingsCMSReducer(state, action) {
    switch (action.type) {
      case "changePrimitiveType":
        return { ...state, [action.propertyId]: action.value };
      case "initTopics":
        return { ...state, topics: action.value };
      case "questionIndexEdit":
        return { ...state, questionIndex: action.value };
      case "modelTypeEdit":
        return { ...state, modelType: action.value };
      case "initCurrentTopicId":
        return { ...state, currentTopicId: action.value };
      case "changeAnswer":
        state.topics.find(el => el.topic_id === action.topicId).questions.find(el => el.question_id === action.questionId).answer = action.value
        return { ...state };
      case "addQuestion":
        state.topics.find(el => el.topic_id === action.topicId).questions.push({ custom: true, title: action.value, question_id: action.questionId, answer: "" })
        return { ...state };
      case "addTopic":
        state.topics.push({ custom: true, label: action.value, topic_id: action.topicId, questions: [] })
        return { ...state };
      case "deleteQuestion":
        const indexQuestion = state.topics.find(el => el.topic_id === action.topicId).questions.findIndex(el => el.question_id === action.questionId);
        state.topics.find(el => el.topic_id === action.topicId).questions.splice(indexQuestion, 1);
        return { ...state };
      case "deleteTopic":
        const indexTopic = state.topics.findIndex(el => el.topic_id === action.topicId);
        state.topics.splice(indexTopic, 1);
        return { ...state };
      case "initDeleteState":
        return { ...state, deleteState: action.value };
      case "reset":
        return { ...settingsCMS };
    }
}