import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";

const enchancers = [];

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "development") {
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();

  if (typeof devToolsExtension === "function") {
    enchancers.push(devToolsExtension);
  }
}

function configureStore(initialState = {}) {
  const store = createStore(
    createRootReducer(),
    initialState,
    compose(applyMiddleware(thunk), ...enchancers)
  );

  return store;
}

export default configureStore;
