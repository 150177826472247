import { useEffect, useState } from "react";
import cn from "classnames";

import Loader from "../Loader";
import FormElement from "../FormElement";

import useData from "../../hook/useData";

import FORM_FIELDS from "./formStructure";

import "./User.scss";
import { useDispatch, useSelector } from "react-redux";
import DealerShip from "./DealerShip/DealerShipSettings";
import { switchActionForUserSettings } from "../../store/actions/userActions";

const User = () => {
  const [state, setState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formError, setFormError] = useState(null);
  const [childAccountId, setChildAccountId] = useState(
    useSelector((state) => state.user.auth?.data?.user_info?.DealerId)
  );

  // const { superAccountId } = useSelector((state) => state.user.auth.data.user_info);

  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  let policy = useSelector((state) => state.user.auth.data.user_info?.Policy);

  let userPolicy = policy?.filter((policy) => policy?.Resource === "UserEdit");
  userPolicy = userPolicy?.length > 0 ? userPolicy[0]?.Policy : null;

  let PROCESSED_FORM_FIELDS = FORM_FIELDS.map((fieldStr) =>
    fieldStr.key !== "Email"
      ? fieldStr
      : { ...fieldStr, disabled: !userPolicy?.includes(fieldStr.key) }
  );

  // if (superAccountId) {
  //   accountId = superAccountId
  // }

  const { data, status, error } = useData(
    "getProfileInfo",
    { accountId },
    [accountId],
    true
  );

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData(
    "updateProfileInfo",
    { ...formData, accountId },
    [formData, accountId],
    false
  );

  useEffect(() => {
    setState(data);
  }, [data]);

  useEffect(() => {
    if (updateStatus === "success" && !updateData.Error) {
      setState(updateData);
      setFormData(null);
    }
  }, [updateStatus, updateData]);

  const handleChangeInput = (value, name) => {
    setFormData((formData) => {
      const key = PROCESSED_FORM_FIELDS.reduce((key, item) => {
        if (item.name === name) return item.key;

        return key;
      }, null);

      if (key === "NewPassword" || key === "OldPassword") {
        const credentials = (formData && formData.Credentials) || {};

        return {
          ...formData,
          Credentials: { ...credentials, [key]: value },
        };
      }

      return { ...formData, [key]: value };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (
      formData &&
      formData.Credentials &&
      (formData.Credentials.OldPassword || formData.Credentials.NewPassword) &&
      (!formData.Credentials.OldPassword || !formData.Credentials.NewPassword)
    ) {
      setFormError(
        "Please fill both the old and the new password fields or leave them empty"
      );
    } else if (formData && formData.Email && formData.Email.length < 2) {
      setFormError("Please enter valid email");
    } else if (formData) {
      setFormError(null);
      updateExecute();
    }
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    const form = PROCESSED_FORM_FIELDS.reduce((form, field) => {
      if (field.type === "password") {
        const groupIdx = form.findIndex((el) => el.id === "credentials");

        if (groupIdx === -1) {
          return [
            ...form,
            {
              type: "group",
              id: "credentials",
              label: "Change password:",
              items: [field],
            },
          ];
        } else {
          form[groupIdx].items.push(field);
          return form;
        }
      }

      return [...form, field];
    }, []);

    const fields = form.map((field) => {
      return (
        <div className="User-formField" key={field.id}>
          <FormElement
            {...field}
            defaultValue={data[field.key] || null}
            onChange={(e) => {
              handleChangeInput(e.target.value, e.target.name);
            }}
          />
        </div>
      );
    });

    return (
      <form
        className={cn("User-form", {
          "User-form--disabled": updateStatus === "pending",
        })}
        onSubmit={handleFormSubmit}
      >
        {formError && <div className="User-formErrors">{formError}</div>}
        {fields}
        {updateData && updateData.Error && (
          <div className="User__error">{updateData.Error}</div>
        )}
        <FormElement
          type="submit"
          label="Submit changes"
          id="submit"
          disabled={!formData}
        />
      </form>
    );
  };

  return (
    <div className="User">
      <DealerShip accountId={accountId} policy={policy} />
      <div className="User-content">
        <h1 className="User-heading">User settings:</h1>
        {render()}
      </div>
    </div>
  );
};

export default User;
