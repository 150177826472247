import "./index.scss";
import cn from "classnames";
import { Button } from "@material-ui/core";
import FormElement from "../../../../../../FormElement/FormElement";
import { useState } from "react";
import CsvRow from "./CsvRow/CsvRow";

const ProcessedDataPreviewer = ({ processedData, reupload }) => {
  if (!processedData) return null;

  const successData = processedData.filter((item) => item.Valid === 1);
  const failData = processedData.filter((item) => item.Valid === 0);

  const renderColumns = (data) => {
    data = JSON.parse(JSON.stringify(data));
    data = data?.[0] || {};
    delete data.Valid;
    delete data.Reason;
    return (
      <div className="csv-data-columns">
        {Object.keys(data).map((col) => (
          <span className="csv-data-column">{col}</span>
        ))}
        <span className="csv-data-column">Status</span>
      </div>
    );
  };

  return (
    <div className="ProcessedDataPreviewer-container">
      <div className="csv-data-container">
        <p className="success">
          successfully uploaded data ({successData.length})
        </p>
        <div className="csv-data">
          {renderColumns(successData)}
          {successData.map((row) => (
            <CsvRow row={row} />
          ))}
        </div>
      </div>
      <div className="csv-data-container">
        <p className="fail">invalid data ({failData.length})</p>
        <div className="csv-data">
          {renderColumns(failData)}
          {failData.map((row) => (
            <CsvRow row={row} />
          ))}
        </div>
      </div>
      {failData.length && (
        <>
          <span>
            Data in "invalid data" section will not be uploaded. Would you like
            to make changes and re-upload data file?
          </span>
          <strong>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={reupload}
            >
              yes, upload new file
            </span>
          </strong>
        </>
      )}
    </div>
  );
};

export default ProcessedDataPreviewer;
