import "./ChatFormModalButtonsGroup.scss";
import FormElement from "../../FormElement/FormElement";
import { useDispatch } from "react-redux";
import { isChatFormValid } from "../../../store/actions/conversationDealerChatActions";

const ChatFormModalButtonsGroup = ({
  validate,
  changeModalState,
  setShrinkModal,
  closeModal,
  setIsShrinkClose,
  checkIfAllFieldsEmpty,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    validate();
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (checkIfAllFieldsEmpty()) {
      changeModalState(false);
      setShrinkModal(false);
    } else {
      setIsShrinkClose(false);
      closeModal(true);
      setShrinkModal(true);
    }
  };

  const handleShrink = (e) => {
    e.preventDefault();
    setShrinkModal(true);
    if (checkIfAllFieldsEmpty()) {
      dispatch(isChatFormValid(false));
    } else {
      dispatch(isChatFormValid(true));
    }
  };

  return (
    <div className="ChatFormModalButtonsGroup-Area">
      <div className="HorizontaButtonsGroup-Area">
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              backgroundColor: "white",
              color: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Cancel"
            id="cancelButton"
            name="cancelButton"
            value="Submit message"
            disabled={false}
            onClick={handleClose}
          />
        </div>
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              borderRadius: "7px",
              backgroundColor: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Submit"
            id="submitButton"
            name="submitButton"
            value="Submit message"
            disabled={false}
            onClick={(event) => handleSubmit(event)}
          />
        </div>
      </div>
      <FormElement
        type="submit"
        style={{
          borderRadius: "7px",
          backgroundColor: "#212230",
          borderRadius: "7px",
          marginTop: "24px",
          marginBottom: "21px",
          fontSize: "10px",
          height: "40px",
        }}
        label="back To Conversation"
        id="backToConversation"
        name="backtoConversation"
        value="Backto conversation"
        disabled={false}
        onClick={handleShrink}
      />
    </div>
  );
};

export default ChatFormModalButtonsGroup;
