import "./FormSubmit.scss";

const FormSubmit = ({ label, ...props }) => {
  delete props.handleChange;

  return (
    <div className="FormSubmit">
      <button className="FormSubmit-button" {...props}>
        {label}
      </button>
    </div>
  );
};

export default FormSubmit;
