import "./ChatsListCollapsed.scss";

const ChatsListCollapsed = ({ onToggleListCollapsed }) => {
  return (
    <div className="ChatsListCollapsed">
      <button
        className="ChatsListCollapsed-toggler"
        onClick={onToggleListCollapsed}
      >
        <span className="ChatsListCollapsed-togglerLabel">
          Show all conversations
        </span>
      </button>
    </div>
  );
};

export default ChatsListCollapsed;
