import TileDetails from "./TileDetails";
import TileSquare from "./TileSquare";
import TileSimple from "./TileSimple";

const Tile = (props) => {
  const { type } = props;

  const render = () => {
    if (type === "details") {
      return <TileDetails {...props} />;
    }
    if (type === "square") {
      return <TileSquare {...props} />;
    }
    if (type === "simple") {
      return <TileSimple {...props} />;
    }
  };

  return render();
};

export default Tile;
