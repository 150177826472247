import {
  FETCH_DEALER_SWITCH,
  FETCH_LOGIN_REJECT,
  FETCH_LOGIN_RESOLVE,
  FETCH_LOGIN_SWITCH,
  LOADING,
  SWITCH_PRODUCT,
} from "../actionTypes/userTypes";

const getSavedItem = () => {
  const item = JSON.parse(localStorage.getItem("auth"));
  if (!item) return item;
  if (item["userInfo"]) item["user_info"] = item["userInfo"];
  if (item["token"]) item["access_token"] = item["token"];

  const { user_info } = item;

  if ("selectedProduct" in user_info) {
    item.selectedProduct = user_info["selectedProduct"];
  }

  return item;
};

const savedUser = getSavedItem();
const initialState = savedUser
  ? {
      auth: {
        data: savedUser,
        isLoading: false,
        isError: false,
        errorMessage: null,
        isLogged: true,
        selectedProduct: savedUser.selectedProduct,
      },
    }
  : {
      auth: {
        data: null,
        isLoading: false,
        isError: false,
        errorMessage: null,
        isLogged: false,
        selectedProduct: null,
      },
    };

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoading: action.payload.loading,
        },
      };
    case FETCH_LOGIN_RESOLVE:
      return {
        ...state,
        auth: {
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: null,
          isLogged: true,
          selectedProduct: action.payload.selectedProduct,
        },
      };

    case FETCH_LOGIN_REJECT:
      return {
        ...state,
        auth: {
          data: null,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.errorMessage,
          isLogged: false,
          selectedProduct: null,
        },
      };

    case FETCH_LOGIN_SWITCH:
      return {
        ...state,
        auth: {
          data: { ...action.payload },
          isLoading: false,
          isError: false,
          isLogged: true,
          selectedProduct: action.payload.selectedProduct,
        },
      };
    case FETCH_DEALER_SWITCH:
      console.log("Action Payload: ", action.payload.Products);
      console.log("selectedProduct: ", action.payload.selectedProduct);
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            selectedProduct: action.payload.selectedProduct,
            user_info: {
              ...state.auth.data.user_info,
              DealerId: action.payload.DealerId,
              username: action.payload.username,
              Products: action.payload.Products,
              selectedProduct: action.payload.selectedProduct,
            },
          },
        },
      };

    case SWITCH_PRODUCT:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            selectedProduct: action.payload,
            user_info: {
              ...state.auth.data.user_info,
              selectedProduct: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default user;
