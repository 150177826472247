import DashboardTiles from "./DashboardTiles/";
import DashboardCharts from "./DashboardCharts";
import DashboardSources from "./DashboardSources";

import "./Dashboard.scss";
import DashboardCards from "./DashboardCards/DashboardCards";
import DashboardLeadSale from "./DashboardSales/DashboardSale";
import DashboardLeadSources from "./DashboardSources";
import QuestionMark from "../Tile/QuestionMark/QuestionMark";

const salesDescription = "Shoppers that were handed off to their assigned sales rep. Handoffs Awaiting Sales Response are shoppers that claim they have not yet been contacted by a sales rep and require immediate attention. Handoffs Worked are shoppers that claim they were successfully contacted by a sales rep after a handoff"

const Dashboard = () => {
  return (
    <div className="Dashboard">
      <div className="DashboardPanel">
      <h2 className="DashboardPanel-title">Operational Efficiency</h2>
        <DashboardCards />
      </div>
      <div className="DashboardPanel DashboardPanel--charts">
        <h2 className="DashboardPanel-title DashboardPanel-definition"><span style={{ paddingRight: "15px" }}>Sales Rep Performance</span> <QuestionMark definition={salesDescription} /></h2>
        <DashboardLeadSale />
      </div>
      <div className="DashboardPanel DashboardPanel--table">
        <h2 className="DashboardPanel-title">Performance by Lead Source</h2>
        <DashboardLeadSources />
      </div>
    </div>
  );
};

export default Dashboard;
