import { useEffect } from "react";

import FandIFlow from "./FandIFlow";

import "./FandI.scss";

const Settings = () => {
  useEffect(() => {}, []);

  return (
    <div className="FandI-Container">
      <FandIFlow />
    </div>
  );
};

export default Settings;
