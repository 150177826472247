import DashboardTiles from "./DashboardTiles";
import DashboardSources from "./DashboardSources";
// import DashboardCharts from "./DashboardChart";
import "./index.scss";

const DashboardFI = () => {
  return (
    <div className="Dashboard">
      <div className="DashboardPanel DashboardPanel--tiles">
        <DashboardTiles />
      </div>
    </div>
  );
};

export default DashboardFI;
