import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import FormTextfield from "../FormElement/FormTextfield";

import { chooseConversationsQuery } from "../../store/actions/conversationActions";
import useDebounce from "../../hook/useDebounce";

const WidgetSearch = ({placeholder, functionToDispatch}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

  const debouncedState = useDebounce(query, 500);

  useEffect(() => {
    dispatch(functionToDispatch(debouncedState));
  }, [debouncedState, dispatch]);

  const handleQueryChange = (e) => setQuery(e.target.value);

  return (
    <FormTextfield
      label="Search"
      icon="search"
      placeholder={placeholder || "Search Conversations..."}
      defaultValue={query}
      onChange={handleQueryChange}
    />
  );
};

export default WidgetSearch;
