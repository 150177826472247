import { Button } from "@material-ui/core";
import React, { useState } from "react";
import FormElement from "../../../../../FormElement";
import Modal from "@material-ui/core/Modal";
import "./index.scss";
import OpCodeComponent from "./OpCodeComponent/OpCodeComponent";
import { HelpOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
const PLACEHOLDER_START = "${";
const PLACEHOLDER_END = "}";

const fillValues = (text, numDays = "-") =>
  text.replace(/f{numDays}/g, numDays);

const getConditionComponent = (text, placeholders, numDaysConfig = {}) => {
  const { numDays, setNumDays } = numDaysConfig;
  if (!placeholders || !placeholders.length) return fillValues(text, numDays);
  let str = text;
  let indexOfPlaceholder = str.indexOf(PLACEHOLDER_START);
  const initialRender = [];

  while (indexOfPlaceholder > -1) {
    const closingBracketIndex = str.indexOf(PLACEHOLDER_END);
    // const placeholder = str.substring(
    //   indexOfPlaceholder,
    //   closingBracketIndex + 1
    // );

    initialRender.push(str.substring(0, indexOfPlaceholder));
    initialRender.push(
      <span className="ConditionComponent-input">
        <input
          // type="number"
          // min={0}
          // max={180}
          style={{ width: "3vw" }}
          value={numDays}
          onChange={(event) => {
            let newValStr = event.target.value;
            const newVal = +newValStr;

            if (isNaN(newVal)) return;

            if (newVal > 180 || newVal < 0) return;

            setNumDays(newVal);
          }}
        />
      </span>
    );

    str = str.substring(closingBracketIndex + 1);
    indexOfPlaceholder = str.indexOf(PLACEHOLDER_START);
  }

  if (str || initialRender.length === 0) initialRender.push(str);

  return <span className="ConditionComponent-container">{initialRender}</span>;
};

const Condition = ({
  condition,
  openOpCodes,
  numDays,
  setNumDays,
  form,
  setForm,
  keyName,
}) => {
  const {
    conditionText,
    placeholders,
    hasCheckbox,
    checkable,
    opCodeSelector,
  } = condition;

  const checkbox = hasCheckbox ? (
    <FormElement
      type="checkbox"
      checked={checkable ? form["Config"][keyName] : true}
      onChange={() => setForm({ [keyName]: !form["Config"][keyName] })}
      disabled={!checkable}
    />
  ) : null;

  const conditionComponent = getConditionComponent(
    conditionText,
    placeholders,
    { numDays, setNumDays }
  );

  const opCodeButton = opCodeSelector ? (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          openOpCodes(opCodeSelector.type);
        }}
        style={{
          margin: "0 1vw",
          minWidth: "15vw",
          fontSize: "1.2vh",
        }}
        size="large"
      >
        {opCodeSelector.label}
      </Button>
    </>
  ) : null;

  return (
    <div className="Condition-container">
      <>{checkbox}</>
      <span className="Condition-with-button">
        <>
          {conditionComponent}
          {condition.description && (
            <Tooltip
              title={
                <p style={{ fontSize: "1.3vh" }}>{condition.description}</p>
              }
            >
              <HelpOutline style={{ fill: "grey", cursor: "pointer" }} />
            </Tooltip>
          )}
        </>
        <>{opCodeButton}</>
      </span>
    </div>
  );
};

const ConditionBlock = ({
  block,
  numDays,
  setNumDays,
  startOpCodes,
  setStartOpCodes,
  interruptOpCodes,
  setInterruptOpCodes,
  form,
  setForm,
}) => {
  const { label, conditions } = block;
  const [openedModal, setOpenedModal] = useState();
  const [uneditedOpCodes, setUneditedOpCodes] = useState([]);

  const handleSetStartOpCodes = (list) => {
    setStartOpCodes(list);
  };

  const handleSetInterruptOpCodes = (list) => {
    setInterruptOpCodes(list);
  };

  const propsSwitcher = {
    start: {
      type: "start",
      label: "OP code start condition",
      opCodes: startOpCodes,
      setOpCodes: handleSetStartOpCodes,
    },
    interruption: {
      type: "interruption",
      label: "OP code interruption condition",
      opCodes: interruptOpCodes,
      setOpCodes: handleSetInterruptOpCodes,
    },
  };

  return (
    <div className="ConditionBlock-container">
      <p className="ConditionBlock-title">{label}</p>
      {conditions.map((condition) => (
        <Condition
          key={condition.id}
          condition={condition}
          openOpCodes={(type) => {
            setOpenedModal(type);
            setUneditedOpCodes([...propsSwitcher[type].opCodes]);
          }}
          numDays={numDays}
          setNumDays={setNumDays}
          form={form}
          setForm={setForm}
          keyName={condition.key}
        />
      ))}
      <div>
        <Modal
          open={openedModal}
          onClose={() => {
            setOpenedModal(null);
            propsSwitcher[openedModal].setOpCodes(uneditedOpCodes);
          }}
          className="ConditionBlock-Modal"
        >
          {React.cloneElement(
            <OpCodeComponent save={() => setOpenedModal(null)} form={form} />,
            {
              ...propsSwitcher[openedModal],
            }
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ConditionBlock;
