import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { DateRangePicker as DatePicker } from "react-date-range";

import { chooseDates } from "../../store/actions/dateActions";
import prepareStaticRanges from "./helpers/prepareStaticRanges";
import prepareDateLabel from "./helpers/prepareDateLabel";
import useAutoClose from "../../hook/useAutoClose";

import cn from "classnames";

import { addMonths, endOfDay } from "date-fns";

import "react-date-range/dist/styles.css";

import "./DateRangePicker.scss";
import "./DatePicker.scss";

import Svg from "../Svg";

const DateRangePicker = () => {
  const dropdown = useRef(null);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [showRanges, setShowRanges] = useState(false);
  const [showTwoCalendars, setShowTwoCalendars] = useState(false);
  const [firstSelectedDate, setFirstSelectedDate] = useState(null);
  const dateRange = useSelector((state) => state.date);

  useAutoClose({ setIsOpen, ref: dropdown });

  useEffect(() => {
    const handleResize = () => {
      const width = document.documentElement.clientWidth;

      setShowTwoCalendars(width >= 544);
      setShowRanges(width >= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const range = [{ ...dateRange, key: "selection" }];
  const dateLabel = prepareDateLabel(dateRange);
  const staticRanges = prepareStaticRanges();

  const handleSelectDate = (ranges) => {
    const { selection } = ranges;
    let { startDate, endDate } = selection;
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    if (startDate.toString() === endDate.toString()) {
      setFirstSelectedDate(startDate);
    } else {
      setFirstSelectedDate(null);
    }

    dispatch(chooseDates(ranges.selection));
  };

  const getMinDate = (date1, date2) => {
    return date1 > date2 ? date2 : date1;
  };

  return (
    <div
      ref={dropdown}
      className={cn("DateRangePicker", {
        "DateRangePicker--open": isOpen,
      })}
      onClick={(e) => {
        if (e.target.closest("button.rdrStaticRange")) setIsOpen(false);
      }}
    >
      <label className="DateRangePicker-label">Date filter</label>
      <button
        className={cn("DateRangePicker-toggler")}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        <span className="DateRangePicker-togglerText">{dateLabel}</span>
        <span className="DateRangePicker-togglerIcon">
          <Svg w="9" h="6" i="markerDropdown" />
        </span>
      </button>
      <div className="DateRangePicker-dropdown" aria-hidden={!isOpen}>
        {
          <DatePicker
            ranges={range}
            onChange={handleSelectDate}
            weekStartsOn={1}
            months={showTwoCalendars ? 2 : 1}
            direction="horizontal"
            showSelectionPreview={true}
            inputRanges={[]}
            minDate={
              firstSelectedDate
                ? endOfDay(addMonths(new Date(firstSelectedDate), -6))
                : new Date(0)
            }
            maxDate={getMinDate(
              new Date(),
              endOfDay(
                addMonths(
                  firstSelectedDate ? new Date(firstSelectedDate) : new Date(),
                  6
                )
              )
            )}
            rangeColors={["#ebf4f7"]}
            showPreview={false}
            dateDisplayFormat="LL / dd / y"
            weekdayDisplayFormat="EEEEEE"
            staticRanges={showRanges ? staticRanges : []}
          />
        }
      </div>
    </div>
  );
};

export default DateRangePicker;
