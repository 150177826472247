import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import WidgetSource from "../../components/WidgetSource/WidgetSource";
import WidgetSearch from "../../components/WidgetSearch/WidgetSearch";
import DealerChatConversations from "../../components/DealerChatConversations/Conversations";
import {chooseConversationsQuery} from "../../store/actions/conversationActions";


const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
    <WidgetSearch key="widgetSearch" functionToDispatch={chooseConversationsQuery} />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageDealerChatConversations = () => {
  return <Layout content={<DealerChatConversations />} widgets={widgets} />;
};

export default PageDealerChatConversations;
