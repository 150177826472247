import { useEffect } from "react";

import CampaignsFlow from "./CampaignsFlow";

import "./Campaigns.scss";

const Settings = () => {
  useEffect(() => {}, []);

  return (
    <div className="Campaigns-Container">
      <CampaignsFlow />
    </div>
  );
};

export default Settings;
