import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useData from "../../../hook/useData";
import "./DateAndTimePicker.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CalendarIcon from "../../../assets/img/CalendarIcon";
import Loader from "../../../components/Loader/Loader";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const areSameDay = (date1, date2) => {
  return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0);
};

const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const localDateToUTCString = (date, hour) => {
  const [h, m] = hour.split(":");

  const realMonth = date.getMonth() + 1;
  const utcMonth = realMonth < 10 ? `0${realMonth}` : realMonth;
  const utcDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  const utcString = `${date.getFullYear()}-${utcMonth}-${utcDay}T${h}:${m}:00.000Z`;
  const utcTimestamp = new Date(utcString);

  return utcTimestamp.toUTCString();
};
const reformatAPIDate = (inputDate) => {
  const [month, day, year] = inputDate.split("/")?.map(Number);
  const date = new Date(year, month - 1, day);
  const formattedDate = date.toString();
  return formattedDate;
};

const reformatAPIResponse = (workingHours) => {
  const resultantDates = [];
  for (const key in workingHours) {
    if (key === "TZ") {
      continue;
    }

    const reformattedDate = reformatAPIDate(key);
    const newDate = new Date(reformattedDate);
    resultantDates.push({
      dayNumber: newDate.getDate(),
      weekDay: days[newDate.getDay()],
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
      date: newDate,
      availableHours: workingHours[key],
    });
  }

  return resultantDates;
};

const getLocalDatesMetadata = (startingDate, workingHours) => {
  const resultantDates = [];
  let includeDayInResultantDates = false;

  workingHours?.forEach((day) => {
    if (!includeDayInResultantDates && areSameDay(day?.date, startingDate)) {
      includeDayInResultantDates = true;
    }

    if (resultantDates?.length === 5) return;

    if (includeDayInResultantDates) {
      const newDate = new Date(day?.date);
      resultantDates.push({
        dayNumber: newDate.getDate(),
        weekDay: days[newDate.getDay()],
        month: newDate.getMonth(),
        year: newDate.getFullYear(),
        date: newDate,
        availableHours: day.availableHours,
      });
    }
  });

  return resultantDates;
};

const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const DateAndTimePicker = ({ onChange, input, error = false }) => {
  const [workingHours, setWorkingHours] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [weekNumber, setWeekNumber] = useState(0);
  const [selectedDateMetadata, setSelectedDateMetadata] = useState({});
  const [localTimeInputValue, setLocalTimeInputValue] = useState("");
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const {
    data: workingTimeSlots,
    status: workingTimeSlotsStatus,
    execute: workingTimeSlotsData,
  } = useData(
    "getWorkingTimeSlots",
    {
      accountId,
    },
    [accountId],
    true
  );

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
    if (isOpen && Object.keys(selectedDateMetadata).length !== 0) {
      const weekDiff = selectedDateMetadata.week - weekNumber;
      const dayDiff = 5 * weekDiff;
      const newStartingDate = new Date(
        dates[0]?.date.setDate(dates[0]?.date.getDate() + dayDiff)
      );
      const newDates = getLocalDatesMetadata(newStartingDate, workingHours);
      const selectedDateInfo = newDates.find(
        (day) => day.dayNumber === selectedDateMetadata?.date
      );
      if (selectedDateInfo) {
        setDates(newDates);
        setSelectedDate(selectedDateInfo);
        setWeekNumber(selectedDateMetadata.week);
      }
    }
  };

  useEffect(() => {
    if (workingTimeSlots) {
      const reformattedAPIResponse = reformatAPIResponse(workingTimeSlots);
      setWorkingHours(reformattedAPIResponse);
      const localDates = getLocalDatesMetadata(
        new Date(),
        reformattedAPIResponse
      );
      setDates(localDates);
      const defaultSelectedDay = localDates.find(
        (el) => el?.availableHours?.length > 0
      );
      setSelectedDate(defaultSelectedDay);
    }
  }, [workingTimeSlots]);

  const handleHourClick = (hour) => {
    if (!selectedDate) return;

    const hour24Format = convertTo24HourFormat(hour);

    setSelectedDateMetadata({
      date: selectedDate.dayNumber,
      hour: hour24Format,
      week: weekNumber,
    });

    const localInputValue = `${selectedDate?.month + 1}/${
      selectedDate?.dayNumber
    }/${selectedDate?.year} ${hour}`;
    setLocalTimeInputValue(localInputValue);

    const newValue = localDateToUTCString(selectedDate?.date, hour24Format);
    onChange(input.name, newValue);
    setIsOpen(false);
  };

  const disabledDay = (date) => {
    return (
      date?.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ||
      !date?.availableHours?.length
    );
  };

  const disabledHour = (hour) => {
    const selectedDateTime = new Date(selectedDate.date);
    const [time, period] = hour.split(" ");
    const [h, m] = time.split(":");
    let hours = parseInt(h);
    if (period.toUpperCase() === "PM" && hours !== 12) {
      hours += 12;
    } else if (period.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }
    selectedDateTime.setHours(hours);
    selectedDateTime.setMinutes(parseInt(m));
    return selectedDateTime < new Date();
  };

  const disabledArrowButton = (arrowType) => {
    if (arrowType === "prev") {
      return weekNumber === 0;
    } else if (arrowType === "next") {
      return weekNumber === 2;
    }
  };

  const buttonStyles = {
    current: {
      color: "#6615D6",
      border: `1px solid #6615D6`,
    },
    selected: {
      background: "#410099",
      color: "white",
    },
  };

  const isSelectedDate = (date) => {
    return areSameDay(date?.date, selectedDate?.date) && !disabledDay(date);
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
  };

  const isCurrentDate = (date) => {
    return areSameDay(date?.date, new Date());
  };

  const getDates = (arrowType) => {
    const currentStartingDate = new Date(
      dates[0]?.year,
      dates[0]?.month,
      dates[0]?.dayNumber
    );
    let newStartingDate = new Date();
    const prevousWeek = weekNumber;
    if (arrowType === "prev") {
      newStartingDate = new Date(
        currentStartingDate?.setDate(currentStartingDate.getDate() - 5)
      );
      setWeekNumber(prevousWeek - 1);
    } else if (arrowType === "next") {
      newStartingDate = new Date(
        currentStartingDate?.setDate(currentStartingDate.getDate() + 5)
      );
      setWeekNumber(prevousWeek + 1);
    }

    const newDates = getLocalDatesMetadata(newStartingDate, workingHours);
    setDates(newDates);
    const firstAvailableDay = newDates.find(
      (el) => el?.availableHours?.length > 0
    );
    setSelectedDate(firstAvailableDay);
  };
  const getDateClassname = (date) => {
    if (disabledDay(date)) {
      return "disabled";
    }
    return isSelectedDate(date) ? "selected" : "default";
  };

  const getHourClassname = (hour) => {
    if (disabledHour(hour)) {
      return "disabled";
    }
    return selectedDate.dayNumber === selectedDateMetadata?.date &&
      hour === selectedDateMetadata?.hour
      ? "selected"
      : "";
  };

  const getToggleStyle = () => {
    if (error) {
      return "error";
    }
    if (isOpen) {
      return "open";
    }
  };

  return (
    <div className="calendarInputArea">
      <div className="calendarInputTitle">Date & Time</div>
      <div className={`calendar ${isOpen && "open"}"`}>
        <button
          type="button"
          className={`calendarToggle ${getToggleStyle()}`}
          onClick={toggleCalendar}
          aria-labelledby="form-calendar-label"
        >
          <span id="form-calendar-label" hidden>
            {input.name}
          </span>
          <div className="calendarToggleContent">
            <div className="calendarSelectedValue">{localTimeInputValue}</div>
            <CalendarIcon />
          </div>
        </button>
        {workingTimeSlotsStatus == "pending" ||
        workingTimeSlots === null ||
        workingTimeSlots?.length === 0 ? (
          <Loader />
        ) : (
          <div
            className={`calendarMenu ${isOpen && "open"}`}
            aria-label={"calendar-options"}
          >
            <div className={"calendarTitle"}>
              {`${months[selectedDate?.month]}, ${selectedDate?.year}`}
            </div>
            <div className={"calendarDaysCarousel"}>
              <ArrowBackIosIcon
                disabled={disabledArrowButton("prev")}
                onClick={() => getDates("prev")}
                color={disabledArrowButton("prev") ? "#9797974d" : "#979797"}
                style={
                  disabledArrowButton("prev")
                    ? {
                        pointerEvents: "none",
                        color: "#979797",
                        marginRight: "10px",
                      }
                    : { marginRight: "10px" }
                }
              />
              <div className={"daysContainer"}>
                <div className={"numbers"}>
                  {dates?.map((date, index) => (
                    <button
                      type="button"
                      className={`dayNumber ${getDateClassname(date)}`}
                      disabled={disabledDay(date)}
                      key={`calendar_day_number_${index}`}
                      onClick={() => {
                        handleDayClick(date);
                      }}
                      aria-label={`day-btn-${date.dayNumber}`}
                    >
                      {date.dayNumber}
                      <span id={`day-btn-${date.dayNumber}`} hidden>
                        {date.dayNumber}
                      </span>
                    </button>
                  ))}
                </div>
                <div className="days">
                  {dates?.map((date, index) => (
                    <div className="day" key={`calendar_day_${index}`}>
                      {date.weekDay}
                    </div>
                  ))}
                </div>
              </div>
              <ArrowForwardIosIcon
                disabled={disabledArrowButton("next")}
                onClick={() => getDates("next")}
                color={disabledArrowButton("next") ? "#9797974d" : "#979797"}
                style={
                  disabledArrowButton("next")
                    ? {
                        pointerEvents: "none",
                        color: "#9797974d",
                        marginLeft: "10px",
                      }
                    : { marginLeft: "10px" }
                }
              />
            </div>
            <div className="hoursContainer">
              {selectedDate?.availableHours?.map((hour, index) => (
                <button
                  type="button"
                  className={`hour ${getHourClassname(hour)}`}
                  key={`calendar_hour_${index}`}
                  disabled={disabledHour(hour)}
                  onClick={() => {
                    handleHourClick(hour);
                  }}
                  aria-label={`hour-btn-${hour}`}
                >
                  {hour}
                  <span id={`hour-btn-${hour}`} hidden>
                    {hour}
                  </span>
                </button>
              ))}
            </div>
          </div>
        )}
        <div
          className={`calendarBackground ${isOpen ? "open" : ""}`}
          onClick={toggleCalendar}
        ></div>
      </div>
    </div>
  );
};
export default DateAndTimePicker;
