import req from "../utils/request";
import { de } from "date-fns/locale";

const getUserMetadata = async (username, accessToken) => {
  const { data: userMetadataResponse, status } = await req("getUserMetadata", {
    accountId: username,
  },
  accessToken
);
  if (status === 200) {
    return userMetadataResponse;
  }
  if (status === 402) {
    throw new Error(userMetadataResponse.message);
  }
  return null;
};

const getDealerSettings = async (dealerId, accessToken) => {
  const { data: dealerSettingsResponse, status } = await req(
    "getDealerSettings",
    {
      dealerId: dealerId,
    },
    accessToken
  );

  if (status === 200) {
    return dealerSettingsResponse?.DealershipSettings;
  }
  return null;
};

const getDealerProducts = async (dealerId, accessToken) => {
  const { data: dealerProductsResponse } = await req(
    "getDealerProducts",
    {
      dealerId,
    },
    accessToken
  );
  return dealerProductsResponse;
};

const getInitialProduct = (products) => {
  if (!Array.isArray(products) || !products.length) {
    return null;
  }
  const product = products[0];
  const attribute = product.Attributes[0];

  return {
    ...attribute,
    Category: product.Id,
  };
};

const login = async (user, access_token) => {
  let prevAuthState = localStorage.getItem("auth");
  if (prevAuthState) {
    prevAuthState = JSON.parse(prevAuthState);
    prevAuthState.access_token = access_token;
    localStorage.setItem("auth", JSON.stringify(prevAuthState));
    return prevAuthState;
  }
  if (user) {
    console.log("user is: ", user);
    const isOktaUser = user.sub.includes("okta");
    const userName = isOktaUser ?  user.email.replace("@","_") : user.nickname 

    if(isOktaUser) {
      const { data: updateCDKUserResponse, status } = await req("updateCDKUser", {
        retailer_name: "cdk_global",
        user_name: userName,
        okta_user_id: user.sub,
      },
      access_token
    );
  }
    const userMetadata = await getUserMetadata(userName, access_token);
    const selectedProduct = getInitialProduct(userMetadata?.Products || []);
    const dealerSettings = await getDealerSettings(
      userMetadata?.DealerId || user.nickname,
      access_token
    );
    console.log("dealerSettings are: ", dealerSettings);
    const policy = userMetadata ? userMetadata.Policy : [];
    const result = {
      selectedProduct: selectedProduct,
      access_token: access_token,
      dealerSettings: dealerSettings,
      user_info: {
        ChildAccounts: userMetadata ? userMetadata.ChildAccounts : [],
        Products: userMetadata ? userMetadata.Products : [],
        Policy: policy,
        selectedProduct: selectedProduct,
        username: userMetadata?.DealerId || user.nickname,
        DealerId: userMetadata?.DealerId || user.nickname,
        Role:
          userMetadata?.Role?.toUpperCase() ||
          (policy.length > 0 ? "ADMIN" : "REGULAR"),
      },
    };
    localStorage.setItem("auth", JSON.stringify(result));
    localStorage.setItem("isAuthUser", "True");

    return result;
  }
  throw new Error("User does not exist");
};

const switchDealer = async (dealerId) => {
  const previousState = JSON.parse(localStorage.getItem("auth"));
  const accessToken = previousState.access_token;
  const dealerProducts = await getDealerProducts(dealerId, accessToken);
  const dealerSettings = await getDealerSettings(dealerId, accessToken);
  const selectedProduct = getInitialProduct(dealerProducts || []);
  if (previousState) {
    previousState.selectedProduct = selectedProduct;
    previousState.dealerSettings = dealerSettings;
    previousState.user_info.username = dealerId;
    previousState.user_info.DealerId = dealerId;
    previousState.user_info.Products = dealerProducts;
    previousState.user_info.selectedProduct = selectedProduct;
    localStorage.setItem("auth", JSON.stringify(previousState));
  }
  return {
    username: dealerId,
    DealerId: dealerId,
    Products: dealerProducts,
    selectedProduct: selectedProduct,
    dealerSettings: dealerSettings,
  };
};

const switchAccount = async (credentials, auth) => {
  const { data: loginResponse } = await req(
    "switch",
    credentials,
    auth.parent_token
  );

  if (loginResponse.access_token) {
    const result = {
      ...loginResponse,
      user_info: {
        ...loginResponse.user_info,
        ChildAccounts: auth.user_info.ChildAccounts,
      },
      parent_token: auth.parent_token,
    };

    const selectedProduct = getInitialProduct(
      loginResponse.user_info.Products || []
    );
    result.selectedProduct = selectedProduct;
    result.user_info.selectedProduct = selectedProduct;
    localStorage.setItem("auth", JSON.stringify(result));

    return result;
  }
};

const logout = async (auth, type = "exit") => {
  // let tokens = (auth && [auth.access_token]) || [];
  //
  // if (type === "exit" && auth && auth.parent_token) {
  //     tokens.push(auth.parent_token);
  // }
  //
  // const {data: requests} = tokens.map((token) => req("logout", {}, token));
  //
  // let response = Promise.all(requests).then((responses) => {
  //     responses.forEach((response) => console.log("### responces", response));
  // });

  // if (type === "exit") {
  localStorage.removeItem("auth");
  localStorage.removeItem("isAuthUser");
  // }

  // return response;
};

export default { login, logout, switchAccount, switchDealer };
