import {useEffect, useState} from "react";
import useData from "../../hook/useData";
import {useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import CustomSwitch from "../Switch/Switch";
import Loader from "../Loader/Loader";

const StopCommunication = () => {
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [responseText, setResponseText] = useState('');
  const [message, setMessage] = useState('');
  const { cid } = useParams();
  // const [confirmDisableButton, setConfirmDisableButton] = useState(true);

  const mapper = {
    OK: 'Success!',
    ALREADY_EXISTS: 'You have already stopped communication between Sales AI and customer!',
  }

  const { data, statusCode: status, error } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  const {data: userData} = useData(
    "getUserInfo",
    {
      accountId: data?.AccountId,
      userId: data?.UserId
    },
    [data?.AccountId, data?.UserId]
  )

  const {data: updateData, status: updateStatus, error: updateError, execute: executeUpdateCommunication} = useData(
    'updateCommunication',
    {
      accountId: data?.AccountId,
      userId: data?.UserId,
      vin: cid,
      action: !checked ? 'stop' : 'continue'
    }, [cid, data?.AccountId, data?.UserId, checked],
    false
  )

  useEffect(() => {
    if (userData?.vin_blacklist && userData.vin_blacklist.includes(cid)) {
        setChecked(false)
    }
  }, [cid, userData?.vin_blacklist])

  const handleInputChange = (e) => {
    setChecked(e.target.checked);
    // setConfirmDisableButton(!!((e.target.checked && prevValue === "checked") || (!e.target.checked && prevValue !== "checked")));
  }

  const handleSubmit = () => {
    setLoading(true)
    setResponseText("");
    setMessage("");

    if (!(data.AccountId && data.UserId && cid)) {
      setResponseText('Some issues on our side!');
      return;
    }

    executeUpdateCommunication();
  }

  useEffect(() => {
    if (updateError) {
      setResponseText('Some issues on our side!');
    } else {
      if (userData?.name) {
        setLoading(false);
      }

      setResponseText(mapper[updateData?.status])
      if (checked) {
        setMessage('This customer will now receive messages from the AI Assistant')
      } else {
        setMessage('This customer will no longer receive messages from the AI Assistant')
      }
    }
  }, [updateData, userData?.name, checked])

  return (
    <div style={{
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: "#EBEBEB",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
    {loading ?
      <Loader />
      :
    <div style={{backgroundColor: "white", width: "640px", height: "480px", margin: "20% auto"}}>
      <div style={{
        textAlign: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}>
        <p style={{paddingTop: "70px", fontSize: "12px"}}>CUSTOMER </p>
        <p style={{fontSize: "31px", color: "#232529"}}>{userData?.name} {userData?.surname}</p>
        <p id="aiAssistantLabel" style={{fontSize: "12px", color: "#232529", paddingTop: "40px"}}>AI ASSISTANT </p>

        <form id="form">
          <Stack style={{display: "flex", justifyContent: "center"}} direction="row" spacing={1} alignItems="center">
            <p>Off</p>
            <CustomSwitch checked={checked} onChange={handleInputChange} inputProps={{ 'aria-label': 'ant design' }} />
            <p>On</p>
          </Stack>

          {loading ?
            <div id="spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Confirming</span>
              </div>
            </div>
            :
            <button
              type="submit"
              className="FormLinkSubmit-button"
              id="submitButton"
              disabled={loading}
              onClick={handleSubmit}
              style={{margin: "50px auto 50px auto", width: "40%"}}
            >
              Confirm
            </button>
          }
        </form>

        {responseText &&
          <p id="response" style={{fontSize: "18px", fontWeight: "bold"}}>
            {responseText}
          </p>
        }
        {message &&
          <p id="messageLabel" style={{fontSize: "18px", paddingTop: "5px"}}>
            {message}
          </p>
        }
      </div>
    </div>
    }
    </div>
  );
};

export default StopCommunication;
