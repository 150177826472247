import { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ChatsListCard from "../ChatsListCard";
import Loader from "../../Loader";
import Pagination from "../../Pagination";
import Svg from "../../Svg";

import useData from "../../../hook/useData";
import useWindowSize from "../../../hook/useWindowSize";

import {
  chooseConversationsMode,
  chooseConversationsPage,
} from "../../../store/actions/conversationActions";

import { formatToDateString } from "../../../utils/formatDate";

import "./ChatsList.scss";

const ChatsList = ({ cid, onToggleListCollapsed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSize();

  const dateRange = useSelector((state) => state.date);
  const accountId = useSelector(
      (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const {
    conversationsPage,
    conversationsEndpoint,
    conversationsSource,
    conversationsMode,
    conversationsQuery,
    conversationsSubMetric,
    conversationsPersonId
  } = useSelector((state) => state.conversation);

  let endpoint = conversationsEndpoint;
  let options = {
    start_date: formatToDateString(dateRange.startDate),
    end_date: formatToDateString(dateRange.endDate),
    page_id: conversationsPage,
    sub_metric: conversationsSubMetric,
    source_name: conversationsSource,
    salesperson_id: conversationsPersonId
  };

  if (conversationsMode === "simple" && cid) {
    endpoint = "getGeneralLeadInfo";
    options = { cid };
  } else if (endpoint === "newBySourceLeads") {
    options = { ...options, source_name: conversationsSource };
  }

  const { data, status, error } = useData(endpoint, options, [
    conversationsPage,
    dateRange,
    conversationsEndpoint,
    accountId
  ]);

  useEffect(() => {
    if (!cid && data && data.items && data.items.length) {
      console.log("DATA ITEMS", data);
      dispatch(chooseConversationsMode("regular"));
      const cid = data.items[0].ConversationId;

      history.push(cid);
    }
  }, [cid, data, history, dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(chooseConversationsPage(pageNumber));
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    const items = (data && data.items) || [
      {
        ConversationId: data.LeadId,
        FullName: data.FullName,
        Channel: data.Channel,
        Email: data.Email,
        Phone: data.Phone,
        Source: data.LeadSource,
      },
    ];

    let listItems = [];

    listItems = items.map((item) => {
      return (
        <li key={item.ConversationId}>
          <ChatsListCard data={item} cid={cid} />
        </li>
      );
    });

    const pagination = items.length > 1 && (
      <div className="ChatsList-pagination">
        <Pagination
          current={data.page_id}
          total={data.num_pages}
          onPageChange={handlePageChange}
          mode="simple"
        />
      </div>
    );

    return (
      <>
        {windowSize.width < 768 && (
          <div className="ChatsList-heading">
            <button
              className="ChatsList-toggler"
              onClick={onToggleListCollapsed}
            >
              <span className="ChatsList-togglerIcon">
                {" "}
                <Svg w="24" h="7" i="arrowRight" />
              </span>
              <span className="ChatsList-togglerLabel">Hide window</span>
            </button>
          </div>
        )}
        <div className="ChatsList-container">
          <ul className="ChatsList-list">{listItems}</ul>
        </div>
        <div className="ChatsList-summary">
          <div className="ChatsList-pagination">{pagination}</div>
        </div>
      </>
    );
  };

  return <div className="ChatsList">{render()}</div>;
};

export default ChatsList;
