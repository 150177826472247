import { combineReducers } from "redux";

import user from "./user";
import conversation from "./conversation";
import conversationDealerChat from "./conversationDealerChat";
import date from "./date";
import setting from "./setting";
import userManagement from "./userManagement";

const createRootReducer = () => {
  return combineReducers({
    user,
    conversation,
    conversationDealerChat,
    date,
    setting,
    userManagement
  });
};

export default createRootReducer;
