const getInteger = (str) => {
  const int = parseInt(str.slice(-2));

  return isNaN(int) ? null : int;
};

const getHours24 = (int, daytime, inputLength) => {
  if (int === null) return null;

  if (int > 23) return 23;
  if (daytime === "PM" && inputLength > 1 && int < 12) return int + 12;
  if (daytime === "AM" && inputLength > 1 && int === 12) return 0;

  return int;
};

const getHours12 = (int, daytime, inputLength) => {
  if (int === null) return null;

  if (int > 12 && daytime) return 12;
  if (int > 12) return int - 12;
  if (inputLength > 1 && int === 0) return 12;

  return int;
};

const getMinutes60 = (int) => {
  if (int === null) return null;

  if (int > 59) return 59;

  return int;
};

const getDaytime12 = (int, daytime) => {
  if (daytime) return daytime;

  if (int === null) return null;

  if (int >= 12) return "PM";

  return "AM";
};

const getFragmentStr = (fragment, fragmentLength) => {
  if (fragment === null) return "";

  let fragmentString = fragment.toString();

  if (fragmentLength > fragmentString.length && fragmentString.length === 1)
    fragmentString = `0${fragmentString}`;

  return fragmentString;
};

const getStr = (hours, minutes, hoursLength, minutesLength) => {
  const hoursStr = getFragmentStr(hours, hoursLength);
  const minutesStr = getFragmentStr(minutes, minutesLength);

  if (!hoursStr && !minutesStr) return "";

  if (hoursStr.length < 2) {
    if (parseInt(hoursStr) > 1) {
      return minutesStr ? `0${hoursStr}:${minutesStr}` : `0${hoursStr}:`;
    } else {
      return `${hoursStr}`;
    }
  }

  if (!hoursStr) return `:${minutesStr}`;

  if (!minutesStr) {
    return `${hoursStr}:`;
  }

  return `${hoursStr}:${minutesStr}`;
};

// String may be time in 24h or 12h format with daytime value
export const getTimeComponents = (string = null, daytime = null) => {
  // console.log("### string:", string);
  if (!string)
    return {
      timestring24: null,
      timestring12: "",
      daytime: daytime,
    };

  //Clean Up
  const parts = string.replace(/[^0-9:]/g, "").split(":");

  // Fragments
  const rawHours = parts[0] || "";
  const rawMinutes = parts[1] || "";

  // Fragments length
  const rawHoursLength = rawHours.length;
  const rawMinutesLength = rawMinutes.length;

  // Convert to Integers and cut up to 2 symbols
  const intHours = getInteger(rawHours);
  const intMinutes = getInteger(rawMinutes);

  // Correct hours to 24h
  const intHours24 = getHours24(intHours, daytime, rawHoursLength);

  // Correct hours to 12h
  const intHours12 = getHours12(intHours, daytime, rawHoursLength);

  // Cut minutes to real value (max 59)
  const intMinutes60 = getMinutes60(intMinutes);

  // Get daytime if the one don't set
  const daytime12 = getDaytime12(intHours, daytime);

  // Prepare string
  const str24 = getStr(
    intHours24,
    intMinutes60,
    rawHoursLength,
    rawMinutesLength
  );

  const str12 = getStr(
    intHours12,
    intMinutes60,
    rawHoursLength,
    rawMinutesLength
  );

  return { timestring24: str24, timestring12: str12, daytime: daytime12 };
};
