import { useEffect, useState } from "react";

import cn from "classnames";

import FormElement from "../../FormElement";
import Svg from "../../Svg";

import useData from "../../../hook/useData";

import "./SettingsAnswersCard.scss";

const SettingsAnswersCard = ({ group }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(group);

  const { data, status, execute } = useData(
    "updateBotAnswersSettings",
    { ...state, group_id: group.GroupId },
    [state],
    false
  );

  useEffect(() => {
    if (data) setState(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    execute();
  };

  const handleOptionChange = (value) => {
    setState((state) => {
      return { ...state, ChosenId: value };
    });
  };

  const handleTexareaChange = (choosenAnswerId, e) => {
    setState((state) => {
      const answerIdx = state.Alternatives.findIndex(
        ({ Id: id }) => id === choosenAnswerId
      );

      const answers = [...state.Alternatives];
      const answer = { ...answers[answerIdx] };

      answer.Content = e.target.value;
      answers[answerIdx] = answer;

      return { ...state, Alternatives: answers };
    });
  };

  const handleInputChange = (answerId, inputId, e) => {
    setState((state) => {
      const answerIdx = state.Alternatives.findIndex(
        ({ Id: id }) => id === answerId
      );

      const answers = [...state.Alternatives];
      const answer = { ...answers[answerIdx] };

      const tokenIdx = answer.InputValues.findIndex(
        ({ Id: id }) => id === inputId
      );

      answer.InputValues[tokenIdx].CurrentValue = e.target.value;

      answers[answerIdx] = answer;

      return { ...state, Alternatives: answers };
    });
  };

  const inputsComplete = (inputValues) => {
    for (const iv of inputValues) {
      if (iv.CurrentValue === "") {
        return false;
      }
    }
    return true;
  };

  const getStatusLabel = () => {
    const { Alternatives } = state;
    const custom = Alternatives.find((item) => item.Editable);
    if (!custom) return null;

    if (!"Status" in custom || !"StatusId" in custom) return null;

    let color;
    switch (custom.StatusId) {
      case 0:
        color = "red";
        break;
      case 1:
        color = "green";
        break;
      default:
        color = "black";
        break;
    }

    return (
      <div style={{ color, margin: "3vh", marginLeft: "4vw" }}>
        {custom.Status}
      </div>
    );
  };

  const render = () => {
    let {
      Alternatives: answers,
      GroupId: groupId,
      ChosenId: choosenAnswerId,
      Question: question,
    } = state;

    const currentAnswerIdx = answers.findIndex(
      ({ Id: id }) => id === choosenAnswerId
    );

    const values = answers.map(
      ({ Id: id, Content: label, Editable: editable, InputValues: tokens }) => {
        const replaceResult = tokens.reduce(
          (result, tokenData) => {
            const token = `#{${tokenData.Id}}`;

            const subResult = result.reduce((subResult, item, idx) => {
              if (typeof item === "string") {
                const tokenStart = item.indexOf(token);

                if (tokenStart !== -1) {
                  const tokenEnd = tokenStart + token.length;

                  return [
                    ...subResult.slice(0, idx),
                    item.slice(0, tokenStart),
                    <input
                      key={tokenData.Id}
                      type="text"
                      className="SettingsAnswerCard-input"
                      value={tokenData.CurrentValue}
                      placeholder={tokenData.PlaceHolder}
                      size={
                        tokenData.CurrentValue.length ||
                        tokenData.PlaceHolder.length ||
                        10
                      }
                      onChange={(e) => handleInputChange(id, tokenData.Id, e)}
                    />,
                    item.slice(tokenEnd),
                    ...subResult.slice(idx + 1),
                  ];
                }
              }

              return subResult;
            }, result);

            return subResult;
          },
          [label]
        );

        const content = editable ? (
          <textarea
            className="SettingsAnswerCard-textarea"
            onChange={(e) => handleTexareaChange(id, e)}
            value={replaceResult}
          ></textarea>
        ) : (
          replaceResult
        );

        return {
          id,
          value: id,
          label: content,
        };
      }
    );

    const optionsList = (
      <FormElement
        type="radios"
        id={`answers${groupId}`}
        name={`answers${groupId}`}
        values={values}
        value={choosenAnswerId}
        onChange={handleOptionChange}
        // onChange={(value) => onChangeAnswer(value, groupId)}
      />
    );

    return (
      <div className="SettingsAnswersCard">
        <div
          className="SettingsAnswersCard-current"
          onClick={() => setIsOpen((state) => !state)}
        >
          <div className="SettingsAnswersCard-currentQuestionWrapper">
            <div className="SettingsAnswersCard-currentQuestionIcon">Q</div>
            <div className="SettingsAnswersCard-currentQuestion">
              {question}
            </div>
          </div>
          <div className="SettingsAnswersCard-currentAnswerWrapper">
            <div className="SettingsAnswersCard-currentAnswerIcon">A</div>
            <div className="SettingsAnswersCard-currentAnswer">
              {currentAnswerIdx > -1 ? answers[currentAnswerIdx].Content : ""}
            </div>
            <div className="SettingsAnswersCard-togglerIcon">
              <Svg w="9" h="6" i="markerDropdown" />
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="SettingsAnswersCard-dropdown">
            <h3 className="SettingsAnswersCard-formLabel">Change Answer</h3>
            <form
              className={cn("SettingsAnswersCard-form", {
                "SettingsAnswersCard-form--disabled": status === "pending",
              })}
              onSubmit={handleSubmit}
            >
              {optionsList}
              <div className="SettingsAnswersCard-meta">{getStatusLabel()}</div>
              <FormElement
                type="submit"
                value="Submit"
                label="Save changes"
                disabled={
                  currentAnswerIdx < 0 ||
                  !inputsComplete(answers[currentAnswerIdx].InputValues)
                }
              />
            </form>
          </div>
        )}
      </div>
    );
  };

  return render();
};

export default SettingsAnswersCard;
