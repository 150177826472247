import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import WidgetSource from "../../components/WidgetSource/WidgetSource";
import "./PageCarBuyingConversation.scss";
import ChatLayout from "../../components/CarBuyingConversation/Chats";


const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageCarBuyingConversation = () => {
  return (
    <Layout
      content={<ChatLayout />}
      widgets={widgets}
      className="Layout--pageConversation"
    />
  );
};

export default PageCarBuyingConversation;
