import { formatValue } from "../../../utils/formatValue";
import Svg from "../../Svg";
import "./ChatConversationContext.scss";

const ChatConversationContext = ({
  data,
  onToggleDetailsCollapsed,
  selectedProductId,
  highlightedEvents,
}) => {
  return (
    <>
      <div className="ConversationsDetails-heading">
        <h2 className="ConversationsDetails-name">{data.FullName}</h2>
        <button
          className="ConversationsDetails-toggler"
          onClick={onToggleDetailsCollapsed}
        >
          <span className="ConversationsDetails-togglerLabel">Hide window</span>
          <span className="ConversationsDetails-togglerIcon">
            {" "}
            <Svg w="24" h="7" i="arrowRight" />
          </span>
        </button>
      </div>
      <div className="ConversationsDetails-info">
        <div className="ConversationsDetails-infoLabel">Email:</div>
        <div className="ConversationsDetails-infoValue">{data.Email}</div>

        <div className="ConversationsDetails-infoLabel">Phone:</div>
        <div className="ConversationsDetails-infoValue">{data.Phone}</div>

        <div className="ConversationsDetails-infoLabel">Lead type:</div>
        <div className="ConversationsDetails-infoValue">{data.Channel}</div>

        <div className="ConversationsDetails-infoLabel">Lead source:</div>
        <div className="ConversationsDetails-infoValue">{data.LeadSource}</div>

        {selectedProductId !== "SERVICE_AI" ? (
          <>
            <div className="ConversationsDetails-infoLabel is-full">
              Lead status:
            </div>
            <div className="ConversationsDetails-infoValue is-full">
              {data.LeadStatus}
            </div>

            <div className="ConversationsDetails-infoLabel">Assigned to:</div>
            <div className="ConversationsDetails-infoValue">
              {data.AssignedTo}
            </div>
          </>
        ) : null}
      </div>
      <div className="ConversationsDetails-actions">
        {data.CrmProfileUrl && (
          <a
            className="ConversationsDetails-action"
            href={data.CrmProfileUrl}
            target="_blank"
            rel="noreferrer"
          >
            <span className="ConversationsDetails-actionLabel">
              CRM Profile
            </span>
            <span className="ConversationsDetails-actionIcon">
              <Svg w="25" h="7" i="arrowRight" />
            </span>
          </a>
        )}

        {data.ReplyAssistant && (
          <a
            className="ConversationsDetails-action"
            href={data.ReplyAssistant}
            target="_blank"
            rel="noreferrer"
          >
            <span className="ConversationsDetails-actionLabel">
              Reply as Assistant
            </span>
            <span className="ConversationsDetails-actionIcon">
              <Svg w="25" h="7" i="arrowRight" />
            </span>
          </a>
        )}

        {data.StopAssistant && (
          <a
            className="ConversationsDetails-action is-danger"
            href={data.StopAssistant}
            target="_blank"
            rel="noreferrer"
          >
            <span className="ConversationsDetails-actionLabel">
              Stop Assistant
            </span>
            <span className="ConversationsDetails-actionIcon">
              <Svg w="25" h="7" i="arrowRight" />
            </span>
          </a>
        )}
      </div>
      {data.VehicleInfo && (
        <div className="ConversationsDetails-object">
          {data.VehicleInfo.Price && (
            <>
              <div className="ConversationsDetails-objectName">
                {data.VehicleInfo.Vehicle}
              </div>
              <div className="ConversationsDetails-objectInfo">
                <div className="ConversationsDetails-objectValue">{`$${formatValue(
                  +data.VehicleInfo.Price
                )}`}</div>
                <div
                  className={cn("ConversationsDetails-objectStatus", {
                    "ConversationsDetails-objectStatus--available":
                      data.VehicleInfo.Available,
                    "ConversationsDetails-objectStatus--unavailable":
                      !data.VehicleInfo.Available,
                  })}
                >
                  {data.VehicleInfo.Available ? "Available" : "Unavailable"}
                </div>
              </div>
            </>
          )}
          {data.VehicleInfo.ImageUrl && (
            <div className="ConversationsDetails-objectPhoto">
              <img src={data.VehicleInfo.ImageUrl} alt="" />
            </div>
          )}
        </div>
      )}
      <div className="ConversationsDetails-notifications">
        {highlightedEvents}
      </div>
    </>
  );
};

export default ChatConversationContext;
