import "./index.scss";
// import opCodeTypes from "./opCodeTypes.js";
import conditionBlocks from "./conditionBlocks";
import ConditionBlock from "./ConditionBlock";

const CampaignConditions = ({ form, setForm }) => {
  const conditionGroupId = form?.Sequence?.ConditionGroupId;

  if (!conditionBlocks[conditionGroupId]) {
    return null;
  }

  const campaignSettingText =
    "Determine which car buyers to include in First Service communications and what conditions need to be met for the initiative to be considered a success.";

  return (
    <div className="CampaignConditions-Container">
      <div className="CampaignConditions-Settings-Container">
        <span className="CampaignConditions-BlockTitle">
          Communication Settings
        </span>
        <div className="CampaignConditions">
          <p>{campaignSettingText}</p>
          {conditionBlocks[conditionGroupId].map((conditionBlock) => (
            <ConditionBlock
              block={conditionBlock}
              key={conditionBlock.id}
              numDays={form["Config"]?.["numDays"]}
              setNumDays={(numDays) => setForm({ numDays })}
              startOpCodes={form["Config"]?.["startOpCodes"] || []}
              setStartOpCodes={(startOpCodes) => setForm({ startOpCodes })}
              interruptOpCodes={form["Config"]?.["interruptOpCodes"] || []}
              setInterruptOpCodes={(interruptOpCodes) =>
                setForm({ interruptOpCodes })
              }
              form={form}
              setForm={setForm}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignConditions;
