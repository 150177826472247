import { format } from "date-fns";

function prepareDateLabel({ startDate, endDate }) {
  const formattedStartDate = format(startDate, "MM/dd/yyyy");
  const formattedEndDate = format(endDate, "MM/dd/yyyy");

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  } else {
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
}

export default prepareDateLabel;
