import PageConversations from "./pages/PageConversations/PageConversations";
import PageConversation from "./pages/PageConversation/PageConversation";
import PageFandI from "./pages/PageFandI/PageCampaigns";
import PageLogin from "./pages/Login/Login";
import PageLogout from "./pages/PageLogout/PageLogout";
import PageUser from "./pages/PageUser/PageUser";
import PageDashboardFI from "./pages/PageDashboardFI/PageDashboardFI";
import PageUserManagement from "./pages/PageUserManagement";
import PageUserForm from "./pages/PageUserForm";
import PageStopCommunication from "./pages/PageStopCommunication";

export const GENERAL_LINKS = [
  {
    icon: "chart",
    label: "Go to Dashboard",
    path: "/",
    component: PageDashboardFI,
    exact: true,
  },
  {
    icon: "conversations",
    label: "Go to Conversations",
    path: "/conversations",
    component: PageConversations,
    subroutes: [
      {
        label: "Conversation View",
        path: "/conversation/",
      },
      {
        label: "List View",
        path: "/conversations",
      },
    ],
  },
  {
    icon: "settings",
    label: "Go to Campaigns",
    path: "/settings",
    component: PageFandI,
    exact: true,
  },
  {
    icon: "user",
    label: "Go to User Settings",
    path: "/user",
    component: PageUser,
    subroutes: [
      {
        label: "Manage Users",
        path: "/user-management",
      },
      {
        label: "Edit Account",
        path: "/user",
      },
      {
        label: "Log Out",
        path: "/logout",
      },
    ],
  },
];

export const SECONDARY_LINKS = [
  {
    label: "Stop Communication Page",
    path: "/conversation/:cid/stop-communication",
    component: PageStopCommunication,
  },
  {
    label: "Go to Single Conversation",
    path: "/conversation/:cid",
    component: PageConversation,
  },
  {
    label: "Go to Single Conversations List",
    path: "/conversation/",
    component: PageConversation,
    exact: true,
  },
  {
    label: "User Management Page",
    path: "/user-management/:action",
    component: PageUserForm,
  },
  {
    label: "User Management Page",
    path: "/user-management/",
    component: PageUserManagement,
    exact: true,
  },
  {
    label: "Stop Communication Page",
    path: "/stop-communication/",
    component: PageStopCommunication,
    exact: true
  },
  {
    label: "Log Out",
    path: "/logout",
    component: PageLogout,
  },
];

export const COMMON_LINKS = [
  {
    label: "Log In",
    path: "/login",
    component: PageLogin,
  },
];
