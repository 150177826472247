import SettingsSignature from "../../../../Settings/SettingsSignature";
import SettingsTime from "../../../../Settings/SettingsTime";
// import SettingsNotifications from "../../../../Settings/SettingsNotifications";

export default [
  {
    resourceType: "signature",
    name: "Signature",
    title: "Assistant Signature",
    description:
      "Give your AI assistant a name and position, and enter other essential information about your dealership. These details will be used to create a personalized signature, which will appear on messages sent by the AI assistant.",
    editorComponent: <SettingsSignature />,
  },

  {
    resourceType: "working-hours",
    name: "Working Hours",
    title: "Work Hours",
    description:
      "Set your dealership’s time zone, regular working hours and special business hours.",
    editorComponent: <SettingsTime />,
  },

  // {
  //   resourceType: "notifications",
  //   name: "Notifications",
  //   title: "Email & SMS Notification Settings",
  //   description:
  //     "Setup personnel, then setup email and sms notifications they receive",
  //   editorComponent: <SettingsNotifications />,
  // },
];
