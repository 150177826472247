import Breadcrumbs from '@mui/material/Breadcrumbs';
import "./WidgetBreadcrumbs.scss";

const WidgetBreadcrumbs = ({breadcrumbs}) => {
  return <Breadcrumbs className={"breadcrumbs"} separator="›">
    {breadcrumbs}
  </Breadcrumbs>
};

export default WidgetBreadcrumbs;
