import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import "./index.scss";

import App from "./components/App";
import configureStore from "./store/configureStore";
import {Auth0ProviderWithNavigate} from "./components/Auth0/Auth0ProviderWithNavigate";

const store = configureStore({});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <Auth0ProviderWithNavigate>
                <App/>
            </Auth0ProviderWithNavigate>
        </React.StrictMode>
    </Provider>,

    document.getElementById("root")
)
;
