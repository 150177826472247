import { useRef, useState, useEffect, useMemo } from "react";

import cn from "classnames";

import "./FormSelect.scss";

import Svg from "../../Svg";
import useAutoClose from "../../../hook/useAutoClose";
import FormTextfield from "../FormTextfield";

const FormSelect = ({
  label,
  labelHidden = false,
  icon = false,
  values,
  defaultValue = null,
  disabled = false,
  onChange,
  searchable = false,
  ...rest
}) => {
  const select = useRef(null);
  const searchRef = useRef(null);
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useAutoClose({ setIsOpen, ref: select });

  const handleChange = (value) => {
    onChange(value);
  };

  const scrollIntoView = (id) => {
    document.getElementById(`${label}/listItem-${id}`)?.focus();

    if (searchRef?.current) {
      searchRef.current.focus();
      searchRef.current.value = "";
      searchRef.current.value = search;
    }
  };

  const defaultValueCalculated = defaultValue || values?.[0];

  const filteredItems = useMemo(
    () =>
      values.filter((value) =>
        value.label?.toLowerCase().includes(search.toLowerCase())
      ),
    [values, search]
  );

  const listItems = filteredItems.map((value, idx) => (
    <li
      key={value.id}
      id={`${label}/listItem-${idx}`}
      className={cn("FormSelect-listItem", {
        "FormSelect-listItems--active": value.id === defaultValueCalculated.id,
        "FormSelect-listItems--disabled": value.isDisabled,
        "FormSelect-listItems--selected": idx === selectedItemIdx,
      })}
      onClick={() => {
        handleChange(value);
        setIsOpen(false);
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        setSelectedItemIdx(idx);
        e.currentTarget.focus();
      }}
      tabIndex={0}
    >
      <span>{value.label}</span>
    </li>
  ));

  useEffect(() => {
    scrollIntoView(0);
    setSelectedItemIdx(0);
    if (isOpen && searchRef) {
      searchRef?.current?.focus();
    }

    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const down = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      } else if (e.key === "Enter") {
        handleChange(filteredItems[selectedItemIdx]);
        setIsOpen(false);
      } else if (e.key === "ArrowDown") {
        setSelectedItemIdx((v) =>
          v + 1 > filteredItems.length - 1 ? 0 : v + 1
        );
      } else if (e.key === "ArrowUp") {
        setSelectedItemIdx((v) =>
          v - 1 < 0 ? filteredItems.length - 1 : v - 1
        );
      }
    };

    document.addEventListener("keydown", down);
    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [isOpen, filteredItems, selectedItemIdx]);

  useEffect(() => {
    setSelectedItemIdx(0);
  }, [search]);

  useEffect(() => {
    scrollIntoView(selectedItemIdx);
  }, [selectedItemIdx]);

  return (
    <div
      ref={select}
      className={cn("FormSelect", {
        "FormSelect--iconed": icon,
        "FormSelect--open": isOpen,
        "FormSelect--disabled": disabled,
      })}
    >
      <label
        className={cn("FormSelect-label", {
          "FormSelect-label--hidden": labelHidden,
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <div className="FormSelect-field">
        {icon && (
          <span className="FormSelect-fieldIcon">
            <Svg {...icon} />
          </span>
        )}
        <button
          className={cn("FormSelect-toggler", {
            "FormSelect-toggler--disabled": disabled,
          })}
          type="button"
          disabled={disabled}
          aria-expanded={isOpen}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((isOpen) => !isOpen);
          }}
        >
          <span className="FormSelect-togglerText">
            {defaultValueCalculated.label}
          </span>
          <span className="FormSelect-togglerIcon">
            <Svg w="9" h="6" i="markerDropdown" />
          </span>
        </button>
        <div className="FormSelect-list" aria-hidden={!isOpen}>
          {searchable ? (
            <FormTextfield
              ref={searchRef}
              autoFocus={isOpen}
              icon="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          ) : null}
          <ul>{listItems}</ul>
        </div>
      </div>
    </div>
  );
};

export default FormSelect;
