import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Paper, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useData from "../../../hook/useData";
import { formatToDateString } from "../../../utils/formatDate";
import {
  chooseConversationLeadType,
  chooseConversationsEndpoint,
  chooseConversationsMetricType,
  chooseConversationsSource,
} from "../../../store/actions/conversationActions";
import { useHistory } from "react-router-dom";
import StyledTableHeader from "./StyledTableHeader/StyledTableHeader";
import { useState } from "react";
import "./DashboardLeadSources.scss";
import ErrorTriangle from "../../../assets/img/sorting/triangle-exclamation-solid (1).svg";
import Loader from "../../Loader/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "HKGrotesk",
    border: "1px solid",
    borderColor: "#EBEBEB",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "white",
    height: "56px !important",
  },
}));

const indexToColumnMapping = {
  0: "source_name",
  1: "new_leads",
  2: "leads_that_worked",
  3: "engaged_leads",
  4: "sales_expertise_needed",
  5: "appt_count",
};

const DashboardLeadSources = () => {
  const headers = [
    "source",
    "incoming leads",
    "workable leads",
    "engaged leads",
    "ai sales handoffs",
    "ai set appointments",
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  const dateRange = useSelector((state) => state.date);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const [activeSortingIndex, setActiveSortingIndex] = useState(0);
  const [ascSortingDirection, setAscSortingDirection] = useState(true);

  let { data, status, statusCode, error } = useData(
    "leadSourceStatistics",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  const handleOpenSourceLeads = (sourceId) => {
    const leadType = data.filter((item) => item.source_name === sourceId)[0]
      .lead_type;
    dispatch(chooseConversationsEndpoint("conversations_source_conversations"));
    dispatch(chooseConversationsMetricType("source_conversations"));
    dispatch(chooseConversationsSource(sourceId));
    dispatch(chooseConversationLeadType(leadType));
    history.push("conversations");
  };

  const compareFunction = (a, b, columnName, columnIndex) => {
    if (columnIndex === 0) {
      if (a[columnName].toUpperCase() < b[columnName].toUpperCase()) {
        return ascSortingDirection ? -1 : 1;
      }
      if (a[columnName].toUpperCase() > b[columnName].toUpperCase()) {
        return ascSortingDirection ? 1 : -1;
      }
      return 0;
    } else {
      if (a[columnName] < b[columnName]) {
        return ascSortingDirection ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return ascSortingDirection ? 1 : -1;
      }
      return 0;
    }
  };

  const handleRequestSort = (newActiveSortingIndex) => {
    if (activeSortingIndex === newActiveSortingIndex) {
      setAscSortingDirection((prevState) => !prevState);
    } else {
      setAscSortingDirection(true);
      setActiveSortingIndex(newActiveSortingIndex);
    }
  };

  if (Array.isArray(data)) {
    data?.sort((a, b) =>
      compareFunction(
        a,
        b,
        indexToColumnMapping[activeSortingIndex],
        activeSortingIndex
      )
    );
  }

  if (status === "pending") {
    return (
      <TableContainer
        component={Paper}
        className="tableContainer"
        sx={{minHeight: 280}}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
        </Table>
        <div className="saleTableLoader">
          <Loader />
        </div>
      </TableContainer>
    );
  }

  if (
    status === "error" ||
    (statusCode !== undefined && statusCode !== 200) ||
    status === "init"
  ) {
    return (
      <TableContainer
        component={Paper}
        className="tableContainer"
        sx={{minHeight: 280}}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
        </Table>
        {
          <div className="leadSourceTableError">
            <img className="ErrorImage" src={ErrorTriangle} alt={"error"} />
            <p className="ErrorMessage">
              There is a problem displaying these data
            </p>
          </div>
        }
      </TableContainer>
    );
  }

  return (
    <div>
      <TableContainer
        component={Paper}
        className="tableContainer"
        sx={{minHeight: 280}}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={headers}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          <TableBody>
            {!!data?.length &&
              data?.map((row) => (
                <StyledTableRow key={row.source_name}>
                  <StyledTableCell
                    scope="row"
                    align="left"
                    className="sourceColumn"
                    onClick={() => handleOpenSourceLeads(row.source_name)}
                  >
                    {row.source_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.new_leads}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.leads_that_worked}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.engaged_leads}{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        opacity: "0.5",
                      }}
                    >
                      ({row.engagement_rate}%)
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.sales_expertise_needed}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.appt_count}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {!data?.length && status !== "pending" && (
              <div className="dashboardLeadSourceNoData">
                <p>There is no data available</p>
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashboardLeadSources;
