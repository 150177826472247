import "./index.scss";
import EditIcon from "@material-ui/icons/Edit";
import cn from "classnames";
import Modal from "@material-ui/core/Modal";
import SettingsModal from "./SettingsModal";
import { useState } from "react";
import useData from "../../../../../../hook/useData";
import Loader from "../../../../../Loader/Loader";

const SettingsBlock = ({ block, form, setForm }) => {
  const [resourceAccessConfig, setResourceAccessConfig] = useState(null);
  const [dependencies, setDependencies] = useState({});

  const {
    resourceType,
    //  description,
    title,
    editorComponent,
  } = block;

  const prepareResourceUrl = (resourceType) => {
    switch (resourceType) {
      case "signature":
        return "getAvailableGeneralInfoResources";
      case "working-hours":
        return "getAvailableWorkingHoursResources";
      default:
        return "";
    }
  };

  const prepareBlankResourceUrl = (resourceType) => {
    switch (resourceType) {
      case "signature":
        return "getBlankGeneralInfoResource";
      case "working-hours":
        return "getBlankWorkingHoursResource";
      default:
        return "";
    }
  };

  const { data: resources, status, error } = useData(
    prepareResourceUrl(resourceType),
    {},
    [dependencies]
  );

  const { data: blankResource } = useData(
    prepareBlankResourceUrl(resourceType),
    {},
    [dependencies]
  );

  const selectResource = (blockId, resourceId) => {
    const updatedConfig = { ...form["Config"] };
    updatedConfig["Resource"] = [...updatedConfig["Resource"]];
    const resources = updatedConfig["Resource"];
    const index = resources.findIndex((item) => item.ResourceType === blockId);
    if (index === -1) {
      resources.push({
        ResourceType: blockId,
        ResourceName: resourceId,
      });
    } else {
      resources[index]["ResourceName"] = resourceId;
    }
    setForm(updatedConfig);
  };

  const handleSettingsBlockAction = (event, resource, create) => {
    event.stopPropagation();

    if (create) {
      return setResourceAccessConfig({
        accessType: "create",
        resource,
      });
    }

    return setResourceAccessConfig({
      accessType: "write",
      resource,
    });
  };

  const isSelected = (resources = [], resourceId) => {
    for (const resource of resources) {
      if (resource.ResourceType === block.resourceType) {
        if (resource.ResourceName === resourceId) {
          return true;
        }
      }
    }
    return false;
  };

  // const deleteResource = (event, resourceId) => {
  //   event.stopPropagation();
  //   console.log("DELETED", resourceId);
  // };

  if (status === "error" || !Array.isArray(resources)) {
    return error || "Error";
  }

  if (status === "init" || status === "pending") {
    return <Loader />;
  }

  return (
    <>
      <div className="SettingsBlock-Container">
        <p className="SettingsBlock-Title">{title}</p>
        <div className="SettingsBlock-Cards-Container">
          {resources.map((resource) => (
            <div
              key={resource.Id}
              className={cn("SettingsBlock-Card", {
                "SettingsBlock-Card--active": isSelected(
                  form?.Config?.Resource,
                  resource.Id
                ),
              })}
              onClick={() => selectResource(block.resourceType, resource.Id)}
            >
              <p className="SettingsBlock-Title">
                Use same {block.name} as {resource.Name}
              </p>
              <div className="SettingsBlock-Card-actions">
                <EditIcon
                  onClick={(event) =>
                    handleSettingsBlockAction(event, resource, false)
                  }
                />
              </div>
            </div>
          ))}
          <div
            className={cn("SettingsBlock-Card", {
              "SettingsBlock-Card--add": true,
            })}
            onClick={(event) =>
              handleSettingsBlockAction(event, blankResource, true)
            }
          >
            <p className="SettingsBlock-Title">
              <strong>+</strong>
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={resourceAccessConfig}
        onClose={() => setResourceAccessConfig(null)}
        className="SettingsBlock-Modal"
      >
        <SettingsModal
          component={editorComponent}
          accessConfig={resourceAccessConfig}
          close={() => setResourceAccessConfig(null)}
          refetch={() => setDependencies({})}
        />
      </Modal>
    </>
  );
};

export default SettingsBlock;
