import AuthService from "../../services/auth.service";

import {
  FETCH_LOGIN_REJECT,
  FETCH_LOGIN_SWITCH,
  FETCH_LOGIN_RESOLVE,
  SWITCH_PRODUCT,
  FETCH_DEALER_SWITCH,
  LOADING,
} from "../actionTypes/userTypes";
import authService from "../../services/auth.service";

export const loginAction = (user, access_token) => {
  // Second argument is getState
  return async (dispatch) => {
    let authData;

    dispatch({
      type: LOADING,
      payload: { loading: true },
    });

    try {
      authData = await AuthService.login(user, access_token);

      if (authData) {
        dispatch({
          type: FETCH_LOGIN_RESOLVE,
          payload: authData,
        });
      } else {
        dispatch({ type: FETCH_LOGIN_REJECT, payload: authData });
      }
    } catch (e) {
      dispatch({ type: FETCH_LOGIN_REJECT, payload: { errorMessage: e.message } });
    }

    return authData;
  };
};

export const switchDealer = (dealerId) => {
  return async (dispatch) => {
    const result = await authService.switchDealer(dealerId);
    if (dealerId) {
      console.log("result in switchDealer is: ", result);
      dispatch({ type: FETCH_DEALER_SWITCH, payload: result });
    }

    return dealerId;
  };
};

export const switchAction = (credentials, auth) => {
  return async (dispatch) => {
    console.log(credentials);

    const authData = await AuthService.switchAccount(
      {
        username: credentials.id,
      },
      auth
    );
    console.log(authData);
    await AuthService.logout(auth, "switch");

    if (authData && authData.access_token) {
      dispatch({ type: FETCH_LOGIN_SWITCH, payload: authData });
    }

    return authData;
  };
};

export const switchActionForUserSettings = (firstChildAccountId, auth) => {
  return async (dispatch) => {
    const authData = await AuthService.switchAccount(
      {
        username: firstChildAccountId,
      },
      auth
    );

    //await AuthService.logout(auth, "switch");

    if (authData && authData.access_token) {
      await dispatch({ type: FETCH_LOGIN_SWITCH, payload: authData });
    }

    return authData;
  };
};

export const logoutAction = (auth) => {
  return async (dispatch) => {
    const authData = await AuthService.logout(auth, "exit");
    if (authData) {
      dispatch({
        type: FETCH_LOGIN_REJECT,
      });
    }

    return authData;
  };
};

export const switchProduct = (product) => {
  return async (dispatch) => {
    if (product) {
      dispatch({
        type: SWITCH_PRODUCT,
        payload: product,
      });
      let authItem = JSON.parse(localStorage.getItem("auth"));
      let user_info = authItem.user_info;
      authItem.selectedProduct = product;
      user_info.selectedProduct = product;
      localStorage.setItem("auth", JSON.stringify(authItem));
    }
  };
};
