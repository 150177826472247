import { GENERAL_LINKS as GENERAL_LINKS_LEAD_ENGAGE } from "../../routes_lead_engage";

import { GENERAL_LINKS as GENERAL_LINKS_CAMPAIGNS } from "../../routes_campaigns";

import { GENERAL_LINKS as GENERAL_LINKS_WEBCHAT } from "../../routes_webchat";

import { GENERAL_LINKS as GENERAL_LINKS_CAR_BUYING } from "../../routes_car_buying"; 

import { GENERAL_LINKS as GENERAL_LINKS_FANDI } from "../../routes_FandI";

import { GENERAL_LINKS as GENERAL_LINKS_FI } from "../../routes_fi";

import SidebarMenuLink from "./SidebarMenuLink";

import "./Sidebar.scss";
import { useSelector } from "react-redux";

const getAllowedGeneralLinks = (product, policy) => {
  if (!product) return [];

  const { Category: productId } = product;
  if (productId === "SALES_AI") {
    let GENERAL_LINKS_FILTERED = [];

    if (policy.length) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_LEAD_ENGAGE;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_LEAD_ENGAGE.filter(
        (item) => item.path !== "/settings"
      );
    }

    return GENERAL_LINKS_FILTERED;
  } else if(productId === "CHAT_AI"){
    let GENERAL_LINKS_FILTERED = [];

    if (policy.length) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_WEBCHAT;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_WEBCHAT.filter(
        (item) => item.path !== "/settings"
      );
    }
    return GENERAL_LINKS_FILTERED;

  } else if (productId === "CARBUYING_AI") {
    let GENERAL_LINKS_FILTERED = [];

    if (policy.length) {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_CAR_BUYING;
    } else {
      GENERAL_LINKS_FILTERED = GENERAL_LINKS_CAR_BUYING.filter(
        (item) => item.path !== "/settings"
      );
    }

    return GENERAL_LINKS_FILTERED;
  } else if (productId === "SERVICE_AI") {
    return GENERAL_LINKS_CAMPAIGNS;
  } else if (productId === "SALESCAMPAIGNS") {
    return GENERAL_LINKS_FI;
  } else if (productId === "FANDI") {
    return GENERAL_LINKS_FANDI;
  }

  return [];
};

const getAllowedRoutes = (policy, subroutes) => {
  const allowedRoutes = [];
  for (const policyItem of policy) {
    const resourceName = policyItem.Resource;
    const allowance = policyItem.Allow;
    if (!allowance?.length) continue;
    const itemsToAdd = subroutes.filter(
      (item) => item.resourceName === resourceName
    );
    allowedRoutes.push(...itemsToAdd);
  }
  allowedRoutes.sort((a, b) =>
    a.index > b.index ? 1 : b.index > a.index ? -1 : 0
  );
  return allowedRoutes;
};

const Sidebar = () => {
  const policy =
    useSelector((state) => state?.user?.auth?.data?.user_info?.Policy) || [];
  const selectedProduct = useSelector(
    (state) => state?.user?.auth?.selectedProduct
  );
  const role = useSelector(state => state?.user?.auth?.data?.user_info?.Role)

  let GENERAL_LINKS_FILTERED = getAllowedGeneralLinks(
    selectedProduct,
    policy || []
  );

  const menuItems = GENERAL_LINKS_FILTERED.map((menuItem) => {
    const { label, subroutes, path } = menuItem;

    if (path === "/settings") {
      menuItem.subroutes = getAllowedRoutes(policy, subroutes || []);
    }

    if (path === "/user" && role?.toUpperCase() === 'REGULAR') {
      menuItem.subroutes = menuItem.subroutes.filter(item => item.path !== '/user-management');
    }

    return (
      <li key={label} className="Sidebar-menuItem">
        <SidebarMenuLink {...menuItem} />
      </li>
    );
  });

  return (
    <div className="Sidebar">
      <ul className="Sidebar-menu">{menuItems}</ul>
    </div>
  );
};

export default Sidebar;
