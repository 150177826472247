import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import Svg from "../../Svg";

import "./FormTextfield.scss";
import { forwardRef } from "react";

const FormTextfield = forwardRef(
  (
    {
      label,
      labelHidden = false,
      icon = false,
      errorMessage = "",
      outlined = false,
      iconComponent = false,
      hoverInfo = null,
      error = false,
      ...rest
    },
    forwardedRef
  ) => {
    return (
      <div
        className={cn("FormTextfield", {
          "FormElement--iconed": icon,
        })}
      >
        <label
          className={cn("FormTextfield-label", {
            "FormTextfield-label--hidden": labelHidden,
          })}
          htmlFor={rest.id}
        >
          {label}
        </label>
        <div className="FormTextfield-field">
          <input
            ref={forwardedRef}
            className={cn({
              "FormTextfield-input-outlined": outlined,
              "FormTextfield-input": !outlined,
              "FormTextfield-input_error": error,
            })}
            {...rest}
          />
          {errorMessage && (
            <span className="FormTextfield-error">{errorMessage}</span>
          )}
          <div>
            {hoverInfo && icon ? (
              <>
                {icon && (
                  <Tooltip
                    title={<h1 style={{ fontSize: "1.3vh" }}>{hoverInfo}</h1>}
                    placement="top"
                  >
                    <span className="FormTextfield-icon">
                      <Svg w="18" h="18" i={icon} />
                    </span>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                {icon && (
                  <span className="FormTextfield-icon">
                    <Svg w="18" h="18" i={icon} />
                  </span>
                )}
              </>
            )}
          </div>
          {/* <div>
          <Tooltip placement="top" title="hello">
            <span>H</span>
          </Tooltip>
        </div> */}
          {iconComponent && (
            <span className="FormTextfield-icon-component">
              {iconComponent}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default FormTextfield;
