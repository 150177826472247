const CalendarIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_2125)">
      <path
        d="M4.28571 0C4.87835 0 5.35714 0.418945 5.35714 0.9375V1.875H9.64286V0.9375C9.64286 0.418945 10.1217 0 10.7143 0C11.3069 0 11.7857 0.418945 11.7857 0.9375V1.875H13.3929C14.2801 1.875 15 2.50488 15 3.28125V4.6875H0V3.28125C0 2.50488 0.719866 1.875 1.60714 1.875H3.21429V0.9375C3.21429 0.418945 3.69308 0 4.28571 0ZM0 5.625H15V13.5938C15 14.3701 14.2801 15 13.3929 15H1.60714C0.719866 15 0 14.3701 0 13.5938V5.625ZM2.14286 7.96875V8.90625C2.14286 9.16406 2.38393 9.375 2.67857 9.375H3.75C4.04464 9.375 4.28571 9.16406 4.28571 8.90625V7.96875C4.28571 7.71094 4.04464 7.5 3.75 7.5H2.67857C2.38393 7.5 2.14286 7.71094 2.14286 7.96875ZM6.42857 7.96875V8.90625C6.42857 9.16406 6.66964 9.375 6.96429 9.375H8.03571C8.33036 9.375 8.57143 9.16406 8.57143 8.90625V7.96875C8.57143 7.71094 8.33036 7.5 8.03571 7.5H6.96429C6.66964 7.5 6.42857 7.71094 6.42857 7.96875ZM11.25 7.5C10.9554 7.5 10.7143 7.71094 10.7143 7.96875V8.90625C10.7143 9.16406 10.9554 9.375 11.25 9.375H12.3214C12.6161 9.375 12.8571 9.16406 12.8571 8.90625V7.96875C12.8571 7.71094 12.6161 7.5 12.3214 7.5H11.25ZM2.14286 11.7188V12.6562C2.14286 12.9141 2.38393 13.125 2.67857 13.125H3.75C4.04464 13.125 4.28571 12.9141 4.28571 12.6562V11.7188C4.28571 11.4609 4.04464 11.25 3.75 11.25H2.67857C2.38393 11.25 2.14286 11.4609 2.14286 11.7188ZM6.96429 11.25C6.66964 11.25 6.42857 11.4609 6.42857 11.7188V12.6562C6.42857 12.9141 6.66964 13.125 6.96429 13.125H8.03571C8.33036 13.125 8.57143 12.9141 8.57143 12.6562V11.7188C8.57143 11.4609 8.33036 11.25 8.03571 11.25H6.96429ZM10.7143 11.7188V12.6562C10.7143 12.9141 10.9554 13.125 11.25 13.125H12.3214C12.6161 13.125 12.8571 12.9141 12.8571 12.6562V11.7188C12.8571 11.4609 12.6161 11.25 12.3214 11.25H11.25C10.9554 11.25 10.7143 11.4609 10.7143 11.7188Z"
        fill="#C1C1C1"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2125">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CalendarIcon;
