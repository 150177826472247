import cn from "classnames";

import Svg from "../../Svg/Svg";

import "./FormNumfield.scss";

const FormNumfield = ({
  label,
  labelHidden = false,
  icon = false,
  errorMessage,
  ...rest
}) => {
  return (
    <div
      className={cn("FormNumfield", {
        "FormElement--iconed": icon,
      })}
    >
      <label
        className={cn("FormNumfield-label", {
          "FormNumfield-label--hidden": labelHidden,
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <div className="FormNumfield-field">
        <input
          className={`FormNumfield-input ${
            errorMessage && " FormNumfield-input-error"
          }`}
          {...rest}
          type="number"
          onWheel={(e) => e.target.blur()}
        />
        {errorMessage && (
          <span className="FormNumfield-error">{errorMessage}</span>
        )}
        {icon && (
          <span className="FormNumfield-icon">
            <Svg w="18" h="18" i={icon} />
          </span>
        )}
      </div>
    </div>
  );
};

export default FormNumfield;
