import { useEffect, useState } from "react";

import useData from "../../../../hook/useData";
import Loader from "../../../Loader/Loader";
import SelectableCard from "../../../SelectableCard/SelectableCard";

import "./index.scss";

const CampaignSequences = ({
  setFormCompleted,
  setForm,
  formValue,
  form,
  handleNext,
}) => {
  const [templateToStop, setTemplateToStop] = useState(null);

  const [templateParams, setTemplateParams] = useState({
    campaign_type: form["Type"]["Id"],
    campaign_category: form["Category"]["Id"],
  });

  const { data: campaignSequences, status, execute: refetch } = useData(
    "getCampaignSequences",
    {
      campaign_type: form["Type"]["Id"],
      campaign_category: form["Category"]["Id"],
    },
    []
  );

  const { execute: stopCampaignExecute, status: stopStatus } = useData(
    "updateCampaignConfiguration",
    templateParams,
    [templateParams],
    false
  );

  useEffect(() => {
    if (formValue) setFormCompleted();
  }, [formValue]);

  useEffect(() => {
    if ("Config" in templateParams) {
      if (
        window.confirm(
          `Are you sure you want to stop ${templateParams.Config.Name}?`
        )
      ) {
        stopCampaignExecute();
      } else {
        // setTemplateToStop(null);
        setTemplateParams({
          campaign_type: form["Type"]["Id"],
          campaign_category: form["Category"]["Id"],
        });
      }
    }
  }, [templateParams]);

  useEffect(() => {
    if (stopStatus === "success") {
      refetch();
      // window.location
    }
  }, [stopStatus]);

  if (status === "pending" || status === "init") {
    return (
      <div className="CampaignSequences-Loader">
        <Loader />
      </div>
    );
  }

  if (status === "error" || !campaignSequences?.length) {
    return (
      <div className="CampaignSequences-Error">
        <p>No campaign sequences</p>
      </div>
    );
  }

  const startedCampaigns = campaignSequences.filter((item) => !item.IsTemplate);
  const campaignTemplates = campaignSequences.filter((item) => item.IsTemplate);

  const stopCampaign = (template) => {
    // setTemplateToStop(template);
    const updatedTemplate = JSON.parse(JSON.stringify(template));
    updatedTemplate.Status = "STOPPED";
    setTemplateParams({
      ...templateParams,
      campaign_sequence: template.Id,
      Config: updatedTemplate,
    });
    // if (window.confirm(`Are you sure you want to stop ${template.Name}?`)) {
    //   stopCampaignExecute();
    // } else {
    //   // setTemplateToStop(null);
    //   setTemplateParams({
    //     campaign_type: form["Type"]["Id"],
    //     campaign_category: form["Category"]["Id"],
    //   });
    // }
  };

  let sequenceText = `Select an initiative to configure, or cancel it altogether by clicking “stop initiative.” Please note: active initiatives cannot be edited. To make changes to an existing initiative, click “stop initiative,” and create a new one with your updated settings and parameters.`;

  if (
    templateParams.campaign_category &&
    (templateParams.campaign_category.toString().split(":").slice(-1)[0] ===
      "Recall" ||
      templateParams.campaign_category.toString().split(":").slice(-1)[0] ===
        "LostSoul")
  ) {
    sequenceText =
      "To create a new initiative, click the plus sign below. To cancel an existing initiative, click “stop initiative.” Please note: active initiatives cannot be edited. To make changes to an existing initiative, click “stop initiative,” and create a new one with your updated settings and parameters.";
  }

  return (
    <div>
      <div className="CampaignSequences-Label">
        <h3>{sequenceText}</h3>
      </div>
      <div className="CampaignSequences-Container">
        {startedCampaigns.map((campaignSequence) => (
          <SelectableCard
            key={campaignSequence.Id}
            active={campaignSequence.Id === formValue?.Id}
            value={campaignSequence.Name}
            createDate={campaignSequence.CreateDate}
            launchedItems={
              "LaunchedItems" in campaignSequence
                ? campaignSequence.LaunchedItems
                : null
            }
            totalItems={
              "TotalItems" in campaignSequence
                ? campaignSequence.TotalItems
                : null
            }
            onSelect={() => setForm(campaignSequence)}
            disabled={
              campaignSequence.Id ===
                "Campaigns:ServiceCampaign:FirstServiceCampaign:Template:TireChange" ||
              campaignSequence.Id ===
                "Campaigns:ServiceCampaign:FirstServiceCampaign:Template:WashAndVacuum"
            }
            hasStoppedButton={campaignSequence.Status !== "STOPPED"}
            stopCampaign={() => {
              stopCampaign(campaignSequence);
            }}
            status={campaignSequence.Status}
            // started={
            //   campaignSequence.Id ===
            //   "Campaigns:ServiceCampaign:FirstServiceCampaign:Template:OilChange"
            //     ? true
            //     : false
            // }
          />
        ))}
        {campaignTemplates.map((campaignSequence) => (
          <SelectableCard
            key={campaignSequence.Id}
            value="+"
            onSelect={() => {
              setForm(campaignSequence, true);
              handleNext();
            }}
          />
        ))}
      </div>
      {stopStatus === "pending" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CampaignSequences;
