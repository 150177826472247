import {Alert, styled} from "@mui/material";

const alertSeverityColorMap = {
  error: '#B72A3F',
  success: '#3D746B',
  default: '#5383EC'
}

const SnackbarMessage = styled(Alert)(({severity}) => ({
  width: '100%',
  fontSize: '1.2rem',
  alignItems: 'center',
  cursor: 'pointer',
  lineHeight: 'normal',
  backgroundColor: alertSeverityColorMap[severity] || alertSeverityColorMap.default
}))

export default SnackbarMessage;
