import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import useData from "./useData";

// Custom hook to handle navigation blocking
const useNavigationBlocker = (shouldBlockNavigation) => {
  const history = useHistory();
  const location = useLocation();
  const [isBlocked, setIsBlocked] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    const unblock = history.block((nextLocation) => {
      if (shouldBlockNavigation) {
        setNextLocation(nextLocation);
        setIsBlocked(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [shouldBlockNavigation, history]);

  const confirmNavigation = () => {
    setIsBlocked(false);
    history.push(nextLocation.pathname);
  };

  const cancelNavigation = () => {
    setIsBlocked(false);
    setNextLocation(null);
  };

  return { isBlocked, confirmNavigation, cancelNavigation };
};

export default useNavigationBlocker;
