import FormElement from "../../../../FormElement/FormElement";
import "./index.scss";

const CampaignVariables = ({ form, setForm, VARIABLES, noTitle }) => {
  console.log("variables: ", form.Category?.CampaignCategory);
  let exampleText = "";
  if (form.Category?.CampaignCategory === "LostSoul") {
    exampleText =
      "For example: Lost Soul - Purchases from May to December 2020";
  } else if (form.Category?.CampaignCategory === "Recall") {
    exampleText =
      "For example: Toyota 2019/2020 Corolla Seat Belt Assembly Recall - 12/10/2020";
  }

  if (noTitle) {
    return (
      <div className="CampaignVariables-Container">
        <div className="CampaignVariables-Settings-Container">
          <span className="CampaignVariables-BlockTitle">Initiative Name</span>
          <div className="CampaignVariables">
            {VARIABLES.map((variable) => (
              <div className="CampaignVariables-input" key={variable.Id}>
                <FormElement
                  label={`${variable.Description}  ${exampleText}  `}
                  type={variable.Type}
                  id={variable.Id}
                  name={variable.Id}
                  value={form?.Config?.[variable.Id]}
                  onChange={(e) => {
                    if (variable.Type === "numfield") {
                      const value = Number(e.target.value);
                      if (value < variable.min || value > variable.max) {
                        return;
                      }
                      setForm({ [variable.Id]: e.target.value });
                    } else {
                      setForm({ [variable.Id]: e.target.value });
                    }
                  }}
                  placeholder={variable.Placeholder}
                  style={{ width: "30vw" }}
                  min={variable.min}
                  max={variable.max}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="CampaignVariables-Container">
      <div className="CampaignVariables-Settings-Container">
        <span className="CampaignVariables-BlockTitle">
          Appointment Scheduling Settings
        </span>
        {/* <p>
          Enter the link to your appointment scheduling tool to allow customers
          to set appointments.
        </p> */}
        <div className="CampaignVariables">
          {VARIABLES.map((variable) => (
            <>
              <div className="CampaignVariables-title">{variable.Title}</div>
              <div className="CampaignVariables-description">
                {" "}
                {variable.Description}
              </div>
              <div className="CampaignVariables-input" key={variable.Id}>
                <FormElement
                  icon="search"
                  type={variable.Type}
                  id={variable.Id}
                  name={variable.Id}
                  value={form?.Config?.[variable.Id]}
                  onChange={(e) => {
                    if (variable.Type === "numfield") {
                      const value = Number(e.target.value);
                      if (value < variable.min || value > variable.max) {
                        return;
                      }
                      setForm({ [variable.Id]: e.target.value });
                    } else {
                      setForm({ [variable.Id]: e.target.value });
                    }
                  }}
                  placeholder={variable.Placeholder}
                  style={{ width: "30vw" }}
                  min={variable.min}
                  max={variable.max}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignVariables;
