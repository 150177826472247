import React, {useCallback} from "react";

import { useEffect, useRef, useState } from "react";

import settingsBlocksStructure from "./settingsBlocksStructure";

import useWindowSize from "../../hook/useWindowSize";

import "./Settings.scss";
import { useSelector } from "react-redux";
import settingsDealerChatBlocks from "./settingsDealerChatBlocksStructure";
import settingsCarBuyingBlocks from "./settingsCarBuyingBlocksStructure";
import { useAuth0 } from "@auth0/auth0-react";

const Settings = () => {
  const { user } = useAuth0();
  const windowSize = useWindowSize();
  const container = useRef(null);

  const [allowedSettingsBlocks, setAllowedSettingsBlocks] = useState([]);
  const [overwriteWithBusinessHours, setOverwriteWithBusinessHours] =
    useState(false);
  const [renderedBlocks, setRenderedBlocks] = useState([]);

  const policy =
    useSelector((state) => state?.user?.auth?.data?.user_info?.Policy) || [];

  const selectedProduct = useSelector(
    (state) => state?.user?.auth?.data?.selectedProduct
  );

  const appendRenderedBlock = useCallback((value) => {
    if (!renderedBlocks.includes(value)) {
      setRenderedBlocks([...renderedBlocks, value])
    }
  }, [renderedBlocks, setRenderedBlocks])

  useEffect(() => {
    const id = location.hash.slice(1);
    if (!id) return;

    const el = document.getElementById(id);
    if (!el) return;

    if (windowSize.width < 768) {
      const parent = document.querySelector(".Layout");

      parent.scroll({
        top:
          el.offsetTop -
          window.getComputedStyle(parent).paddingTop.slice(0, -2),
        behavior: "smooth",
      });
    } else {
      const containerRect = container.current.getBoundingClientRect();

      container.current.scroll({
        top: el.offsetTop - containerRect.top,
        behavior: "smooth",
      });
    }
  }, [location.hash.slice(1), renderedBlocks]);

  useEffect(() => {
    const allowedBlocks = [];
    for (const policyItem of policy) {
      const resourceName = policyItem.Resource;
      const allowance = policyItem.Allow;
      if (!allowance?.length) continue;
      // if (!allowance.includes("*")) continue;
      const itemsToAdd = settingsBlocksStructure.filter(
        (item) => item.resourceName === resourceName
      );
      allowedBlocks.push(...itemsToAdd);
    }
    console.log(allowedBlocks);
    allowedBlocks.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    if (selectedProduct?.Id === "DealerChat") {
      let settingsDealerChatBlocksFiltered = settingsDealerChatBlocks;
      if (user.email !== "super-admin@example.com") {
        settingsDealerChatBlocksFiltered = settingsDealerChatBlocks?.filter(
          (item) => item.resourceName !== "Settings:DealershipTakeover"
        );
      }
      setAllowedSettingsBlocks(settingsDealerChatBlocksFiltered);
    } else if (selectedProduct?.Id === "CAR_BUYING") {
      setAllowedSettingsBlocks(settingsCarBuyingBlocks);
    } /*  if(selectedProduct?.Id === "LEAD_ENGAGE"){
      setAllowedSettingsBlocks(settingsBlocksStructure)
    }else */ else {
      setAllowedSettingsBlocks(allowedBlocks);
    }
  }, [policy]);

  const formatIndex = (i) => {
    i += 1;
    if (i < 10) {
      return `0${i}`;
    }
    return i;
  };

  const blocks = allowedSettingsBlocks.map((block, i) => {
    const {
      id,
      title,
      description,
      component,
      noHeader,
      resourceName,
      topDescription,
    } = block;

    const componentParams = {
      renderedCallback: () => appendRenderedBlock(blockId)
    };

    if (resourceName === "Settings:WorkingHours") {
      // componentParams.
    }

    const clonedComponent = React.cloneElement(component, componentParams);

    const blockId = title.split(" ").join("");

    if (noHeader) {
      return (
        <div key={id} className="Settings-block" id={blockId}>
          {clonedComponent}
        </div>
      );
    }

    return (
      <div key={id} className="Settings-block" id={blockId}>
        <div className="Settings-blockHeader">
          <h2 className="Settings-blockTitle">
            <span>{formatIndex(i)}</span> {title}
          </h2>
          <div className="Settings-blockDescription">{description}</div>
          {topDescription ? (
            <div className="Settings-topDescription">{topDescription}</div>
          ) : (
            <></>
          )}
        </div>
        {clonedComponent}
      </div>
    );
  });

  return (
    <div className="Settings" ref={container}>
      <div className="Settings-blocks">{blocks}</div>
    </div>
  );
};

export default Settings;
