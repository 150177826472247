import { useEffect, useRef, useReducer, useState } from "react";
import cn from "classnames";

import SettingsDatePicker from "../SettingsDatePicker";
import SettingsTimePicker from "../SettingsTimePicker";
import FormElement from "../../FormElement";
import Loader from "../../Loader";
import Svg from "../../Svg";

import useWindowSize from "../../../hook/useWindowSize";
import useData from "../../../hook/useData";

import reducer from "./reducer";

import TIMEZONES from "./timeZones";
import DAYS from "./days";

import "./SettingsTime.scss";

const SettingsTime = ({
  accessConfig = {},
  refetch = () => {},
  close = () => {},
}) => {
  const initialRender = useRef(true);
  const windowSize = useWindowSize();
  const [state, setState] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [update, dispatch] = useReducer(reducer, null);
  const [resourceName, setResourceName] = useState(
    accessConfig?.resource?.Name
  );

  const [invalidSpecificDateError, setInvalidSpecificDateError] = useState(
    false
  );

  const { accessType, resource } = accessConfig;

  const { data, status, error } = useData(
    "getAccountWorkingHours",
    {},
    [],
    resource === undefined
  );

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("updateAccountWorkingHours", update, [update], false);

  const {
    // data: updateResourceData,
    status: updateResourceStatus,
    execute: updateResourceExecute,
  } = useData("updateAccountWorkingHoursResource", update, [update], false);

  const {
    // data: createData,
    status: createStatus,
    execute: createExecute,
  } = useData("createAccountWorkingHoursResource", update, [update], false);

  // Fill state with initial data
  useEffect(() => {
    if (
      data &&
      ("WorkingHours" in data || "SpecificDates" in data || "UtcOffset" in data)
    ) {
      setState(data);
      dispatch({
        type: "overwrite",
        payload: data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (resource) {
      const payload = {
        ...JSON.parse(JSON.stringify(resource.Resource)),
      };

      setState(payload);

      if (accessType === "write") {
        payload.resource_id = resource?.Id;
      }

      dispatch({
        type: "overwrite",
        payload: payload,
      });
    }
  }, [resource]);

  // Reset update object and Refill state with updated data if update was success
  useEffect(() => {
    if (updateStatus === "success") {
      setState(updateData);
      dispatch({ type: "overwrite", payload: updateData });
      setUpdateError(null);
    }
    if (updateStatus === "error")
      setUpdateError("Could not update working hours");
  }, [updateStatus]);

  useEffect(() => {
    if (updateResourceStatus === "success") {
      close();
      refetch();
    }
  }, [updateResourceStatus]);

  useEffect(() => {
    if (createStatus === "success") {
      close();
      refetch();
    }
  }, [createStatus]);

  // Execute update if not initial render and debounce data exists.
  // Maybe no need for init render check
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (update) {
      let error = false;

      if (update.SpecificDates) {
        update.SpecificDates.forEach((specificDate) => {
          if (
            !specificDate.Date.Day ||
            !specificDate.Date.Month ||
            !specificDate.Date.Year ||
            !specificDate.TimeRange.Start ||
            !specificDate.TimeRange.End
          ) {
            error = true;
          }
        });
      }

      if (!error) {
        // updateExecute();
      }
    }
  }, [update]);

  const handleChangeTimezone = (value) => {
    dispatch({ type: "changeTimezone", payload: value.id });
  };

  const handleUpdateAvailableTimes = (day, key, value) => {
    if (state === null) return;

    const defaultBranch = {
      ...state.WorkingHours,
      ...((update && update.SpecificDates) || {}),
    };

    dispatch({
      type: "updateAvailableTimes",
      payload: {
        defaultBranch,
        day,
        key,
        value,
      },
    });
  };

  const handleAddSpecificDate = () => {
    if (update?.SpecificDates?.length > state?.SpecificDates?.length) {
      return;
    }

    const defaultBranch = [
      ...((update && update.SpecificDates) || []),
    ];

    dispatch({
      type: "addSpecificDate",
      payload: {
        defaultBranch,
      },
    });
  };

  const handleUpdateSpecificDate = (idx, path, value) => {
    if (state === null) return;

    const defaultBranch = [
      ...((update && update.SpecificDates) || []),
    ];

    dispatch({
      type: "updateSpecificDate",
      payload: {
        defaultBranch,
        idx,
        path,
        value,
      },
    });
  };

  const handleDeleteSpecificDate = (idx) => {
    const defaultBranch = [...update.SpecificDates]
    dispatch({
      type: "deleteSpecificDate",
      payload: {
        defaultBranch,
        idx,
      },
    });
  };

  const correctDates = () => {
    let newSpecificDates = [];
    for (const date of update.SpecificDates) {
      if (state.SpecificDates.indexOf(date) === -1) {
        newSpecificDates.push(date);
      }
    }
    const newDate = new Date();
    const currentDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    );

    for (const newDate of newSpecificDates) {
      const selectedDate = new Date(
        +newDate.Date.Year,
        +newDate.Date.Month - 1,
        +newDate.Date.Day
      );

      if (currentDate.getTime() > selectedDate.getTime()) {

        setInvalidSpecificDateError(true);
        return false;
      }
    }
    setInvalidSpecificDateError(false);
    return true;
  };

  const handleSubmitData = () => {
    if (!correctDates()) {
      return;
    }

    if (accessType === "create") {
      createExecute();
    } else if (accessType === "write") {
      updateResourceExecute();
    } else {
      updateExecute();
    }
  };

  const handleResourceNameChange = (value) => {
    setResourceName(value);
    dispatch({
      type: "addResourceName",
      payload: {
        ResourceName: value,
      },
    });
  };

  const getButtonActionLabel = () => {
    if (accessType === "write") return "save";
    if (accessType === "create") return "create";
    return "save";
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending") {
      return <Loader />;
    }

    const combinedState = state || update ? { ...state, ...update } : null;

    if (!combinedState) return null;

    let currentTimezone = TIMEZONES.find((timezone) => {
      return timezone.id === combinedState.UtcOffset;
    });

    if (!currentTimezone) {
      currentTimezone = TIMEZONES.find((timezone) => {
        return timezone.id === "-04";
      });
    }

    const groupsPrimary = DAYS.map((day, idx) => {
      const dayState = combinedState.WorkingHours[day];

      return (
        <div key={day} className="SettingsTime-group">
          <FormElement
            type="textfield"
            label="Day"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${day}`}
            name={`timeDay${day}`}
            readOnly
            value={day}
          />
          <SettingsTimePicker
            label="From"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${day}From`}
            name={`timeDay${day}From`}
            value={dayState.Start}
            icon={{ w: 18, h: 18, i: "clock" }}
            disabled={!dayState.Workday}
            onChange={(value) => {
              handleUpdateAvailableTimes(day, "Start", value);
            }}
            readOnly={accessType === "read"}
          />
          <SettingsTimePicker
            label="To"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${day}To`}
            name={`timeDay${day}To`}
            value={dayState.End}
            icon={{ w: 18, h: 18, i: "clock" }}
            disabled={!dayState.Workday}
            onChange={(value) => {
              handleUpdateAvailableTimes(day, "End", value);
            }}
            readOnly={accessType === "read"}
          />
          <div className="SettingsTime-actions">
            <FormElement
              type="checkbox"
              label="Closed"
              id={`timeDay${day}Closed`}
              name={`timeDay${day}Closed`}
              checked={!dayState.Workday}
              onChange={(e) =>
                handleUpdateAvailableTimes(day, "Workday", !e.target.checked)
              }
              disabled={accessType === "read"}
            />
            <div className="SettingsTime-action SettingsTime-action--placeholder"></div>
          </div>
        </div>
      );
    });

    const groupsSecondary = update.SpecificDates.map((day, idx) => {
      return (
        <div key={day + idx} className="SettingsTime-group">
          <SettingsDatePicker
            label="Date"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${idx}`}
            name={`timeDay${idx}`}
            value={`${day.Date.Month || "mm"} / ${day.Date.Day || "dd"} / ${
              day.Date.Year || "yyyy"
            }`}
            disabled={day.Closed}
            onChange={(value) => {
              handleUpdateSpecificDate(idx, "Date", value);
            }}
            readOnly={accessType === "read"}
          />

          <SettingsTimePicker
            label="From"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${idx}From`}
            name={`timeDay${idx}From`}
            value={day.TimeRange.Start}
            icon={{ w: 18, h: 18, i: "clock" }}
            disabled={day.Closed}
            onChange={(value) => {
              handleUpdateSpecificDate(idx, "TimeRange.Start", value);
            }}
            readOnly={accessType === "read"}
          />
          <SettingsTimePicker
            label="To"
            labelHidden={windowSize.width >= 768 && idx !== 0}
            id={`timeDay${idx}To`}
            name={`timeDay${idx}To`}
            value={day.TimeRange.End}
            icon={{ w: 18, h: 18, i: "clock" }}
            disabled={day.Closed}
            onChange={(value) => {
              handleUpdateSpecificDate(idx, "TimeRange.End", value);
            }}
            readOnly={accessType === "read"}
          />
          <div className="SettingsTime-actions">
            <FormElement
              type="checkbox"
              label="Closed"
              id={`timeDay${idx}Closed`}
              name={`timeDay${idx}Closed`}
              checked={day.Closed}
              onChange={(e) =>
                handleUpdateSpecificDate(idx, "Closed", e.target.checked)
              }
              disabled={accessType === "read"}
            />
            {accessType !== "read" && (
              <button
                type="button"
                className="SettingsTime-action SettingsTime-action--delete"
                onClick={() => handleDeleteSpecificDate(idx)}
              >
                <Svg w="18" h="18" i="garbage" />
              </button>
            )}
          </div>
        </div>
      );
    });

    return (
      <>
        {resource && (
          <div key={resource.Id} className="SettingsTime-ResourceName">
            <FormElement
              type="textfield"
              icon="info"
              hoverInfo="If desired, you can create a different set of work hours for different initiatives. Enter a unique name to help identify which work hours you’ve selected."
              defaultValue={resourceName}
              label="Work Hours Settings Nickname"
              id={resource.Id}
              key={resource.Id}
              readOnly={accessType === "write"}
              onChange={(e) => handleResourceNameChange(e.target.value)}
            />
          </div>
        )}
        <div className="SettingsTime-timezone">
          <FormElement
            type="select"
            label="Time zone"
            id="timezone"
            name="timezone"
            values={TIMEZONES}
            defaultValue={currentTimezone || null}
            onChange={(value) => {
              handleChangeTimezone(value);
            }}
            disabled={accessType === "read"}
          />
        </div>
        <div className="SettingsTime-groups">
          <h3 className="SettingsTime-groupsLabel">Business Hours</h3>
          <h4 className="SettingsTime-desc">
            Enter your regular hours of operation.
          </h4>
          {groupsPrimary}
        </div>
        <div className="SettingsTime-groups">
          <h3 className="SettingsTime-groupsLabel">Special Business Hours</h3>
          <h4 className="SettingsTime-desc">
            Schedule special business hours, including closures and reduced
            hours of operation, for holidays and other events.
          </h4>
          {groupsSecondary}
        </div>
        {accessType !== "read" && (
          <div className="SettingsTime-addDate">
            <button
              className="SettingsTime-addDateButton"
              onClick={handleAddSpecificDate}
            >
              <div className="SettingsTime-addDateIcon">
                <Svg w="32" h="32" i="add" />
              </div>
              <div className="SettingsTime-addDateLabel">
                Add Special Business Hours
              </div>
            </button>
          </div>
        )}
        <span>{updateError}</span>
        {invalidSpecificDateError && (
          <span className="SpecificDate-error">
            do not add specific dates in the past
          </span>
        )}
        {accessType !== "read" && (
          <div className="SettingsTime-save">
            <FormElement
              onClick={handleSubmitData}
              type="submit"
              label={`${getButtonActionLabel()} Business Hours`}
              disabled={accessType === "create" && !resourceName}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={cn("SettingsTime", {
        "SettingsTime--disabled":
          updateStatus === "pending" ||
          updateResourceStatus === "pending" ||
          createStatus === "pending",
      })}
    >
      {render()}
    </div>
  );
};

export default SettingsTime;
