import { Link } from "react-router-dom";
import cn from "classnames";

import Sidebar from "../Sidebar";
import Header from "../Header";

import "./Layout.scss";

import logo from "./assets/logoWhite.png";
import logoCDK from "./assets/logoCDK.svg";

const Layout = ({ content, widgets, className = "", withHeader = true }) => {
  console.log(window.location.hostname)
  const indexCDK = window.location.hostname;
  const isCDK = indexCDK.includes("crmaiva");

  return (
    <div className={cn("Layout", {"Layout_no-header": !withHeader}, className)}>
      <div className="Layout-logo">
        <Link to="/">
          <img src={isCDK ? logoCDK : logo} alt="" />
        </Link>
      </div>
      <div className="Layout-navigation">
        <Sidebar />
      </div>

      {withHeader &&
        <div className="Layout-widgets">
          <Header widgets={widgets} />
        </div>
      }

      <div className={cn("Layout-content", {"Layout-content_no-header": !withHeader})}>{content}</div>
    </div>
  );
};

export default Layout;
