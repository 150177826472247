import "./ContactFormModalLayout.scss";
import FormElement from "../../FormElement/FormElement";
import ChatFormModalButtonsGroup from "../ChatFormModalButtonsGroup/ChatFormModalButtonsGroup";
import { useEffect, useState } from "react";
import CloseIcon from "../../../assets/img/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useData from "../../../hook/useData";
import { CircularProgress } from "@material-ui/core";
import Loader from "../../Loader/Loader";
import { refetchChat } from "../../../store/actions/conversationDealerChatActions";

const options = [
  { id: 0, label: "Text" },
  { id: 1, label: "Call" },
  { id: 2, label: "Email" },
];

const ContactFormModalLayout = ({
  changeModalState,
  setShrinkModal,
  closeModal,
  generalInfoData,
  setIsShrinkClose,
  refetchGeneralInfoData,
  setShowSnackbar,
}) => {
  const dispatch = useDispatch();
  const getDefaultPrefferedForm = () => {
    if (generalInfoData.Channel !== "") {
      if (generalInfoData.Channel === "Text") {
        return options[0];
      }
      if (generalInfoData.Channel === "Call") {
        return options[1];
      }
    }
    return options[2];
  };
  const splittedFullname = generalInfoData?.FullName?.split(" ");
  const splittedFirstname =
    splittedFullname && splittedFullname[0] ? splittedFullname[0] : "";
  const splittedLastname =
    splittedFullname && splittedFullname[1] ? splittedFullname[1] : "";
  const [firstname, setFirstname] = useState(splittedFirstname);
  const [lastname, setLastname] = useState(splittedLastname);
  const [email, setEmail] = useState(
    generalInfoData?.Email ? generalInfoData.Email : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    generalInfoData?.Phone ? generalInfoData.Phone : ""
  );
  const [prefferedMethod, setPrefferedMethod] = useState(
    getDefaultPrefferedForm()
  );
  const [validationErrors, setValidationErrors] = useState({});
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const validatePhonenumber = (phoneNumber) => {
    return phoneNumber.length >= 10;
  };
  const { cid: urlCid } = useParams();

  const {
    status: createContactFormStatus,
    execute: createContactForm,
    data: createContactFormData,
  } = useData(
    "createContactForm",
    {
      account_id: accountId,
      user_id: urlCid,
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone_number: phoneNumber,
      preferred_channel: [
        {
          value: prefferedMethod.label,
          selected: true,
        },
      ],
      form_type: "ContactForm",
    },
    [
      accountId,
      urlCid,
      firstname,
      lastname,
      email,
      phoneNumber,
      prefferedMethod,
    ],
    false
  );

  useEffect(() => {
    refetchGeneralInfoData();
  }, []);

  const validateForm = async () => {
    const errors = {};
    if (firstname === "" && lastname === "") {
      errors["firstname"] = "Firstname or lastname is required!";
      errors["lastname"] = "Firstname or lastname is required!";
    }
    if (email === "" && phoneNumber === "") {
      errors["email"] = "Email or phone number is required!";
      errors["phonenumber"] = "Email or phone number is required!";
    }
    if (email !== "" && !validateEmail(email)) {
      errors["email"] = "Incorrect email";
    }
    if (phoneNumber !== "" && !validatePhonenumber(phoneNumber)) {
      errors["phonenumber"] = "Phone number should be at least 10 digits!";
    }
    if (
      (prefferedMethod?.label === "Text" ||
        prefferedMethod?.label === "Call") &&
      phoneNumber === ""
    ) {
      errors["phonenumber"] = "Phone number is required!";
    }
    if (prefferedMethod?.label === "Email" && email === "") {
      errors["email"] = "Email is required!";
    }
    setValidationErrors(errors);
    if (Object.keys(errors)?.length === 0) {
      await createContactForm();
      const timer = setTimeout(async () => {
        dispatch(refetchChat(true));
      }, 2000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (createContactFormStatus === "success") {
      closeModal(false);
      setShrinkModal(false);
      changeModalState(false);
      setShowSnackbar(true);
    }
  }, [createContactFormStatus]);

  const checkIfAllFieldsEmpty = () => {
    return (
      phoneNumber === "" && firstname === "" && lastname === "" && email === ""
    );
  };

  return (
    <div className="ContactForm_layout">
      <div className="CloseIconArea">
        <div
          className="CloseIcon"
          onClick={() => {
            if (checkIfAllFieldsEmpty()) {
              changeModalState(false);
            } else {
              closeModal(true);
              setShrinkModal(true);
            }
          }}
        >
          <CloseIcon />
        </div>
      </div>
      {createContactFormStatus === "pending" ? (
        <div className="LoadingArea">
          <Loader />
        </div>
      ) : (
        <>
          <h1 className="ContactTitle">Contact Form</h1>
          <p className="ContactDescription">
            Fill out the form and out team will get back to you soon!
          </p>
          <form className="Contact_form">
            <FormElement
              type="textfield"
              label="First name"
              id="first_name"
              name="first name"
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              error={!!validationErrors["firstname"]}
              errorMessage={validationErrors["firstname"]}
            />
            <FormElement
              type="textfield"
              label="Last name"
              id="last_name"
              name="last name"
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              error={!!validationErrors["lastname"]}
              errorMessage={validationErrors["lastname"]}
            />
            <FormElement
              type="textfield"
              label="Email"
              id="email"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="user@domain.com"
              error={!!validationErrors["email"]}
              errorMessage={validationErrors["email"]}
            />
            <FormElement
              type="numfield"
              label="phone number"
              id="phonenumber"
              name="phonenumber"
              value={phoneNumber}
              onChange={(event) => {
                if (event.target.value?.length < 11) {
                  setPhoneNumber(event.target.value);
                }
              }}
              placeholder="(123) 456-7890"
              error={!!validationErrors["phonenumber"]}
              errorMessage={validationErrors["phonenumber"]}
            />
            <FormElement
              type="select"
              label={"preferred method of contact"}
              defaultValue={prefferedMethod}
              values={options}
              onChange={(value) => setPrefferedMethod(value)}
            />
            <ChatFormModalButtonsGroup
              validate={validateForm}
              changeModalState={changeModalState}
              setShrinkModal={setShrinkModal}
              closeModal={closeModal}
              setIsShrinkClose={setIsShrinkClose}
              checkIfAllFieldsEmpty={checkIfAllFieldsEmpty}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default ContactFormModalLayout;
