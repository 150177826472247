import Layout from "../../components/Layout";
import Conversations from "../../components/Conversations";
import DateRangePicker from "../../components/DateRangePicker";
import WidgetSource from "../../components/WidgetSource";
import WidgetSearch from "../../components/WidgetSearch";
import {chooseConversationsQuery} from "../../store/actions/conversationActions";


const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
    <WidgetSearch key="widgetSearch" functionToDispatch={chooseConversationsQuery} />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageConversations = () => {
  return <Layout content={<Conversations />} widgets={widgets} />;
};

export default PageConversations;
