
import QuestionMark from "../Tile/QuestionMark/QuestionMark";
import "./DashboardCarBuying.scss";
import DashboardCards from "./DashboardCards/DashboardCards";
import DashboardCarBuyingSale from "./DashboardSales/DashboardSale";
import DashboardCarBuyingSources from "./DashboardSources/DashboardCarBuyingSources";

const salesDescription = "Shoppers that were handed off to their assigned sales rep. Handoffs Awaiting Sales Response are shoppers that claim they have not yet been contacted by a sales rep and require immediate attention. Handoffs Worked are shoppers that claim they were successfully contacted by a sales rep after a handoff"

const DashboardCarBuying = () => {
  return (
    <div className="Dashboard">
      <div className="DashboardPanel">
      <h2 className="DashboardPanel-title">Operational Efficiency</h2>
        <DashboardCards />
      </div>
      <div className="DashboardPanel DashboardPanel--charts">
        <h2 className="DashboardPanel-title DashboardPanel-definition"><span style={{ paddingRight: "15px" }}>Sales Rep Performance</span> <QuestionMark definition={salesDescription} /></h2>
        <DashboardCarBuyingSale />
      </div>
      <div className="DashboardPanel DashboardPanel--table">
        <h2 className="DashboardPanel-title">Performance by Lead Source</h2>
        <DashboardCarBuyingSources />
      </div>
    </div>
  );
};

export default DashboardCarBuying;