import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ChatsListCard from "../ChatsListCard";
import Loader from "../../Loader";
import Pagination from "../../Pagination";
import Svg from "../../Svg";

import useData from "../../../hook/useData";
import useWindowSize from "../../../hook/useWindowSize";

import {
  chooseConversationsMode,
  chooseConversationsPage,
} from "../../../store/actions/conversationDealerChatActions";

import { formatToDateString } from "../../../utils/formatDate";

import "./ChatsList.scss";

const ChatsList = ({ cid, onToggleListCollapsed, chatListData, status }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSize();
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const {
    data: takeoverData,
    status: takeoverStatus,
    error: takeoverError,
  } = useData(
    "getDealershipTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId, cid]
  );

  const handlePageChange = (pageNumber) => {
    dispatch(chooseConversationsPage(pageNumber));
  };

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    let listItems = [];

    listItems = chatListData.items?.map((item) => {
      return (
        <li key={item.ConversationId}>
          <ChatsListCard
            data={item}
            cid={cid}
            takeover={takeoverData?.Status}
          />
        </li>
      );
    });

    const pagination = chatListData.items?.length > 1 && (
      <div className="ChatsList-pagination">
        <Pagination
          current={chatListData.page_id}
          total={chatListData.num_pages}
          onPageChange={handlePageChange}
          mode="simple"
        />
      </div>
    );

    return (
      <>
        {windowSize.width < 768 && (
          <div className="ChatsList-heading">
            <button
              className="ChatsList-toggler"
              onClick={onToggleListCollapsed}
            >
              <span className="ChatsList-togglerIcon">
                {" "}
                <Svg w="24" h="7" i="arrowRight" />
              </span>
              <span className="ChatsList-togglerLabel">Hide window</span>
            </button>
          </div>
        )}
        <div className="ChatsList-container">
          <ul className="ChatsList-list">{listItems}</ul>
        </div>
        <div className="ChatsList-summary">
          <div className="ChatsList-pagination">{pagination}</div>
        </div>
      </>
    );
  };

  return <div className="ChatsList">{render()}</div>;
};

export default ChatsList;
