import "./FormLinkSubmit.scss";

const FormLinkSubmit = ({ label, link, ...props }) => {
  delete props.handleChange;

  return (
    <div className="FormLinkSubmit">
      <form action={link} className="FormLinkSubmit-form">
        <button type="submit" className="FormLinkSubmit-button" {...props}>
          {label}
        </button>
      </form>
    </div>
);
};

export default FormLinkSubmit;
