import PageDashboardDealerChat from "./pages/PageDashboardDealerChat/PageDashboardDealerChat";
import PageSettings from "./pages/PageSettings/PageSettings";
import PageLogin from "./pages/Login/Login";
import PageLogout from "./pages/PageLogout/PageLogout";
import PageUser from "./pages/PageUser/PageUser";

import settingsDealerChatBlocks from "./components/Settings/settingsDealerChatBlocksStructure";
import PageDealerChatConversations from "./pages/PageDealerChatConversations/PageDealerChatConversations";
import PageDealerChatConversation from "./pages/PageDealerChatConversation/PageDealerChatConversation";
import PageUserManagement from "./pages/PageUserManagement";
import PageUserForm from "./pages/PageUserForm";
import PageStopCommunication from "./pages/PageStopCommunication";



export const GENERAL_LINKS = [
  {
    icon: "chart",
    label: "Go to Dashboard",
    path: "/",
    component: PageDashboardDealerChat,
    exact: true,
  },
  {
    icon: "conversations",
    label: "Go to Conversations",
    path: "/conversations",
    component: PageDealerChatConversations,
    subroutes: [
      {
        label: "Conversation View",
        path: "/conversation/",
      },
      {
        label: "List View",
        path: "/conversations",
      },
    ],
  },
  {
    icon: "settings",
    label: "Go to Settings",
    path: "/settings",
    component: PageSettings,
    subroutes: settingsDealerChatBlocks.map((item) => {
      return {
        label: item.title,
        path: `/settings#${item.title.split(" ").join("")}`,
        resourceName: item.resourceName,
        index: item.index,
      };
    }),
  },
  {
    icon: "user",
    label: "Go to User Settings",
    path: "/user",
    component: PageUser,
    subroutes: [
      {
        label: "Manage Users",
        path: "/user-management",
      },
      {
        label: "Edit Account",
        path: "/user",
      },
      {
        label: "Log Out",
        path: "/logout",
      },
    ],
  },
];

export const SECONDARY_LINKS = [
  {
    label: "Stop Communication Page",
    path: "/conversation/:cid/stop-communication",
    component: PageStopCommunication,
  },
  {
    label: "Go to Single Conversation",
    path: "/conversation/:cid",
    component: PageDealerChatConversation,
  },
  {
    label: "Go to Single Conversations List",
    path: "/conversation/",
    component: PageDealerChatConversation,
    exact: true,
  },
  {
    label: "User Management Page",
    path: "/user-management/:action",
    component: PageUserForm,
  },
  {
    label: "User Management Page",
    path: "/user-management/",
    component: PageUserManagement,
    exact: true,
  },
  {
    label: "Stop Communication Page",
    path: "/stop-communication/",
    component: PageStopCommunication,
    exact: true
  },
  {
    label: "Log Out",
    path: "/logout",
    component: PageLogout,
  },
];

export const COMMON_LINKS = [
  {
    label: "Log In",
    path: "/login",
    component: PageLogin,
  },
];
