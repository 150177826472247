import SettingAccessSwitcher from "../SettingsAccessSwitcher";
import collapseImg from '../../../assets/img/settings/collapse.svg'
import expandImg from '../../../assets/img/settings/expand.svg'

export default [
    {
        id: "SourceId",
        label: "Source",
        className: "TableCell-formatText",
        width: "30%",
        sortable: true,
        formatter: (row, events) => {
            const handleStatusChange = () => {
                events["handleStatus"](row)

            };

            const handleExpandButtonClick = () => {
                events["handleExpand"](row)
            }

            const handleConditionalLogic = () => {
                if (row.SubSourceNum !== null) {
                    console.log('This is a main source')
                    // return events["handleStatusChange"](row)
                    events["handleStatus"](row)
                } else{
                    console.log('This is a sub source in tableColumnsAccess!!')
                    console.log("events in tableColumnsAccess are: ", events)
                    events["handleSubSourceUpdate"](row)
                }
            }

            const isSubSource = row.SubSourceNum === null
            const isMainSourceOff = row.MainSourceStatus === 0
            const isDashSource = row.SourceName === '-'
            const isDisabled = (isDashSource || (isSubSource && isMainSourceOff))

            return (
                <div className="SettingsAccess-cell--source">
                    <SettingAccessSwitcher
                        main_source={row.SubSourceNum !== null}
                        status={row.Status}
                        handleEvent={ () => handleConditionalLogic(row)}
                        isDisabled={isDisabled}
                    />
                    <div className="SettingsAccess-cell--source-label">
                        <span>{row.SourceName}</span>
                        {
                            row.SubSourceNum ? (<div className="SettingsAccess-cell--sub-source" onClick={handleExpandButtonClick}>
                                Sub-Sources
                                {
                                    row.SubSourceNum ?
                                        <img className="SettingsAccess-cell--expand_button"
                                             src={!row?.expanded ? expandImg : collapseImg}
                                             alt={""}
                                             onClick={handleExpandButtonClick}/> : null}
                                </div>) : null
                        }
                    </div>
                </div>
            );
        },
    },
    {
        id: "NewLeads",
        label: "Incoming leads",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "CloseRate",
        label: "Close Rate",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "LeadsThatWorked",
        label: "Leads That Worked",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "CustomersEngaged",
        label: "Customers Engaged",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "EngagementRate",
        label: "Engagement Rate (%)",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "AIAppointments",
        label: "Appointments Set",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "SalesExpertiseNeeded",
        label: "Sales Expertise Needed",
        className: "TableCell-formatNumber",
        sortable: true,
    },
    {
        id: "status",
        label: "Status",
        hiddenColumn: true,
    },
];
