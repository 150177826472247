export default function sortObjects(items, key, direction) {
  if (!key) return items;

  const sortedObjects = items.sort((_a, _b) => {
    const a = _a[key];
    const b = _b[key];

    if (direction === "desc") {
      if (typeof a === "string") {
        return a.localeCompare(b);
      } else {
        return a - b;
      }
    } else {
      if (typeof a === "string") {
        return b.localeCompare(a);
      } else {
        return b - a;
      }
    }
  });

  return sortedObjects;
}
