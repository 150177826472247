import cn from "classnames";

import Svg from "../../Svg";

import "./FormTextarea.scss";

const FormTextarea = ({
  label,
  labelHidden = false,
  icon = false,
  errorMessage = "",
  outlined = false,
  iconComponent = false,
  ...rest
}) => {
  return (
    <div
      className={cn("FormTextfield", {
        "FormElement--iconed": icon,
      })}
    >
      <label
        className={cn("FormTextfield-label", {
          "FormTextfield-label--hidden": labelHidden,
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <div className="FormTextfield-field">
        <textarea
          className={
            outlined ? "FormTextfield-input-outlined" : "FormTextfield-input"
          }
          {...rest}
        />
        {errorMessage && (
          <span className="FormTextfield-error">{errorMessage}</span>
        )}
        {icon && (
          <span className="FormTextfield-icon">
            <Svg w="18" h="18" i={icon} />
          </span>
        )}
        {iconComponent && (
          <span className="FormTextfield-icon-component">{iconComponent}</span>
        )}
      </div>
    </div>
  );
};

export default FormTextarea;
