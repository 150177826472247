import {
  FETCH_SUBSCRIPTIONS,
  FETCH_PRINCIPALS,
} from "../actionTypes/settingTypes";

export const saveSubscriptions = (data) => {
  return {
    type: FETCH_SUBSCRIPTIONS,
    payload: data,
  };
};

export const savePrincipals = (data) => {
  return {
    type: FETCH_PRINCIPALS,
    payload: data,
  };
};
