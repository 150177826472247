export default [
  {
    id: "source_name",
    label: "Campaign Name",
    width: "27.8%",
    className: "TableCell-formatText",
    sortable: true,
  },
  {
    id: "closed_ros",
    label: "Closed ROS",
    className: "TableCell-formatNumber",
    sortable: true,
  },
  {
    id: "new_leads",
    label: "Customers Messaged",
    className: "TableCell-formatNumber",
    sortable: true,
  },
  {
    id: "engagement_rate",
    label: "Engagement rate (%)",
    className: "TableCell-formatNumber",
    sortable: true,
  },
  {
    id: "acquired_phone_numbers",
    label: "Acquired Phone Numbers",
    className: "TableCell-formatNumber",
    sortable: true,
  },
  {
    id: "total_messages_out",
    label: "Messages Sent",
    className: "TableCell-formatNumber",
    sortable: true,
  },
  {
    id: "messages_sent_to_engage_customer",
    label: "Messages Sent to Engage Customers",
    className: "TableCell-formatNumber",
    sortable: true,
  },
];
