import "./ChatAppointmentForm.scss";
import FormElement from "../../FormElement/FormElement";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useData from "../../../hook/useData";
import Loader from "../../Loader/Loader";
import {
  isAppointmentFormOpen,
  isChatFormOpen,
  refetchChat,
} from "../../../store/actions/conversationDealerChatActions";
import {
  validateEmail,
  validatePhonenumber,
} from "../../../utils/fieldValidators";
import DateAndTimePicker from "../../../ui/common/DateAndTimePicker/DateAndTimePicker";

const options = [
  { id: 0, label: "Text" },
  { id: 1, label: "Call" },
  { id: 2, label: "Email" },
];

const ChatAppointmentForm = ({ setShowSnackbar }) => {
  const cid = useSelector((state) => state.conversation.conversationsId);

  const {
    data: generalInfoDataFetched,
    status: generalInfoStatus,
    execute: fetchGeneralInfoData,
  } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid],
    true
  );

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState();

  const formatJsDateToString = (jsDate) => {
    const dateValue = new Date(jsDate);
    const chosenDate = `${dateValue.getDate().toString().padStart(2, "0")}/${(
      dateValue.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${dateValue.getFullYear()}`;
    const chosenTime = `${dateValue
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${dateValue
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;
    setDate(chosenDate);
    setTime(chosenTime);
  };

  useEffect(() => {
    const splittedFullname = generalInfoDataFetched?.FullName?.split(" ");
    const splittedFirstname =
      splittedFullname && splittedFullname[0] ? splittedFullname[0] : "";
    const splittedLastname =
      splittedFullname && splittedFullname[1] ? splittedFullname[1] : "";
    if (generalInfoDataFetched) {
      setFirstname(splittedFirstname);
      setLastname(splittedLastname);
      setEmail(
        generalInfoDataFetched?.Email ? generalInfoDataFetched.Email : ""
      );
      setPhoneNumber(
        generalInfoDataFetched?.Phone ? generalInfoDataFetched.Phone : ""
      );
    }
  }, [generalInfoDataFetched]);

  const [validationErrors, setValidationErrors] = useState({});
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const dispatch = useDispatch();

  const { cid: urlCid } = useParams();

  const {
    status: createContactFormStatus,
    execute: createContactForm,
    data: createContactFormData,
  } = useData(
    "createContactForm",
    {
      account_id: accountId,
      user_id: urlCid,
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone_number: phoneNumber,
      form_type: "AppointmentForm",
      date: date,
      time: time,
    },
    [accountId, urlCid, firstname, lastname, email, phoneNumber, date, time],
    false
  );

  const validateForm = async () => {
    const errors = {};
    if (firstname === "" && lastname === "") {
      errors["firstname"] = "Firstname or lastname is required!";
      errors["lastname"] = "Firstname or lastname is required!";
    }
    if (email === "" && phoneNumber === "") {
      errors["email"] = "Email or phone number is required!";
      errors["phonenumber"] = "Email or phone number is required!";
    }
    if (email !== "" && !validateEmail(email)) {
      errors["email"] = "Incorrect email";
    }
    if (phoneNumber !== "" && !validatePhonenumber(phoneNumber)) {
      errors["phonenumber"] = "Phone number should be at least 10 digits!";
    }
    if (!date || !time) {
      errors["date"] = "Date and time is required!";
    }
    setValidationErrors(errors);
    if (Object.keys(errors)?.length === 0) {
      await createContactForm();
      const timer = setTimeout(async () => {
        dispatch(refetchChat(true));
      }, 2000);
      return () => clearTimeout(timer);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateForm();
  };

  useEffect(() => {
    if (createContactFormStatus === "success") {
      setShowSnackbar(true);
      dispatch(isAppointmentFormOpen(false));
    }
  }, [createContactFormStatus]);

  const checkIfAllFieldsEmpty = () => {
    return (
      phoneNumber === "" && firstname === "" && lastname === "" && email === ""
    );
  };

  return (
    <div className="Contact_layout">
      {createContactFormStatus === "pending" ||
      generalInfoStatus === "pending" ? (
        <div className="LoadingArea">
          <Loader />
        </div>
      ) : (
        <>
          <h1 className="ContactTitle">Schedule Appointment Form</h1>
          <p className="ContactDescription">
            Submit customer's information below to set an appointment
          </p>
          <form className="Contact_form">
            <FormElement
              type="textfield"
              label="First name"
              id="first_name"
              name="first name"
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              error={!!validationErrors["firstname"]}
              errorMessage={validationErrors["firstname"]}
            />
            <FormElement
              type="textfield"
              label="Last name"
              id="last_name"
              name="last name"
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              error={!!validationErrors["lastname"]}
              errorMessage={validationErrors["lastname"]}
            />
            <FormElement
              type="textfield"
              label="Email"
              id="email"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="user@domain.com"
              error={!!validationErrors["email"]}
              errorMessage={validationErrors["email"]}
            />
            <FormElement
              type="numfield"
              label="phone number"
              id="phonenumber"
              name="phonenumber"
              value={phoneNumber}
              onChange={(event) => {
                if (event.target.value?.length <= 11) {
                  setPhoneNumber(event.target.value);
                }
              }}
              placeholder="(123) 456-7890"
              error={!!validationErrors["phonenumber"]}
              errorMessage={validationErrors["phonenumber"]}
            />
            <DateAndTimePicker
              onChange={(name, value) => {
                formatJsDateToString(value);
              }}
              input={{
                label: "appointment.date_time",
                name: "date_time",
                type: "timestamp",
                required: true,
              }}
              error={!!validationErrors["date"]}
            />
            <FormElement
              type="submit"
              style={{
                borderRadius: "7px",
                backgroundColor: "white",
                border: "1px solid #D62B56",
                color: "#D62B56",
                fontSize: "10px",
                height: "40px",
              }}
              label="Submit"
              id="submitButton"
              name="submitButton"
              value="Submit message"
              disabled={false}
              onClick={(event) => handleSubmit(event)}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default ChatAppointmentForm;
