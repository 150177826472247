import cn from "classnames";

import "./PaginationPage.scss";

const PaginationPage = ({
  number,
  disabled = false,
  className,
  onPageChange,
  children,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(number);
  };

  return (
    <a
      className={cn("PaginationPage", className, { "is-disabled": disabled })}
      href={disabled ? null : `#page-${number}`}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export default PaginationPage;
