import settingsBlockStructure from "./settingsBlockStructure";
import SettingsBlock from "./SettingsBlock";
import "./index.scss";

const SettingsSelector = ({ form, setForm }) => {
  return (
    <div className="SettingsSelector-Container">
      <div className="SettingsSelector-Settings-Container">
        <span className="SettingsSelector-BlockTitle">Settings</span>
        <div className="SettingsSelector">
          {settingsBlockStructure.map((block) => (
            <SettingsBlock
              key={block.resourceType}
              block={block}
              form={form}
              setForm={setForm}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsSelector;
