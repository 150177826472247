import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import Pagination from "../Pagination";

import useData from "../../hook/useData";

import {
    chooseConversationsMode,
    chooseConversationsPage,
    chooseConversationsSort,
} from "../../store/actions/conversationActions";

import tableColumnsConversations from "./tableColumnsConversations";
import serviceTableColumnsConversations from "./serviceTableColumnsConversations";

import {formatToDateString} from "../../utils/formatDate";

import "./Conversations.scss";
import transformConversationsFieldToDB from "../../utils/transformConversationsFieldToDB";

const Conversations = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        conversationsPage,
        conversationsEndpoint,
        conversationsQuery,
        conversationsSource,
        conversationLeadType,
        conversationsMetric,
        conversationsSubMetric,
        conversationsPersonId,
        conversationsSortColumn,
        conversationsSortDirection
    } = useSelector((state) => {
        return state.conversation;
    });

    const accountId = useSelector(
        (state) => state.user.auth?.data?.user_info?.DealerId
    );

    const category = useSelector((state) => {
        return state.user.auth.selectedProduct.Category
    });

    const dateRange = useSelector((state) => state.date);

    let endpoint = conversationsEndpoint;

    let options = {
        start_date: formatToDateString(dateRange.startDate),
        end_date: formatToDateString(dateRange.endDate),
        page_id: conversationsPage,
        lead_type: conversationLeadType || "",
        sub_metric: conversationsSubMetric || "",
        source_name: conversationsSource,
        salesperson_id: conversationsPersonId,
        sort_column: transformConversationsFieldToDB(conversationsSortColumn),
        sort_direction: conversationsSortDirection
    };

    if (conversationsQuery.length) {
        endpoint = "conversationsSearch";
        options = {...options, search_pattern: conversationsQuery};
    } else if (endpoint === "newBySourceLeads") {
        options = {...options, source_name: conversationsSource};
    }

    const {data, status, error} = useData(endpoint, options, [
        conversationsPage,
        conversationsEndpoint,
        conversationsQuery,
        conversationsSource,
        dateRange,
        conversationsMetric,
        conversationsSubMetric,
        conversationsSortColumn,
        conversationsSortDirection,
        accountId
    ]);

    useEffect(() => {
        if (data && data.num_pages < conversationsPage) {
            dispatch(chooseConversationsPage(1));
        }
    }, [data, conversationsPage, dispatch, accountId]);

    const handleOpenChat = (cid) => {
        dispatch(chooseConversationsMode("regular"));
        cid && history.push(`conversation/${cid}`);
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= data.num_pages) {
            dispatch(chooseConversationsPage(pageNumber));
        }
    };

    const sortFunction = (items, key, direction) => {
        if (key && direction && (key !== conversationsSortColumn || direction !== conversationsSortDirection)) {
            dispatch(chooseConversationsSort(key, direction));
        }
        return items;
    }

    const render = () => {
        if (status === "error") {
            return error || "Error";
        }

        if (status === "init" || status === "pending") {
            return <Loader/>;
        }

        return (
            <>
                <Table
                    sortArray={true}
                    columns={category === "SERVICE_AI" ? serviceTableColumnsConversations : tableColumnsConversations}
                    rows={data && data.items}
                    options={{fillParent: true, baseColumnWidth: 160, sortBy: conversationsSortColumn, sortDirection: conversationsSortDirection}}
                    events={{
                        rows: [
                            {
                                eventName: "onClick",
                                eventHandler: handleOpenChat,
                                id: "ConversationId",
                            },
                        ],
                    }}
                    sortFunction={sortFunction}
                />
                <div className="Conversations-summary">
                    <div className="Conversations-info">
                        <span className="Conversations-infoLabel">Showing page </span>
                        {data.page_id} / {data.num_pages}
                    </div>
                    <div className="Conversations-pagination">
                        <Pagination
                            current={data.page_id}
                            total={data.num_pages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </>
        );
    };

    return <div className="Conversations">{render()}</div>;
};

export default Conversations;
