export default function reducer(state, action) {
  switch (action.type) {
    case "changeInput": {
      const { value, field } = action.payload;

      return { ...state, [field]: value };
    }

    case "toggleNotification": {
      const { checked, notification, channel, notificationPrincipals } = action.payload;

      const result = [...state.Channels];

      let stateChannel = result.find(
        (stateChannel) => stateChannel.Channel === channel
      );

      if (!stateChannel) {
        stateChannel = { Channel: channel, SubscribedNotifications: [] };

        result.push(stateChannel);
      }

      const stateNotifications = stateChannel.SubscribedNotifications;

      if (checked) {
        const principalsWithStatus = notificationPrincipals.map(principal => {
          return {
            ...principal,
            "Status": false
          }
        })
        stateNotifications.push({ Id: notification, Principals: principalsWithStatus });
      } else {
        const stateNotificationIdx = stateNotifications.findIndex(
          (stateNotification) => stateNotification.Id === notification
        );

        if (stateNotificationIdx !== -1) {
          stateNotifications.splice(stateNotificationIdx, 1);
        }
      }

      return { ...state, Channels: result };
    }

    case "togglePrincipal": {
      const { checked, channel, notification, principal } = action.payload;

      const result = [...state.Channels];

      const stateChannel = result.find(
        (stateChannel) => stateChannel.Channel === channel
      );

      const stateNotifications = stateChannel.SubscribedNotifications;

      const stateNotification = stateNotifications.find(
        (stateNotification) => stateNotification.Id === notification
      );

      let statePrincipals = stateNotification.Principals;
      let prevValue = statePrincipals.filter((statePrincipal) => statePrincipal.Id === principal)
      if (prevValue.length > 0) {
        prevValue = prevValue[0]
        statePrincipals = statePrincipals.filter(statePrincipal => statePrincipal.Id !== principal)

        if (checked) {
          let updatedValue = {
            ...prevValue,
            "Status": true
          }
          statePrincipals.push(updatedValue)
        } else {
          let updatedValue = {
            ...prevValue,
            "Status": false
          }
          statePrincipals.push(updatedValue)
        }
      }
      for (let i = 0; i < result.length; i++) {
        if (result[i].Channel === channel) {
          let subscribedNotifications = result[i].SubscribedNotifications
          for (let notificationIndex = 0; notificationIndex < subscribedNotifications.length; notificationIndex++) {
            if (result[i].SubscribedNotifications[notificationIndex].Id === notification) {
              result[i].SubscribedNotifications[notificationIndex].Principals = statePrincipals
            }
          }
        }
      }
      return { ...state, Channels: result };
    }

    default:
      console.error(`### Action with type "${action.type}" didn't found`);
      return state;
  }
}
