import AIAssisted from "./AIAssisted/AIAssisted";
import TotalLeads from "./TotalLeads/TotalLeads";

const DashboardCard = (props) => {
  const { type } = props;

  const render = () => {
    if (type === "square" && props?.renderType !== 'ai_assisted_sales') {
      return <TotalLeads {...props}/>;
    }
    if (type === "twin" && props?.renderType !== 'ai_assisted_sales') {
      return <TotalLeads {...props}/>;
    }
    if (props?.renderType === 'ai_assisted_sales') {
      return <AIAssisted {...props}/>;
    }
  };

  return render();
};

export default DashboardCard;
