export default {
  admin: {
    id: "admin",
    label: "ADMIN",
  },
  regular: {
    id: "regular",
    label: "REGULAR",
  },
};
