import cn from "classnames";
import "./index.scss";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const SelectableCard = ({
  value,
  onSelect,
  active,
  disabled,
  count,
  started,
  createDate,
  launchedItems,
  hasStoppedButton,
  stopCampaign,
  status,
  totalItems,
  description,
}) => {
  return (
    <div
      className={cn("SelectableCard-Container", {
        "SelectableCard-Container--active": active,
        "SelectableCard-Container--disabled": disabled,
      })}
      onClick={!disabled ? onSelect : () => {}}
    >
      <p className="SelectableCard-Container-p">
        <span className="SelectableCard-Container-p-title">
          {started === true ? (
            <FiberManualRecordIcon
              fontSize="small"
              style={{
                fill: "#2fff2f",
                margin: "0 .5vw 0 0",
              }}
            />
          ) : null}
          {value}
        </span>
        {/* {launchedItems ? (
          <span className="SelectableCard-count">{launchedItems}</span>
        ) : null} */}
        {description && (
          <div className="SelectableCard-meta"> {description} </div>
        )}
        {createDate && (
          <div className="SelectableCard-meta">create date - {createDate}</div>
        )}
        {launchedItems !== undefined && launchedItems !== null && (
          <div className="SelectableCard-meta">
            launched items - {launchedItems} out of {totalItems}
          </div>
        )}
        {status && <div className="SelectableCard-meta">status - {status}</div>}
      </p>
      {active && <p className="SelectableCard-check">&#10003;</p>}
      {hasStoppedButton && (
        <span className="SelectableCard-stop" onClick={stopCampaign}>
          stop initiative
        </span>
      )}
    </div>
  );
};

export default SelectableCard;
