import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ChatsList from "./ChatsList";
import ChatsListCollapsed from "./ChatsListCollapsed";
import ChatsDialog from "./ChatsDialog";
import ChatsDetails from "./ChatsDetails";
import ChatsDetailsCollapsed from "./ChatsDetailsCollapsed";

import useData from "../../hook/useData";
import useWindowSize from "../../hook/useWindowSize";

import {
  chooseConversationsId,
  chooseConversationsMode,
} from "../../store/actions/conversationDealerChatActions";

import "./Chats.scss";
import { formatToDateString } from "../../utils/formatDate";
import { useAuth0 } from "@auth0/auth0-react";
import {
  isChatFormOpen,
  refetchChat,
} from "../../store/actions/conversationDealerChatActions";

const DealerChatConversation = () => {
  const { user } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const [detailsCollapsed, setDetailsCollapsed] = useState();
  const refetch = useSelector((state) => state.conversationDealerChat.refetch);
  // const [chatListData, setChatListData] = useState([]); // it will be Array of objects {cid: cid, updatedStatus: null}
  const [chatsCollapsed, setListCollapsed] = useState(true);
  const [chatListDataSt, setChatListDataSt] = useState({
    items: [],
    page_id: 1,
    num_pages: 20,
  });
  const { cid: urlCid } = useParams();
  const savedCid = useSelector(
    (state) => state.conversationDealerChat.conversationsId
  );

  const cid = urlCid || savedCid || null;

  if (urlCid && urlCid !== savedCid) dispatch(chooseConversationsId(urlCid));

  if (cid && !urlCid) history.push(cid);

  useEffect(() => {
    setDetailsCollapsed(windowSize.width < 1280 ? true : false);
  }, [windowSize]);

  const dateRange = useSelector((state) => state.date);

  const {
    conversationsPage,
    conversationsEndpoint,
    conversationsSource,
    conversationsMode,
    conversationsQuery,
    conversationsSubMetric,
    conversationsPersonId,
  } = useSelector((state) => state.conversationDealerChat);

  let endDate = formatToDateString(dateRange.endDate);
  // Create a Date object from the date string
  let date = new Date(endDate);
  // Add one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  // Format the Date object back to the desired string format
  let newDateString = date.toISOString().slice(0, 19);
  console.log("newDateString: ", newDateString);

  let endpoint = conversationsEndpoint;
  let options = {
    start_date: formatToDateString(dateRange.startDate),
    end_date: newDateString,
    page_id: conversationsPage,
    sub_metric: conversationsSubMetric,
    source_name: conversationsSource,
    salesperson_id: conversationsPersonId,
  };

  if (conversationsMode === "simple" && cid) {
    endpoint = "getGeneralLeadInfo";
    options = { cid };
  } else if (endpoint === "newBySourceLeads") {
    options = { ...options, source_name: conversationsSource };
  }

  const {
    data: chatListData,
    status: chatListStatus,
    error: chatListError,
    execute: fetchChatListData,
  } = useData(
    endpoint,
    options,
    [conversationsPage, dateRange, conversationsEndpoint],
    true
  );

  useEffect(async () => {
    if (refetch) {
      await fetchChatListData();
      dispatch(refetchChat(false));
      // console.log("chatListData: ", chatListData);
    }
  }, [refetch]);

  useEffect(() => {
    if (chatListData && !chatListData.items) {
      setChatListDataSt({ items: [chatListData], page_id: 1, num_pages: 10 });
    }
    if (chatListData && chatListData.items && chatListData.items.length) {
      setChatListDataSt(chatListData);
      if (!cid) {
        dispatch(chooseConversationsMode("regular"));
        const cid = chatListData.items[0].ConversationId;

        history.push(cid);
      }
    }
  }, [chatListData, dispatch]);

  const {
    data,
    status,
    error,
    execute: fetchDialogue,
  } = useData(
    "dialogueDealerChat",
    {
      conversation_id: cid,
    },
    [cid]
  );

  const { data: leadNotifications, execute: fetchNotifications } = useData(
    "getNotificationsInvoked",
    {
      conversation_id: cid,
    },
    [cid]
  );

  const { data: leadEvents, execute: fetchEvents } = useData(
    "getEvents",
    {
      conversation_id: cid,
    },
    [cid]
  );

  const { data: stopBotEvents, execute: fetchStopBotEvents } = useData(
    "getStopBotEvents",
    {
      conversation_id: cid,
    },
    [cid]
  );

  const handleToggleListCollapsed = () => {
    if (windowSize.width < 768) {
      setDetailsCollapsed(true);
    }

    setListCollapsed((state) => {
      return !state;
    });
  };

  const handleToggleDetailsCollapsed = () => {
    if (windowSize.width < 768) {
      setListCollapsed(true);
    }

    setDetailsCollapsed((state) => {
      return !state;
    });
  };

  const refetchConversation = () => {
    fetchDialogue();
    fetchNotifications();
    fetchEvents();
    fetchStopBotEvents();
  };

  const handleTakeoverStatusUpdate = (updatedStatus, convoId) => {
    setChatListDataSt((prevState) => {
      return {
        items: prevState.items.map((item) =>
          item.cid === convoId ||
          item.lead_id === convoId ||
          item.ConversationId === convoId
            ? {
                ...item,
                TakeoverEventType: `EVENT#DEALERSHIP_TAKEOVER_${updatedStatus}`,
                SalesPerson: `${user.name} ${user.family_name || user.name}`,
              }
            : item
        ),
        page_id: prevState.page_id,
        num_pages: prevState.num_pages,
      };
    });
  };

  return (
    <div className="Chats">
      {
        <ChatsDialog
          data={data}
          status={status}
          error={error}
          leadNotifications={leadNotifications}
          leadEvents={leadEvents?.Items}
          stopBotEvents={stopBotEvents}
          refetchConversation={refetchConversation}
          cid={cid}
          handleTakeoverStatusUpdate={handleTakeoverStatusUpdate}
        />
      }

      {windowSize.width >= 768 || !chatsCollapsed ? (
        <ChatsList
          cid={cid}
          onToggleListCollapsed={handleToggleListCollapsed}
          chatListData={chatListDataSt}
          status={null}
        />
      ) : (
        <ChatsListCollapsed onToggleListCollapsed={handleToggleListCollapsed} />
      )}

      {cid &&
        ((windowSize.width >= 1280 || !detailsCollapsed) &&
        !detailsCollapsed ? (
          <ChatsDetails
            cid={cid}
            onToggleDetailsCollapsed={handleToggleDetailsCollapsed}
          />
        ) : (
          <ChatsDetailsCollapsed
            cid={cid}
            onToggleDetailsCollapsed={handleToggleDetailsCollapsed}
          />
        ))}
    </div>
  );
};

export default DealerChatConversation;
