const ExpandIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="open_in_full_FILL0_wght400_GRAD0_opsz24 1">
      <path
        id="Vector"
        d="M3.33333 17.5C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667V11.6667C2.5 11.4306 2.57986 11.2326 2.73958 11.0729C2.89931 10.9132 3.09722 10.8333 3.33333 10.8333C3.56944 10.8333 3.76736 10.9132 3.92708 11.0729C4.08681 11.2326 4.16667 11.4306 4.16667 11.6667V14.6667L14.6667 4.16667H11.6667C11.4306 4.16667 11.2326 4.08681 11.0729 3.92708C10.9132 3.76736 10.8333 3.56944 10.8333 3.33333C10.8333 3.09722 10.9132 2.89931 11.0729 2.73958C11.2326 2.57986 11.4306 2.5 11.6667 2.5H16.6667C16.9028 2.5 17.1007 2.57986 17.2604 2.73958C17.4201 2.89931 17.5 3.09722 17.5 3.33333V8.33333C17.5 8.56944 17.4201 8.76736 17.2604 8.92708C17.1007 9.08681 16.9028 9.16667 16.6667 9.16667C16.4306 9.16667 16.2326 9.08681 16.0729 8.92708C15.9132 8.76736 15.8333 8.56944 15.8333 8.33333V5.33333L5.33333 15.8333H8.33333C8.56944 15.8333 8.76736 15.9132 8.92708 16.0729C9.08681 16.2326 9.16667 16.4306 9.16667 16.6667C9.16667 16.9028 9.08681 17.1007 8.92708 17.2604C8.76736 17.4201 8.56944 17.5 8.33333 17.5H3.33333Z"
        fill="white"
      />
    </g>
  </svg>
);

export default ExpandIcon;
