import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import ChatsDialogEmpty from "../ChatsDialogEmpty";
import ChatsDialogMessage from "../ChatsDialogMessage";
import ChatsReply from "../ChatsReply";
import FormElement from "../../../components/FormElement";
// import ChatsDialogNotification from "../ChatsDialogNotification";
import { chooseUserManagementSnackbarState } from "../../../store/actions/userManagementActions";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import { useDispatch } from "react-redux";
import Loader from "../../Loader";
import "./ChatsDialog.scss";
import cn from "classnames";
import useData from "../../../hook/useData";
import ChatsDialogMessageCard from "../ChatsDialogMessage/ChatsDialogMessageCard";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { has24HoursPassed } from "../../../utils/formatDate";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import Confirmation from "../../Confirmation";
import { Snackbar } from "@mui/material";
import useNavigationBlocker from "../../../hook/useNavigationBlocker";
import {
  isAppointmentFormOpen,
  isChatFormOpen,
  refetchChat,
} from "../../../store/actions/conversationDealerChatActions";

const CHANNELS = [
  {
    Id: "*",
    Name: "Chat",
  },
];

const Channel = ({ name, selected, onSelect }) => {
  return (
    <div
      className={cn("ChannelSelector_channel", `ChannelSelector_${name}`, {
        selected,
      })}
      onClick={onSelect}
    >
      {name}
    </div>
  );
};

const ChatsDialog = ({
  data,
  status,
  leadNotifications = [],
  leadEvents = [],
  stopBotEvents = [],
  refetchConversation,
  cid,
  handleTakeoverStatusUpdate,
}) => {
  const dispatch = useDispatch();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const disconnectTimerRef = useRef(null);
  const { isBlocked, confirmNavigation, cancelNavigation } =
    useNavigationBlocker(shouldBlockNavigation);
  const [conversation, setConversation] = useState(data);
  const refDialog = useRef(null);
  const bottomRef = useRef(null);
  const [socketUrl, setSocketUrl] = useState(null);
  const { user } = useAuth0();
  const token = useSelector((state) => state.user.auth?.data?.access_token);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const refetch = useSelector((state) => state.conversationDealerChat.refetch);
  const [selectedChannel, setSelectedChannel] = useState(CHANNELS[0]);
  const [unsubscribed, setUnsubscribed] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [state, setState] = useState({
    status: null,
    sales_person: {
      first_name: user.name,
      last_name: user.family_name || user.name,
      email: user.email,
    },
    user_id: cid,
  });
  const [modalOpen, setModalOpen] = useState(isBlocked);

  useEffect(() => {
    if (isBlocked) {
      setModalOpen(true);
    }
  }, [isBlocked]);

  useEffect(() => {
    if (data) {
      setConversation(data);
      setLoading(false);
    }
  }, [data, cid]);

  console.log("conversation is: ", conversation);
  // let updatedConversationStatus = null;

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onOpen: () => handleOnOpen(),
      onClose: () => handleOnClose(),
      onError: (error) => handleOnError(error),
      onMessage: (message) => {
        // Handle incoming messages
        handleOnMessage(message);
        // setMessageHistory((prevMessages) => [...prevMessages, message]);
      },
      shouldReconnect: (event, attempts) => {
        return false; // Automatically reconnect on disconnection
      },
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const {
    data: generalInfoData,
    status: generalInfoStatus,
    execute: fetchGeneralInfoData,
  } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  const {
    data: chatEventsData,
    status: chatEventsStatus,
    execute: chatEventsExecute,
  } = useData(
    "getChatEventsData",
    {
      conversation_id: cid,
    },
    [cid]
  );

  useEffect(async () => {
    if (refetch) {
      await chatEventsExecute();
      // dispatch(refetchChat(false));
    }
  }, [refetch]);

  console.log("chatEventsData: ", chatEventsData);

  useEffect(() => {
    const isActiveConversation =
      generalInfoData?.TakeoverEventType === "IN_PROGRESS" &&
      generalInfoData?.SalesPersonEmail === user.email &&
      !has24HoursPassed(generalInfoData?.LastHumanTimestamp);
    const handleBeforeUnload = (e) => {
      if (isActiveConversation) {
        e.preventDefault();
        e.returnValue = ""; // Standard way to show the confirmation dialog
        return "";
      }
    };
    // setConversationStatus(generalInfoData?.TakeoverEventType);
    if (isActiveConversation) {
      setShouldBlockNavigation(true);
      let url = process.env["REACT_APP_HOST_SOCKET_URL"] || "";
      setSocketUrl(
        //`ws://localhost:7001/wss?domain=dashboard&access_token=${token}=&cid=${cid}&agent=${user.name}` // TO DO!!! TO CHANGE TO THE REAL SOCKET URL
        `${url}?domain=dashboard&access_token=${token}=&cid=${cid}&agent=${user.name}`
        // TO DO!!! TO CHANGE TO THE REAL SOCKET URLwss://webchat-api-test.pulsar.ai/wss?domain=dashboard&access_token=${token}=&cid=${cid}&agent=${user.name}` // TO DO!!! TO CHANGE TO THE REAL SOCKET URL
      );
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      setSocketUrl(null);
    }
  }, [generalInfoData]);

  useEffect(() => {
    if (
      generalInfoData?.TakeoverEventType === "IN_PROGRESS" &&
      generalInfoData?.SalesPersonEmail === user.email &&
      !has24HoursPassed(generalInfoData?.LastHumanTimestamp)
    ) {
      if (
        readyState !== ReadyState.OPEN &&
        readyState !== ReadyState.CONNECTING
      ) {
        setAlertState((prevState) => {
          return {
            severity: "error",
            message:
              "Connection lost. Check your internet connection and try again",
            open: true,
          };
        });
        dispatch(isAppointmentFormOpen(false));
        dispatch(isChatFormOpen(false));
      } else {
        setAlertState((prevState) => {
          return {
            severity: "success",
            message: "",
            open: false,
          };
        });
      }
    }
    // else {
    //   setAlertState((prevState) => {
    //     return {
    //       severity: "success",
    //       message: "",
    //       open: false,
    //     };
    //   });
    // }
  }, [generalInfoData, readyState, cid]);

  const { data: unsubscribedResponse, status: unsubscribedStatus } = useData(
    "isUnsubscribed",
    { conversationId: cid, selectedChannel: selectedChannel.Id },
    [cid, selectedChannel]
  );

  const {
    data: takeoverData,
    status: takeoverStatus,
    error: takeoverError,
    execute: fetchTakeoverData,
  } = useData(
    "getDealershipTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId]
  );

  console.log("CHAT DIALOG DATA IS: ", data);

  const {
    data: statusData,
    status: updatedStatus,
    execute: updateConversationStatus,
    error: updateConversationStatusError,
  } = useData("updateDealershipTakeoverState", state, [state], false);

  useEffect(() => {
    const dialog = refDialog.current;
    if (!dialog || takeoverData?.Status === true) return null;

    dialog.scrollTop = dialog.scrollHeight - dialog.offsetHeight;
    setSelectedChannel(CHANNELS[0]);
  }, [conversation]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "auto" });
      refDialog.current.scrollIntoView({ behavior: "auto" });
    }
  }, [conversation]);

  useEffect(async () => {
    if (state.status) {
      if (isBlocked) {
        setLoading(true);
        await updateConversationStatus();
        if (!updateConversationStatusError) {
          await fetchGeneralInfoData();
          await handleTakeoverStatusUpdate(state.status, cid);
        }
        setLoading(false);
        confirmNavigation();
      } else {
        setLoading(true);
        await updateConversationStatus();
        if (!updateConversationStatusError) {
          await fetchGeneralInfoData();
          handleTakeoverStatusUpdate(state.status, cid);
          refetchConversation();
          await chatEventsExecute();
        }
        setLoading(false);
      }
    }
  }, [state]);

  if (leadEvents) {
    leadEvents.map((event) => {
      event["Timestamp"] = Date.parse(event.RepairOrderClosed);
      return event;
    });
  }

  const handleOnOpen = () => {
    console.log("Websocket Connected");
    if (disconnectTimerRef.current) {
      clearTimeout(disconnectTimerRef.current);
    }
  };

  const handleOnClose = () => {
    console.log("Websocket Disconnected");
    if (disconnectTimerRef.current) {
      clearTimeout(disconnectTimerRef.current);
    }
    disconnectTimerRef.current = setTimeout(() => {
      console.log("disconnect because of socket disconnection");
      handleConversationStatus("COMPLETED");
      dispatch(isAppointmentFormOpen(false));
      dispatch(isChatFormOpen(false));
    }, 3 * 60 * 1000); // 3 minutes
  };

  const handleOnMessage = (message) => {
    console.log("Websocket Message: ", message);
    const data = JSON.parse(message.data);
    if (!data || data.event_name === "ack") return;
    const messageText = data.message?.text;
    if (!messageText || messageText.trim().length === 0) return;
    const messageId = uuidv4();
    const newMessage = assembleNewMessage(
      data.message?.text,
      messageId,
      "HUMAN"
    );
    setConversation((prevState) => {
      return [...prevState, newMessage];
    });
  };

  const handleOnError = (error) => {
    console.log("Websocket Error: ", error);
  };

  const render = () => {
    if (status === "error") {
      return "";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    if (!conversation || !conversation.length) return <ChatsDialogEmpty />;

    let channelFilteredDialog = conversation;
    if (leadEvents)
      channelFilteredDialog = channelFilteredDialog.concat(leadEvents);
    if (stopBotEvents)
      channelFilteredDialog = channelFilteredDialog.concat(
        stopBotEvents.map((event) => {
          event["Timestamp"] = event["ts"];
          return event;
        })
      );

    if (chatEventsData) {
      channelFilteredDialog = channelFilteredDialog.concat(chatEventsData);
    }
    console.log("channelFilteredDialog: ", channelFilteredDialog);
    channelFilteredDialog.sort((a, b) => {
      return a.Timestamp - b.Timestamp;
    });

    if (selectedChannel && selectedChannel.Id !== "*") {
      channelFilteredDialog = channelFilteredDialog.filter(
        (item) => item.Channel === selectedChannel.Id
      );
    }

    // disable notifs when empty chat
    if (channelFilteredDialog.length === 0) {
      return null;
    }

    const messages = channelFilteredDialog.map((message) => {
      const notificationToAssign = leadNotifications?.find(
        (item) => item.MessageId === message.MessageId
      );
      const appointmentSet = message.AppointmentData;
      const sentByCrmSalesperson =
        message.Initiator !== "human" ? message.SentByCrmSalesperson : null;
      if (
        message?.event_name === "CTA" ||
        message?.event_name === "CRM_REGISTRATION" ||
        message?.event_name === "APPOINTMENT_SET" ||
        message?.event_name?.includes("DEALERSHIP TAKEOVER")
      ) {
        return {
          component: (
            <ChatsDialogMessageCard
              key={message.Timestamp}
              sender="bot"
              event={message}
              eventName={message?.event_name}
              botName={message.BotName}
              payload={message?.payload}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      if (
        message?.event_name === "SelectCarouselCard" ||
        message?.event_name === "CRM_REGISTRATION"
      ) {
        return {
          component: (
            <ChatsDialogMessageCard
              key={message.Timestamp}
              sender="bot"
              // meta={{ timestamp: message.Timestamp }}
              //justNotifications={true}
              event={message}
              eventName={message?.event_name}
              botName={message.BotName}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      if ("RepairOrderClosed" in message) {
        return {
          component: (
            <ChatsDialogMessage
              key={message.Timestamp}
              sender="bot"
              // meta={{ timestamp: message.Timestamp }}
              justNotifications={true}
              event={message}
              botName={message.BotName}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      if ("Action" in message) {
        return {
          component: (
            <ChatsDialogMessage
              key={message.Timestamp}
              sender="bot"
              meta={{ timestamp: message.Timestamp }}
              justNotifications={true}
              action={message.Action}
              botName={message.BotName}
            />
          ),
          ts: message?.Timestamp,
        };
      }
      return {
        component: (
          <ChatsDialogMessage
            key={message.Timestamp}
            sender={message.Initiator}
            message={message.Message}
            attachments={message?.Attachments || []}
            meta={{ timestamp: message.Timestamp }}
            notification={notificationToAssign}
            appointmentSet={appointmentSet}
            sentByCrmSalesperson={sentByCrmSalesperson}
            channel={message.Channel}
            name={message.Name}
            surname={message.Surname}
            botName={message.BotName}
            blacklisted={message.Blacklisted}
          />
        ),
        ts: message.Timestamp,
      };
    });

    const justNotifications = leadNotifications?.filter((notification) => {
      const index = conversation?.findIndex(
        (item) => item.MessageId === notification.MessageId
      );
      return index === -1;
    });

    // if justSave and hto happens, that notifications are assigned to a message, that is not displayed here, so they are stand alone notifications
    const justNotificationsComponent = justNotifications?.map(
      (notification) => {
        return {
          component: (
            <ChatsDialogMessage
              key={notification.MessageId}
              sender="bot"
              notification={notification}
              justNotifications={true}
            />
          ),
          ts: notification?.Notifications[0].ts,
        };
      }
    );

    let render = [...messages];
    if (justNotificationsComponent) {
      render = [...render, ...justNotificationsComponent];
    }
    render = render.sort(function (a, b) {
      return a.ts - b.ts;
    });
    return render.map((item) => item.component);
  };

  const handleConversationStatus = (newStatus) => {
    setState((prevState) => ({
      ...prevState,
      status: newStatus,
      user_id: cid,
    }));
  };

  const handleEndConversationClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    cancelNavigation();
  };

  const assembleNewMessage = (messageText, messageId, Initiator) => {
    return {
      Attachments: [],
      Blacklisted: false,
      BotName: user.name,
      Channel: "CHAT",
      ConversationId: cid,
      Email: "",
      Initiator: Initiator,
      LeadSource: "CHAT_AI",
      Message: `<article><p>${messageText}</p></article>`,
      MessageId: messageId,
      Name: generalInfoData.FullName,
      Phone: generalInfoData.Phone,
      Surname: generalInfoData.FullName,
      Timestamp: Date.now(),
      event_name: "Message",
    };
  };

  const handleSendMessage = (message) => {
    console.log("ready state is: ", readyState);
    if (
      readyState !== ReadyState.OPEN ||
      message.text?.trim() === "" ||
      message.html === "<article><p></p></article>"
    )
      return;
    const messageId = uuidv4();
    sendJsonMessage({
      event_name: "Message",
      page: "other",
      vehicle_info: null,
      payload: message.text,
      id: messageId,
    });
    console.log("general data is: ", generalInfoData);
    const newMessage = assembleNewMessage(
      message.text,
      messageId,
      "SPECIALIST"
    );
    setConversation((prevState) => {
      return [...prevState, newMessage];
    });
    console.log("message to send is: ", message);
  };

  return (
    <div className="ChatsDialog">
      <div className="ChatsDialog-header">
        {status === "success" &&
          CHANNELS.map((channel) => (
            <Channel
              key={channel.Id}
              name={channel.Name}
              selected={channel.Id === selectedChannel.Id}
              onSelect={() => setSelectedChannel(channel)}
            />
          ))}
      </div>
      <div className="ChatsDialog-container">
        <Snackbar
          style={{ marginTop: "76px" }}
          open={alertState.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <SnackbarMessage
            elevation={2}
            variant="filled"
            onClick={() => {
              setAlertState({ ...alertState, open: false });
            }}
            severity={alertState.severity}
          >
            {alertState.message}
          </SnackbarMessage>
        </Snackbar>
        <div className="ChatsDialog-messages">{render()}</div>
        <div ref={refDialog} />
      </div>
      <div>
        {takeoverData && loading && (
          <div className="ChatsDialog-center">
            <FormElement
              type="submit"
              label={
                <p>
                  <CircularProgress
                    style={{
                      color: "white",
                      width: "13px",
                      height: "13px",
                      marginRight: "5px",
                    }}
                  ></CircularProgress>
                  JOIN THE CONVERSATION
                </p>
              }
              name="submit"
              style={{
                margin: "4vh auto",
                backgroundColor: "#959595",
                width: "48vw",
              }}
              disabled={true} // LOADING
            />
          </div>
        )}
        {takeoverData?.Status &&
          !loading &&
          status !== "init" &&
          status !== "pending" &&
          (generalInfoData?.TakeoverEventType === "IN_PROGRESS" ? (
            generalInfoData?.SalesPersonEmail === user.email ? (
              !has24HoursPassed(generalInfoData?.LastHumanTimestamp) ? (
                <div className="ChatsDialog-footer">
                  <ChatsReply
                    unsubscribed={unsubscribed}
                    conversation={data}
                    selectedChannel={selectedChannel}
                    refetchConversation={refetchConversation}
                    handleSendMessage={(message) => handleSendMessage(message)}
                    handleEndConversationClick={handleEndConversationClick}
                    disableSend={readyState !== ReadyState.OPEN}
                    generalInfoData={generalInfoData}
                    refetchGeneralInfoData={fetchGeneralInfoData}
                  />
                </div>
              ) : (
                <div> STUCK IN PROGRESS MORE THAN 24 HOURS </div> // IN PROGRESS WITH CORRECT USER BUT OVER 24 HOURS
              )
            ) : (
              <FormElement
                type="submit"
                label="JOIN THE CONVERSATION"
                name="submit"
                style={{
                  margin: "4vh auto",
                  backgroundColor: "#959595",
                  width: "48vw",
                }}
                disabled={true} // ALREADY STARTED BY DIFFERENT USER
              />
            )
          ) : !has24HoursPassed(generalInfoData?.LastHumanTimestamp) ? (
            <div>
              <FormElement
                type="submit"
                label="JOIN THE CONVERSATION"
                name="submit"
                style={{
                  margin: "4vh auto",
                  backgroundColor: "#D62C56",
                  width: "48vw",
                }}
                onClick={() => handleConversationStatus("IN_PROGRESS")} // NOT IN PROGRESS AND WITHIN 24 HOURS
              />
            </div>
          ) : (
            <div>
              <FormElement
                type="submit"
                label="CUSTOMER OFFLINE, PLEASE TRY IN A WHILE"
                name="submit"
                style={{
                  margin: "4vh auto",
                  backgroundColor: "#959595",
                  width: "48vw",
                }}
                disabled={true} // NOT IN PROGRESS AND WITHIN 24 HOURS
              />
            </div>
          ))}
      </div>
      <Confirmation
        isOpen={modalOpen || (isBlocked && modalOpen)}
        closeDialog={handleCloseModal}
        title="Close Conversation"
        text="Exiting the conversation will notify the customer that the session is ending and the chat will be handed back to the AI. && 
Would you like to continue?"
        cancelButtonTitle="CANCEL"
        confirmButtonTitle="CONTINUE"
        handleConfirmDelete={() => {
          setModalOpen(false);
          setShouldBlockNavigation(false);
          handleConversationStatus("COMPLETED");
          dispatch(isChatFormOpen(false));
          dispatch(isAppointmentFormOpen(false));
        }}
      />
      <div ref={bottomRef} />
    </div>
  );
};

export default ChatsDialog;
