import PageDashboardCarBuying from "./pages/PageDashboardCarBuying/PageDashboardCarBuying";
import PageCarBuyingConversations from "./pages/PageCarBuyingConversations/PageCarBuyingConversations";
import PageCarBuyingConversation from "./pages/PageCarBuyingConversation/PageCarBuyingConversation";
import PageSettings from "./pages/PageSettings/PageSettings";
import PageLogin from "./pages/Login/Login";
import PageLogout from "./pages/PageLogout/PageLogout";
import PageUser from "./pages/PageUser/PageUser";
import PageHealth from "./pages/Health/Health";

import settingsCarBuyingBlocks from "./components/Settings/settingsCarBuyingBlocksStructure";
import PageUserManagement from "./pages/PageUserManagement";
import PageUserForm from "./pages/PageUserForm";




export const GENERAL_LINKS = [
  {
    icon: "chart",
    label: "Go to Dashboard",
    path: "/",
    component: PageDashboardCarBuying,
    exact: true,
  },
  {
    icon: "conversations",
    label: "Go to Conversations",
    path: "/conversations",
    component: PageCarBuyingConversations,
    subroutes: [
      {
        label: "Conversation View",
        path: "/conversation/",
      },
      {
        label: "List View",
        path: "/conversations",
      },
    ],
  },
  {
    icon: "settings",
    label: "Go to Settings",
    path: "/settings",
    component: PageSettings,
    subroutes: settingsCarBuyingBlocks.map((item) => {
      return {
        label: item.title,
        path: `/settings#${item.title.split(" ").join("")}`,
        resourceName: item.resourceName,
        index: item.index,
      };
    }),
  },
  {
    icon: "user",
    label: "Go to User Settings",
    path: "/user",
    component: PageUser,
    subroutes: [
      {
        label: "Manage Users",
        path: "/user-management",
      },
      {
        label: "Edit Account",
        path: "/user",
      },
      {
        label: "Log Out",
        path: "/logout",
      },
    ],
  },
];

export const SECONDARY_LINKS = [
  {
    label: "Go to Single Conversation",
    path: "/conversation/:cid",
    component: PageCarBuyingConversation,
  },
  {
    label: "Go to Single Conversations List",
    path: "/conversation/",
    component: PageCarBuyingConversation,
    exact: true,
  },
  {
    label: "User Management Page",
    path: "/user-management/:action",
    component: PageUserForm,
  },
  {
    label: "User Management Page",
    path: "/user-management/",
    component: PageUserManagement,
    exact: true,
  },
  {
    label: "Log Out",
    path: "/logout",
    component: PageLogout,
  },
];

export const COMMON_LINKS = [
  {
    label: "Log In",
    path: "/login",
    component: PageLogin,
  },
  {
    label: "Health Check",
    path: "/health",
    component: PageHealth,
  },
  // {
  //   label: "Metrics",
  //   path: "/",
  //   component: DummySettings,
  // },
];
