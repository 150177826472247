import cn from "classnames";

import PaginationPage from "./PaginationPage";
import PaginationSeparator from "./PaginationSeparator";

import "./Pagination.scss";

import svg from "../../assets/img/vector.svg";

const Pagination = ({ current, total, onPageChange, mode = "regular" }) => {
  const pages = [];

  pages.push(
    <PaginationPage
      number={1}
      disabled={current === 1}
      className="is-first"
      onPageChange={onPageChange}
    >
      <svg width="10" height="10">
        <use xlinkHref={svg + "#v-chevronDoubleLeft"} />
      </svg>
    </PaginationPage>
  );

  if (mode !== "simple") {
    pages.push(
      <PaginationPage
        number={current - 1}
        disabled={current === 1}
        className="is-prev"
        onPageChange={onPageChange}
      >
        <svg width="10" height="10">
          <use xlinkHref={svg + "#v-chevronSingleLeft"} />
        </svg>
      </PaginationPage>
    );
  }

  for (let idx = 1; idx < 3; idx++) {
    if (idx < current - 2 && mode !== "simple")
      pages.push(
        <PaginationPage
          number={idx}
          className={cn({ "is-current": idx === current })}
          onPageChange={onPageChange}
        >
          {idx}
        </PaginationPage>
      );
  }

  if (current > 5 && mode !== "simple") pages.push(<PaginationSeparator />);

  for (let idx = current - 2; idx <= current + 2; idx++) {
    if (idx > 0 && idx <= total)
      pages.push(
        <PaginationPage
          number={idx}
          className={cn({ "is-current": idx === current })}
          onPageChange={onPageChange}
        >
          {idx}
        </PaginationPage>
      );
  }

  if (current + 4 < total && mode !== "simple")
    pages.push(<PaginationSeparator />);

  for (let idx = total - 1; idx <= total; idx++) {
    if (idx > current + 2 && idx <= total && mode !== "simple")
      pages.push(
        <PaginationPage
          number={idx}
          className={cn({ "is-current": idx === current })}
          onPageChange={onPageChange}
        >
          {idx}
        </PaginationPage>
      );
  }

  if (mode !== "simple") {
    pages.push(
      <PaginationPage
        number={current + 1}
        disabled={current === total}
        className="is-next"
        onPageChange={onPageChange}
      >
        <svg width="10" height="10">
          <use xlinkHref={svg + "#v-chevronSingleRight"} />
        </svg>
      </PaginationPage>
    );
  }

  pages.push(
    <PaginationPage
      number={total}
      disabled={current === total}
      className="is-last"
      onPageChange={onPageChange}
    >
      <svg width="10" height="10">
        <use xlinkHref={svg + "#v-chevronDoubleRight"} />
      </svg>
    </PaginationPage>
  );

  return (
    <ul className="Pagination">
      {pages.map((page, idx) => (
        <li key={idx} className="Pagination-item">
          {page}
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
