import useData from "../../../hook/useData";

import "./ChatsDetailsCollapsed.scss";

const ChatsDetailsCollapsed = ({ cid, onToggleDetailsCollapsed }) => {
  const { data, status, error } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return "Loading...";
    }

    return data.FullName;
  };

  return (
    <div className="ChatsDetailsCollapsed">
      <button
        className="ChatsDetailsCollapsed-toggler"
        onClick={onToggleDetailsCollapsed}
      >
        <span className="ChatsDetailsCollapsed-togglerLabel">{render()}</span>
      </button>
    </div>
  );
};

export default ChatsDetailsCollapsed;
