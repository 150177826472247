export default [
  {
    type: "textfield",
    label: "Dealership Name:",
    id: "signatureDealershipName",
    name: "signatureDealershipName",
    key: "DealershipName",
    isWide: true,
  },
/*   {
    type: "textfield",
    label: "AI ASSISTANT’S NAME:",
    id: "signatureName",
    name: "signatureName",
    key: "AssistantName",
  },
  {
    type: "textfield",
    label: "AI ASSISTANT’S JOB TITLE (OPTIONAL):",
    id: "signaturePosition",
    name: "signaturePosition",
    key: "AssistantJobTitle",
  }, */
  {
    type: "textarea",
    label: "Sales Phone number:",
    id: "signaturePhonePrimary",
    name: "signaturePhonePrimary",
    key: "SalesPhoneNumber",
  },
  {
    type: "textarea",
    label: "Service Phone number (optional):",
    id: "signaturePhoneSecondary",
    name: "signaturePhoneSecondary",
    key: "ServicePhoneNumber",
  },
  {
    type: "textarea",
    label: "Parts Phone number (optional):",
    id: "signaturePhoneParts",
    name: "signaturePhoneParts",
    key: "PartsPhoneNumber",
  },
  {
    type: "textarea",
    label: "Website URL:",
    id: "signatureWebsite",
    name: "signatureWebsite",
    key: "WebsiteURL",
  },
  {
    type: "textarea",
    label: "Address:",
    id: "signatureAddress",
    name: "signatureAddress",
    key: "Address",
  },
];
