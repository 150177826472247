import { useEffect, useRef, useState } from "react";

import cn from "classnames";

import "./MDropDown.scss";

import Svg from "../../Svg/Svg";
import useAutoClose from "../../../hook/useAutoClose";
import { useDispatch, useSelector } from "react-redux";
import { chooseConversationsEndpoint, chooseConversationsMetricType, chooseConversationsSubMetricType } from "../../../store/actions/conversationActions";

const MDropDown = ({ label, labelHidden = false, icon = false, listOptions = [], defaultValue = null, disabled = false, onChange, error, status, statusCode, ...rest}) => {
  const select = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  
  const { conversationsMetric, conversationsSubMetric } = useSelector((state) => state.conversation);

  useAutoClose({ setIsOpen, ref: select });

  const handleChange = (value, type) => {
    //onChange(value); //ეს გადასაკეთებელი მექნება მშობელ ფუნქციაში
    if(type === 'sub-metric'){
      dispatch(chooseConversationsEndpoint(`conversations_${value?.parent}`)); 
      dispatch(chooseConversationsMetricType(value?.parent)); 
      dispatch(chooseConversationsSubMetricType(value?.metric));
    }else if(type === 'metric'){
      dispatch(chooseConversationsEndpoint(`conversations_${value?.metric}`)); 
      dispatch(chooseConversationsMetricType(value?.metric)); 
      dispatch(chooseConversationsSubMetricType(""));
    }
    //console.log(value)
  };

  const SecondaryListItems = ({item}) => {
    return (
      <li
        key={item?.metric}
        className={cn("MDropDown-listItem", {
          "MDropDown-listItems--active": conversationsSubMetric === item?.metric && conversationsMetric === item.parent,
          "MDropDown-listItems--disabled": item?.isDisabled,
        })}
        onClick={() => {
          handleChange(item, "sub-metric");
          setIsOpen(false);
        }}
      >
        <span>{item?.label}</span>
      </li>
    );
  };

  const PrimaryListItems = ({item}) => {
    return (
      <li
        key={item?.metric}
        className={cn("MDropDown-listItem", {
          "MDropDown-listItems--active": conversationsMetric === item?.metric && !conversationsSubMetric,
          "MDropDown-listItems--disabled": item?.isDisabled,
        })}
      >
        <span onClick={() => { handleChange(item, "metric"); setIsOpen(false); }}>{item?.label}</span>
        {!!item?.sub_metrics?.length  && 
          <span className="MDropDown-togglerIcon" style={{ color: item?.sub_metrics?.findIndex(value => value.metric === conversationsSubMetric && value.parent === conversationsMetric) !== -1 ? "red" : "" }}>
            <Svg w="15" h="17" i="chevronSingleRight" />
          </span>}
        {
          !!item?.sub_metrics?.length &&  
          <ul className="submenu">
            {item?.sub_metrics.map(el => <SecondaryListItems key={el.metric} item={el} />)}
          </ul>
        }
      </li>
    );
  };

  return (
    <div 
      ref={select}
      className={cn("FormSelect cm-e-menu", {
        "FormSelect--iconed": icon,
        "FormSelect--open": isOpen,
        "FormSelect--disabled": disabled,
      })}
    >
      <label
        className={cn("MDropDown-label", {
          "MDropDown-label--hidden": labelHidden,
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <ul>
        <li className="topmenu">
          <button
            className="MDropDown-toggler"
            type="button"
            disabled={disabled}
            aria-expanded={isOpen}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((isOpen) => !isOpen);
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <span className="MDropDown-togglerText" >
                {(conversationsMetric && !!listOptions?.length) && `${listOptions?.find(el => el.metric === conversationsMetric)?.label || ''}${conversationsSubMetric ? ":" : ""} `}
                {!!listOptions?.find(el => el.metric === conversationsMetric)?.sub_metrics?.length && listOptions.find(el => el.metric === conversationsMetric)?.sub_metrics.find(el => el.metric === conversationsSubMetric)?.label}
              </span>
              <span className="MDropDown-togglerIcon">
                <Svg w="9" h="6" i="markerDropdown" />
              </span>
            </div>
          </button>
          <ul className="FormSelect-list submenu">
            { !!listOptions?.length && listOptions.map(el => <PrimaryListItems key={el.metric} item={el}/>)}
          </ul> 
        </li>
      </ul>
    </div>
  );
};

export default MDropDown;


/* 
    <div
      ref={select}
      className={cn("MDropDown", {
        "MDropDown--iconed": icon,
        "MDropDown--open": isOpen,
        "MDropDown--disabled": disabled,
      })}
    >
      <label
        className={cn("MDropDown-label", {
          "MDropDown-label--hidden": labelHidden,
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <div className="MDropDown-field">
        {icon && (
          <span className="MDropDown-fieldIcon">
            <Svg {...icon} />
          </span>
        )}
        <button
          className="MDropDown-toggler"
          type="button"
          disabled={disabled}
          aria-expanded={isOpen}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((isOpen) => !isOpen);
          }}
        >
          <span className="MDropDown-togglerText">
            {defaultValueCalculated.label}
          </span>
          <span className="MDropDown-togglerIcon">
            <Svg w="9" h="6" i="markerDropdown" />
          </span>
        </button>
        <ul className="MDropDown-list" aria-hidden={!isOpen}>
          {listItems}
        </ul>
      </div>
    </div>
*/