export default [
  {
    id: "engagedLeads",
    label: "Engaged Leads",
  },
  {
    id: "aiAppointmentsLeads",
    label: "AI Appointmented Leads",
  },
  {
    id: "salesExpertiseNeededLeads",
    label: "Sales Expertise Needed Leads",
  },
  {
    id: "acquiredPhoneNumbersLeads",
    label: "Acquired Phone Numbers",
  },
  {
    id: "losingOpportunitiesLeads",
    label: "Awaiting Sales Response",
  },
  {
    id: "newProcessedLeads",
    label: "New Leads Processed",
  },
  {
    id: "newBySourceLeads",
    label: "New Leads By Source",
  },
];
