import "./index.scss";
// import opCodesData from "./opCodes";
import cn from "classnames";
import { Button } from "@material-ui/core";
import FormElement from "../../../../../../FormElement/FormElement";
import { useState } from "react";

const OpCode = ({ opCode, selected, select }) => {
  return (
    <div
      onClick={() => select(opCode)}
      className={cn("OpCode-Main", {
        "OpCode-Main--active": selected,
      })}
    >
      <p className="OpCode-Main-code">{opCode.code}</p>
      <p className="OpCode-Main-description">{opCode.description}</p>
    </div>
  );
};

const OpCodeComponent = ({
  // type,
  label,
  opCodes,
  setOpCodes,
  save,
  cancel,
  form,
}) => {
  const [search, setSearch] = useState("");
  const mapOpCodesVariables = (arr) => {
    return arr.map((item) => ({
      code: item.Code,
      description: item.Description,
    }));
  };
  let opCodesData = form?.Config?.Template?.ServiceOpCodes || [];
  opCodesData = mapOpCodesVariables(opCodesData);

  const isSelected = (array, item) => {
    for (const i of array) {
      if (i.code === item.code) return true;
    }
    return false;
  };

  const handleSelect = (opCode) => {
    if (isSelected(opCodes, opCode)) {
      return setOpCodes([...opCodes].filter((oc) => oc.code !== opCode.code));
    }
    return setOpCodes([...opCodes, opCode]);
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      return setOpCodes([...opCodesData]);
    } else {
      return setOpCodes([]);
    }
  };

  console.log(search);

  return (
    <div className="OpCodeComponent-Container">
      <p className="OpCodeComponent-Header">{label}</p>
      <div className="OpCodeComponent-Controlls">
        <FormElement
          type="checkbox"
          label="Select All"
          checked={opCodes.length === opCodesData.length}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />

        <FormElement
          type="textfield"
          style={{ width: "20vw" }}
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
        />
      </div>
      <div className="OpCodeComponent-opCodes">
        {opCodesData
          .filter(
            (item) =>
              item.code.toLowerCase().includes(search) ||
              item.description.toLowerCase().includes(search)
          )
          .map((opCode) => (
            <OpCode
              key={opCode.code}
              opCode={opCode}
              selected={isSelected(opCodes, opCode)}
              select={(oc) => handleSelect(oc)}
            />
          ))}
      </div>
      <div className="OpCodeComponent-Actions">
        <Button
          variant="contained"
          color="primary"
          onClick={save}
          style={{
            width: "5vw",
            fontSize: "1.2vh",
          }}
          size="large"
        >
          done
        </Button>
      </div>
    </div>
  );
};

export default OpCodeComponent;
