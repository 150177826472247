import cn from "classnames";

import "./ChatsDialogMessage.scss";

import Svg from "../../Svg";

import { formatToFullDateString } from "../../../utils/formatDate";
import { useState } from "react";
import Modal from "@material-ui/core/Modal";

const ChatsDialogMessage = ({
  message = "",
  attachments = [],
  meta = {},
  sender = "bot",
  notification,
  appointmentSet,
  sentByCrmSalesperson,
  channel,
  justNotifications = false,
  action,
  event,
  name,
  surname,
  botName,
  blacklisted,
}) => {
  const [showNotificationDialog, setShowNotificationDialog] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);

  const time = formatToFullDateString(meta.timestamp);
  const messageAfterLinksProcessing = message.replaceAll('\n', '<br/>').replaceAll(/<(?<link>http[^>]+)>/gi, "<a target='_blank' href='$<link>'>$<link></a>")
  const typesCounter = {};
  const attachmentsDisplay = (attachments || []).map(x => {
    const content_type = x['content_type'].split('/').pop().toUpperCase();
    typesCounter[content_type] = (typesCounter[content_type] || 0) + 1;
    const attachment_number = typesCounter[content_type];

    return `<a href=${x['object_key']} target="_blank">${content_type} (${attachment_number})</a>`
  }).join('  ');

  const getSalespersonsNotifiedLabel = (num = 0) => {
    if (num === 0) return "";
    if (num === 1) return "Salesperson was notified";
    return `${num} Salespersons were notified`;
  };

  const showNotificationRecipients = () => {
    if (notification?.Notifications?.length)
      setShowNotificationDialog(notification.Notifications);
    else if (event) setShowEventModal(true);
  };

  return (
    <>
      <div
        className={cn("ChatsDialogMessage", `ChatsDialogMessage--${sender}`, {
          "ChatsDialogMessage--left": sender === "human",
          "ChatsDialogMessage--right":
            sender === "bot" || sender === "operator",
        })}
      >
        {!justNotifications && (
          <>
            <div className="ChatsDialogMessage-data">
              <div className="ChatsDialogMessage-icon">
                <Svg w="22" h="29" i={`${channel}-icon`} />
              </div>
              <div className="ChatsDialogMessage-wrapper">
                <div className="ChatsDialogMessage-author-wrapper">
                  <div
                    className={cn("ChatsDialogMessage-author", {
                      sms: channel === "sms",
                      email: channel === "email",
                      whatsApp: channel === "whatsapp",
                    })}
                  >
                    {sender === "human" ? (
                      blacklisted ? (
                        <>A customer</>
                      ) : (
                        <>{`${name} ${surname}`}</>
                      )
                    ) : sentByCrmSalesperson ? (
                      <>Dealer Representative</>
                    ) : (
                      <div className="bot-author">
                        {botName}
                        <Svg w="16" h="16" i={`${channel}-ai`} /> 
                      </div>
                    )}
                  </div>
                  {time && (
                    <div className="ChatsDialogMessage-meta">
                      {time}
                      {/* {sentByCrmSalesperson && " By CRM Salesperson"} */}
                    </div>
                  )}
                </div>
                <div
                  className={cn("ChatsDialogMessage-text", {
                    sms: channel === "sms",
                    email: channel === "email",
                    whatsApp: channel === "whatsapp",
                  })}
                  dangerouslySetInnerHTML={{
                    __html: messageAfterLinksProcessing + '<br/>' + attachmentsDisplay,
                  }}
                ></div>
              </div>
            </div>
            {appointmentSet && (
              <div className="ChatsDialogMessage-text ChatsDialogMessage-event">
                Appointment was set in CRM
              </div>
            )}
          </>
        )}
        {notification && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {getSalespersonsNotifiedLabel(notification?.Notifications?.length)}
          </div>
        )}
        {event && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            Customer completed service
          </div>
        )}
        {action && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event"
            )}
          >
            {action === "stop"
              ? "Dealer Representative has stopped AI responses for this customer."
              : "Dealer Representative has re-started AI responses for this customer."}
            {meta.timestamp && (
              <div className="ChatsDialogMessage-meta">
                {formatToFullDateString(meta.timestamp)}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={Boolean(showNotificationDialog.length || showEventModal)}
        onClose={() => {
          setShowNotificationDialog([]);
          setShowEventModal(false);
        }}
        className="ChatDialogMessage-modal"
      >
        <div className="ChatDialogMessage-modal-content">
          {notification && (
            <>
              <p>Salespersons notified</p>
              <div className="ChatDialogMessage-notification-list">
                {showNotificationDialog.map((notificationItem) => (
                  <div className="ChatDialogMessage-notification-list-item">
                    {notificationItem.UserId}
                    <p>{notificationItem.NotifName} notification</p>
                  </div>
                ))}
              </div>
            </>
          )}
          {event?.Details &&
          event?.Details.length > 0 &&
          !Object.keys(event?.Details[0]).includes("") ? (
            <>
              <p>Event Details</p>
              <div className="ChatDialogMessage-notification-list">
                {event.Details.map((each) => {
                  return (
                    <>
                      <div className="ChatDialogMessage-notification-list-item">
                        {typeof each === "object" ? (
                          <>
                            <span class="key">{Object.keys(each)[0]}</span>:{" "}
                            {each[Object.keys(each)[0]]}
                          </>
                        ) : (
                          <>{each}</>
                        )}
                      </div>
                    </>
                  );
                })}
                <hr className="HorizontalLine" />
                <div>
                  <span style={{ fontWeight: "bold" }}>TOTAL:</span>{" "}
                  <span style={{ float: "right", fontWeight: "bold" }}>
                    ${event.TotalRepairPaidAmount}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {!notification && (
                <>
                  <p className="EventDetailsLabel">Event Details</p>
                  <div className="ChatDialogMessage-notification">
                    <p className="Margin">Service Completed Date: </p>
                    <p className="Margin">
                      {" "}
                      {event?.RepairOrderClosed?.split(" ")[0]}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChatsDialogMessage; 
