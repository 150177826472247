import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import ProductSelector from "../../components/ProductSelector/ProductSelector";
import "./index.scss";
import DashboardCarBuying from "../../components/DashboardCarBuying/DashboardCarBuying";


const PageDashboardCarBuying = () => {
  const isCDK = window.location.hostname.includes('crmaiva');

  const widgets = {
    primary: [
      <DateRangePicker type="date" key="WidgetDateRange" label="Date Filter" />,
    ]
  };
  if (!isCDK) {
    widgets.secondary = [<ProductSelector key="WidgetProductSelector" />]
  }

  let content = <DashboardCarBuying />;
  return <Layout content={content} widgets={widgets} />;
};

export default PageDashboardCarBuying;
