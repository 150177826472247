import ActionButtons from "./ActionButtons/ActionButtons";

export default [
  {
    id: "UserId",
    hiddenColumn: true,
  },
  {
    id: "Name",
    label: "Name",
    className: ["TableCell-formatText", "TableCell--important"],
    sortable: true,
    formatter: (row) => {
      return row.FullName;
    },
  },
  {
    id: "Email",
    label: "Email",
    className: "TableCell-formatText",
    sortable: true,
    formatter: (row) => {
      return row.Email;
    },
  },
  {
    id: "Role",
    label: "Role",
    className: "TableCell-formatText",
    sortable: true,
    formatter: (row) => {
      return row.UserRole;
    },
  },
  {
    id: "Dealership",
    label: "Dealership",
    className: "TableCell-formatText",
    sortable: true,
    formatter: (row) => {
      return (row.ChildAccounts || []).map(item => item.name).join(', ');
    },
  },
  {
    id: "Action",
    label: "Action",
    formatter: (row) => {
      return <ActionButtons userId={row.Auth0UserId} fullName={row.FullName} userName={row.Username} retailerName={row.RetailerName || 'impel'} />;
    },
  },
];
