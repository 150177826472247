import cn from "classnames";
import "./TableCell.scss";

import { formatValue } from "../../../utils/formatValue";

const prepareCellContent = (children) => {
  if (
    typeof children === "string" &&
    children.length > 300 &&
    !children.length < 400
  ) {
    return children.slice(0, children.indexOf(" ", 350)) + "...";
  }

  return formatValue(children);
};

const TableCell = ({ className, children, ...props }) => {
  const cellContent = prepareCellContent(children);

  return (
    <td className={cn("TableCell", className)} {...props}>
      {cellContent}
    </td>
  );
};

export default TableCell;
