import cn from "classnames";

import Loader from "../../Loader";
import Svg from "../../Svg";

import { formatValue } from "../../../utils/formatValue";
import { getValue } from "../helpers/getValue";

import "./TileDetails.scss";
import QuestionMark from "../QuestionMark/QuestionMark"

const TileDetails = ({
  number,
  isFirst,
  isImportant,
  label,
  description,
  status,
  error,
  dataPrimary,
  keyPrimary,
  dataSecondary,
  keySecondary,
  currency,
  ...rest
}) => {
  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init") {
      return "No data loaded yet";
    }

    if (status === "pending") {
      return <Loader />;
    }

    let valuePrimary = formatValue(getValue(dataPrimary, keyPrimary));
    if (currency === "USD") valuePrimary = `$${valuePrimary}`;
    const valueSecondary = getValue(dataSecondary, keySecondary); 
    
    return (
      <>
        <div className="TileDetails-frame"></div>
        {isImportant && (
          <div className="TileDetails-icon">
            <Svg w="38" h="38" i="important" />
          </div>
        )}
        <div className="TileDetails-label">
          {label} <QuestionMark definition={description} />
        </div>
        <div className="TileDetails-data">
          {valuePrimary && (
            <div
              className={cn("TileDetails-primary-lighter", {
                "TileDetails-primary": !currency,
              })}
            >
              {valuePrimary}
            </div>
          )}
          {valueSecondary && (
            <div className="TileDetails-secondary">{valueSecondary}%</div>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={cn("TileDetails", {
        "TileDetails--clickable": rest.onClick,
        "is-first": isFirst,
        "is-important": isImportant,
      })}
      data-number={number}
      {...rest}
    >
      {render()}
    </div>
  );
};

export default TileDetails;
