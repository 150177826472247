import { useState, useEffect } from "react";

import useData from "../../../../hook/useData";
import Loader from "../../../Loader/Loader";

import "./index.scss";
import CampaignCSVUploader from "./CampaignCSVUploader";
import CampaignConditions from "./CampaignConditions";
import SequenceTemplateEditor from "./SequenceTemplateEditor/SequenceTemplateEditor";
import SettingsSelector from "./SettingsSelector/SettingsSelector";
import CampaignVariables from "./CampaignVariables/CampaignVariables";
import NotificationsSection from "./NotificationsSection/NotificationsSection";
import FormTextfield from "../../../FormElement/FormTextfield/FormTextfield";

const VARIABLES = [
  {
    Type: "textfield",
    Name: "",
    Description:
      "Enter the link to your appointment scheduling tool to allow customers to set appointments.",
    Title: "APPOINTMENT URL",
    Id: "apptUrl",
    Placeholder: "http://example.com/",
  },
  {
    Type: "numfield",
    Name: "",
    Description:
      "Enter the maximum number of emails you want to send per day. Please note: you will only be able to enter a number that’s 500 or less.",
    Id: "MaxCampaignDailyQuota",
    Title: "MAXIMUM DAILY EMAILS",
    Placeholder: "100",
    min: 0,
    max: 500,
  },
];

const NAME_VARIABLE = {
  Type: "textfield",
  Name: "Initiative Name",
  Description:
    "Enter a unique name for this initiative to help differentiate it from existing ones.",
  Id: "Name",
  Placeholder: "Campaign...",
};

const SequenceConfiguration = ({
  setFormCompleted,
  setForm,
  formValue,
  form,
}) => {
  let m_variables = VARIABLES;
  const [sequences, setSequences] = useState([]);

  console.log("seq: ", sequences);

  const p = {
    campaign_category:
      "Campaigns:ServiceCampaign:Category:FirstServiceCampaign",
    campaign_sequence:
      "Campaigns:ServiceCampaign:FirstServiceCampaign:Template:OilChange",
    campaign_type: "Campaigns:Type:ServiceCampaign",
  };

  const sequenceConfiguration = form["Sequence"];

  // const { data: sequenceConfiguration, status } = useData(
  //   "getSequenceConfig",
  //   {
  //     campaign_type: form["Type"]["Id"],
  //     campaign_category: form["Category"]["Id"],
  //     campaign_sequence: form["Sequence"]["Id"],
  //   },
  //   []
  // );

  useEffect(() => {
    if (!sequenceConfiguration || !sequenceConfiguration.Sequence) return;
    setForm({ ...sequenceConfiguration });
    setSequences(sequenceConfiguration.Sequence);
  }, []);

  useEffect(() => {
    if (formValue) setFormCompleted();
  }, [formValue]);

  const handleSequenceUpdate = (data) => {
    setSequences(data);
    setForm({ Sequence: data });
  };

  if (!sequenceConfiguration) {
    return (
      <div className="CampaignSequences-Error">
        <p>No sequence config</p>
      </div>
    );
  }

  if (
    !"MaxCampaignDailyQuota" in form.Config ||
    form.Config.MaxCampaignDailyQuota === null
  ) {
    m_variables = m_variables.filter((x) => x.Id !== "MaxCampaignDailyQuota");
  }

  return (
    <div className="SequenceConfiguration-Container">
      <p className="SequenceTemplateEditor-Title">
        Initiative:
        {form?.Config?.Name ? (
          <strong>{form?.Config?.Name}</strong>
        ) : (
          <span>{form["Category"]["Name"]}</span>
        )}
        {/* <strong>{form?.Config?.Name}</strong>-{" "}
        <span>{form["Category"]["Name"]}</span> */}
      </p>
      {form?.Sequence?.IsTemplate && (
        <div className="SequenceConfiguration-Block">
          <CampaignVariables
            setForm={setForm}
            form={form}
            VARIABLES={[NAME_VARIABLE]}
            noTitle={true}
          />
        </div>
      )}
      {form?.Sequence?.DataSource === "CSV" && (
        <div className="SequenceConfiguration-Block">
          <CampaignCSVUploader setForm={setForm} form={form} />
        </div>
      )}
      <div className="SequenceConfiguration-Block">
        <CampaignConditions setForm={setForm} form={form} />
      </div>
      <div className="SequenceConfiguration-Block">
        <CampaignVariables
          setForm={setForm}
          form={form}
          VARIABLES={m_variables}
        />
      </div>
      <div className="SequenceConfiguration-Block">
        {sequences && (
          <SequenceTemplateEditor
            sequences={sequences}
            setSequences={(updatedSequences) =>
              handleSequenceUpdate(updatedSequences)
            }
          />
        )}
      </div>
      <div className="SequenceConfiguration-Block">
        <SettingsSelector form={form} setForm={setForm} />
      </div>
      <div className="SequenceConfiguration-Block">
        <NotificationsSection form={form} setForm={setForm} />
      </div>
    </div>
  );
};

export default SequenceConfiguration;
