import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import Loader from "../../Loader/Loader";
import useWindowSize from "../../../hook/useWindowSize";
import useData from "../../../hook/useData";
import "./SettingsBusinessHours.scss";
import BusinesshoursForm from "./Form/BusinessHours/BusinesshoursForm";
import SpecialBusinessForm from "./Form/SpecialBusinessHours/SpecialBusinessForm";
import Svg from "../../Svg/Svg";
import FormElement from "../../FormElement/FormElement";
import AppointmentHoursForm from "./Form/AppointmentHours/AppointmentHoursForm";
import moment from "moment";
import {Alert, Snackbar, styled} from "@mui/material";
import {useSelector} from "react-redux";


const alertSeverityColorMap = {
  error: '#DE0700',
  success: '#66BE50',
  default: '#5383EC'
}

const StyledAlert = styled(Alert)(({severity}) => ({
  width: '100%',
  fontSize: '1.2rem',
  alignItems: 'center',
  cursor: 'pointer',
  lineHeight: 'normal',
  backgroundColor: alertSeverityColorMap[severity] || alertSeverityColorMap.default
}))


const SettingsBusinessHours = ({accessConfig = {}, renderedCallback = () => {}}) => {
  const windowSize = useWindowSize();
  const [resourceName, setResourceName] = useState(
    accessConfig?.resource?.Name
  );

  const [createOrUpdate, setCreateOrUdpate] = useState();
  const [businessHours, setBusinessHours] = useState([]);
  const [currentSpecialBusinessHours, setCurrentSpecialBusinessHours] = useState([]);
  const [nextSpecialBusinessHours, setNextSpecialBusinessHours] = useState([]);
  const [specialBusinessHours, setSpecialBusinessHours] = useState("current_year");
  const [appointmentHours, setAppointmentHours] = useState();
  const [alertOpen, setAlertOpen] = useState(false);

  const accountId = useSelector(
      (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const { accessType, resource } = accessConfig;

  const { data, status, error } = useData(
    "getAccountBusinessHours",
    {},
    [accountId],
    resource === undefined
  );

  const {
    data: updateData,
    error: updateError,
    status: updateStatus,
    execute: updateExecute,
  } = useData("createAndUpdateAccountBusinessHours", createOrUpdate, [createOrUpdate], false);

  // Fill state with initial data
  useEffect(() => {
    if (data && ("business_hours" in data || "special_business_hours" in data || "appointment_working_hours" in data)) {
      setBusinessHours(data.business_hours);
      setCurrentSpecialBusinessHours(data.special_business_hours.current_year);
      setNextSpecialBusinessHours(data.special_business_hours.next_year);
      setAppointmentHours(data.appointment_working_hours)

      renderedCallback();
    }
  }, [data, accountId]);

  useEffect(() => {
    setCreateOrUdpate({
      business_hours: businessHours,
      special_business_hours: {
        current_year: currentSpecialBusinessHours,
        next_year: nextSpecialBusinessHours
      },
      appointment_working_hours: appointmentHours
    })
  }, [businessHours, currentSpecialBusinessHours, nextSpecialBusinessHours, appointmentHours])

  useEffect(() => {
    if (['error', 'success'].includes(updateStatus)) {
      setAlertOpen(true);
    }
  }, [updateStatus])

  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  const handleAddSpecificDate = () => {
    if(specialBusinessHours === "current_year"){
      const obj = { status: "edit", custom: true, date: moment().format("YYYY/MM/DD"), name: "", open: false, work_hours: null };
      //currentSpecialBusinessHours.holidays.findIndex(el => el?.status === "edit") === -1 &&
      setCurrentSpecialBusinessHours(el => ({ ...el, holidays: [ ...el.holidays, obj]}))
    }else{
      const obj = { status: "edit", custom: true, date: moment().add(1, 'Y').format("YYYY/MM/DD"), name: "", open: false, work_hours: null };
      //nextSpecialBusinessHours.holidays.findIndex(el => el?.status === "edit") === -1 &&
      setNextSpecialBusinessHours(el => ({ ...el, holidays: [ ...el.holidays, obj]}))
    }
  }

  const handleSubmitData = () => {
    //todo აქ მიმდინარე და მოდევნო წლის holidays ჩამოვუვლი და სტატუსების გარეშე გავუშვებ მუტაციაზე. წარმატებულ მუტაციაზე მათ სთეითებს ჩავანაცვლებ მოსული response-ით
    //console.log(createOrUpdate);
    updateExecute();
  }

  if (status === "error") {
    return error || "Error";
  }

  if (status === "pending") {
    return <Loader />;
  }

  return (
    <>
      <div className="BusinessHours">
        <div className="header">
          <p className="title">Business Hours</p>
          <p className="description">Enter your regular hours of operation.</p>
        </div>
        <div className="body">
          <BusinesshoursForm hoursArr={businessHours} setHoursArr={setBusinessHours}/>
        </div>
      </div>
      <div className="SpecialBusinessHours">
        <div className="header">
          <p className="title">Special Business Hours</p>
          <p className="description">Schedule special business hours, including closures and reduced/extended hours of operation, for holidays and other events.</p>
        </div>
        <div className="btnContainer">
          <label for="currentYear">
            <input type="radio" id="currentYear" name="alignment" className="peer" value="current_year" onChange={(e) => setSpecialBusinessHours(e.target.value)} checked={specialBusinessHours === "current_year"} />
            <div className="icon">
              {currentSpecialBusinessHours.year}
            </div>
          </label>
          <label for="nextYear">
            <input type="radio" id="nextYear" name="alignment" className="peer" value="next_year" onChange={(e) => setSpecialBusinessHours(e.target.value)} checked={specialBusinessHours === "next_year"} />
            <div className="icon">
              {nextSpecialBusinessHours.year}
            </div>
          </label>
        </div>
        <div className="body">
          <SpecialBusinessForm hoursArr={specialBusinessHours === "current_year" ? currentSpecialBusinessHours : nextSpecialBusinessHours } setHolidaysArr={specialBusinessHours === "current_year" ? setCurrentSpecialBusinessHours : setNextSpecialBusinessHours } />
        </div>
        <div className="SettingsTime-addDate">
          <button
            className="SettingsTime-addDateButton"
            onClick={handleAddSpecificDate}
          >
            <div className="SettingsTime-addDateIcon">
              <Svg w="32" h="32" i="add" />
            </div>
            <div className="SettingsTime-addDateLabel">
              Add Special Business Hours
            </div>
          </button>
        </div>
      </div>
      <div className="AppointmentWorkingHours">
        <div className="header">
          <p className="title">Appointment Working Hours</p>
          <p className="description">This section allows you to specify the timeframe after opening and before the close of working days during which appointments won't be scheduled by the AI assistant.</p>
        </div>
        <div className="body">
          <AppointmentHoursForm appointmentHours={appointmentHours} setAppointmentHours={setAppointmentHours} />
        </div>
        <div className="SettingsTime-save">
          <FormElement
            onClick={handleSubmitData}
            type="submit"
            label={'SAVE HOURS OF OPERATION'}
            disabled={updateStatus === 'pending'}
          />
          {['error', 'success'].includes(updateStatus) &&
            <Snackbar
              open={alertOpen}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={handleAlertClose}
            >
              <StyledAlert elevation={2} variant="filled" onClick={handleAlertClose} severity={updateStatus}>
                {updateStatus === 'error' ? 'Working Hours Unable To Save!' : 'Working Hours Saved Successfully!'}
              </StyledAlert>
            </Snackbar>
          }
        </div>
      </div>
    </>
  );
};

export default SettingsBusinessHours;

/*

const useTextFieldStyle = makeStyles({
  root: {
    '& .MuiInput-root': {
      padding: 2
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'gray',
    },
    '& .MuiInput-underline:before': {
      borderBottom: "2px solid gray",
      borderBottomColor: 'gray',
      bottom: -4,
    },
    '& .MuiInput-underline:before:hover': {
      borderBottomColor: 'gray',
    },
    '& label.Mui-focused': {
      color: '#808080',
      fontSize: '1rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.05rem',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'gray',
      bottom: -4
    },
  },
});

const textFieldClasses = useTextFieldStyle();
*/
