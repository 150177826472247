import Layout from "../../components/Layout";
import Dashboard from "../../components/Dashboard";
import DateRangePicker from "../../components/DateRangePicker";
import ProductSelector from "../../components/ProductSelector";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  switchActionForUserSettings,
  switchDealer,
} from "../../store/actions/userActions";


const PageDashboard = () => {
  const isCDK = window.location.hostname.includes('crmaiva');

  const widgets = {
    primary: [
      <DateRangePicker type="date" key="WidgetDateRange" label="Date Filter" />,
    ],
  };
  if (!isCDK) {
    widgets.secondary = [<ProductSelector key="WidgetProductSelector" />]
  }

  const dispatch = useDispatch();

  const [childAccountId, setChildAccountId] = useState(
    useSelector((state) => state.user.auth.data.user_info.DealerId)
  );

  const dashboardState = useSelector((state) => state.user.auth);
  // const {superAccountId} = useSelector((state) => state.user.auth.data.user_info);

  const auth = useSelector((state) => state.user.auth.data);
  const childAccounts =
    useSelector((state) => state.user.auth.data.user_info?.ChildAccounts) || [];

  // const switchActionChangeHandler = async (firstChildAccountId) => {
  //     await dispatch(switchDealer(firstChildAccountId));
  // };

  // useEffect(() => {
  //     if (childAccounts?.length > 0 && superAccountId) {
  //         switchActionChangeHandler(childAccounts[0].id).then(() => {
  //             setChildAccountId(childAccounts[0].id)
  //         })
  //     }
  // }, [superAccountId])

  // useEffect(async () => {
  //     if (childAccountId) {
  //         await switchActionChangeHandler(childAccountId)
  //     }
  // }, [childAccountId])

  let content = <Dashboard />;
  return <Layout content={content} widgets={widgets} />;
};

export default PageDashboard;
