import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutAction } from "../../store/actions/userActions";
import {useAuth0} from "@auth0/auth0-react";

const PageLogout = () => {
  const auth = useSelector((state) => state.user.auth.data);
  const {logout} = useAuth0()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutAction(auth))
      .catch((error) => console.log("### error", error))
      .finally(async () => {
        // window.location.reload();
        await logout({
          logoutParams: { returnTo: window.location.origin }
        })
      });
  });

  return "Logging out...";
};

export default PageLogout;
