import React, { useEffect, useState } from "react";
import cn from "classnames";

import SettingsNotificationsForm from "./SettingsNotificationsForm/SettingsNotificationsForm";
import SettingsNotificationsPerson from "./SettingsNotificationsPerson/SettingsNotificationsPerson";

import Svg from "../../Svg/Svg";
import Loader from "../../Loader/Loader";

import useData from "../../../hook/useData";

import "./SettingsNotifications.scss";
import {useDispatch, useSelector} from "react-redux";

const SettingsNotifications = ({
  campaigns = false,
  selectPerson = () => {},
  selectedPersons = new Set(),
  renderedCallback = () => {}
}) => {
  const reduxDispatch = useDispatch();
  const [state, setState] = useState(null);
  const [personEdit, setPersonEdit] = useState(null);
  const [personDelete, setPersonDelete] = useState(null);

  const { data, status, error, execute: fetchNotifications } = useData("getDealerChatNotifications", {}, []);

  const {
    data: deleteData,
    status: deleteStatus,
    execute: deleteExecute,
  } = useData("deleteDealerChatSubscriber", { subscriber_id: personDelete }, [personDelete], false);

  const { notificationPrincipals } = useSelector(
      (state) => state.setting
  );

  useEffect(() => {
    if(data?.subscribers){
      setState(data?.subscribers);

      renderedCallback();
    }  
  }, [data]);

  useEffect(() => {
    if (personDelete) deleteExecute();
  }, [personDelete]);

  useEffect(() => {
    if (deleteData?.status === "Success") fetchNotifications();
  }, [deleteData]);

  const handleSubscriptionSelect = (person) => {
    if (!campaigns) return;
    selectPerson(person.Id);
  };

  const handlePersonEdit = (uid) => {
    if (uid === personEdit) {
      setPersonEdit(null);
    } else {
      setPersonEdit(uid);
    }
  };

  const handlePersonDelete = (uid) => {
    setPersonDelete(uid);
  };

  const handleUpdate = (update, uid) => {
    setState(update?.subscribers);

    if (!uid) setPersonEdit(null);
  };

  const channelInfo = (personnelChannelInfo, channel) => {
    let channelInfo = personnelChannelInfo?.filter(ch => ch.Channel === channel)
    if (channelInfo.length > 0) {
      channelInfo = channelInfo[0]
      let subscribedNotifications = channelInfo.SubscribedNotifications
      subscribedNotifications = subscribedNotifications.map(notification => {
        let principals = notification.Principals
        if (principals < notificationPrincipals) {
          let updatedPrincipals = notificationPrincipals?.map((principal) => {
            let principalToUpdate = principals.filter(subscribedNotificationPrincipal => subscribedNotificationPrincipal.Id === principal.Id)
            if (principalToUpdate.length > 0) {
              principalToUpdate = principalToUpdate[0]
              if ("Status" in principalToUpdate) {
                return {
                  ...principal,
                  "Status": principalToUpdate["Status"]
                }
              } else {
                return {
                  ...principal,
                  "Status": true
                }
              }
            } else {
              return {
                ...principal,
                "Status": false
              }
            }
          })
          return {
            ...notification,
            "Principals": updatedPrincipals
          }
        }
        else {
          return {
            ...notification,
            "Principals": principals
          }
        }

      })
      return {
        "Channel": channel,
        "SubscribedNotifications": subscribedNotifications
      }
    }
  }

  const modifyPersonnelsData = (personnels) => {
    return personnels?.map(personnel => {
      let channels = personnel.SubscriberInformation?.Channels
      let emailChannelInfo = channelInfo(channels, "Email")
      let smsChannelInfo = channelInfo(channels, "SMS")

      let result = []
      if (emailChannelInfo) {
        result.push(emailChannelInfo)
      }
      if (smsChannelInfo) {
        result.push(smsChannelInfo)
      }

      return {
        ...personnel,
        "Channels": [
            ...result
        ]
      }
    })
  }

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    if (Array.isArray(state) === false) {
      return state?.Message || "Error";
    }

    // console.log("state is: ", state)
    // console.log("modifyPersonnelsData: ", modifyPersonnelsData(state))

    const personnel = modifyPersonnelsData(state)?.map((person) => {
      const el = (
        <React.Fragment key={person.UserId}>
          <li
            className={cn("SettingsNotifications-personsItem", {
              "SettingsNotifications-personsItem--processing":
                person.UserId === personDelete,
              "SettingsNotifications-personsItem--selectable": campaigns,
            })}
            onClick={() => handleSubscriptionSelect(person)}
          >
            <SettingsNotificationsPerson
              data={{ Id: person.Id, ...person?.SubscriberInformation}}
              handlePersonEdit={handlePersonEdit}
              handlePersonDelete={handlePersonDelete}
              selected={selectedPersons.has(person.Id)}
              campaigns={campaigns}
            />
          </li>

          {personEdit === person.Id && ( 
            <SettingsNotificationsForm
              data={{ Id: person.Id, ...person?.SubscriberInformation}}
              onUpdate={handleUpdate}
              type="Update"
              campaigns={campaigns}
            />
          )}
        </React.Fragment>
      );

      return el;
    });

    return personnel;
  };

  return (
    <div className="SettingsNotifications">
      <div className="SettingsNotifications-add">
        <button
          className="SettingsNotifications-addToggler"
          onClick={() => handlePersonEdit(-1)}
        >
          <div className="SettingsNotifications-addTogglerLabel">
            Add Personnel
          </div>
          <div className="SettingsNotifications-addTogglerIcon">
            <Svg w="9" h="6" i="markerDropdown" />
          </div>
        </button>
        {personEdit === -1 && (
          <SettingsNotificationsForm
            onUpdate={handleUpdate}
            type="Add"
            campaigns={campaigns}
          />
        )}
      </div>
      <div className="SettingsNotifications-persons">
        <h3 className="SettingsNotifications-personsHeading">
          Personnel in Database
        </h3>
        <ul className="SettingsNotifications-personsList">{render()}</ul>
      </div>
    </div>
  );
};

export default SettingsNotifications;
