import { red } from "@material-ui/core/colors";
import React, { createRef, useState } from "react";
import useData from "../../../../../hook/useData";
import FormElement from "../../../../FormElement";
import Loader from "../../../../Loader/Loader";
import ReorderAnimation from "../../../../ReorderAnimation";

import "./index.scss";

const compare = (a, b) => {
  if (+a.Interval.Value < +b.Interval.Value) {
    return -1;
  }
  if (+a.Interval.Value > +b.Interval.Value) {
    return 1;
  }
  return 0;
};

const MAX_TEXT_LENGTH = 150;

// const dummyAnswers = {
//   "Template:EmailObject:1": `Hi $ {NAME},

// Thank you for purchasing the $ {CARYEAR} $ {CARMAKE} $ {CARMODEL} from $ {DEALERNAME}.

// It has been a while since you drove your car off the lot. We recommend that you visit our service center for its first scheduled maintenance.

// If you would like to schedule a service appointment, click HERE.

// You may also just reply with your phone number and our Service Team Members will contact you.`,

//   "Template:EmailObject:2": `Hi $ {NAME},

// Just a quick follow up on my first email. I wanted to check if you would like to have your first service set up.

// If you would like to schedule a service appointment on your own, click HERE.

// If you want to simply reply with your phone number, one of our Service Team Members will contact you.`,

//   "Template:EmailObject:3": `Hey $ {NAME},

// I have been trying to contact you, but haven't heard from you since you bought the $ {CARYEAR} $ {CARMAKE} $ {CARMODEL} at $ {DEALERNAME}. We want to remind you to have your first service completed. Remember, we will provide you with a full circle video inspection during your visit.

// If you would like to schedule a service appointment right now, click HERE.

// Feel free to reply with your phone number and our Service Team will contact you as soon as possible.`,
// };

// const getInputLabel = (count) => `${count} ${count > 1 ? "days" : "day"}`;

function ordinal_suffix_of(i) {
  let j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

const SequenceCard = ({ data, handleChange, index }) => {
  const [showMore, setShowMore] = useState(false);

  const { data: emailObject, status } = useData(
    "getEmailObject",
    { template_id: data.ResourceName },
    []
  );

  if (status === "init" || status === "pending" || status === "error") {
    return <Loader />;
  }

  if (!emailObject?.Template?.Text) {
    return null;
  }

  const text = emailObject?.Template?.Text;
  // const text = dummyAnswers[data.ResourceName];

  let textToShow = text;
  if (text.length > MAX_TEXT_LENGTH) {
    textToShow = showMore ? text : text.substr(0, MAX_TEXT_LENGTH) + "...";
  }
  let value = +data.Value;
  value =
    value.toString().length > 1
      ? value.toString().replace(/^0+/, "")
      : value.toString();

  return (
    <div className="SequenceCard">
      <div className="SequenceCard-Section-Small">
        <FormElement type="checkbox" checked={true} />
      </div>
      <div className="SequenceCard-Section-Large">
        <div className="SequenceCard-Header">
          <p>{ordinal_suffix_of(index)} Message</p>
        </div>
        <div className="SequenceCard-sequence">
          Message for day
          <div className="SequenceCard-input">
            <input
              // min={0}
              // type="number"
              style={{ width: "2.5vw" }}
              value={value}
              onChange={(event) => handleChange(event.target.value)}
            />
          </div>
        </div>
        <div className="SequenceCard-answer">{textToShow}</div>
        {text.length > MAX_TEXT_LENGTH && (
          <div className="SequenceCard-answer-showMore">
            <span onClick={() => setShowMore((prevState) => !prevState)}>
              {showMore ? "show less" : "show more"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const SequenceTemplateEditor = ({ sequences, setSequences }) => {
  const handleSequenceChange = (idx, newValue) => {
    let newValStr = newValue;
    const newVal = +newValStr;

    if (isNaN(newVal)) return;

    if (newVal < 0) return;

    let updatedSequences = JSON.parse(JSON.stringify(sequences));
    updatedSequences[idx]["Interval"]["Value"] = String(newVal);

    const intervals = updatedSequences.map((seq) => +seq["Interval"]["Value"]);

    intervals.sort((a, b) => a - b);

    const highest = intervals[intervals.length - 1];

    if (highest > 20) return;

    setSequences(updatedSequences);
  };

  const sequencesToRender = sequences.map((i) => ({
    ResourceName: i.ResourceName,
    Value: i.Interval.Value,
  }));

  let errorMessageText =
    "Error: message order cannot be changed. Please ensure the date inputs for the 1st and 2nd messages are before the 3rd message and try again.";

  let showErrorMessage = false;
  for (let i = 0; i < sequencesToRender.length - 1; i++) {
    if (+sequencesToRender[i].Value >= +sequencesToRender[i + 1].Value) {
      if (i === 0) {
        errorMessageText =
          "Error: message order cannot be changed. Please ensure the date inputs for the 2nd and 3rd messages are after the 1st message and try again.";
      }
      showErrorMessage = true;
      break;
    }
  }

  const emailOutreachingMessageText =
    "By default, the initial message will be sent on Day 1, and follow-up messages will be sent on Day 3, and Day 10. However, you can set custom email outreach intervals to suit your preferences below. Please note: messages cannot extend past 20 days, and message order cannot be changed.";

  return (
    <div className="SequenceTemplateEditor-Container">
      <div className="SequenceTemplateEditor-Sequences-Container">
        <span className="SequenceTemplateEditor-BlockTitle">
          Email Outreach Settings
        </span>
        <div className="SequenceTemplateEditor-Sequences">
          <p className="SequenceTemplateEditor-Description">
            {emailOutreachingMessageText}
          </p>
        </div>
        <div className="SequenceTemplateEditor-Sequences">
          <div className="SequenceTemplateEditor-Error">
            {showErrorMessage ? (
              <h1 className="SequenceTemplateEditor-Text">
                {errorMessageText}
              </h1>
            ) : null}
          </div>
          <div>
            {sequencesToRender.map((sequence, idx) => (
              <div ref={createRef()} key={sequence.ResourceName}>
                <SequenceCard
                  data={sequence}
                  handleChange={(newValue) =>
                    handleSequenceChange(idx, newValue)
                  }
                  index={idx + 1}
                  // count={idx + 1}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SequenceTemplateEditor);
