import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import FormSelect from "../../components/FormElement";

import { chooseConversationsEndpoint, chooseConversationsMetricType, chooseConversationsSubMetricType } from "../../store/actions/conversationActions";

import widgetItemsLeadEngage from "./widgetItems";
import widgetItemsCampaigns from "./widgetItemsCampaigns";
import widgetItemsFI from "./widgetItemsFI";
import widgetItemsFandI from "./widgetItemsFandI";
import MDropDown from "../FormElement/MultiLevelDropDown/MDropDown";
import useData from "../../hook/useData";
import MDropDownDealerChat from "../FormElement/MultiLevelDropDownDealerChat/MDropDown";

const widgetMap = {
  SALES_AI: widgetItemsLeadEngage,
  SERVICE_AI: widgetItemsCampaigns,
  SALESCAMPAIGNS: widgetItemsFI,
  FANDI: widgetItemsFandI,
  CHAT_AI: widgetItemsLeadEngage
};

const WidgetSource = () => {
  const productId = useSelector( (state) => state?.user?.auth?.selectedProduct?.Category);
  
  const { conversationsMetric, conversationsSubMetric } = useSelector((state) => (productId === "SALES_AI" || productId === "CARBUYING_AI") ? (productId === "SALES_AI" || productId === "CARBUYING_AI") ? state.conversation : state.conversationDealerChat : state.conversationDealerChat );
  
  const widgetItems = (productId === "SALES_AI" || productId === "CARBUYING_AI") ? widgetMap["SALES_AI"] : widgetMap["CHAT_AI"];

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  let { conversationsEndpoint: id, conversationsSource } = useSelector(
    (state) => (productId === "SALES_AI" || productId === "CARBUYING_AI") ? state.conversation : state.conversationDealerChat
  );

  if (widgetItems[0].id === 'newProcessedLeads') {
    id = "newProcessedLeads"
  }

  const handleChange = (value, type) => {
    if(type === 'sub-metric'){
      dispatch(chooseConversationsEndpoint(`conversations_${value?.parent}`)); 
      dispatch(chooseConversationsMetricType(value?.parent)); 
      dispatch(chooseConversationsSubMetricType(value?.metric));
    }else if(type === 'metric'){
      dispatch(chooseConversationsEndpoint(`conversations_${value?.metric}`)); 
      dispatch(chooseConversationsMetricType(value?.metric)); 
      dispatch(chooseConversationsSubMetricType(""));
    }
    if (pathname.indexOf("/conversation/") > -1) {
      history.push("/conversation/");
    }
  };
  // const handleChange = (value, type) => {
  //   const isDisabled = widgetItems.find((item) => item.id === value.id)?.isDisabled;

  //   if (!isDisabled) {
  //     dispatch(chooseConversationsEndpoint(value.id));
  //     if (pathname.indexOf("/conversation/") > -1) {
  //       history.push("/conversation/");
  //     }
  //   }
  // };

  const {
    data: widgetItems_data,
    status: widgetItems_status,
    error: widgetItems_error,
    statusCode: widgetItems_statusCode,
  } = useData( "filter_metrics");

  useEffect(() => {
    const item = widgetItems.find((item) => item.id === "newBySourceLeads");

    if (!item) return;

    item.isDisabled = !conversationsSource;
  }, [conversationsSource]);

  let defaultValueIdx = widgetItems.findIndex((element) => {
    return element.id === id;
  });

  if (defaultValueIdx === -1) {
    defaultValueIdx = 0
  }

  const defaultValue = widgetItems[defaultValueIdx];

  if(productId === "SALESCAMPAIGNS"){
    return (
      <FormSelect
        type="select"
        label="Showing"
        values={widgetItems}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
    );
  }

  if(productId === "CHAT_AI"){
    return (
      <MDropDownDealerChat 
        type="select"
        label="Showing"
        listOptions={widgetItems_data || []}
        defaultValue={widgetItems_data?.find(el => el.metric === conversationsMetric)}
        onChange={handleChange}
        error={widgetItems_error}
        status={widgetItems_status}
        statusCode={widgetItems_statusCode}
      />
    );
  }

  return (
    (productId === "SALES_AI" || productId === "CARBUYING_AI") && <MDropDown 
      type="select"
      label="Showing"
      listOptions={widgetItems_data || []}
      defaultValue={widgetItems_data?.find(el => el.metric === conversationsMetric)}
      onChange={handleChange}
      error={widgetItems_error}
      status={widgetItems_status}
      statusCode={widgetItems_statusCode}
    />
  );
};

export default WidgetSource;
