// import CampaignTypes from "./CampaignTypes";
import CampaignCategories from "./CampaignCategories/CampaignCategories";
import CampaignSequences from "./CampaignSequences/CampaignSequences";
import SequenceConfiguration from "./SequenceConfiguration/SequenceConfiguration";

export default [
  // {
  //   index: 0,
  //   label: "Campaign Types",
  //   component: <CampaignTypes />,
  //   title: "Which type of campaign would you like to start?",
  //   formKey: "Type",
  // },
  {
    index: 1,
    label: "Communication Types",
    component: <CampaignCategories />,
    title: "Please choose campaign category",
    formKey: "Category",
  },
  {
    index: 2,
    label: "Communication Sequence",
    component: <CampaignSequences />,
    title: "Select sequence",
    formKey: "Sequence",
  },
  {
    index: 3,
    label: "Sequence Configuration",
    component: <SequenceConfiguration />,
    title: "Finishing campaign setup",
    formKey: "Config",
  },
];
