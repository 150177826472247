import { useContext, useEffect, useState } from "react";
import useData from "../../../../../hook/useData";
import FormElement from "../../../../FormElement/FormElement";
import { StoreContextSettingsCMSPage } from "../../SettingsCMS";



const TopicForm = () => {
    const { stateCMS, dispatchCMS } = useContext(StoreContextSettingsCMSPage);

    const [topicName, setTopicName] = useState("");
    const [state, setState] = useState({
        save_information: {
            topic_name : "",
        },
        key: "topic"
    });

    const {
        data: updatedData,
        status: updateStatus,
        execute: updateExecute,
    } = useData("addQuestionOrTopic", state, [state], false);

    const questionHandler = (value) => {
        setTopicName(value);
        setState({
            save_information: {
                topic_name : value,
            },
        key: "topic"
        })
    }
    
    useEffect(() => {
        if(updatedData?.save_information?.topic_id){
          dispatchCMS({ type: "addTopic", topicId: updatedData?.save_information?.topic_id, value: topicName });
          dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})
        }
    },[updatedData])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        updateExecute();
    };

    return(
        <form className="SettingsCMS-ModalBlok" onSubmit={handleFormSubmit}>
            <p>Add New Topic</p>
            <div>
            <FormElement
                type="textfield"
                label="TOPIC NAME:"
                id="topic"
                name="topic"
                value={topicName}
                onChange={(e) => questionHandler(e.target.value)}
            /> 
            </div>
            <div style={{ marginTop: "40px" }}>
            <FormElement
                type="submit"
                label={`ADD TOPIC`}
                disabled={!topicName}
            />
            </div>
            <button type="button" className="SettingsCMS-ModalCloseBtn" onClick={() => dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g id="X" transform="translate(-952 -256)">
                    <circle id="Ellipse_150" dataName="Ellipse 150" cx="16" cy="16" r="16" transform="translate(952 256)" fill="#f7f7f7"/>
                    <g id="Group_1501" dataName="Group 1501" transform="translate(-16.5 -1074.5)">
                        <line id="Line_155" dataName="Line 155" x2="10" y2="10" transform="translate(979.5 1341.5)" fill="none" stroke="#000" strokeWidth="1.5"/>
                        <line id="Line_156" dataName="Line 156" y1="10" x2="10" transform="translate(979.5 1341.5)" fill="none" stroke="#000" strokeWidth="1.5"/>
                    </g>
                    </g>
                </svg>
            </button>
        </form>
    )
}

export default TopicForm;