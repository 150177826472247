import "./CarCard.scss";


const CarCard = ({ list }) => {

    return(
        <div className="ChatsDialogMessage-Card_Box">
         {
            !!list.length && list.map(el => (
                <div className="ChatsDialogMessage-Card_Container">
                    <div className="ChatsDialogMessage-Card_Container ImageSection">
                        <img src={el?.Links || "/"} alt={el?.Data?.Vin || "Car" } />
                    </div>
                    <div className="ChatsDialogMessage-Card_Container TextSection">
                        <p>Vin: {el?.Data?.Vin}</p>
                        <p>Stock #: {el?.Data?.Stock}</p>
                        <p><a>{el?.Data?.Name}</a></p>
                        <p style={{ fontWeight: "bold" }}>{el?.Data?.Price}</p>
                    </div>
                </div>
            ))
         }
        </div>
    )
}

export default CarCard;