import { useState } from "react";
import cn from "classnames";

import "./FormRadio.scss";

const FormRadio = ({
  label,
  labelHidden = false,
  checked = false,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleToggleFocused = () => {
    return setIsFocused((oldState) => !oldState);
  };

  return (
    <div
      className={cn("FormRadio", {
        "FormRadio--checked": checked,
        "FormRadio--focused": isFocused,
      })}
    >
      <label className="FormRadio-field">
        <input
          className="FormRadio-input"
          type="radio"
          checked={checked}
          onFocus={handleToggleFocused}
          onBlur={handleToggleFocused}
          onChange={() => onChange(rest.value)}
          {...rest}
        />

        <span className="FormRadio-fakeInput">
          {checked && <span className="FormRadio-dot"></span>}
        </span>
        <span
          className={cn("FormRadio-label", {
            "FormRadio-label--hidden": labelHidden,
          })}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default FormRadio;
