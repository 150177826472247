import Url from "url";

import getUrlWithParamsConfig from "./getUrlWithParamsConfig";

async function req(endpoint, query, token) {
  const { method, uri, body } = getUrlWithParamsConfig(endpoint, query);
  const options = {
    method,
  };

  if (Object.keys(body).length > 0) {
    options.body = JSON.stringify(body);
    options.headers = {
      "Content-Type": "application/json",
    };
  }

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  try {
    // if (
    //   endpoint === "getAccountWorkingHours" ||
    //   endpoint === "updateAccountWorkingHours"
    // ) {
    //   return {
    //     WorkingHours: {
    //       Monday: {
    //         Start: "10:00",
    //         End: "11:00",
    //         Workday: true,
    //       },
    //       Thursday: {
    //         Start: "18:00",
    //         End: "20:00",
    //         Workday: true,
    //       },
    //       Friday: {
    //         Start: "12:00",
    //         End: "13:30",
    //         Workday: true,
    //       },
    //       Sunday: {
    //         Start: null,
    //         End: null,
    //         Workday: false,
    //       },
    //       Wednesday: {
    //         Start: "09:00",
    //         End: "08:10",
    //         Workday: true,
    //       },
    //       Tuesday: {
    //         Start: "09:00",
    //         End: "20:00",
    //         Workday: true,
    //       },
    //       Saturday: {
    //         Start: "16:00",
    //         End: "17:00",
    //         Workday: true,
    //       },
    //     },
    //     UtcOffset: "-07",
    //     SpecificDates: [
    //       {
    //         TimeRange: {
    //           Start: "12:00",
    //           End: "13:30",
    //         },
    //         Closed: false,
    //         Date: {
    //           Year: "2021",
    //           Month: "11",
    //           Day: "27",
    //         },
    //       },
    //       {
    //         TimeRange: {
    //           Start: "12:00",
    //           End: "13:30",
    //         },
    //         Closed: false,
    //         Date: {
    //           Year: "2022",
    //           Month: "12",
    //           Day: "20",
    //         },
    //       },
    //       {
    //         TimeRange: {
    //           Start: "12:00",
    //           End: "13:30",
    //         },
    //         Closed: false,
    //         Date: {
    //           Year: "2023",
    //           Month: "12",
    //           Day: "10",
    //         },
    //       },
    //     ],
    //   };
    // }

    console.log("PAYLOAD", options);
    let url = `${uri.protocol}://${uri.host}/${uri.pathname}`;

    if (Object.keys(uri.query).length > 0) {
      const queryParams = new URLSearchParams(uri.query);
      url += `?${queryParams.toString()}`;
    }
    const response = await fetch(url, options);
    //console.log("Link", Url.format(uri), options)
    const result = await response.json();
    return { data: result, status: response.status };
  } catch (error) {
    console.log("_______________MY#ERROR_______________");
    console.log(error);
    throw new Error(`Can't fetch data: ${error}`);
  }
}

export default req;
