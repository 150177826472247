import {
  CHOOSE_START_DATE,
  CHOOSE_END_DATE,
  CHOOSE_DATES,
} from "../actionTypes/dateTypes";

import {
  addDays,
  startOfDay,
  endOfDay,
  startOfMonth,
  addMonths,
} from "date-fns";

const initialState = {
  // startDate: startOfDay(addDays(new Date(), -6)),
  // endDate: endOfDay(new Date()),
  startDate: startOfDay(addDays(new Date(), -29)),
  endDate: endOfDay(new Date()),
};

const date = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case CHOOSE_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };

    case CHOOSE_DATES:
      return {
        ...state,
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
      };

    default:
      return state;
  }
};

export default date;
