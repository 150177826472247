import "./Svg.scss";

import svg from "../../assets/img/vector.svg";

const Svg = ({ w, h, i }) => {
  return (
    <svg className="Svg" width={w} height={h}>
      <use xlinkHref={`${svg}#v-${i}`} />
    </svg>
  );
};

export default Svg;
