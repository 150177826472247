const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Artwork"
      version="1.1"
      viewBox="0 0 991.03 215"
    >
      <defs>
        <style>
          {`
                        .cls-1, .cls-2, .cls-3, .cls-4 { stroke-width: 0px; }
                        .cls-5 { isolation: isolate; }
                        .cls-2 { fill: #410099; }
                        .cls-3 { fill: #6615d6; }
                        .cls-4 { fill: #fff; }
                    `}
        </style>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M302,177.5h-22V14h22v163.5ZM422.5,71c-8.45-14.3-21-21.5-40.5-21.5-11.5,0-22.06,3.7-31,14l.14-12h-22l-.14,126h22v-77.76c0-15.51,11-28,30-28,22,0,29.5,17,29.5,34v71.76h22v-71.96c0-16.8,5.5-34,29.5-34,22.37,0,29,16,29,33v72.96h22v-76.76c0-16.71-9.75-51.2-48.77-51.2-34.19,0-42.23,21.46-42.23,21.46M859.93,159.5c-8.99,0-11.93-1.79-11.93-12V8h-22v143.5c0,28,21.44,27.99,28.46,27.99s9.54-1.36,9.54-1.36v-18.63h-4.07ZM600.16,70.5c-22.56,0-42.66,19.7-42.66,44,0,22.81,20.1,44,42.66,44s42.66-19.7,42.66-44-19.1-44-42.66-44ZM665,114.5c0,35.9-28.65,65-64,65-16.07,0-30.76-6.02-42-15.96v51.46h-22V51.5h22v14.06c11.24-9.94,25.93-16.06,42-16.06,35.35,0,64,29.1,64,65ZM792.27,124h-91.41c4.28,19.66,22.55,34.38,43.24,34.5,15.6,0,29.85-6.35,38.02-18.79l17.09,11.86c-12.72,17.51-34.15,27.93-56.21,27.93-35.62,0-64.5-29.1-64.5-65s28.88-65,64.5-65,64.5,29.1,64.5,65c0,3.23-.24,6.4-.69,9.5h-14.54ZM785.47,103.5c-4.83-18.98-21.86-33-42.13-33s-37.3,14.02-42.13,33h84.26Z"
        ></path>
        <g>
          <path
            className="cls-2"
            d="M91.41,0h-36.57L0,95l54.85,95h36.57l54.85-95L91.41,0ZM73.13,158.33l-36.57-63.33,36.57-63.33,36.57,63.33-36.57,63.33Z"
          ></path>
          <path
            className="cls-3"
            d="M219.39,95l-54.85,95h-36.57l54.85-95L127.98,0h36.57l54.85,95h0ZM0,95h36.57L91.41,0h-36.57L0,95ZM54.85,190h36.57l54.85-95h-36.57s-54.85,95-54.85,95Z"
          ></path>
        </g>
      </g>
      <rect
        className="cls-1"
        x="876.21"
        y="9.23"
        width="114.83"
        height="80.54"
        rx="12.23"
        ry="12.23"
      ></rect>
      <g className="cls-5">
        <g className="cls-5">
          <path
            className="cls-4"
            d="M918.27,22.74h9.15l20.25,53.25h-10.65l-4.95-13.28h-18.6l-4.88,13.28h-10.65l20.33-53.25ZM930.2,55.44l-7.35-20.92-7.65,20.92h15Z"
          ></path>
          <path
            className="cls-4"
            d="M953.52,75.99V22.74h10.35v53.25h-10.35Z"
          ></path>
        </g>
      </g>
      <path className="cls-4" d="M-327.07-1020.92"></path>
      <path className="cls-4" d="M3595.21,231.17"></path>
      <path className="cls-4" d="M3595.21-394.34"></path>
      <path className="cls-4" d="M3595.21-1025.3"></path>
    </svg>
  );
};

export default Logo;
