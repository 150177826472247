export default [
  {
    id: "engagedLeads",
    label: "Engaged Leads",
  },
  {
    id: "newProcessedLeads",
    label: "Customers Messaged",
  },
  {
    id: "acquiredPhoneNumbersLeads",
    label: "Acquired Phone Numbers",
  },
  {
    id: "ctaConversions",
    label: "Cta Conversions",
  },
  {
    id: "confirmedPurchases",
    label: "Confirmed Purchases",
  },
  {
    id: "businessOfficeHandOffs",
    label: "Business Office Hand-Offs",
  },

];
