import Svg from "../../../Svg";

import "./SettingsNotificationsPerson.scss";
import cn from "classnames";

const SettingsNotificationsPerson = ({
  data,
  handlePersonEdit,
  handlePersonDelete,
  readOnly,
  selected,
  campaigns = false,
}) => {
  const uid = data.Id;

  return (
    <div
      className={cn("SettingsNotificationsPerson", {
        "SettingsNotificationsPerson--selected": selected,
      })}
    >
      <div className="SettingsNotificationsPerson-heading">
        <div className="SettingsNotificationsPerson-title">{`${
          data.FirstName || ""
        } ${data.LastName || ""}`}</div>
        <div className="SettingsNotificationsPerson-actions">
          <button
            type="button"
            className="SettingsNotificationsPerson-action SettingsNotificationsPerson-action--edit"
            onClick={(e) => {
              e.stopPropagation();
              handlePersonEdit(uid);
            }}
          >
            {readOnly ? "View" : "Edit"}
          </button>
          {!readOnly && (
            <button
              type="button"
              className="SettingsNotificationsPerson-action SettingsNotificationsPerson-action--delete"
              onClick={(e) => {
                e.stopPropagation();
                handlePersonDelete(uid);
              }}
            >
              <Svg w="18" h="18" i="garbage" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsNotificationsPerson;
