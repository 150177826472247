import cn from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { chooseConversationsEndpoint, chooseConversationsMetricType, chooseConversationsSubMetricType } from "../../store/actions/conversationActions";

import "./ProgressBar.scss";


const ProgressBar = ({ leftTitle, leftValue, leftRate, leftRedirect, rightTitle, rightValue, rightRate, rightRedirect }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTileClick = (redirectObj) => {
    dispatch(chooseConversationsEndpoint(redirectObj?.endpoint)); 
    dispatch(chooseConversationsMetricType(redirectObj?.metric)); 
    dispatch(chooseConversationsSubMetricType(redirectObj?.sub_metric)); 
    history.push("conversations");
  };

  if(leftValue <= 0 && rightValue <= 0){
    return(
      <div className="progressBarContainer">
        <div className="header">
          <p>{leftTitle}</p>
          <p>{rightTitle}</p>
        </div>
        <div className="bar">
          <div className="barError" style={{ width: "100%" }}></div>
        </div>
        <div className="value">
          {
            leftValue === 0 ?
            <p><span className={`${leftRedirect && "redirectLink"}`} onClick={() => leftRedirect && handleTileClick(leftRedirect)}>{leftValue}</span>{`(${leftRate}%)`}</p>
            :
            <p><span>&mdash;</span></p>
          }
          {
            rightValue === 0 ?
            <p><span className={`${rightRedirect && "redirectLink"}`} onClick={() => rightRedirect && handleTileClick(rightRedirect)}>{rightValue}</span>{`(${rightRate}%)` }</p>
            :
            <p><span>&mdash;</span></p>
          }
        </div>
      </div>
    )
  }

  return (
    <div className="progressBarContainer">
      <div className="header">
        <p>{leftTitle}</p>
        <p>{rightTitle}</p>
      </div>
      <div className="bar">
        <div style={{ width: `${leftRate != 0 ? leftRate : 2}%` }}></div>
        <div style={{ width: `${rightRate != 0 ? rightRate : 2}%` }}></div>
      </div>
      <div className="value">
        <p><span className={`${leftRedirect && "redirectLink"}`} onClick={() => leftRedirect && handleTileClick(leftRedirect)}>{leftValue}</span>{`(${leftRate}%)`}</p>
        <p><span className={`${rightRedirect && "redirectLink"}`} onClick={() => rightRedirect && handleTileClick(rightRedirect)}>{rightValue}</span>{`(${rightRate}%)` }</p>
      </div>
    </div>
  );
};

export default ProgressBar;
