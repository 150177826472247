import React, { useState } from "react";

import "./index.scss";
import { useHistory } from "react-router-dom";
import Check from "@material-ui/icons/Check";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepConnector from "@material-ui/core/StepConnector";
import PropTypes from "prop-types";
import clsx from "clsx";

import campaignsFlowStructure from "./campaignsFlowStructure";
import useData from "../../../hook/useData";
import { useEffect } from "react";
import Loader from "../../Loader/Loader";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#1f67cc",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#1f67cc",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#1f67cc",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#1f67cc",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    margin: "0 1vw",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    fontSize: "1.4vh",
  },
}));

const CampaignsFlow = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [form, setForm] = useState({
    Config: {},
    Type: { Id: "Campaigns:Type:SalesCampaign", Name: "Sales Campaign" },
    Category: {
      Id: "Campaigns:SalesCampaign:Category:FI",
      Name: "Sales Campaign",
    },
    // Sequence: {
    //   Id: "Campaigns:SalesCampaign:FI:bulldog-kia:FI",
    //   Name: "Sales Campaign",
    // },
  });

  const pathParams = () =>
    form.Type &&
    form.Category &&
    form.Sequence && {
      campaign_type: form["Type"]["Id"],
      campaign_category: form["Category"]["Id"],
      campaign_sequence: form["Config"]["Name"],
    };

  const {
    // data: createData,
    // error: createError,
    status: createStatus,
    execute: createCampaignConfiguration,
  } = useData(
    "createCampaignConfiguration",
    {
      ...pathParams(),
      ...form,
    },
    [form],
    false
  );

  const { data: campaignSequences, status, execute: refetch } = useData(
    "getCampaignSequences",
    {
      campaign_type: form["Type"]["Id"],
      campaign_category: form["Category"]["Id"],
    },
    []
  );

  useEffect(() => {
    if (campaignSequences && campaignSequences.length === 1) {
      const updatedForm = { ...form };
      updatedForm["Sequence"] = {
        ...updatedForm["Sequence"],
        ...campaignSequences[0],
      };
      updatedForm["Config"] = {
        ...updatedForm["Config"],
        ...campaignSequences[0],
      };
      setForm(updatedForm);
    }
  }, [campaignSequences]);

  console.log("campaignSequences", campaignSequences);

  useEffect(() => {
    if (createStatus === "success") {
      history.push("/");
      return setError(null);
    }
    if (createStatus === "init") {
      return setError(null);
    }
    if (createStatus === "pending") {
      return setError(null);
    }
    if (createStatus === "error") {
      return setError("Error occurred during saving configuration of campaign");
    }
  }, [createStatus]);

  const steps = campaignsFlowStructure;

  const handleNext = () => {
    setError(null);

    // Final step finish
    if (activeStep === steps.length - 1) {
      createCampaignConfiguration();
      return;
    }

    // Just next
    setFormCompleted(false);
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setError(null);

    const updatedForm = { ...form };
    const toDelete = campaignsFlowStructure.slice(activeStep);
    const keysToDelete = toDelete.map((item) => item.formKey);
    for (const key of keysToDelete) {
      delete updatedForm[key];
    }
    updatedForm["Config"] = {};
    setForm(updatedForm);
    setFormCompleted(false);
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => setActiveStep(0);

  const getFlowAttribute = (stepIndex, attribute) =>
    steps[stepIndex][attribute];

  const ContentElement = getFlowAttribute(activeStep, "component");

  const formKey = getFlowAttribute(activeStep, "formKey");

  const formValue = form[formKey];

  const handleFormChange = (value, key, override = false) => {
    const updatedForm = { ...form };
    if (override) {
      updatedForm[key] = { ...value };
    } else {
      updatedForm[key] = { ...updatedForm[key], ...value };
    }
    setForm(updatedForm);
  };

  const isSequenceValid = (form) => {
    const sequences = form?.Config?.Sequence || [];
    let valid = true;
    for (let i = 0; i < sequences.length - 1; i++) {
      if (+sequences[i].Interval.Value >= +sequences[i + 1].Interval.Value) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const isActionButtonDisabled = () => {
    if (activeStep === steps.length - 1) {
      // if (!form?.Config?.IsTemplate) {
      //   return true;
      // }

      if (!isSequenceValid(form)) {
        return true;
      }


      if (form?.Config?.Resource?.length != 2) {
        return true;
      }

      if (form?.Config?.Template?.Editable === false) {
        return true;
      }

      if (
        !form?.Config?.numDays &&
        form?.Sequence?.ConditionGroupId === "GeneralFirstService"
      ) {
        return true;
      }


      if (
        !form?.Config?.MaxCampaignDailyQuota &&
        "MaxCampaignDailyQuota" in form?.Sequence &&
        form?.Sequence?.MaxCampaignDailyQuota !== null
      ) {
        return true;
      }

      if (!form?.Config?.S3Key && form?.Sequence?.DataSource === "CSV") {
        return true;
      }

      return false;
    } else {
      return !formCompleted;
    }
  };

  console.log("<FORM>", form);

  if (status === "pending") {
    return <Loader />;
  }

  return (
    <div className="CampaignFlowRoot">
      <div className={classes.root}>
        <div className="CampaignFlowStepper">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  classes={{ label: classes.stepLabel }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="CampaignsFlow-Container">
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div className="CampaignsFlow-Content-Wrapper">
              <Typography className={classes.instructions}>
                {React.cloneElement(ContentElement, {
                  setFormCompleted: () => setFormCompleted(true),
                  setForm: (value, override) =>
                    handleFormChange(value, formKey, override),
                  formValue: formValue,
                  form: form,
                  handleNext: () => handleNext(),
                })}
              </Typography>
              <div className="CampaignsFlow-Error">{error}</div>
              <div className="CampaignsFlow-Actions">
                {createStatus === "pending" ? (
                  <Loader />
                ) : (
                  <>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      size="large"
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={isActionButtonDisabled()}
                      className={classes.button}
                      size="large"
                    >
                      {activeStep === steps.length - 1 ? "Start" : "Next"}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignsFlow;
