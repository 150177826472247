const AppointmentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M14.1667 2.49992H17.5001C17.7211 2.49992 17.9331 2.58772 18.0893 2.744C18.2456 2.90028 18.3334 3.11224 18.3334 3.33325V16.6666C18.3334 16.8876 18.2456 17.0996 18.0893 17.2558C17.9331 17.4121 17.7211 17.4999 17.5001 17.4999H2.50008C2.27907 17.4999 2.06711 17.4121 1.91083 17.2558C1.75455 17.0996 1.66675 16.8876 1.66675 16.6666V3.33325C1.66675 3.11224 1.75455 2.90028 1.91083 2.744C2.06711 2.58772 2.27907 2.49992 2.50008 2.49992H5.83342V0.833252H7.50008V2.49992H12.5001V0.833252H14.1667V2.49992ZM3.33341 7.49992V15.8333H16.6667V7.49992H3.33341ZM5.00008 10.8333H9.16675V14.1666H5.00008V10.8333Z"
        fill="#AFB8BA"
      />
    </g>
  </svg>
);

export default AppointmentIcon;
