// const currentDate = new Date();

const specificDatePlaceholder = {
  TimeRange: {
    Start: null,
    End: null,
  },
  Closed: false,
  Date: {
    // Year: currentDate.getFullYear().toString(),
    Year: "",
    Month: "",
    Day: "",
  },
};

export default function reducer(state, action) {
  // console.log("### Reducer:", state, action);

  switch (action.type) {
    case "changeTimezone":
      return { ...state, UtcOffset: action.payload };

    case "overwrite":
      return action.payload;

    case "updateAvailableTimes": {
      const { defaultBranch, day, key, value } = action.payload;

      const res = {};

      if (!state || !state.WorkingHours) {
        Object.assign(res, defaultBranch);
      } else {
        Object.assign(res, state.WorkingHours);
      }

      res[day][key] = value;

      return { ...state, ...{ WorkingHours: res } };
    }

    case "addSpecificDate": {
      const { defaultBranch } = action.payload;

      let res = [...defaultBranch];

      // if (!state || !state.SpecificDates) {
      //   res = [...defaultBranch];
      // } else {
      //   res = [...state.SpecificDates];
      // }

      // If last item is empty don't add a new one
      if (res.length && res[res.length - 1] && !res[res.length - 1].Date.Day) {
        return state;
      }

      res.push({ ...specificDatePlaceholder });

      return { ...state, SpecificDates: res };
    }

    case "updateSpecificDate": {
      const { defaultBranch, idx, path, value } = action.payload;
      if (idx <= defaultBranch.length) {
        if (path === "Date") {
          defaultBranch[idx].Date = value
        } else if (path === "TimeRange.Start") {
          defaultBranch[idx].TimeRange.Start = value
        } else if (path === "TimeRange.End") {
          defaultBranch[idx].TimeRange.End = value
        } else if (path === "Closed") {
          defaultBranch[idx].Closed = value
        }
      }

      let res = [...defaultBranch];

      // if (!state || !state.SpecificDates) {
      //   res = [...defaultBranch];
      // } else {
      //   res = [...state.SpecificDates];
      // }
      //
      // const keys = path.split(".");
      // const lastKey = keys[keys.length - 1];
      //
      // const deepestObject = keys.reduce((deepestObject, key, idx) => {
      //   if (idx === keys.length - 1 || deepestObject[key] === null)
      //     return deepestObject;
      //
      //   return deepestObject[key];
      // }, res[idx]);
      //
      // deepestObject[lastKey] = value;

      return { ...state, SpecificDates: res };
    }

    case "deleteSpecificDate": {
      const { defaultBranch, idx } = action.payload;
      let defaultB = [...defaultBranch]
      defaultB.splice(idx, 1);
       return { ...state, SpecificDates: defaultB };
    }

    case "addResourceId": {
      const { resource_id } = action.payload;
      return {
        ...state,
        resource_id,
      };
    }

    case "addResourceName": {
      const { ResourceName } = action.payload;
      return {
        ...state,
        ResourceName,
      };
    }

    default:
      console.error(`### Action with type "${action.type}" didn't found`);
      return state;
  }
}
