import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Svg from "../../Svg";
import FormElement from "../../FormElement";
import "./DeleteDialog.scss";


const DeleteDialog = ({isOpen, closeDialog, fullName, handleConfirmDelete}) => {
  return <Dialog
    aria-labelledby="dialog-title"
    open={isOpen}
  >
    <DialogTitle id="dialog-title" className="DeleteDialog-header">
      <span>
        Delete User
      </span>
      <button type="button" onClick={closeDialog} className="DeleteDialog-close">
        <Svg w="16" h="16" i="close"/>
      </button>
    </DialogTitle>
    <DialogContent>
      <p className="DeleteDialog-text">
        Are you sure you want to delete this user: <span className="DeleteDialog-nameInText">{fullName}</span>? This will permanently delete all the data associated
        with the user. The user won’t be able to log in to the Dashboard.
      </p>

      <div className="DeleteDialog-actions">
        <FormElement className="DeleteDialog-cancelButton" type="submit" onClick={closeDialog} label={'Cancel'}/>
        <FormElement type="submit" label={'Delete User'} onClick={handleConfirmDelete}/>
      </div>
    </DialogContent>
  </Dialog>
}

export default DeleteDialog;