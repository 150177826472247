import { useEffect, useReducer, useState } from "react";
import "./DealerShipSettings.scss";
import cn from "classnames";
import useData from "../../../hook/useData";
import FormElement from "../../FormElement/FormElement";
import Loader from "../../Loader/Loader";
import { dealerShipReducer } from "../../../state/reducers/DealerShip";
import { dealerShipState } from "../../../state/initState/DealerShip";

const DealerShip = ({ accountId, policy }) => {
  //const [dealerParams, setDealerParams] = useState(policy?.find((item) => item.Resource === "Settings:DealerParams") || "");

  const [formData, setFormData] = useState(null);
  const [formError, setFormError] = useState(null);

  const [defaultDateFormat, setDefaultDateFormat] = useState({
    id: "",
    label: "",
  });
  const [defaultPhoneFormat, setDefaultPhoneFormat] = useState({
    id: "",
    label: "",
  });
  const [defaultTimeFormat, setDefaultTimeFormat] = useState({
    id: "",
    label: "",
  });
  const [defaultTimeZone, setDefaultTimeZone] = useState({
    id: "",
    label: "",
  });

  const [stateDealerShip, dispatchDealerShip] = useReducer(
    dealerShipReducer,
    dealerShipState
  );

  const {
    data: dealerAllParamsData,
    status: dealerAllParamsStatus,
    error: dealerAllParamsError,
  } = useData("getDealerALLParams", { accountId }, [accountId], true);

  const {
    data: dealerParamsData,
    status: dealerParamsStatus,
    error: dealerParamsError,
  } = useData("getDealerParams", { accountId }, [accountId], true);

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData(
    "updateDealerParams",
    { ...formData, accountId },
    [formData, accountId],
    false
  );

  useEffect(() => {
    if (dealerParamsData) {
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "country",
        value: {
          id: dealerParamsData.country.id.toUpperCase(),
          label: dealerParamsData.country.name.toUpperCase(),
        },
      });
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "state",
        value: {
          id: dealerParamsData.country.state.id,
          label: dealerParamsData.country.state.name,
        },
      });
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "dateFormat",
        value: {
          id: dealerParamsData.date_format.id,
          label: dealerParamsData.date_format.name,
        },
      });
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "timeFormat",
        value: {
          id: dealerParamsData.time_format.id,
          label: dealerParamsData.time_format.name,
        },
      });
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "phoneFormat",
        value: {
          id: dealerParamsData.phone_number_format.id,
          label: dealerParamsData.phone_number_format.name,
        },
      });
      dispatchDealerShip({
        type: "changePrimitiveType",
        propertyId: "timeZone",
        value: {
          id: dealerParamsData.time_zone.id,
          label: dealerParamsData.time_zone.name,
        },
      });
    }
  }, [dealerParamsData]);

  useEffect(() => {
    setFormData({
      country_id: stateDealerShip?.country?.id,
      state_id: stateDealerShip?.state?.id,
      date_format_id: stateDealerShip?.dateFormat?.id || defaultDateFormat?.id,
      phone_number_format_id:
        stateDealerShip?.phoneFormat?.id || defaultPhoneFormat?.id,
      time_format_id: stateDealerShip?.timeFormat?.id || defaultTimeFormat?.id,
      time_zone_id: stateDealerShip?.timeZone?.id || defaultTimeZone?.id,
    });
  }, [stateDealerShip]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateExecute();
  };

  console.log(
    "!policy?.find((item) => item.Resource === \"Settings:DealerParams\")?.Allow?.includes('*'): ",
    !policy
      ?.find((item) => item.Resource === "Settings:DealerParams")
      ?.Allow?.includes("*")
  );

  const countryChangeHandler = (value) => {
    const countryItem = dealerAllParamsData?.find((el) => el?.country.id === value.id);

    const defaultDateFormatForCountry = countryItem?.date_formats.find((el) => el.default);
    const defaultPhoneFormatForCountry = countryItem?.phone_number_formats.find((el) => el.default);
    const defaultTimeFormatForCountry = countryItem?.time_formats.find((el) => el.default);
    const defaultTimeZoneForCountry = countryItem?.time_zones.find((el) => el.default);

    setDefaultDateFormat({
      id: defaultDateFormatForCountry.id,
      label: defaultDateFormatForCountry.name,
    });
    setDefaultPhoneFormat({
      id: defaultPhoneFormatForCountry.id,
      label: defaultPhoneFormatForCountry.name,
    });
    setDefaultTimeFormat({
      id: defaultTimeFormatForCountry.id,
      label: defaultTimeFormatForCountry.name,
    });
    setDefaultTimeZone({
      id: defaultTimeZoneForCountry?.id ?? "",
      label: defaultTimeZoneForCountry?.name ?? "",
    });

    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "country",
      value,
    });
    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "state",
      value: "",
    });
    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "dateFormat",
      value: "",
    });
    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "timeFormat",
      value: "",
    });
    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "phoneFormat",
      value: "",
    });
    dispatchDealerShip({
      type: "changePrimitiveType",
      propertyId: "timeZone",
      value: "",
    });
  };

  if (dealerAllParamsStatus === "error") {
    return dealerAllParamsError || "Error";
  }

  if (
    dealerAllParamsStatus === "pending" ||
    dealerAllParamsStatus === "init" ||
    dealerAllParamsData === null
  ) {
    return (
      <div className="DealerShip-content">
        <h1 className="DealerShip-heading">Dealership Settings</h1>
        <Loader />
      </div>
    );
  }

  return (
    <div className="DealerShip-content">
      <h1 className="DealerShip-heading">Dealership Settings</h1>
      <form
        className={cn("DealerShip-form", {
          "DealerShip-form--disabled": updateStatus === "pending",
        })}
        onSubmit={handleFormSubmit}
      >
        {formError && <div className="DealerShip-formErrors">{formError}</div>}
        <div className="DealerShip-formField">
          <div>
            <FormElement
              type="select"
              label="country"
              defaultValue={stateDealerShip?.country}
              values={dealerAllParamsData?.map((el) => ({
                id: el.country.id,
                label: el.country.name,
              }))}
              onChange={(v) => countryChangeHandler(v)}
              disabled={
                !policy
                  ?.find((item) => item.Resource === "Settings:DealerParams")
                  ?.Allow?.includes("*")
              }
            />
          </div>
          <div>
            <FormElement
              type="select"
              label={
                dealerAllParamsData?.find(
                  (el) => el?.country.id === stateDealerShip?.country?.id
                )?.country?.territorial_entity_name || "State"
              }
              defaultValue={stateDealerShip?.state || { id: "", label: "" }}
              values={
                dealerAllParamsData
                  ?.find(
                    (el) => el?.country.id === stateDealerShip?.country?.id
                  )
                  ?.country?.states?.map((el) => ({
                    id: el.id,
                    label: el.name,
                  })) || []
              }
              onChange={(v) =>
                dispatchDealerShip({
                  type: "changePrimitiveType",
                  propertyId: "state",
                  value: v,
                })
              }
              disabled={
                !policy
                  ?.find((item) => item.Resource === "Settings:DealerParams")
                  ?.Allow?.includes("*")
              }
            />
          </div>
          <div>
            {stateDealerShip?.country && stateDealerShip?.state && (
              <FormElement
                type="select"
                label="Date Format"
                defaultValue={
                  stateDealerShip?.dateFormat ||
                  defaultDateFormat || { id: "", label: "" }
                }
                values={
                  dealerAllParamsData
                    ?.find(
                      (el) => el?.country.id === stateDealerShip?.country?.id
                    )
                    ?.date_formats?.map((el) => ({
                      id: el.id,
                      label: el.name,
                    })) || []
                }
                onChange={(v) =>
                  dispatchDealerShip({
                    type: "changePrimitiveType",
                    propertyId: "dateFormat",
                    value: v,
                  })
                }
                disabled={
                  !policy
                    ?.find((item) => item.Resource === "Settings:DealerParams")
                    ?.Allow?.includes("*")
                }
              />
            )}
          </div>
          <div>
            {stateDealerShip?.country && stateDealerShip?.state && (
              <FormElement
                type="select"
                label="Time Format"
                defaultValue={
                  stateDealerShip?.timeFormat ||
                  defaultTimeFormat || { id: "", label: "" }
                }
                values={
                  dealerAllParamsData
                    ?.find(
                      (el) => el?.country.id === stateDealerShip?.country?.id
                    )
                    ?.time_formats?.map((el) => ({
                      id: el.id,
                      label: el.name,
                    })) || []
                }
                onChange={(v) =>
                  dispatchDealerShip({
                    type: "changePrimitiveType",
                    propertyId: "timeFormat",
                    value: v,
                  })
                }
                disabled={
                  !policy
                    ?.find((item) => item.Resource === "Settings:DealerParams")
                    ?.Allow?.includes("*")
                }
              />
            )}
          </div>
          <div>
            {stateDealerShip?.country && stateDealerShip?.state && (
              <FormElement
                type="select"
                label="Phone Number Format"
                defaultValue={
                  stateDealerShip?.phoneFormat ||
                  defaultPhoneFormat || { id: "", label: "" }
                }
                values={
                  dealerAllParamsData
                    ?.find(
                      (el) => el?.country.id === stateDealerShip?.country?.id
                    )
                    ?.phone_number_formats?.map((el) => ({
                      id: el.id,
                      label: el.name,
                    })) || []
                }
                onChange={(v) =>
                  dispatchDealerShip({
                    type: "changePrimitiveType",
                    propertyId: "phoneFormat",
                    value: v,
                  })
                }
                disabled={
                  !policy
                    ?.find((item) => item.Resource === "Settings:DealerParams")
                    ?.Allow?.includes("*")
                }
              />
            )}
          </div>
          <div>
            {stateDealerShip?.country && stateDealerShip?.state && (
              <FormElement
                type="select"
                label="Time Zone"
                defaultValue={
                  stateDealerShip?.timeZone ||
                  defaultTimeZone || { id: "", label: "" }
                }
                values={
                  dealerAllParamsData
                    ?.find(
                      (el) => el?.country.id === stateDealerShip?.country?.id
                    )
                    ?.time_zones?.map((el) => ({
                      id: el.id,
                      label: el.name,
                    })) || []
                }
                onChange={(v) =>
                  dispatchDealerShip({
                    type: "changePrimitiveType",
                    propertyId: "timeZone",
                    value: v,
                  })
                }
                disabled={
                  !policy
                    ?.find((item) => item.Resource === "Settings:DealerParams")
                    ?.Allow?.includes("*")
                }
              />
            )}
          </div>
        </div>
        {updateData && updateData.Error && (
          <div className="DealerShip__error">{updateData.Error}</div>
        )}
        <FormElement
          type="submit"
          label="Save Dealership Settings"
          id="dealershipSubmit"
          disabled={
            !policy
              ?.find((item) => item.Resource === "Settings:DealerParams")
              ?.Allow?.includes("*")
          }
        />
      </form>
    </div>
  );
};

export default DealerShip;
