import cn from "classnames";

import "./ChatsDialogMessage.scss";

import Svg from "../../Svg/Svg";

import { formatToFullDateString } from "../../../utils/formatDate";
import { useState } from "react";
import Modal from "@material-ui/core/Modal";

const ChatsDialogMessageCard = ({
  message = "",
  meta = {},
  sender = "bot",
  notification,
  appointmentSet,
  sentByCrmSalesperson,
  channel,
  justNotifications = false,
  action,
  event,
  eventName,
  payload,
  name,
  surname,
  botName,
  blacklisted,
}) => {
  const [showNotificationDialog, setShowNotificationDialog] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);

  const time = formatToFullDateString(meta.timestamp);
  if (
    eventName.includes("DEALERSHIP TAKEOVER") &&
    !eventName.includes("CONTACT FORM") &&
    !eventName.includes("APPOINTMENT FORM")
  ) {
    payload = payload.sales_person;
  }

  const getSalespersonsNotifiedLabel = (num = 0) => {
    if (num === 0) return "";
    if (num === 1) return "Salesperson was notified";
    return `${num} Salespersons were notified`;
  };

  const showNotificationRecipients = () => {
    if (notification?.Notifications?.length) {
      if (!payload) {
        setShowNotificationDialog(notification.Notifications);
      }
    } else if (event) setShowEventModal(true);
  };

  const getCorrectModalTitle = () => {
    if (eventName.includes("APPOINTMENT FORM")) {
      return "Appointment Form";
    }
    if (eventName.includes("CONTACT FORM")) {
      return "Contact Form";
    }
    return eventName.includes("DEALERSHIP TAKEOVER")
      ? "Sales Person"
      : payload?.button_title;
  };

  const renderPayload = () => {
    const keysArr =
      payload && eventName === "CTA"
        ? Object.keys(payload.form)
        : Object.keys(payload);

    return (
      <>
        {keysArr
          .filter((el) => el != "button_title")
          .map((key, index) => (
            <div key={index} className="PayloadForm">
              <p className="PayloadFormText">
                <span className="PayloadFormKey">
                  {key !== "sales_person" &&
                    key?.replace("_", " ")?.toUpperCase()}
                  {key !== "sales_person" && ":"}
                </span>
                <span className="PayloadFormValue">
                  {eventName === "CTA"
                    ? payload?.form[key]
                    : key !== "sales_person" && payload[key]}
                </span>
              </p>
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <div
        className={cn("ChatsDialogMessage", `ChatsDialogMessage--${sender}`, {
          "ChatsDialogMessage--left": sender === "human",
          "ChatsDialogMessage--right": sender === "bot" || sender === "system",
        })}
      >
        {!justNotifications && (
          <>
            <div className="ChatsDialogMessage-data">
              {message && (
                <div className="ChatsDialogMessage-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17.927"
                    viewBox="0 0 18 17.927"
                  >
                    <path
                      id="bot_icon"
                      d="M19.1,10.084V7.407a1.8,1.8,0,0,0-1.8-1.8H11.9V4.427a1.35,1.35,0,1,0-1.8,0V5.607H4.7a1.8,1.8,0,0,0-1.8,1.8v2.7l-.065,0a.9.9,0,0,0-.835.9v1.8a.9.9,0,0,0,.9.9v4.5a1.8,1.8,0,0,0,1.8,1.8H17.3a1.8,1.8,0,0,0,1.8-1.8v-4.5a.9.9,0,0,0,.9-.9V11.062a.9.9,0,0,0-.065-.409A.937.937,0,0,0,19.1,10.084Zm-12.6.922c0-.993.6-1.8,1.35-1.8s1.35.806,1.35,1.8-.6,1.8-1.35,1.8S6.5,12,6.5,11.007Zm8.1,5.4c-.9,0-7.2,0-7.2,0v-1.8s6.3,0,7.2,0l0,1.8Zm-.448-3.6c-.745,0-1.35-.806-1.35-1.8s.6-1.8,1.35-1.8,1.35.806,1.35,1.8S14.893,12.806,14.148,12.806Z"
                      transform="translate(-2 -2.078)"
                      fill="#8777c6"
                    />
                  </svg>
                </div>
              )}
              <div className="ChatsDialogMessage-wrapper">
                <div className="ChatsDialogMessage-author-wrapper">
                  <div
                    className={cn("ChatsDialogMessage-author", {
                      sms: channel === "sms",
                      email: channel === "email",
                    })}
                  >
                    {sender === "human" ? (
                      blacklisted ? (
                        <>A customer</>
                      ) : (
                        <>{`${name} ${surname}`}</>
                      )
                    ) : sentByCrmSalesperson ? (
                      <>Dealer Representative</>
                    ) : (
                      <div className="bot-author">
                        {botName}
                        <Svg w="16" h="16" i={`${channel}-ai`} />
                      </div>
                    )}
                  </div>
                  {time && (
                    <div className="ChatsDialogMessage-meta">
                      {time}
                      {/* {sentByCrmSalesperson && " By CRM Salesperson"} */}
                    </div>
                  )}
                </div>
                {message && (
                  <div
                    className={cn("ChatsDialogMessage-text", {
                      sms: channel === "sms",
                      email: channel === "email",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  ></div>
                )}
              </div>
            </div>
            {appointmentSet && (
              <div className="ChatsDialogMessage-text ChatsDialogMessage-event">
                Appointment was set in CRM
              </div>
            )}
          </>
        )}
        {notification && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {getSalespersonsNotifiedLabel(notification?.Notifications?.length)}
          </div>
        )}
        {event && eventName === "APPOINTMENT_SET" && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {payload?.button_title || "APPOINTMENT SET"}
          </div>
        )}
        {event && eventName === "CTA" && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {payload?.button_title || "CTA"}
          </div>
        )}
        {event && eventName === "SelectCarouselCard" && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            //onClick={showNotificationRecipients}
          >
            Explore More{" "}
            <a className="ChatsDialogMessage-link">
              {event.vehicle_info.car_title}
            </a>{" "}
            clicked
          </div>
        )}
        {event && eventName.includes("DEALERSHIP TAKEOVER") && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {eventName || "DEALERSHIP TAKEOVER"}
          </div>
        )}
        {event && eventName === "CRM_REGISTRATION" && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event",
              "ChatsDialogMessage-event-clickable"
            )}
            onClick={showNotificationRecipients}
          >
            {payload?.button_title || "Lead registered in CRM"}
          </div>
        )}
        {action && (
          <div
            className={cn(
              "ChatsDialogMessage-text",
              "ChatsDialogMessage-event"
            )}
          >
            {action === "stop"
              ? "Dealer Representative has stopped AI responses for this customer."
              : "Dealer Representative has re-started AI responses for this customer."}
            {meta.timestamp && (
              <div className="ChatsDialogMessage-meta">
                {formatToFullDateString(meta.timestamp)}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={Boolean(showNotificationDialog.length || showEventModal)}
        onClose={() => {
          setShowNotificationDialog([]);
          setShowEventModal(false);
        }}
        className="ChatDialogMessage-modal"
      >
        <div className="ChatDialogMessage-modal-content_DealerChat">
          {notification && !payload && (
            <>
              <p>Salespersons notified</p>
              <div className="ChatDialogMessage-notification-list">
                {showNotificationDialog.map((notificationItem) => (
                  <div className="ChatDialogMessage-notification-list-item">
                    {notificationItem.UserId}
                    <p>{notificationItem.NotifName} notification</p>
                  </div>
                ))}
              </div>
            </>
          )}
          {payload && (
            <div>
              <p className="PayloadFormTitle">{getCorrectModalTitle()}</p>
              {renderPayload(showNotificationDialog)}
            </div>
          )}
          {event?.Details &&
          event?.Details.length > 0 &&
          !payload &&
          !Object.keys(event?.Details[0]).includes("") ? (
            <>
              <p>Event Details</p>
              <div className="ChatDialogMessage-notification-list">
                {event.Details.map((each) => {
                  return (
                    <>
                      <div className="ChatDialogMessage-notification-list-item">
                        {typeof each === "object" ? (
                          <>
                            <span class="key">{Object.keys(each)[0]}</span>:{" "}
                            {each[Object.keys(each)[0]]}
                          </>
                        ) : (
                          <>{each}</>
                        )}
                      </div>
                    </>
                  );
                })}
                <hr className="HorizontalLine" />
                <div>
                  <span style={{ fontWeight: "bold" }}>TOTAL:</span>{" "}
                  <span style={{ float: "right", fontWeight: "bold" }}>
                    ${event.TotalRepairPaidAmount}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {!notification && !payload && (
                <>
                  <p className="EventDetailsLabel">Event Details</p>
                  <div className="ChatDialogMessage-notification">
                    <p className="Margin">Service Completed Date: </p>
                    <p className="Margin">
                      {" "}
                      {event?.RepairOrderClosed?.split(" ")[0]}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChatsDialogMessageCard;
