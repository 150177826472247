import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { IOSSwitch } from "../settingsDealerChatBusinessHours/Toogle/Toggle";
import FormElement from "../../FormElement";
import "./SettingsDealershipTakeover.scss";
import useData from "../../../hook/useData";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

const SettingsDealershipTakeover = ({ type, renderedCallback = () => {} }) => {
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const {
    data: convensionData,
    status: convensionStatus,
    error: convensionError,
  } = useData(
    "getConvensionTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId]
  );

  const { data, status, error } = useData(
    "getDealershipTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId]
  );

  const [dealershipTakeoverStatus, setDealershipTakeoverStatus] = useState(
    data?.Status
  );

  const [conversionsStatus, setConversionsStatus] = useState(
    convensionData?.Status || false
  );
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { status: updatedStatus, execute: updateTakeoverStatus } = useData(
    "updateDealershipTakeoverStatus",
    {
      account_id: accountId,
      status: dealershipTakeoverStatus,
    },
    [accountId, dealershipTakeoverStatus],
    false
  );

  const {
    status: updatedConversionStatus,
    execute: updateConvensionStatus,
    error: convenstionUpdateError,
  } = useData(
    "updateConversionStatus",
    {
      account_id: accountId,
      status: conversionsStatus,
    },
    [accountId, conversionsStatus],
    false
  );

  useEffect(() => {
    setDealershipTakeoverStatus(data?.Status);
    setConversionsStatus(convensionData?.Status);

    renderedCallback();
  }, [data, convensionData]);

  const handleDealershipTakeoverStatusChange = () => {
    setDealershipTakeoverStatus((prevState) => !prevState);
  };

  const handleConversionsStatusChange = () => {
    setConversionsStatus((prevState) => !prevState);
  };

  const handleSubmitData = async () => {
    setLoading(true);
    if (type === "DEALERSHIP_TAKEOVER") {
      await updateTakeoverStatus();
    }
    if (type === "MAXIMIZE_CONVERSIONS") {
      await updateConvensionStatus();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (convenstionUpdateError !== null) {
      setShowError(true);
    }
  }, [convenstionUpdateError]);

  if (error) {
    return <div>Can't fetch Dealership Takeover Status</div>;
  }

  return (
    <>
      <Snackbar
        style={{ marginTop: "76px" }}
        open={showError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <SnackbarMessage
          elevation={2}
          variant="filled"
          onClick={() => {
            setShowError(false);
          }}
          severity={"error"}
        >
          Error updating settings. Please try in a few minutes. If the problem
          persists contact our Support team.
        </SnackbarMessage>
      </Snackbar>
      <div className="StatusContent">
        {type === "DEALERSHIP_TAKEOVER" && (
          <div className="ActionsArea">
            <div className="StatusContentLabel">Dealership Takeover</div>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={!!dealershipTakeoverStatus}
                    onChange={handleDealershipTakeoverStatusChange}
                    name="Toggle"
                  />
                }
                label={dealershipTakeoverStatus ? "Enabled" : "Disabled"}
              />
            </FormGroup>
          </div>
        )}
        {type === "MAXIMIZE_CONVERSIONS" && (
          <div className="ActionsArea">
            <div className="StatusContentLabel">Maximize Conversions</div>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={!!conversionsStatus}
                    onChange={handleConversionsStatusChange}
                    name="Toggle"
                  />
                }
                label={conversionsStatus ? "Enabled" : "Disabled"}
              />
            </FormGroup>
          </div>
        )}
      </div>
      <div className="SettingsTime-save">
        <FormElement
          onClick={handleSubmitData}
          type="submit"
          label={
            type === "DEALERSHIP_TAKEOVER"
              ? "SAVE DEALERSHIP TAKEOVER STATUS"
              : "SAVE capabilities"
          }
          disabled={loading}
        />
      </div>
    </>
  );
};

export default SettingsDealershipTakeover;
