import Svg from "../../Svg";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import {Tooltip, withStyles} from "@material-ui/core";

import "./ActionButtons.scss";
import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {
  chooseUserManagementSnackbarState,
  updateUserManagementReloadCounter
} from "../../../store/actions/userManagementActions";
import useData from "../../../hook/useData";


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: "#212230",
    backgroundColor: "#fff",
    maxWidth: '20rem',
    fontSize: '1.1rem',
    padding: '0.5rem 1rem',
    border: "1px solid #fff",
    borderRadius: "0.8rem",
  }
}))(Tooltip);


const ActionButtons = ({userId, fullName, userName, retailerName}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const { status, execute: callDelete } = useData('deleteUser', {user_id: userId || userName, retailer_name: retailerName, user_name:userName}, [], false)
  const dispatch = useDispatch();

  const openDeleteDialog = () => {
    setIsDialogOpen(true);
  }

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
  }

  const handleConfirmDelete = () => {
    callDelete()
    closeDeleteDialog();
  }

  useEffect(() => {
    if (status === 'error') {
      dispatch(chooseUserManagementSnackbarState({
        severity: "error",
        message: "Failed to delete user",
        open: true
      }));
      dispatch(updateUserManagementReloadCounter());
    }
    if (status === 'success') {
      dispatch(chooseUserManagementSnackbarState({
        severity: "success",
        message: "User deleted successfully",
        open: true
      }));
      dispatch(updateUserManagementReloadCounter());
    }
  }, [status])

  return <div className="ActionButtons">
    <form action={`/user-management/edit/${retailerName==='impel'?userId:userName}/${retailerName}`} className="actionButtons-editButtonWrapper">
      <input type="hidden" name="user_id" value={userId} />
      <HtmlTooltip title={"Edit"} placement="top">
        <button type="submit" className="ActionButtons-button">
          <Svg w="18" h="18" i="edit" />
        </button>
      </HtmlTooltip>
    </form>
    <HtmlTooltip title={"Delete"} placement="top">
      <button type="button" onClick={openDeleteDialog} className="ActionButtons-button">
        <Svg w="18" h="18" i="delete" />
      </button>
    </HtmlTooltip>
    <DeleteDialog
      isOpen={isDialogOpen}
      closeDialog={closeDeleteDialog}
      fullName={fullName}
      handleConfirmDelete={handleConfirmDelete}
    />
  </div>
};

export default ActionButtons;
