import getNumberSuffix from "./getNumberSuffix";

export const formatToDateWithSuffix = (timestamp) => {
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
    hour12: true,
  });

  const date = dateFormatter.formatToParts(timestamp);
  const suffix = getNumberSuffix(date[2].value);
  const dateString = `${date[2].value}${suffix} ${date[0].value}, ${date[4].value}, ${date[6].value}:${date[8].value}${date[10].value}`;

  return dateString;
};

export const has24HoursPassed = (lastMessageTimestamp) => {
  if (!lastMessageTimestamp) return true;
  const currentTime = Date.now();

  // Calculate the difference in milliseconds
  const difference = currentTime - lastMessageTimestamp;

  // Convert 24 hours to milliseconds (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

  // Check if the difference is greater than or equal to 24 hours
  return difference >= millisecondsIn24Hours;
};

export const timestampToString = (timestamp) => {
  if (timestamp === null || timestamp === undefined) {
    return "";
  }
  const date = new Date(timestamp);

  // Get day, month, year
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  // Get hours, minutes
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Get AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the string
  const dateString = `${day}/${month}/${year}`;
  const timeString = `${formattedHours}:${minutes} ${ampm}`;

  return `${dateString} ${timeString}`;
};

export const formatToFullDateString = (timestamp) => {
  if (!timestamp) return null;

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
    hour12: true,
  });

  const date = dateFormatter.formatToParts(timestamp);

  const dateString = `${date[0].value} ${date[2].value}, ${date[4].value} ${date[6].value}:${date[8].value} ${date[10].value}`;

  return dateString;
};

export const formatToDateString = (date) => {
  const offset_m = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset_m * 60000);

  const isoDate = date.toISOString();

  return isoDate.slice(0, isoDate.indexOf("."));
};
