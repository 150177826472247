import { formatToFullDateString } from "../../utils/formatDate";
import ConversationsButton from "./ConversationsButton/ConversationsButton";

export default [
    {
        id: "ConversationId",
        hiddenColumn: true,
    },
    {
        id: "NameSurname",
        label: "Customer",
        className: ["TableCell-formatText", "TableCell--important"],
        sortable: true,
        formatter: (row) => {
            return `${row.Name} ${row.Surname}`;
        },
    },
    {
        id: "LastHumanMessage",
        label: "Customer's Last Message",
        className: "TableCell-formatText",
        width: "25%",
    },
    {
        id: "Timestamp",
        label: "Activity Date",
        className: ["TableCell-formatNumber", "TableCell--date"],
        sortable: true,
        formatter: (row) => {
            return formatToFullDateString(row.Timestamp);
        },
    },
    {
        id: "Channel",
        label: "Channel",
        className: "TableCell-formatText",
        sortable: true,
    },
    // {
    //   id: "Status",
    //   label: "Status",
    //   className: "TableCell-formatText",
    // },
    {
        id: "PhoneEmail",
        label: "Customer Contact",
        className: "TableCell-formatText",
        formatter: (row) => {
            const phone = row.Phone || null;
            const email = row.Email || null;

            return (
                <div className="Conversations-contacts">
                    {phone && (
                        <>
                            <div className="Conversations-contactLabel">Phone:</div>
                            <div className="Conversations-contactValue">{phone}</div>
                        </>
                    )}
                    {email && (
                        <>
                            <div className="Conversations-contactLabel">Email:</div>
                            <div className="Conversations-contactValue">
                                <a href={`mailto: ${email}`}>{email}</a>
                            </div>
                        </>
                    )}
                </div>
            );
        },
    },
    {
        id: "ServiceCompleted",
        label: "Service Completed",
        className: ["TableCell-formatNumber", "TableCell--date"],
        formatter: (row) => {
            const closeDate = row.CloseDate || null;
            const price = row.ServicePrice || null;
            return <div>
                {closeDate && <div> {formatToFullDateString(row.CloseDate)} </div>}

                {price && <a> ${row.ServicePrice}.00</a>}
            </div>
        }
    },
    {
        id: "Reply",
        label: "Reply as Assistant",
        formatter: (row) => {
            return <ConversationsButton cid={row.ConversationId} />;
        },
    },
];
