import {
  CHOOSE_USER_MANAGEMENT_TABLE_PAGE,
  CHOOSE_USER_MANAGEMENT_QUERY,
  CHOOSE_USER_MANAGEMENT_SNACKBAR_STATE,
  UPDATE_USER_MANAGEMENT_RELOAD_COUNTER
} from "../actionTypes/userManagementTypes";

const initialState = {
  usersPage: 1,
  usersSearch: "",
  snackbarState: {
    severity: "",
    message: "",
    open: false
  },
  reload: 1
};

const conversation = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_USER_MANAGEMENT_TABLE_PAGE:
      return {
        ...state,
        usersPage: action.payload,
      };

    case CHOOSE_USER_MANAGEMENT_QUERY:
      return {
        ...state,
        usersSearch: action.payload,
      };

    case CHOOSE_USER_MANAGEMENT_SNACKBAR_STATE:
      return {
        ...state,
        snackbarState: action.payload
      };

    case UPDATE_USER_MANAGEMENT_RELOAD_COUNTER:
      return {
        ...state,
        reload: state.reload + 1
      }

    default:
      return state;
  }
};

export default conversation;
