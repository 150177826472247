import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import "./Switch.scss";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 50, // 42
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    color: "#265b9f",
    "&$checked": {
      transform: "translateX(24px)",
      color: "#265b9f",
      "& + $track": {
        backgroundColor: "#ebf4f7",
        opacity: 1,
        border: "1px solid #265b9f",
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    border: `1px solid #ebf4f7`,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #265b9f`,
    backgroundColor: "#ebf4f7",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, defaultChecked, handleToggleChange = () => {}, ...props }) => {
  return (
    <Switch
      checked={defaultChecked}
      onChange={handleToggleChange}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
