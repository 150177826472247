import SettingsCMS from "./SettingsCMS/SettingsCMS";
import SettingsCMSSignature from "./SettingsDealerChatSignature/SettingsSignature";
import SettingsCMSBusinessHours from "./settingsDealerChatBusinessHours/SettingsCMSBusinessHours";
import SettingsNotifications from "./SettingsDealerChatNotifications/SettingsNotifications";
import SettingsDealershipTakeover from "./SettingsDealershipTakeover";

const settingsCMSBlocks = [
  {
    index: 1,
    id: "01",
    resourceName: "Settings:Signature",
    title: "Dealer Details Settings",
    description:
      "Give your AI assistant a name and position, and enter other essential information about your dealership. These details will be used to create a personalized signature, which will appear on messages sent by the AI assistant.",
    component: <SettingsCMSSignature />,
  },
  {
    index: 2,
    id: "02",
    resourceName: "Settings:WorkingHours",
    title: "Work Hours Settings",
    description:
      "Set your dealership's regular working hours and special business hours.",
    component: <SettingsCMSBusinessHours />,
  },
  {
    index: 3,
    id: "03",
    resourceName: "Settings:DealershipTakeover",
    title: "Dealership Takeover",
    description: "Enable Dealership Takeover during Working Hours.",
    component: <SettingsDealershipTakeover type="DEALERSHIP_TAKEOVER" />,
  },
  {
    index: 4,
    id: "04",
    resourceName: "Settings:WorkingHours",
    title: "Capability Manager",
    description:
      "Configure and manage various capabilities and integrations to tailor the AI assistant’s functionality to your dealership's needs.",
    component: <SettingsDealershipTakeover type="MAXIMIZE_CONVERSIONS" />,
  },
  {
    index: 5,
    id: "05",
    resourceName: "Settings:Notifications",
    title: "Email & SMS Notification Settings",
    description:
      "Set up personnel to receive important AI notifications. Choose from text only, email only or text and email notifications, and select which events each rep will receive alerts for.",
    component: <SettingsNotifications />,
  },
  {
    index: 6,
    id: "06",
    resourceName: "Settings:WorkingHours",
    title: "Content Management (CMS)",
    description:
      "Provide the information AI will utilize to generate corresponding responses. For each topic/question provide all necessary textual information, including links if applicable. Please note: if not information is provided on a certain topic, AI won't be able to generate its reply.",
    component: <SettingsCMS />,
  },
];

export default settingsCMSBlocks;
