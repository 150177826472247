import "./ChatContactForm.scss";
import FormElement from "../../FormElement/FormElement";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useData from "../../../hook/useData";
import Loader from "../../Loader/Loader";
import {
  isChatFormOpen,
  refetchChat,
} from "../../../store/actions/conversationDealerChatActions";

const options = [
  { id: 0, label: "Text" },
  { id: 1, label: "Call" },
  { id: 2, label: "Email" },
];

const ChatContactForm = ({ setShowSnackbar }) => {
  const cid = useSelector((state) => state.conversation.conversationsId);

  const {
    data: generalInfoDataFetched,
    status: generalInfoStatus,
    execute: fetchGeneralInfoData,
  } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid],
    true
  );

  const getDefaultPrefferedForm = () => {
    if (generalInfoDataFetched?.Channel !== "") {
      if (generalInfoDataFetched?.Channel === "Text") {
        return options[0];
      }
      if (generalInfoDataFetched?.Channel === "Call") {
        return options[1];
      }
    }
    return options[2];
  };
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [prefferedMethod, setPrefferedMethod] = useState(options[0]);

  useEffect(() => {
    const splittedFullname = generalInfoDataFetched?.FullName?.split(" ");
    const splittedFirstname =
      splittedFullname && splittedFullname[0] ? splittedFullname[0] : "";
    const splittedLastname =
      splittedFullname && splittedFullname[1] ? splittedFullname[1] : "";
    if (generalInfoDataFetched) {
      setFirstname(splittedFirstname);
      setLastname(splittedLastname);
      setEmail(
        generalInfoDataFetched?.Email ? generalInfoDataFetched.Email : ""
      );
      setPhoneNumber(
        generalInfoDataFetched?.Phone ? generalInfoDataFetched.Phone : ""
      );
      setPrefferedMethod(getDefaultPrefferedForm);
    }
  }, [generalInfoDataFetched]);

  const [validationErrors, setValidationErrors] = useState({});
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const dispatch = useDispatch();
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const validatePhonenumber = (phoneNumber) => {
    return phoneNumber.length >= 10;
  };
  const { cid: urlCid } = useParams();

  const {
    status: createContactFormStatus,
    execute: createContactForm,
    data: createContactFormData,
  } = useData(
    "createContactForm",
    {
      account_id: accountId,
      user_id: urlCid,
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone_number: phoneNumber,
      preferred_channel: [
        {
          value: prefferedMethod?.label,
          selected: true,
        },
      ],
      form_type: "ContactForm",
    },
    [
      accountId,
      urlCid,
      firstname,
      lastname,
      email,
      phoneNumber,
      prefferedMethod,
    ],
    false
  );

  const validateForm = async () => {
    const errors = {};
    if (firstname === "" && lastname === "") {
      errors["firstname"] = "Firstname or lastname is required!";
      errors["lastname"] = "Firstname or lastname is required!";
    }
    if (email === "" && phoneNumber === "") {
      errors["email"] = "Email or phone number is required!";
      errors["phonenumber"] = "Email or phone number is required!";
    }
    if (email !== "" && !validateEmail(email)) {
      errors["email"] = "Incorrect email";
    }
    if (phoneNumber !== "" && !validatePhonenumber(phoneNumber)) {
      errors["phonenumber"] = "Phone number should be at least 10 digits!";
    }
    if (
      (prefferedMethod?.label === "Text" ||
        prefferedMethod?.label === "Call") &&
      phoneNumber === ""
    ) {
      errors["phonenumber"] = "Phone number is required!";
    }
    if (prefferedMethod?.label === "Email" && email === "") {
      errors["email"] = "Email is required!";
    }
    setValidationErrors(errors);
    if (Object.keys(errors)?.length === 0) {
      await createContactForm();
      const timer = setTimeout(async () => {
        dispatch(refetchChat(true));
      }, 2000);
      return () => clearTimeout(timer);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateForm();
  };

  useEffect(() => {
    if (createContactFormStatus === "success") {
      setShowSnackbar(true);
      dispatch(isChatFormOpen(false));
    }
  }, [createContactFormStatus]);

  const checkIfAllFieldsEmpty = () => {
    return (
      phoneNumber === "" && firstname === "" && lastname === "" && email === ""
    );
  };

  return (
    <div className="Contact_layout">
      {createContactFormStatus === "pending" ||
      generalInfoStatus === "pending" ? (
        <div className="LoadingArea">
          <Loader />
        </div>
      ) : (
        <>
          <h1 className="ContactTitle">Contact Form</h1>
          <p className="ContactDescription">
            Fill out the form and out team will get back to you soon!
          </p>
          <form className="Contact_form">
            <FormElement
              type="textfield"
              label="First name"
              id="first_name"
              name="first name"
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              error={!!validationErrors["firstname"]}
              errorMessage={validationErrors["firstname"]}
            />
            <FormElement
              type="textfield"
              label="Last name"
              id="last_name"
              name="last name"
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              error={!!validationErrors["lastname"]}
              errorMessage={validationErrors["lastname"]}
            />
            <FormElement
              type="textfield"
              label="Email"
              id="email"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="user@domain.com"
              error={!!validationErrors["email"]}
              errorMessage={validationErrors["email"]}
            />
            <FormElement
              type="numfield"
              label="phone number"
              id="phonenumber"
              name="phonenumber"
              value={phoneNumber}
              onChange={(event) => {
                if (event.target.value?.length <= 11) {
                  setPhoneNumber(event.target.value);
                }
              }}
              placeholder="(123) 456-7890"
              error={!!validationErrors["phonenumber"]}
              errorMessage={validationErrors["phonenumber"]}
            />
            <FormElement
              type="select"
              label={"preferred method of contact"}
              defaultValue={prefferedMethod}
              values={options}
              onChange={(value) => setPrefferedMethod(value)}
            />
            <FormElement
              type="submit"
              style={{
                borderRadius: "7px",
                backgroundColor: "white",
                border: "1px solid #D62B56",
                color: "#D62B56",
                fontSize: "10px",
                height: "40px",
                // marginTop: "40px",
              }}
              label="Submit"
              id="submitButton"
              name="submitButton"
              value="Submit message"
              disabled={false}
              onClick={(event) => handleSubmit(event)}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default ChatContactForm;
