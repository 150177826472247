import "./index.scss";

const CsvRow = ({ row }) => {
  return (
    <div className="csv-data-rows">
      {Object.keys(row).map((col) => {
        if (!["Valid", "Reason"].includes(col))
          return <span className="csv-data-row">{row[col]}</span>;
      })}
      <span className="csv-data-row-status">{row.Reason}</span>
    </div>
  );
};

export default CsvRow;
