import FormElement from "../FormElement";

import "./FormRadios.scss";

const FormRadios = ({ values, value, id, ...rest }) => {
  delete rest.type;

  const radios =
    values &&
    values.map((radio) => {
      return (
        <FormElement
          key={radio.id}
          type="radio"
          label={radio.label}
          id={`${id}${radio.id}`}
          checked={value ? radio.value === value : false}
          value={radio.value}
          {...rest}
        />
      );
    });

  return <div className="FormRadios">{radios}</div>;
};

export default FormRadios;
