import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import cn from "classnames";

import {
  chooseConversationsMode,
  isChatFormOpen,
} from "../../../store/actions/conversationDealerChatActions";

import "./ChatsListCard.scss";
import {
  formatToFullDateString,
  timestampToString,
} from "../../../utils/formatDate";
import { useEffect, useState } from "react";

const ChatsListCard = ({ data, cid, takeover }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleActiveChat = () => {
    dispatch(chooseConversationsMode("regular"));
    history.push(`${data.ConversationId}`);
  };

  console.log("data in CHATS LIST CARD IS: ", data);

  const takeoverStatus =
    data.TakeoverEventType &&
    [
      "EVENT#DEALERSHIP_TAKEOVER_IN_PROGRESS",
      "EVENT#DEALERSHIP_TAKEOVER_COMPLETED",
      "IN_PROGRESS",
      "COMPLETED",
    ].includes(data.TakeoverEventType)
      ? data.TakeoverEventType === "EVENT#DEALERSHIP_TAKEOVER_IN_PROGRESS" ||
        data.TakeoverEventType === "IN_PROGRESS"
        ? "IN PROGRESS"
        : "COMPLETED"
      : null;

  return (
    <div
      className={cn("ChatsListCard", {
        "is-active": data.ConversationId === cid,
      })}
      onClick={handleActiveChat}
    >
      <h2 className="ChatsListCard-name">
        {data.FullName || `${data.Name} ${data.Surname}`}
      </h2>
      <div className="ChatsListCard-table">
        <div className="ChatsListCard-label">Channel:</div>
        <div className="ChatsListCard-value ChatsListCard-value--channel">
          {data.Channel}
        </div>
        <div className="ChatsListCard-label">Contact:</div>
        <div className="ChatsListCard-value ChatsListCard-value--contact">
          {!data.Phone ? (
            <span title={data.Email}>{data.Email}</span>
          ) : (
            data.Phone
          )}
        </div>
        <div className="ChatsListCard-label">Source:</div>
        <div className="ChatsListCard-value ChatsListCard-value--source">
          {data.LeadSource}
        </div>
        {takeover && (
          <>
            <div className="ChatsListCard-label">Sales Person:</div>
            <div className="ChatsListCard-value ChatsListCard-value--source">
              {data.SalesPerson}
            </div>
            <div className="ChatsListCard-label">LAST MESSAGE RECEIVED:</div>
            <div className="ChatsListCard-value ChatsListCard-value--source">
              {timestampToString(
                data.Timestamp ? data.Timestamp : data.LastHumanTimestamp
              )}
            </div>
            {takeoverStatus != null && (
              <>
                <div
                  className="ChatsListCard-label"
                  style={{ marginTop: "1.6rem" }}
                >
                  Takeover Status:
                </div>
                <div
                  className={cn("ChatsListCard-value", {
                    "ChatsListCard-value--takeover-status-complete":
                      takeoverStatus === "COMPLETED",
                    "ChatsListCard-value--takeover-status-progress":
                      takeoverStatus === "IN PROGRESS",
                  })}
                >
                  <p className="ChatsListCard-value--progress">
                    {takeoverStatus}
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatsListCard;
