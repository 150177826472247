import Layout from "../../components/Layout/Layout";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import WidgetSource from "../../components/WidgetSource/WidgetSource";
import DealerChatConversation from "../../components/DealerChatConversation/Chats";
import "./PageDealerChatConversation.scss";


const widgets = {
  primary: [
    <DateRangePicker type="date" key="widgetDateRange" label="Date Filter" />,
  ],
  secondary: [<WidgetSource key="widgetSource" />],
};

const PageDealerChatConversation = () => {
  return (
    <Layout
      content={<DealerChatConversation />}
      widgets={widgets}
      className="Layout--pageConversation"
    />
  );
};

export default PageDealerChatConversation;
