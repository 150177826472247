import { useContext, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SettingsCMSForm.scss";
import useDebounce from "../../../../hook/useDebounce";
import FormElement from "../../../FormElement/FormElement";
import { StoreContextSettingsCMSPage } from "../SettingsCMS";
import useData from "../../../../hook/useData";

const SettingsCMSForm = ({ answer, questionId, topicId}) => {
  const { stateCMS, dispatchCMS } = useContext(StoreContextSettingsCMSPage);
  const [state, setState] = useState({
    "question_information": {
      "topic_id" : topicId,
      "question_id": questionId,
      "answer": answer || ""
      }
  });
  const [debounce, setDebounce] = useState({});
  const [answerValue, setAnswerValue] = useState(answer || "");
  const debouncedState = useDebounce(debounce, 300);

  const {
    data: updatedData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("updateAnswer", state, [state], false);

  const answerHandler = (value) => {
    setAnswerValue(value);
    setState({
      "question_information": {
        "topic_id" : topicId,
        "question_id": questionId,
        "answer": value
        }
    })
  }

  useEffect(() => {
    if(updatedData?.question_information?.question_id){
      dispatchCMS({ type: "changeAnswer", topicId, questionId, value: answerValue });
    }
  },[updatedData])

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateExecute();
  };

  return (
    <form className="SettingsCMSForm" onSubmit={handleFormSubmit}>
      <div className="SettingsCMSForm-frame"></div>
      <div style={{ marginBottom: "30px" }}>
        <textarea 
          className="SettingsCMSForm-textarea" 
          value={answerValue}
          onChange={(e) => answerHandler(e.target.value)}
          placeholder="Please provide the information, including URLs if applicable, about financing process in your dealership in order for AI to utilize the details to generate responses"
        />
      </div>
      <div className="SettingsCMSForm-save">
        <FormElement type="submit" label="SAVE CHANGES" disabled={!answerValue || updateStatus === 'pending'} />
      </div>
    </form>
  );
};

export default SettingsCMSForm;
