export default [
  {
    type: "textfield",
    label: "First name:",
    id: "addPersonFirstName",
    name: "FirstName",
  },
  {
    type: "textfield",
    label: "Last name:",
    id: "addPersonLastName",
    name: "LastName",
  },
  {
    type: "textfield",
    label: "Email address:",
    id: "addPersonEmail",
    name: "Email",
  },
  {
    type: "textfield",
    label: "Main Phone Number:",
    id: "addPersonMainPhone",
    name: "MainPhone",
  },
  {
    type: "textfield",
    label: "Secondary Phone Number:",
    id: "addPersonSecondaryPhone",
    name: "SecondaryPhone",
    hidden: true,
  },
  {
    type: "textfield",
    label: "ID:",
    id: "addPersonID",
    name: "UserId",
    readOnly: true,
  },
];
