import cn from "classnames";

import Loader from "../../Loader";

import { formatValue } from "../../../utils/formatValue";
import { getValue } from "../helpers/getValue";

import "./TileSquare.scss";
import QuestionMark from "../QuestionMark/QuestionMark";

const TileSquare = ({
  isMain,
  number,
  label,
  description,
  status,
  error,
  dataPrimary,
  keyPrimary,
  dataSecondary,
  keySecondary,
  currency,
  ...rest
}) => {
  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    const valuePrimary = formatValue(getValue(dataPrimary, keyPrimary));
    let valueSecondary = getValue(dataSecondary, keySecondary);

    let symbol = "%";
    if (currency === "USD") {
      if (valueSecondary) valueSecondary = formatValue(valueSecondary);
      symbol = "$";
    }
    // let valueSecondary = getValue(dataSecondary, keySecondary);

    return (
      <div className="TileSquare-content">
        <div className="TileSquare-label">
          {label} <QuestionMark definition={description} />
        </div>
        {valuePrimary && (
          <div className="TileSquare-primary">{valuePrimary}</div>
        )}
        {valueSecondary ? (
          <div className="TileSquare-secondary">
            {currency === "USD"
              ? symbol + valueSecondary
              : valueSecondary + symbol}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn("TileSquare", {
        "TileSquare--main": isMain,
        "TileSquare--clickable": rest.onClick,
      })}
      data-number={number}
      {...rest}
    >
      {render()}
    </div>
  );
};

export default TileSquare;
