import { FormControlLabel, FormGroup, InputAdornment, makeStyles, Slider, TextField, withStyles } from "@material-ui/core";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import DatePicker from "../../DatePicker/DatePicker";
import Svg from "../../../../Svg/Svg";
import { IOSSwitch } from "../../Toogle/Toggle";
import FormElement from "../../../../FormElement/FormElement";
import { useEffect, useState } from "react";
import moment from "moment";
import "./BusinesshoursForm.scss";

const useSliderStyles = makeStyles({
    root: {
      width: 392,
      color: 'var(--s90)',
      height: 8,
      padding: 0,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      marginTop: -7,
      marginLeft: -7,
      border: '2px solid var(--s90)',
      boxShadow: '#ebebeb 0 2px 2px',
      '&:focus, &:hover, &$active': {
        boxShadow: '#ccc 0 2px 20px 5px',
      }
    },
    track: {
      height: 8,
    },
    rail: {
      height: 8,
      opacity: 0.5,
      backgroundColor: '#ccc',
      borderRadius: 100
    },
    valueLabel: {
      left: 'calc(-50%)',
  
    },
});

const StyledValueLabel = withStyles({
    circle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 70, // Height and Width should be equal
      height: 10,
      backgroundColor: "transparent",
      borderRadius: 0,
      marginTop: 20,
      marginLeft: -12
    },
    label: {
      color: "black"
    }
})(ValueLabel);

const BusinesshoursForm = ({ hoursArr, setHoursArr }) => {
    const sliderClasses = useSliderStyles();

    const [toggle, setToggle] = useState(true);
    const [sliderValue, setSliderValue] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekdays, setWeekdays] = useState(false);
    const [isOpen, setIsOpen] = useState(true);


  const toggleHandleChange = (name, value) => {
    const workHoursObj = value ? { start_time: 540, end_time: 1080 } : null
    const arr = hoursArr.map(el => el.name === name ? { ...el, open: value, work_hours: workHoursObj } : { ...el });

    setHoursArr([ ...arr ]);
  };

  const convertTime = (obj) => {
    if(obj){
        return [ obj.start_time, obj.end_time ];
    }else{
        return [540, 1080];
    }
  }

  const sliderHandleChange = (event, newValue, name) => {
    const arr = hoursArr.map(el => {
        if(el.name === name){
            return { ...el, work_hours: { ...el.work_hours, start_time: newValue[0], end_time: newValue[1] } }
        }

        return { ...el }
    });
    
    setHoursArr([ ...arr ]);
  };

  const weekDaysHandler = () => {
    const weekArr = ['Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const noWeekArr = ['Saturday', 'Sunday'];

    if(!weekdays){
        const mondayWorkHoursObj = hoursArr.find(el => el.name === 'Monday').work_hours;
        //console.log(hoursArr.find(el => el.name === 'Monday'));
        const arr = hoursArr.map(el => {
            if(weekArr.includes(el.name)){
                return { ...el, open: true, work_hours: { ...el.work_hours, start_time: mondayWorkHoursObj.start_time, end_time: mondayWorkHoursObj.end_time } }
            }
            if(noWeekArr.includes(el.name)){
                return { ...el, open: false, work_hours: { ...el.work_hours, start_time: 540, end_time: 1080 } }
            }
    
            return { ...el }
        });
        
        setHoursArr([ ...arr ]);
    }
    setWeekdays(!weekdays);
  }

    return(
        <>
            <div className="form">
                {
                    !!hoursArr.length &&
                    hoursArr.map((el, index) => (
                        <div key={index} className="elements">
                            <div className="form_day">
                                <FormElement
                                    type="textfield"
                                    label={el.name}
                                    id={el.name}
                                    name={el.name}
                                    readOnly
                                    labelHidden={true}
                                    value={el.name}
                                />
                            </div>
                            <div className="form_isOpen">
                                <FormGroup>
                                    <FormControlLabel
                                        className={"switchFormControlLabel"}
                                        control={<IOSSwitch checked={el.open} onChange={() => toggleHandleChange(el.name, !el.open)} name="Toggle" />}
                                        label={el.open ? "Open" : "close"}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form_slider">
                                <Slider
                                    classes={sliderClasses}
                                    value={convertTime(el.work_hours)}
                                    onChange={(e,value) => sliderHandleChange(e, value, el.name)}
                                    valueLabelDisplay="on"
                                    aria-labelledby="range-slider"
                                    ValueLabelComponent={StyledValueLabel}
                                    valueLabelFormat={value => <div style={{ fontSize: "14px" }}>{moment(`${Math.floor(value / 60)}.${value % 60}`, ["HH.mm"]).format("hh:mm a")}</div>}
                                    step={30}
                                    min={0}
                                    max={1439}
                                    style={{ display: !el.open ? "none": "block" }}
                                />
                            </div>
                        </div> 
                    ))
                }
            </div>
            <div>
                <FormElement
                    type="checkbox"
                    label="Apply to weekdays"
                    id={`weekdays`}
                    name={`weekdays`}
                    checked={weekdays}
                    onChange={() => weekDaysHandler()}
                    disabled={false}
                />
            </div>
        </>
    )
}

export default BusinesshoursForm;