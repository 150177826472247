import Loader from "../../Loader";

import { formatValue } from "../../../utils/formatValue";
import { getValue } from "../helpers/getValue";

import "./TileSimple.scss";
import QuestionMark from "../QuestionMark/QuestionMark"

const TileSimple = ({ 
  label,
  description,
  status,
  error,
  dataPrimary,
  keyPrimary
}) => {
  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init") {
      return "No data loaded yet";
    }

    if (status === "pending") {
      return <Loader />;
    }

    const value = formatValue(getValue(dataPrimary, keyPrimary));

    return (
      <>
        <div className="TileSimple-label">
          {label} <QuestionMark definition={description} />
        </div>
        {dataPrimary && <div className="TileSimple-primary">{value}</div>}
      </>
    );
  };

  return <div className="TileSimple">{render()}</div>;
};

export default TileSimple;
